const useDebounce = (func: { apply: (arg0: undefined, arg1: any[]) => void }, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default useDebounce;
