import React, { Fragment, useEffect } from 'react';
import Icon from './Icon';
import Button from './inputs/Button';
import { useHistory } from 'react-router-dom';

const Finished = ({
  finished,
  callback = () => {},
  done,
  title,
  action = {},
  backButtonAction = null,
  reloadManagedAreas,
  handleCompletedOnlineAnnotatedTrees = () => {},
  handleCompletedSemanticTrees = () => {},
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!finished) return null;

    handleCompletedOnlineAnnotatedTrees();
    handleCompletedSemanticTrees();
  }, [finished]);

  const _handleBack = async () => {
    await reloadManagedAreas();

    history.push(`/validation`);
  };

  return (
    <div className='finished-wrapper'>
      <Icon icon='rtms' />
      {/*       <h1>{ title || (!done ? 'Process finished' : 'TSE job started') }</h1>
      {
        !done && <Button primary label='Start TSE job' onClick={callback} { ...action } />
      } */}
      <Button label='Back to the map' onClick={backButtonAction || _handleBack} />
    </div>
  );
};

const FinishedWrapper = ({ children, finished, onStart, done, ...props }) => {
  return (
    <Fragment>
      {finished && <Finished {...props} callback={onStart} done={done} finished={finished} />}
      {children}
    </Fragment>
  );
};

export default FinishedWrapper;
