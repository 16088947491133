import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconElementParams, IconParams } from '../../@types/Icon';
import Approve from './Approve';
import Archive from './Archive';
import BadSegmentation from './BadSegmentation';
import Bell from './Bell';
import Brush from './Brush';
import Checkmark from './Checkmark';
import Cogwheel from './Cogwheel';
import Deselect from './Deselect';
import Edit from './Edit';
import EmptyCircle from './EmptyCircle';
import ExclamationMark from './ExclamationMark';
import Expand from './Expand';
import GH from './GH';
import Hamburger from './Hamburger';
import Info from './Info';
import Invalid from './Invalid';
import Layout from './Layout';
import Map from './Map';
import MapView from './MapView';
import MemoryClear from './MemoryClear';
import MemoryRead from './MemoryRead';
import MemoryStore from './MemoryStore';
import Minus from './Minus';
import Nparks from './Nparks';
import Panorama from './Panorama';
import Pin from './Pin';
import Plus from './Plus';
import PointCloud360 from './PointCloud360';
import Question from './Question';
import QuestionMark from './QuestionMark';
import RTMS from './RTMS';
import RemoveX from './RemoveX';
import Resume from './Resume';
import Search from './Search';
import SectionControl from './SectionControl';
import SectionView from './SectionView';
import Singapore from './Singapore';
import SortDown from './SortDown';
import SortUp from './SortUp';
import TickFill from './TickFill';
import TickGrey from './TickGrey';
import TicketCheck from './TicketCheck';
import ToggleToolbar from './ToggleToolbar';
import Tools from './Tools';
import TopDown from './TopDown';
import Tree from './Tree';
import Valid from './Valid';
import Visibility from './Visibility';
import Voxel from './Voxel';
import WarningRed from './WarningRed';
import XCircle from './XCircle';

const icons: { [key: string]: (params: IconElementParams) => JSX.Element } = {
  nparks: Nparks,
  rtms: RTMS,
  gh: GH,
  singapore: Singapore,
  'semantics-editor': Edit,
  question: Question,
  info: Info,
  pointcloud_360: PointCloud360,
  panorama: Panorama,
  top_down: TopDown,
  mapView: MapView,
  map: Map,
  voxel: Voxel,
  pin: Pin,
  layout: Layout,
  tools: Tools,
  questionMark: QuestionMark,
  checkmark: Checkmark,
  hamburger: Hamburger,
  treeIcon: Tree,
  toggleToolbar: ToggleToolbar,
  bell: Bell,
  'memory-store': MemoryStore,
  'memory-read': MemoryRead,
  'memory-clear': MemoryClear,
  xCircle: XCircle,
  'empty-circle': EmptyCircle,
  'tick-fill': TickFill,
  'tick-grey': TickGrey,
  'warning-red': WarningRed,
  valid: Valid,
  invalid: Invalid,
  expand: Expand,
  sortUp: SortUp,
  sortDown: SortDown,
  search: Search,
  openEye: Visibility,
  removeX: RemoveX,
  ticketCheck: TicketCheck,
  brush: Brush,
  plusWithCircle: Plus,
  minusWithCircle: Minus,
  cogwheel: Cogwheel,
  sectionView: SectionView,
  exclamation: ExclamationMark,
  resume: Resume,
  archive: Archive,
  'section-control': SectionControl,
  approve: Approve,
  deselect: Deselect,
  'bad-segmentation': BadSegmentation,
};

export type IconComponentKey = keyof typeof icons;

const Icon = ({ icon, color, className, style, size }: IconParams) => {
  if (!icon) return null;
  const Comp = (icon as IconComponentKey) in icons ? icons[icon as IconComponentKey] : null;
  if (Comp) return <Comp {...style} />;
  return <FontAwesomeIcon className={className} icon={icon as IconProp} color={color} size={size} />;
};

export default Icon;
