import { useState, useEffect } from "react";
import useStore from "../store/useStore";

export const useTreeIndex = (
  selectedTreeId,
  relevantTrees,
  allTrees,
  options = { allowSelectingValidatedTrees: false }
) => {
  const [currentIndex, setCurrentIndex] = useState(null);

  const {setTree} = useStore((s) => s.tree.actions)

  let currentTree;

  if (selectedTreeId && currentIndex === null) {
    // Manual selection
    currentTree = getManuallySelectedTree(
      options,
      allTrees,
      selectedTreeId,
      relevantTrees
    );
  }

  if (!currentTree) {
    if (relevantTrees?.length > 0) {
      // Next/first relevant tree
      currentTree = relevantTrees[currentIndex] ?? relevantTrees[0];
    } else {
      currentTree = allTrees[0];
    }
  }

  useEffect(() => {
    if (currentIndex === null) {
      const todoIndex = relevantTrees.findIndex(
        (tree) => tree.id === currentTree?.id
      );

      if (todoIndex >= 0) {
        setCurrentIndex(todoIndex);
      }
    }
  }, [currentIndex, currentTree?.id, relevantTrees]);

  const selectTree = (selected) => {
    const index = relevantTrees.findIndex(
      (tree) => Number(tree.id) === Number(selected.id)
    );

    if (index > -1) {
      setCurrentIndex(index);
      setTree(relevantTrees[index])
    }
  };

  const moveToNeighbour = (direction = 1) => {
    const index =
      relevantTrees.findIndex((tree) => tree.id === currentTree.id) ??
      currentIndex;

    const nextIndex =
      index !== null
        ? (index + direction + relevantTrees.length) % relevantTrees.length
        : 0;
    setCurrentIndex(nextIndex);
    setTree(relevantTrees[nextIndex])

    return { index, nextIndex };
  };

  return { currentTree, setCurrentIndex, selectTree, moveToNeighbour };
};

function getManuallySelectedTree(
  options,
  allTrees,
  selectedTreeId,
  relevantTrees
) {
  let currentTree = null;

  if (options.allowSelectingValidatedTrees) {
    currentTree = allTrees.find((tree) => tree.id === selectedTreeId);
  } else {
    currentTree = relevantTrees.find((tree) => tree.id === selectedTreeId);
  }

  return currentTree;
}
