import { CylinderGeometry, Mesh, MeshBasicMaterial } from 'three';

export class CapturePoint extends Mesh {
  public material: any;

  constructor(cp: any) {
    const g = new CylinderGeometry(.3, .3, .02, 30);

    const m = new MeshBasicMaterial({
      color: 'white',
      opacity: 0.8,
      transparent: true,
    });

    super(g, m);
    this.material = m;

    this.rotateX(Math.PI / 2);
    this.userData = { type: 'capture-point', data: cp };
  }

  public markHover() {
    this.material.opacity = 1;
    this.material.needsUpdate = true;
  }

  public restoreHover() {
    this.material.opacity = 0.8;
    this.material.needsUpdate = true;
  }
}
