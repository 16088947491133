import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Skeleton, LoaderWrapper } from '../layout';
import { TextInput, Button } from '../components/inputs';
import { useUser } from '../providers/user';
import appConfig from '../config';

const LoginPage = ({ history, location }) => {
  const { token, setApiToken, loginUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const isAd = !['1', 'true'].includes(appConfig.auth.disableAD);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    // TODO: handle multiple accounts ?
    
    if (accounts.length > 0 && isAd) {
      instance.acquireTokenSilent({
        account: accounts[0],
        scopes: [appConfig.auth.scope]
      }).then((response) => {
        if (response.accessToken) {
          setApiToken(response.accessToken);
          loginUser(response.accessToken);
          history.push('/');
        }
        return null;
      }).catch(console.error);
    }
  }, [accounts]);

  
  const handleLogin = (loginType) => {
    // setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup();
    } else if (loginType === "redirect") {
      instance.handleRedirectPromise().then(console.log).catch(console.error)
      instance.loginRedirect();
    }
  }

  const _handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${appConfig.server.endpoint}/login`, { 
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        }),
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await res.text()

      if (res.status === 500) {
        setPassword('');
        setError('Internal server error');
      }
      else if (res.status !== 200 || !data) {
        setPassword('');
        setError('Wrong username or password.')
      } else {
  
        setApiToken(data);
        history.push('/');
      }
    } catch (e) {
      console.error(e);
    }
  }

  const _handlePassword = (val) => {
    setPassword(val);
    setError('');
  }
  return (
    <Skeleton header={{
      title: 'Login'
    }}>
      <LoaderWrapper loading={location.hash}>
        <div className="login-wrapper">
          {/* <video muted autoPlay src="/login-background.mp4" loop></video> */}
          <form onSubmit={_handleSubmit}>
            {isAd ? 
              <Button primary onClick={() => handleLogin("redirect")} key="loginRedirect" label='Sign in using Azure AD'></Button> : 
              (<><TextInput value={email} onPureChange={setEmail} leadingIcon='user' label='Email' autoFocus />
              <TextInput value={password} error={error} onPureChange={_handlePassword} leadingIcon='key' type='password' label='Password' />
              <Button label='Login' primary /></>)}
            {/* <Button primary onClick={() => handleLogin("popup")} key="loginPopup" label='Sign in using Popup (AD)'></Button> */}
          </form>
        </div>
      </LoaderWrapper>
    </Skeleton>
  )
}

export default withRouter(LoginPage);