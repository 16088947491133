import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { memo } from 'react';
import Icon from '../Icon';

type TitleProps = {
  title: string;
  onSort: () => void;
  id: string;
  sort: { [key: string]: any };
  sortable?: boolean;
  subTitle?: boolean;
  subTitleAction?: (data: any) => void;
  data: any;
  isNewTableVersion: boolean;
};

const Title = memo(({ title, onSort, id, sort, sortable, subTitle, subTitleAction, data, isNewTableVersion }: TitleProps) => {
  const _handleAction = () => sortable && onSort();
  const _handleSubTitleAction: React.MouseEventHandler<HTMLDivElement> = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    subTitleAction && subTitleAction(data);
  };

  return (
    <th onClick={_handleAction}>
      <div className='title-wrapper'>
        {title}
        <div
          className={`sort-wrapper`}
          style={{ flexDirection: isNewTableVersion ? 'column' : 'row', gap: isNewTableVersion ? '4px' : '' }}
        >
          {isNewTableVersion && sortable ? (
            <>
              <Icon icon='sortUp' style={{ color: !!sort?.[id] && sort[id] > 0 ? '#FFFFFF' : '#6B6B6B' }} />
              <Icon icon='sortDown' style={{ color: !!sort?.[id] && sort[id] < 0 ? '#FFFFFF' : '#6B6B6B' }} />
            </>
          ) : (
            sort?.[id] && <Icon icon={sort?.[id] > 0 ? ('sort-up' as unknown as IconDefinition) : ('sort-down' as unknown as IconDefinition)} />
          )}

          {subTitle && (
            <div className='title-wrapper second-title-action' onClick={_handleSubTitleAction}>
              💾
            </div>
          )}
        </div>
      </div>
    </th>
  );
});

export default Title;
