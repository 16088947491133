import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { getPointClassLabel, getRgbColorByClassification } from '../../providers/config';

class PointcloudViewer extends PureComponent {
    constructor(props) {
        super(props);

        this.scene = new THREE.Scene();
        this.scene.up.set(0, 0, 1);
        this.scene.background = new THREE.Color(this.props.background || 0x000000);

        this.sphere = new THREE.Mesh(
            new THREE.SphereGeometry(PointcloudViewer.sphereSize, 32, 32),
            new THREE.MeshBasicMaterial({ color: PointcloudViewer.sphereColor })
        );

        this.sphere.visible = false;

        this.scene.add(this.sphere);

        window.addEventListener('resize', () => this.updateRatio());

        this.resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                this.updateRatio();
            }
        });

    }

    static frustumSize = 12;
    static sphereSize = 0.2;
    static sphereColor = 0xff0000;
    static minZoom = 0.3;
    static maxZoom = 7;

    componentDidMount = () => {
        this.resizeObserver.observe(this.container);

        this.cameras = {
            PERSPECTIVE: new THREE.PerspectiveCamera(
                60,
                5 / 2,
                0.1,
                1000,
            ),
            ORTHOGRAPHIC: new THREE.OrthographicCamera(
                0.5 * PointcloudViewer.frustumSize * 5/2,
                0.5 * PointcloudViewer.frustumSize * 5/2,
                PointcloudViewer.frustumSize / 2,
                PointcloudViewer.frustumSize / -2,
                0.1,
                1000
            ),
        }

        Object.values(this.cameras).forEach((camera) => {
            camera.up.set(0, 0, 1);
            camera.updateProjectionMatrix();
        });

        this.camera = this.cameras.ORTHOGRAPHIC;

        this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.localClippingEnabled = true;

        this.updateRatio();

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enablePan = true;
        this.controls.rotateSpeed = 1.0;
        this.controls.minZoom = PointcloudViewer.minZoom;
        this.controls.maxZoom = PointcloudViewer.maxZoom;

        this.controls.addEventListener('change', () => this.repaint());

        if (this.props.isUp) {
            this.controls.minPolarAngle = 0;
            this.controls.maxPolarAngle = 0;
        } else {
            this.controls.minPolarAngle = Math.PI / 2;
            this.controls.maxPolarAngle = Math.PI / 2;
        }

        this.controls.update();
        this.repaint();

        this.updateFromProps({});
    }

    updateRatio = () => {
        const container = this.container || document.getElementById(this.scene.uuid);

        if (!this.container) return console.warn('this.container is undefined, this is probably due to the hot reloader', this);
        if (!container) return console.error('No container has been found for MovableTree.', this);

        this.aspectRatio = container.clientWidth / container.clientHeight;
        this.renderer.setSize(container.clientWidth, container.clientHeight);

        this.cameras.ORTHOGRAPHIC.left  = - 0.5 * PointcloudViewer.frustumSize * this.aspectRatio;
        this.cameras.ORTHOGRAPHIC.right = 0.5 * PointcloudViewer.frustumSize * this.aspectRatio;
        this.cameras.ORTHOGRAPHIC.top  = 0.5 * PointcloudViewer.frustumSize;
        this.cameras.ORTHOGRAPHIC.bottom = - 0.5 * PointcloudViewer.frustumSize;
        if (this.cameras.ORTHOGRAPHIC.updateProjectionMatrix) this.cameras.ORTHOGRAPHIC.updateProjectionMatrix();

        this.cameras.PERSPECTIVE = this.aspectRatio;
        if (this.cameras.PERSPECTIVE.updateProjectionMatrix) this.cameras.PERSPECTIVE.updateProjectionMatrix();

        if (this.controls) this.controls.update();

        this.repaint();
    }

    updateFromProps = (props) => {
        if (props.background !== this.props.background) {
            this.scene.background = new THREE.Color(this.props.background || 0x000000);
            requestAnimationFrame(this.repaint);
        }

        if (props.pointcloud !== this.props.pointcloud) {

            if (this.points) {
                this.points.geometry.dispose();
                this.points.material.dispose();
                this.scene.remove(this.points);
            }

            let boundingSphere;
            if (this.props.pointcloud) {
                this.pointcloud = this.props.pointcloud;
                const points = new THREE.Points(this.props.pointcloud.geometry, this.props.pointcloud.createMaterial());
                this.points = points;
                this.points.up.set(0, 0, 1);

                this.scene.add(this.points);

                boundingSphere = points.geometry.boundingSphere;

            } else {
                boundingSphere = new THREE.Sphere(
                    new THREE.Vector3().copy(this.sphere.position),
                    8,
                );
            }

            if (this.controls) {
                this.controls.target.set(
                    boundingSphere.center.x,
                    boundingSphere.center.y,
                    boundingSphere.center.z,
                );
            }

            if (this.props.isUp) {
                this.camera.position.set(
                    this.props.position.x,
                    this.props.position.y,
                    150,
                );
                this.controls.target.copy(
                    new THREE.Vector3(
                        this.props.position.x,
                        this.props.position.y,
                        this.props.position.z
                    )
                );
            } else {
                this.camera.position.set(
                    boundingSphere.center.x + boundingSphere.radius * 1.8,
                    boundingSphere.center.y + boundingSphere.radius * 1.8,
                    boundingSphere.center.z,
                );
            }

            this.camera.zoom = 0.5;
            this.camera.updateProjectionMatrix();

            this.controls.update();
            requestAnimationFrame(this.repaint);
        }

        if (props.environmentPointCloud !== this.props.environmentPointCloud) {
          if (this.environmentPoints) {
            this.environmentPoints.geometry.dispose();
            this.environmentPoints.material.dispose();
            this.scene.remove(this.environmentPoints);
          }
    
          if (this.props.environmentPointCloud) {
            this.environmentPoints = new THREE.Points(
              this.props.environmentPointCloud.geometry,
              this.props.environmentPointCloud.createMaterial(THREE, this.props.pointSize ?? 0.5)
            );
            this.environmentPoints.up.set(0, 0, 1);
            this.scene.add(this.environmentPoints);
          }

          if (this.props.pointcloud && this.props.environmentPointCloud) {
            const treeMins = new THREE.Vector3(...this.pointcloud.pc.mins);
            const envMins = new THREE.Vector3(...this.props.environmentPointCloud.pc.mins);
            const diffBetweenTreeAndEnvironment = envMins.clone().sub(treeMins);

            this.environmentPoints.position.copy(diffBetweenTreeAndEnvironment);
          }

          requestAnimationFrame(this.repaint);
        }

        // Color updates
        if (props.colors !== this.props.colors) {
            this._reactToColorChanges(this.props.pointcloud);
        }   
    }

    componentDidUpdate = prevProps => this.updateFromProps(prevProps)

    repaint = () => requestAnimationFrame(() => this.forceUpdate());
    

    _reactToColorChanges(pc) {
        const pointCount = pc?.pc.pointCount;
        if (pointCount) {
          const classColors = {};
    
          const colors = new Float32Array(pointCount * 3);
          const classification = pc?.geometry.getAttribute("classification");
    
          for (const [index, pointClass] of classification.array.entries()) {
            let rgb = [255, 255, 255];
            if (Object.keys(classColors).includes(pointClass)) {
              rgb = classColors[pointClass];
            } else {
              const pointClassLabel = getPointClassLabel(pointClass);
              const hexOverride = this.props.colors[pointClassLabel];
              rgb = getRgbColorByClassification(pointClass, hexOverride) ?? [255, 255, 255];
              classColors[pointClass] = rgb;
            }
    
            colors[3 * index] = rgb[0] / 255;
            colors[3 * index + 1] = rgb[1] / 255;
            colors[3 * index + 2] = rgb[2] / 255;
          }
    
          const newColors = new THREE.Float32BufferAttribute(colors, 3);
          this.pointcloud.geometry.setAttribute("color", newColors);
          this.points.geometry.setAttribute("color", newColors);
          requestAnimationFrame(this.repaint);
        }
      }

    render() {

        this.sphere.position.copy(this.props.position);
        if (this.renderer) this.renderer.render(this.scene, this.camera);

        return (
            <div
                className='tree-mover'
                id={this.scene && this.scene.uuid}
                ref={e => this.container = e}
                style={{ flex: '1', position: 'relative' }}
            >
                <canvas
                    ref={e => this.canvas = e}
                    style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
                />
            </div>
        );
    }
}

export default PointcloudViewer;