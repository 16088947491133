const MapView = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.00008 17.3333C13.6026 17.3333 17.3334 13.6025 17.3334 8.99999C17.3334 4.39749 13.6026 0.666656 9.00008 0.666656C4.39758 0.666656 0.666748 4.39749 0.666748 8.99999C0.666748 13.6025 4.39758 17.3333 9.00008 17.3333Z'
        strokeWidth='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.08325 9.41667L5.66659 11.0833L4.83325 14L5.66659 16.5M13.1666 16.0833L12.7499 14L10.6666 13.1667V10.25L13.1666 9.41667L16.9166 9.83333M14.8333 3.58333L14.4166 4.83333L11.4999 5.25V7.75L13.5833 6.91667H15.2499L16.9166 7.75M1.08325 7.75L3.16659 6.08333L5.24992 5.66667L6.91659 3.16667L6.08325 1.5'
        strokeWidth='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MapView;
