import { IconStyle } from '../../@types/Icon';

const Bell = ({ width = 14, height = 14, color }: IconStyle) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.865 10.5C6.7772 10.6517 6.65106 10.7776 6.49924 10.8651C6.34742 10.9526 6.17525 10.9987 6 10.9987C5.82475 10.9987 5.65258 10.9526 5.50076 10.8651C5.34894 10.7776 5.2228 10.6517 5.135 10.5M9 4.2C9 3.3515 8.684 2.5375 8.1215 1.9375C7.5585 1.3375 6.795 1 6 1C5.2045 1 4.4415 1.337 3.8785 1.9375C3.316 2.5375 3 3.3515 3 4.2C3 7.9335 1.5 9 1.5 9H10.5C10.5 9 9 7.9335 9 4.2Z'
        stroke={color}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Bell;
