import React from 'react';
import Icon from './Icon';
import { useStaticConfig } from '../providers/config';
import { Button } from './inputs';

const round = (val, unit) => `${(Math.round(val * 100) / 100) || '–'} ${unit || ''}`;

const details = {
  'LOCATION_VALIDATION_DELETED': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Confidence level',
      resolver: ({ properties }) => round(properties?.location_confidence * 100, '%')
    },
  ],
  'SENT_TO_FIELD': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Comment',
      resolver: ({ properties }) => properties?.comment || '-'
    },
  ],
  'LOCATION_VALIDATION_QUEUED|LOCATION_VALIDATION_DONE': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Confidence level',
      resolver: ({ properties }) => round(properties?.location_confidence * 100, '%')
    },
  ],
  'db_match_todo|MEASUREMENT_VALIDATION_QUEUED|SEGMENTATION_VALIDATION_QUEUED|MEASUREMENT_CALCULATION_DONE': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Girth',
      resolver: ({ properties }) => round(properties?.girth_1_0m, 'm')
    },
    {
      label: 'Height',
      resolver: ({ properties }) => round(properties?.height, 'm')
    },
  ],
  'COMPLETED': [
    {
      label: 'ID',
      resolver: 'properties.maven_tree_id'
    },
    {
      label: 'height',
      resolver: ({ properties }) => round(properties?.height, 'm')
    },
    {
      label: 'girth',
      resolver: ({ properties }) => round(properties?.girth_1_0m, 'm')
    },
    {
      label: 'species',
      resolver: ({ properties }) =>  properties.spsc_nm || properties.manual_scientific_name || '–'
    },
  ],
  'maven': [
    {
      label: 'ID',
      resolver: 'properties.tree_id'
    },
    {
      label: 'species',
      resolver: ({ properties }) => properties.spsc_nm || '–'
    },
    {
      label: 'height',
      resolver: ({ properties }) => properties?.height
    },
    {
      label: 'girth',
      resolver: ({ properties }) => round(properties?.grth_size, 'm')
    },
  ]
}

const TreePopup = ({ tree, onClose, isDetailsVisible }) => {
  const { getConfig } = useStaticConfig();

  const treeStatus = tree.properties?.status || 'maven'; // Safe to assume that every RTMS tree has status
  const status = getConfig(`statuses.${tree.properties.status}`);

  const configKey = Object.keys(details).find(key => key.split('|').includes(treeStatus));
  const config = details?.[configKey];

  return (
    <div className="tree-popup custom-popup-wrapper">
      <div className="close-wrapper" id='popup-close-wrapper' >
        <Icon icon='times' />
      </div>
      {
        config
        ? config?.map(conf => <Detail label={conf.label} key={conf.resolver} value={Object.byResolver(tree, conf.resolver)} />)
        : <span className="empty">No configuration found for status: {status?.label || treeStatus}</span>
      }
      { isDetailsVisible && <Button primary id={`tree-details-handler`} label='Details' /> }
    </div>
  )
}

const Detail = ({ label, value }) => {
  return (
    <div className="detail-wrapper">
      <span className="label">{ label }</span>
      <span className="value">{ value }</span>
    </div>
  )
}

export default TreePopup;
