import { Canvas, extend, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Crown from './Crown';
import Trunk from './Trunk';

extend({ OrbitControls });

const Scene = ({ data, ...props }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  return (
    <>
      <Trunk data={data.trunk_voxels} {...props} />
      <Crown data={data.crown_voxels} {...props} />
      <orbitControls
        args={[camera, domElement]}
        enablePan={true}
        enableRotate={true}
        minPolarAngle={0.5 * Math.PI}
        maxPolarAngle={0.5 * Math.PI}
      />
    </>
  );
};

const VoxelXML = ({ json, backgroundColor }) => {
  return (
    <Canvas
      camera={{ position: [0, 0, 150] }}
      onCreated={({ gl }) => gl?.setClearColor?.(backgroundColor || 'white')}
    >
      <ambientLight />
      <pointLight position={[150, 150, 150]} intensity={0.55} />
      <Scene data={json} />
    </Canvas>
  );
};

export default VoxelXML;
