const Panorama = () => {
  return (
    <svg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 1.03413V9.59746C15.9996 9.67857 15.98 9.75844 15.9427 9.83048C15.9054 9.90253 15.8516 9.9647 15.7856 10.0119C15.7197 10.0591 15.6434 10.0899 15.5632 10.1019C15.483 10.1139 15.401 10.1066 15.3242 10.0808C13.955 9.61996 10.7908 8.64913 8.5 8.64913C6.20917 8.64913 3.045 9.62079 1.67583 10.08C1.59895 10.1058 1.51703 10.113 1.4368 10.1011C1.35658 10.0891 1.28034 10.0582 1.21437 10.0111C1.14839 9.96387 1.09455 9.9017 1.05729 9.82965C1.02002 9.7576 1.00039 9.67774 1 9.59663V1.03496C1 0.686628 1.34583 0.441628 1.67583 0.551628C3.045 1.01163 6.20917 1.98246 8.5 1.98246C10.7908 1.98246 13.955 1.01079 15.3242 0.551628C15.401 0.525776 15.483 0.518543 15.5632 0.530523C15.6434 0.542502 15.7197 0.573353 15.7856 0.620538C15.8516 0.667723 15.9054 0.729894 15.9427 0.80194C15.98 0.873986 15.9996 0.953849 16 1.03496V1.03413Z'
        strokeWidth='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Panorama;
