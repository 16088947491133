export const StyledPointClass = {
  canopy: 21,
  otherCanopy: 23,
  trunk: 20,
  otherTrunk: 22,
  environment: 0,
} as const;

export type StyleProperties = {
  color: string;
  opacity: boolean;
};

type PointClassStyles = Record<keyof typeof StyledPointClass, StyleProperties>;

export default PointClassStyles;
