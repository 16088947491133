import DebouncedColorPicker from './DebouncedColorPicker';

type ColorPickerProps = {
  color: string;
  onColorChange: (color: string) => void;
};

const ColorPickerInput = ({ color, onColorChange }: ColorPickerProps) => (
  <div>
    <DebouncedColorPicker color={color} onChange={onColorChange} delay={100} colorPickerType={'input'} />
  </div>
);

export default ColorPickerInput;
