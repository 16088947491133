import { HexColorInput, HexColorPicker } from 'react-colorful';
import useDebounce from '../../hooks/useDebounce';

const DebouncedColorPicker = ({
  color,
  onChange,
  delay,
  colorPickerType,
}: {
  color: string;
  onChange: any;
  delay: number;
  colorPickerType: 'picker' | 'input';
}) => {
  const debounceOnChange = useDebounce(onChange, delay);

  return (
    <>
      {colorPickerType === 'picker' ? (
        <HexColorPicker color={color} onChange={(newColor) => debounceOnChange(newColor)} />
      ) : (
        <div className='cp-hex-input'>
          <div className='cp-hex-title'>Hex</div>
          <div className='cp-hex-value'>
            <HexColorInput color={color} onChange={(newColor) => debounceOnChange(newColor)} />
          </div>
        </div>
      )}
    </>
  );
};

export default DebouncedColorPicker;
