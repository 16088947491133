import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Button } from '../components/inputs';
export const Context = createContext({ });

export const useAlert = () => useContext(Context);

export const withAlert = (Component) => props => <Context.Consumer>{theme => <Component { ...props } theme={theme} />}</Context.Consumer>;

export const Provider = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState(null);

  const presentAlert = useCallback((content) => {
    setContent(content);
    setIsVisible(true);
  }, []);

  const dismissAlert = useCallback(() => setIsVisible(false), []);
  
  /** Handling keyboard event */
  const _handleKeyDown = (e) => (e.key === 'Escape') && setIsVisible(false);
  
  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => window.removeEventListener('keydown', _handleKeyDown);
  }, []);
  return (
    <Context.Provider value={{ isVisible, presentAlert, dismissAlert }}>
      <Popup { ...{ isVisible, ...content, dismissAlert }} />
      { props.children }
    </Context.Provider>
  )
};

const Popup = ({ isVisible, title = 'POPUP TITLE', subtitle = 'Lorem ipsum', actions = [], dismissAlert = () => {} }) => {
  return (
    <div className={`alert-outer-wrapper ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="backdrop" onClick={dismissAlert} />
      <div className="alert-wrapper">
        <div className="header">
          <span className="title">{ title }</span>
          <span className="subtitle">{ subtitle }</span>
        </div>
        <div className="actions">
          {
            actions.map((action, index) => <Button {...action} key={`alert-action-${index}`} />)
          }
        </div>
      </div>
    </div>
  )
}