import { Lens } from '@dhmk/zustand-lens';
import { createRef } from 'react';
import ValidationAction from '../enums/ValidationAction';
import ValidationTool from '../enums/ValidationTool';

export type ValidationSlice = {
  tool: ValidationTool;
  editing: ValidationAction;
  rotateFunction: (direction: THREE.Vector3) => void;
  finished: boolean;
  azimuthAngle: React.MutableRefObject<number>;
  viewLocked: boolean;
  hasLoadedParams: boolean;
  hasLoadedBif: boolean;
  hasLoadedDeltaCorrection: boolean;

  isTreeHeightCompleted: boolean | null;
  isCrownHeightCompleted: boolean | null;
  isFirstBifurcationCompleted: boolean | null;
  isFirstBifurcationMidPointCompleted: boolean | null;
  isCanopyCompleted: boolean | null;
  isLeanAngleCompleted: boolean | null;
  isTrunkDetailsCompleted: boolean | null;

  areAllActionsCompleted: () => boolean;

  actions: {
    setTool: (tool: ValidationTool) => void;
    setEditing: (editing: ValidationAction) => void;
    setFinished: (finished: boolean) => void;
    setRotateFunction: (cb: (direction: THREE.Vector3) => void) => void;
    setAzimuthAngle: (azimuthAngle: number) => void;
    setHasLoadedParams: (hasLoadedParams: boolean) => void;
    setHasLoadedBif: (hasLoadedBif: boolean) => void;
    setHasLoadedDeltaCorrection: (hasLoadedDeltaCorrection: boolean) => void;

    setIsTreeHeightCompleted: (isTreeHeightCompleted: boolean | null) => void;
    setIsCrownHeightCompleted: (isCrownHeightCompleted: boolean | null) => void;
    setIsFirstBifurcationCompleted: (isFirstBifurcationCompleted: boolean | null) => void;
    setIsFirstBifurcationMidPointCompleted: (isFirstBifurcationMidPointCompleted: boolean | null) => void;
    setIsCanopyCompleted: (isCanopyCompleted: boolean | null) => void;
    // setIsLeanAngleCompleted: (isLeanAngleCompleted: boolean | null) => void;
    setIsTrunkDetailsCompleted: (isTrunkDetailsCompleted: boolean | null) => void;

    setActionCompleted: (action: ValidationAction) => void;

    setAllActionsTo: (value: boolean | null) => void;
  };
};

const createValidationSlice: Lens<ValidationSlice> = (set, get) => ({
  tool: ValidationTool.View,

  editing: ValidationAction.Height,

  finished: false,
  hasLoadedParams: false,
  hasLoadedBif: false,
  hasLoadedDeltaCorrection: false,

  rotateFunction: () => {
    console.error('Rotate function is unitialized!');
  },

  azimuthAngle: createRef<number>() as React.MutableRefObject<number>,

  viewLocked: false,

  isTreeHeightCompleted: null,
  isCrownHeightCompleted: null,
  isFirstBifurcationCompleted: null,
  isFirstBifurcationMidPointCompleted: null,
  isCanopyCompleted: null,
  isLeanAngleCompleted: null,
  isTrunkDetailsCompleted: null,

  areAllActionsCompleted: () =>
    Boolean(
      get().isTreeHeightCompleted &&
        get().isCrownHeightCompleted &&
        get().isFirstBifurcationCompleted &&
        get().isFirstBifurcationMidPointCompleted &&
        get().isCanopyCompleted &&
        // get().isLeanAngleCompleted &&
        get().isTrunkDetailsCompleted
    ),

  actions: {
    setTool: (tool) => {
      if (tool === get().tool) return;
      set({ tool });
    },

    setEditing: (editing) => set({ editing }),

    setFinished: (finished) => set({ finished }),

    setHasLoadedParams: (loaded) => set({ hasLoadedParams: loaded }),
    setHasLoadedBif: (loaded) => set({ hasLoadedBif: loaded }),
    setHasLoadedDeltaCorrection: (loaded) => set({ hasLoadedDeltaCorrection: loaded }),

    setRotateFunction: (rotateFunction) => set({ rotateFunction }),

    setAzimuthAngle: (azimuthAngle) => {
      get().azimuthAngle.current = azimuthAngle;
    },

    setIsTreeHeightCompleted: (isTreeHeightCompleted) => set({ isTreeHeightCompleted }),
    setIsCrownHeightCompleted: (isCrownHeightCompleted) => set({ isCrownHeightCompleted }),
    setIsFirstBifurcationCompleted: (isFirstBifurcationCompleted) => set({ isFirstBifurcationCompleted }),
    setIsFirstBifurcationMidPointCompleted: (isFirstBifurcationMidPointCompleted) => set({ isFirstBifurcationMidPointCompleted }),
    setIsCanopyCompleted: (isCanopyCompleted) => set({ isCanopyCompleted }),
    // setIsLeanAngleCompleted: (isLeanAngleCompleted) => set({ isLeanAngleCompleted }),
    setIsTrunkDetailsCompleted: (isTrunkDetailsCompleted) => set({ isTrunkDetailsCompleted }),

    setActionCompleted: (action: ValidationAction) => {
      switch (action) {
        case ValidationAction.Height:
          return set({ isTreeHeightCompleted: true });
        case ValidationAction.CanopyHeight:
          return set({ isCrownHeightCompleted: true });
        case ValidationAction.FirstBifurcationPoint:
          set({ isFirstBifurcationCompleted: true });
          set({ isFirstBifurcationMidPointCompleted: true });
          return;
        case ValidationAction.Canopy:
          return set({ isCanopyCompleted: true });
/*         case ValidationAction.LeaningVector:
          return set({ isLeanAngleCompleted: true }); */
        case ValidationAction.Girth:
          return set({ isTrunkDetailsCompleted: true });
        default:
          return;
      }
    },

    setAllActionsTo: (value) => {
      set({
        isTreeHeightCompleted: value,
        isCrownHeightCompleted: value,
        isFirstBifurcationCompleted: value,
        isFirstBifurcationMidPointCompleted: value,
        isCanopyCompleted: value,
        // isLeanAngleCompleted: value,
        isTrunkDetailsCompleted: value,
      });
    },
  },
});

export default createValidationSlice;
