const HOTKEYS = {
  microclimate: {
    STORE_ATTRIBUTES_TO_MEMORY_POPUP: 'ctrl+shift+up',
    READ_ATTRIBUTES_FROM_MEMORY_POPUP: 'ctrl+shift+down',
    READ_ATTRIBUTES_FROM_MEMORY_OVERRIDE: 'CTRL + SHIFT + K',
    READ_ATTRIBUTES_FROM_MEMORY_CANCEL: 'CTRL + SHIFT + L',
    READ_ATTRIBUTES_CLEAR: 'CTRL + SHIFT + X',
  },
  general: {
    CLOSE_POPUP: 'escape',
  },
  semantics: {
    MARK_ATTRIBUTE_COMPLETE: 'm+c',
  },
};

export default HOTKEYS;
