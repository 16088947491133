import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, PropsWithChildren, forwardRef, useCallback, useEffect } from 'react';
import { IconType } from '../@types/Icon';
import { ButtonParams, SelectParams } from '../@types/Select';
import { WithStyleProp } from '../@types/WithStyleProp';
import useComponentVisible from '../hooks/useComponentVisible';
import Icon from './Icon';

const Button = forwardRef(function Button(
  { children, onClick, open }: PropsWithChildren<ButtonParams>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <button className='dropdown-button--button' type='button' onClick={onClick} ref={ref}>
      <span className='title'>{children}</span>
      <div className='arrow'>
        <Icon icon={open ? (faAngleUp as IconType) : (faAngleDown as IconType)} size='xs' />
      </div>
    </button>
  );
});

const TabSelector = ({
  tabs = [],
  active,
  onSelect,
  side = 'left',
  style,
  hideActiveMarker = false,
  hideTabSelector,
}: SelectParams & WithStyleProp) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const _handleSelect = (tabId: string) => {
    onSelect({ side, value: tabId });
    setIsComponentVisible((state) => !state);
  };

  const handleClick = () => setIsComponentVisible((state) => !state);

  useEffect(() => {
    if (tabs?.find((tab) => tab.id === active)?.disabled) onSelect({ side, value: tabs[0].id });
  }, [active, onSelect, side, tabs]);

  const isActive = useCallback((tabId: string) => tabId === active, [active]);

  return (
    <div ref={ref} className={`dropdown-button ${side ?? ''}`} style={style}>
      {!hideTabSelector && (
        <Fragment>
          <Button onClick={handleClick} open={isComponentVisible}>
            {active}
          </Button>
          <div className={`dropdown-button--select-wrapper ${isComponentVisible ? 'visible' : ''}`}>
            <div className={`dropdown-button--select-arrow ${isComponentVisible ? 'visible' : ''}`}></div>
            <div className={`dropdown-button--select-list--wrapper`}>
              <ul className={`dropdown-button--select-list`}>
                {tabs.map((tab) => (
                  <li key={tab.id} className={`dropdown-button--select-list__item`} onClick={() => _handleSelect(tab.id)}>
                    {!hideActiveMarker && <div className={`activity-mark ${isActive(tab.id) ? 'active' : ''}`} />}
                    {tab.iconName && <Icon icon={tab.iconName as IconType} color='primary'></Icon>}
                    <span>{tab.id}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TabSelector;
