import { PropsWithChildren } from 'react';

type ViewGridProps = {
  layout:
    | 'quartered'
    | 'horizontal'
    | 'vertical'
    | 'horizontal-top-halved'
    | 'horizontal-bottom-halved'
    | 'vertical-left-halved'
    | 'vertical-right-halved';
};

const ViewGrid = ({ layout, children }: PropsWithChildren<ViewGridProps>) => {
  return <div className={`tree-mover ${layout}`}>{children}</div>;
};

export default ViewGrid;
