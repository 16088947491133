import { IconStyle } from '../../@types/Icon';

const BadSegmentation = ({ width = 20, height = 20, color = 'white' }: IconStyle) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.16667 5.83325V9.99992M6.5625 7.91658H12.3958C16.9792 7.91658 16.9792 14.9999 12.3958 14.9999H4.0625'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.47917 10.8333L6.5625 7.91667L9.47917 5' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default BadSegmentation;
