import React from 'react';
import { Popup } from '.';
import { useTheme } from '../providers/theme';

const ViewOptions = ({ isSatellite, setIsSatellite, isPanoramaSelected, isPanoramaVisible, setPanoramaSelected }) => {
  const _handleChange = (type) => {
    if (type === 'SATELLITE') {
      setIsSatellite(true);
      setPanoramaSelected(false);
    } else if (type === 'PANORAMA') {
      setIsSatellite(false);
      setPanoramaSelected(true);
    } else {
      setIsSatellite(false);
      setPanoramaSelected(false);
    }
  }
  return (
    <div className="view-options-wrapper">
      <ViewOption
        type="DEFAULT"
        active={!isSatellite && !isPanoramaSelected}
        onClick={_handleChange}
      />
      <ViewOption
        type="SATELLITE"
        active={isSatellite && !isPanoramaSelected}
        onClick={_handleChange}
      />
      <Popup
        active={isPanoramaSelected && !isPanoramaVisible}
        direction="top"
        noHover
        noDelay
        centerAlign
        title="Please select[BR]position/tree[BR]on the map."
      >
        <ViewOption
          type="PANORAMA"
          active={isPanoramaSelected}
          onClick={_handleChange}
        />
      </Popup>
    </div>
  );
}

const ViewOption = ({ type, active, onClick, disabled }) => {
  const _handleAction = () => onClick(type);
  const { isDark } = useTheme();
  const file = type === 'DEFAULT' ? `DEFAULT_${isDark ? 'DARK' : 'LIGHT'}` : type;
  return (
    <div onClick={_handleAction} className={`view-option-wrapper ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
      <div className="image-wrapper">
        <img src={`/images/viewoptions/${file}.png`} alt='' />
      </div>
      <span className="type">{ type === 'DEFAULT' ? 'MAP' : type }</span>
    </div>
  )
}

export default ViewOptions;