const TicketCheck = ({ color = '#494B4D' }: { color?: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <path
        d='M10.5 18.3334C15.1025 18.3334 18.8333 14.6025 18.8333 10C18.8333 5.39752 15.1025 1.66669 10.5 1.66669C5.89749 1.66669 2.16666 5.39752 2.16666 10C2.16666 14.6025 5.89749 18.3334 10.5 18.3334Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.33334 10.4167L8.83334 12.9167L14.6667 7.08337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TicketCheck;
