const Cogwheel = ({ width = 20, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5V12.5Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.352 8.66258L15.4378 6.45425L16.667 5.00008L15.0003 3.33341L13.5545 4.56925L11.2987 3.64175L10.7795 1.66675H9.15116L8.62449 3.66758L6.42033 4.59675L5.00033 3.33341L3.33366 5.00008L4.54449 6.49091L3.64449 8.70508L1.66699 9.16675V10.8334L3.66783 11.3792L4.59699 13.5834L3.33366 15.0001L5.00033 16.6667L6.49283 15.4501L8.66449 16.3434L9.16699 18.3334H10.8337L11.337 16.3442L13.5462 15.4292C13.9145 15.6926 15.0003 16.6667 15.0003 16.6667L16.667 15.0001L15.4303 13.5417L16.3453 11.3317L18.3337 10.8151V9.16675L16.352 8.66258V8.66258Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Cogwheel;
