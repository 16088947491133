const Approve = ({ width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.25 10.4166L4.64667 13.8133C4.74042 13.9069 4.8675 13.9595 5 13.9595C5.1325 13.9595 5.25958 13.9069 5.35333 13.8133L7.5 11.6666M13.3333 5.83325L10 9.16658'
        stroke='#818385'
        strokeWidth='1.21667'
        strokeLinecap='round'
      />
      <path
        d='M5.83325 9.99992L9.64659 13.8133C9.74034 13.9069 9.86742 13.9595 9.99992 13.9595C10.1324 13.9595 10.2595 13.9069 10.3533 13.8133L18.3333 5.83325'
        stroke='#818385'
        strokeWidth='1.21667'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Approve;
