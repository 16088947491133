import { useCallback } from 'react';
import { Girth, TrunkWithGirths } from '../store/TreeSlice';
import useApi from './api';

const transformGirthToTrunks = (girths: Girth[]) => {
  const [girth1, girth2] = girths;

  return {
    ...(girth1 && {
      measurementHeight1: girth1.height,
      normalVector1: [girth1.normal.x, girth1.normal.y, girth1.normal.z],
      girth1: girth1.diameter,
      trunkOffsetX1: girth1.dX,
      trunkOffsetY1: girth1.dY,
      trunkEllipseA1: girth1.rX,
      trunkEllipseB1: girth1.rY,
      trunkEllipseDirection1: girth1.rotation,
    }),
    ...(girth2 && {
      measurementHeight2: girth2.height,
      normalVector2: [girth2.normal.x, girth2.normal.y, girth2.normal.z],
      girth2: girth2.diameter,
      trunkOffsetX2: girth2.dX,
      trunkOffsetY2: girth2.dY,
      trunkEllipseA2: girth2.rX,
      trunkEllipseB2: girth2.rY,
      trunkEllipseDirection2: girth2.rotation,
    }),
  };
};

const useSyncTrunks = () => {
  const { handleRequest } = useApi();

  const syncTrunks = useCallback(
    (treeId: string, trunks: TrunkWithGirths[]) => {
      const trunkPromises = trunks.map((trunk) => {
        const girthsWithHeights = trunk.girths.map((girth) => ({ ...girth, height: girth.height }));
        // PATCH - Update existing trunk
        if (trunk.id) {
          return handleRequest(`/trees/${treeId}/trunks`, {
            method: 'PATCH',
            body: JSON.stringify({
              id: trunk.id,
              treeId,
              ...transformGirthToTrunks(girthsWithHeights),
            }),
            headers: { 'Content-Type': 'application/json' },
          }).then((res) => res.json());
        }

        // POST - Create new trunk
        return handleRequest(`/trees/${treeId}/trunks`, {
          method: 'POST',
          body: JSON.stringify({
            treeId,
            ...transformGirthToTrunks(girthsWithHeights),
          }),
          headers: { 'Content-Type': 'application/json' },
        }).then((res) => res.json());
      });

      return Promise.all(trunkPromises).then((trunks) => trunks.flat());
    },
    [handleRequest]
  );

  return syncTrunks;
};

export default useSyncTrunks;
