import { Lens } from "@dhmk/zustand-lens";
import ValidationError from "../@types/ValidationError";
import ErrorTool from "../enums/ErrorTool";
import { handleRequest } from "../hooks/api";

export type MarkErrorSlice = {
  tool: ErrorTool;
  points: THREE.Intersection[];

  actions: {
    setTool: (tool: ErrorTool) => void;
    addPoint: (point: THREE.Intersection) => void;
    save: (error: ValidationError, token: string) => Promise<void>;
    handleUndo: () => void;
    reset: () => void;
  };
};

const createMarkErrorSlice: Lens<MarkErrorSlice> = (set, get) => ({
  tool: ErrorTool.Line,
  points: [],

  actions: {
    setTool: (tool) => {
      if (get().tool === tool) return;
      set({ tool: tool, points: [] });
      //TODO dismissModal();
    },

    addPoint: (point) => {
      if (get().tool === ErrorTool.Point) {
        set({ points: [point] });
      } else {
        set({ points: [...get().points, point] });
      }
    },

    handleUndo: () => {
      if (get().points.length > 0) {
        set({ points: get().points.slice(0, -1) });
      }
    },

    save: async (error, token) => {
      const payload = {
        treeId: 0, // TODO
        errorType: get().tool,
        points: get().points.map((p) => p.point),
        comment: error.comment,
        azimuthAngle: error.azimuthAngle,
        polarAngle: error.polarAngle,
        cameraScale: error.cameraScale,
        cameraPosition: error.worldPos,
        cameraDirectionVector: error.worldDir,
      };
      try {
        const response = await handleRequest(token, null)(
          `/v1/trees/${payload.treeId}/error`,
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" },
          }
        );
        const content = await response.json();
        if (Object.keys(content).length === 0) throw new Error();
        //TODO dismissModal();
        set({ points: [] });
      } catch (err) {
        //TODO: showToast()
        console.error("Error during tree error save:", err);
      }
    },

    reset: () => {
      set({ points: [] });
      //TODO dismissModal
    },
  },
});

export default createMarkErrorSlice;
