import XZYCoords from '../@types/XZYCoords';

/**
 * Convert value from one range to another.
 * @param {{min: number, max: number}} range1 - Object with min and max properties that define a numerical range
 * @param {{min: number, max: number}} range2 - Object with min and max properties that define a numerical range
 * @param {number} range1Value - Value from range 1.
 * @returns {number} - Value in range 2, same ratio for range 2 as range1Value has to range 1
 * @public
 */
type Range = { min: number; max: number };

const convertValueBetweenRanges = (range1: Range, range2: Range, range1Value: number) => {
  // r1.min       value           r1.max
  //   |------------|---------------|
  // r2.min     newValue          r2.max
  const distanceR1 = Math.abs(range1.max - range1.min);
  const distanceR2 = Math.abs(range2.max - range2.min);
  const ratio = (range1Value - range1.min) / distanceR1;
  const range2Value = range2.max - distanceR2 * ratio;

  return range2Value;
};

const meterToCentimeter = (value?: number | null): number => {
  const meters = Number(value) ?? 0;
  return 100 * meters;
};

const centimeterToMeter = (value?: number | null): number => {
  const centimeter = Number(value) ?? 0;
  return centimeter / 100;
};

const circumferenceOfEllipse = (rX: number, rY: number) => 2 * Math.PI * Math.sqrt((rX ** 2 + rY ** 2) / 2);

const radiusFromCircumference = (circumference: number) => circumference / (Math.PI * 2);

const mergeXYZValues = <T extends XZYCoords, K extends XZYCoords>(v1: T, v2: K): T => {
  if (v2.x) v1.x = v2.x;
  if (v2.y) v1.y = v2.y;
  if (v2.z) v1.z = v2.z;
  return v1;
};

const calculatePositiveAverage = (...params: number[]) => calculateAverage(...params.map((p) => Math.abs(p)));

const calculateAverage = (...params: number[]) => {
  const sum = params.reduce((a, b) => a + b, 0);
  return sum / params.length || 0;
};

export {
  convertValueBetweenRanges,
  meterToCentimeter,
  centimeterToMeter,
  circumferenceOfEllipse,
  mergeXYZValues,
  calculatePositiveAverage,
  calculateAverage,
  radiusFromCircumference,
};
