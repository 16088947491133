import { useTheme } from '@mui/material';
import MuiAutoComplete, { autocompleteClasses, AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Option } from '../../@types/Autocomplete';
import Icon from '../Icon';


type CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<Option<T>, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'renderOption'> & {
  inputRef?: React.Ref<any>
  label?: string;
  helperText?: string;
  highlightValue?: boolean;
};

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { palette, typography } = useTheme();
  const { inputRef, label, helperText, highlightValue, placeholder, ...autocompleteProps } = props;

  return (
    <MuiAutoComplete
      { ...autocompleteProps }
      className={highlightValue ? 'highlight-value' : ''}
      renderInput={(params) => <TextField {...params} label={label} inputRef={inputRef} placeholder={placeholder} />}
      size='small'
      renderOption={(params, option) => (
        <li
          style={{
            color: palette.text.primary,
            fontFamily: typography.fontFamily,
            fontSize: typography.fontSize,
          }}
          {...params}
        >
          {option.label}
        </li>
      )}
      popupIcon={<Icon icon='search' style={{ color: palette.text.primary }} />}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          color: palette.text.primary,
          fontFamily: typography.fontFamily,
          fontSize: typography.fontSize,
          borderRadius: 0,
          ':hover': {
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderBottom: `1px solid ${palette.text.primary} !important`,
            ':hover': {
              borderBottom: `1px solid ${palette.text.primary} !important`,
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.text.primary}`,
            borderRadius: 1,
          },
          '& .MuiSvgIcon-root': {
            fill: palette.text.primary,
            fontSize: typography.fontSize,
          },
          '& .MuiFormControl-root .MuiTextField-root': {
            borderBottom: `1px solid ${palette.text.primary}`,
          },
        },
        [`& .${autocompleteClasses.endAdornment}`]: {
          top: 'initial',
        },
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none',
        },
      }}
    />
  );
};

export default Autocomplete;
