const MemoryClear = ({ width = 32, height = 32, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.1213 13.8787L16 16M16 16L13.8787 18.1213M16 16L13.8787 13.8787M16 16L18.1213 18.1213M16 23.619V26M11.7895 23.619V26M16 8.38095V6M11.7895 8.38095V6M8.63158 16H6M26 16H23.3684M23.3684 16V8.95238C23.3684 8.87734 23.3521 8.80303 23.3203 8.7337C23.2886 8.66438 23.2421 8.60138 23.1834 8.54832C23.1248 8.49526 23.0552 8.45317 22.9785 8.42445C22.9019 8.39573 22.8198 8.38095 22.7368 8.38095H20.2105M23.3684 16V23.0476C23.3684 23.1227 23.3521 23.197 23.3203 23.2663C23.2886 23.3356 23.2421 23.3986 23.1834 23.4517C23.1248 23.5047 23.0552 23.5468 22.9785 23.5756C22.9019 23.6043 22.8198 23.619 22.7368 23.619H20.2105M8.63158 10.7619H6M26 10.7619H23.3684M8.63158 21.2381H6M26 21.2381H23.3684M20.2105 8.38095H9.26316C9.09565 8.38095 8.93501 8.44116 8.81656 8.54832C8.69812 8.65548 8.63158 8.80083 8.63158 8.95238V23.0476C8.63158 23.1992 8.69812 23.3445 8.81656 23.4517C8.93501 23.5588 9.09565 23.619 9.26316 23.619H20.2105M20.2105 8.38095V6M20.2105 23.619V26'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MemoryClear;
