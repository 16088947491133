import { useCallback, useState } from "react";
import Icon from "../Icon";

const DropdownInput = ({
  options = [],
  value,
  label,
  onChange = (val) => {},
  onPureChange = (val) => {},
  leadingIcon,
  error,
  warning ,
  clearLabel,
  showNumberOfOptions,
  isLimited,
  small = false,
  optionComponent,
  style = {},
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isMultiSelect = Array.isArray(value);

  const _handleSelect = (selectedValue) => {
    setIsFocused(false);
    window.removeEventListener("click", _handleBlur);

    if (isMultiSelect) {
      if (value.includes(selectedValue)) return;

      const _value = [...value, selectedValue];
      onChange({ label, value: _value });
      onPureChange(_value);
      return;
    }

    onChange({ label, value: selectedValue });
    onPureChange(selectedValue);
  };

  const _handleDeselect = (selectedValue) => {
    setIsFocused(false);
    window.removeEventListener("click", _handleBlur);

    if (isMultiSelect) {
      if (!value.includes(selectedValue)) return;

      const _value = value.filter((item) => item !== selectedValue);
      onChange({ label, value: _value });
      onPureChange(_value);
      return;
    }
  };

  const _handleFocus = (e) => {
    e.preventDefault();

    if (isFocused) {
      window.removeEventListener("click", _handleBlur);
    } else {
      requestAnimationFrame(() =>
        window.addEventListener("click", _handleBlur)
      );
    }
    setIsFocused((state) => !state);
  };

  const _handleBlur = useCallback((e) => {
    e.preventDefault();
    setIsFocused(false);
    window.removeEventListener("click", _handleBlur);
  }, []);

  const selection = isMultiSelect
    ? options.filter((option) => value.includes(option.value))
    : options.find((option) => option.value === value);
  const isSelected = isMultiSelect ? selection.length > 0 : selection;

  const optionsToDisplay = clearLabel
    ? [{ label: clearLabel, value: null }, ...options]
    : options;

  const labelToDisplay = showNumberOfOptions
    ? `${label} [${isLimited ? ">" : ""}${options.length}]`
    : label;

  const color = style?.color ? {color: style.color} : undefined;
  const backgroundColor = style?.backgroundColor ? {backgroundColor: style?.backgroundColor} : undefined;

  const handleRemoveTag = (selectedValue) => {
    const _value = value.filter((item) => item !== selectedValue);
    onChange({ label, value: _value });
    onPureChange(_value);
  };

  return (
    <div
      className={`dropdown-input-wrapper ${isFocused ? "focused" : "focused"} ${
        error ? "error" : ""
      } ${warning ? "warning" : ""} ${small ? "small" : ""}`}
    >
      <div
        onClick={options.length ? _handleFocus : null}
        className={`dropdown-content-wrapper ${isFocused ? "focused" : ""} ${
          error ? "error" : ""
        } ${warning ? "warning" : ""}`}
        style={backgroundColor}
      >
        {leadingIcon && <Icon icon={leadingIcon} />}
        {isSelected ? (
          isMultiSelect ? (
            <>
              &nbsp;&nbsp;{" "}
              {selection.map((option) => (
                <Tag
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  onClick={handleRemoveTag}
                />
              ))}
            </>
          ) : (
            <span className={`label selected`} style={color}>{selection.label}</span>
          )
        ) : (
          <span className={`label`} style={color}>{labelToDisplay}</span>
        )}
        {(error || warning) && (
          <span
            className={`alert ${error ? "error" : ""} ${
              warning ? "warning" : ""
            }`}
          >
            {error || warning}
          </span>
        )}
        <div className="arrow" style={color}>
          <Icon icon={isFocused ? "sort-up" : "sort-down"} />
        </div>
      </div>
      <Options
        options={optionsToDisplay}
        visible={isFocused}
        value={value}
        onSelect={_handleSelect}
        onDeselect={_handleDeselect}
        optionComponent={optionComponent}
      />
    </div>
  );
};

const Options = ({
  options = [],
  visible,
  value,
  onSelect = () => {},
  onDeselect = () => {},
  optionComponent,
}) => {
  const isMultiSelect = Array.isArray(value);

  const OptionComponent = optionComponent ?? Option;

  return (
    <div
      className={`options-dropdown-wrapper ${visible ? "visible" : "hidden"}`}
    >
      {options.map((option, index) => (
        <OptionComponent
          active={
            isMultiSelect
              ? value.includes(option.value)
              : value === options.value
          }
          onSelect={onSelect}
          onDeselect={onDeselect}
          selectable={isMultiSelect}
          {...option}
          key={option.value ?? index}
        />
      ))}
    </div>
  );
};

const Option = ({
  value,
  label,
  onSelect,
  onDeselect,
  active,
  selectable = true,
}) => {
  const _handleSelect = () => onSelect(value);
  const _handleDeselect = () => onDeselect(value);

  return (
    <div
      className={`option-wrapper ${active ? "active" : ""}`}
      onClick={_handleSelect}
    >
      {selectable && (
        <>
          <Option.Checkbox
            checked={active}
            onChange={() => (active ? _handleDeselect() : _handleSelect())}
          />
          &nbsp;
        </>
      )}
      <span className="label">{label}</span>
    </div>
  );
};

Option.Checkbox = ({ checked, onChange = () => {} }) => {
  const className = checked ? "checkbox checked" : "checkbox";

  return (
    <>
      <input type="checkbox" checked={checked} readOnly />
      <span className={className} onClick={onChange}>
        {checked ? <Icon icon="check" /> : <></>}
      </span>
    </>
  );
};

const Tag = ({ label, value, onClick }) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick && onClick(value);
  };

  return (
    <span className="tag">
      {label}
      <span className="tag-close" onClick={handleOnClick}>
        <Icon icon="times" />
      </span>
    </span>
  );
};

export default DropdownInput;
