import { useMemo, useState } from 'react'
import * as THREE from 'three';
import { LoaderWrapper } from '../../layout';

import MovableTree from './MovableTree';
import { Toggle } from '../inputs';

const TreeMover = ({
  position,
  onPositionChangeStarted = () => {},
  onPositionChange = () => {},
  onPositionChangeDone = () => {},
  pointcloud = {},
  background,
  treeLoaded = false,
  error = false,
  loaderBtn,
  loader,
  closeByTrees
}) => {
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [activeMoverName, setActiveMoverName] = useState();
  const [isGridEnabled, setIsGridEnabled] = useState(false);

  const convertPointCloudPositionToTreePosition = (position) =>
    {
      const pos = position.map((pos, i) => {
        if (pointcloud?.pc?.mins?.length) {
          return pos + pointcloud?.pc?.mins?.[i]
        }

        return pos;
      });

      return pos;
    };

  const _handlePositionChange = (position) =>
    onPositionChange(convertPointCloudPositionToTreePosition(position));

  const _handlePositionChangeDone = (position) =>
    onPositionChangeDone(convertPointCloudPositionToTreePosition(position));

  const threeJsCoordinateSystemDelta = useMemo(() => new THREE.Vector3(
    (pointcloud?.pc?.mins?.[0] || 0),
    (pointcloud?.pc?.mins?.[1] || 0),
    (pointcloud?.pc?.mins?.[2] || 0),
  ), [pointcloud?.pc?.mins]);

  const pos = useMemo(() => new THREE.Vector3(
    position[0] - threeJsCoordinateSystemDelta.x,
    position[1] - threeJsCoordinateSystemDelta.y,
    position[2] - threeJsCoordinateSystemDelta.z,
  ), [position, threeJsCoordinateSystemDelta]);

  const closeByTreePoints = useMemo(() => closeByTrees?.map((tree) => ({
    coords: new THREE.Vector3(
      tree.location_local[0] - threeJsCoordinateSystemDelta.x,
      tree.location_local[1] - threeJsCoordinateSystemDelta.y,
      tree.location_local[2] - threeJsCoordinateSystemDelta.z,
    ),
    status: tree.status,
    tree_flow_status: tree.tree_flow_status
  })), [closeByTrees, threeJsCoordinateSystemDelta]);

  return (
    <LoaderWrapper customLoader={loader} loading={treeLoaded && pointcloud !== false && !pointcloud && !error}>
      <div className="tree-mover-wrapper">
        {loaderBtn}
        <div className="tree-mover-toggle">
          <Toggle
            value={isGridEnabled}
            onPureChange={() => setIsGridEnabled((oldValue) => !oldValue)}
            label="Toggle grid"
          />
        </div>
        <MovableTree
          name='side-view'
          position={pos}
          closeByTreePoints={closeByTreePoints || []}
          background={background}
          azimuthAngle={azimuthAngle}
          setAzimuthAngle={setAzimuthAngle}
          activeMoverName={activeMoverName}
          setActiveMoverName={setActiveMoverName}
          onPositionChangeStarted={onPositionChangeStarted}
          onPositionChange={_handlePositionChange}
          onPositionChangeDone={_handlePositionChangeDone}
          pointcloud={pointcloud}
          error={error}
          isGridEnabled={isGridEnabled}
        />
        <MovableTree
          name='top-view'
          position={pos}
          closeByTreePoints={closeByTreePoints || []}
          background={background}
          azimuthAngle={azimuthAngle}
          setAzimuthAngle={setAzimuthAngle}
          onPositionChangeStarted={onPositionChangeStarted}
          activeMoverName={activeMoverName}
          setActiveMoverName={setActiveMoverName}
          onPositionChange={_handlePositionChange}
          onPositionChangeDone={_handlePositionChangeDone}
          pointcloud={pointcloud}
          // error={error}
          isUp
          isGridEnabled={false}
        />
      </div>
    </LoaderWrapper>
  )
}

export default TreeMover;
