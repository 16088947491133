const Plus = ({ width = 20, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10C13.75 10.3452 13.4702 10.625 13.125 10.625H10.625V13.125C10.625 13.4702 10.3452 13.75 10 13.75C9.65482 13.75 9.375 13.4702 9.375 13.125V10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H9.375V6.875C9.375 6.52982 9.65482 6.25 10 6.25ZM2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10ZM10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75Z'
        fill={color}
      />
    </svg>
  );
};

export default Plus;
