import { clone } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Tree from '../../@types/Tree';
import AttributesInput from '../../components/Microclimate/Attributes/AttributesInput';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import { BetaManagedAreaContextValue, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';
import { Grid, LoaderWrapper } from '../../layout';
import SingleTree from '../Completed/SingleTree';

type MicroclimateValidationProps = {
  match: any;
  currentTree: Tree;
  loaded: boolean;
  isToolbarVisible: boolean;
  pendingUpdateMicroclimateParameters: boolean;
  managedArea: BetaManagedAreaContextValue['managedArea'];
  treeId: string;
  contextsLoadingState: boolean;
  setCurrentTreeId: any;
  activeTabs: Record<'left' | 'right', string>;
  setActiveTabs: React.Dispatch<React.SetStateAction<null>>;
  todoTrees: Tree[];
  skippedTrees: any[];
};

const MicroclimateValidation = ({
  match,
  isToolbarVisible,
  pendingUpdateMicroclimateParameters,
  managedArea,
  treeId,
  contextsLoadingState,
  setCurrentTreeId,
  activeTabs,
  setActiveTabs,
  todoTrees,
  skippedTrees,
}: MicroclimateValidationProps) => {
  const [voxel, setVoxel] = useState(null);
  const [pointcloud, setPointcloud] = useState(null);
  const history = useHistory();

  const selectedTreeId = match.params?.tree;

  const { fetchTrees: reloadTrees, pipeline, fetch: reloadManagedArea } = useBetaManagedAreaContext();

  const betaTree = useBetaTree({
    treeId: selectedTreeId,
    managedAreaId: managedArea?.id,
    managedAreaCode: managedArea?.code,
    isMicroclimate: true,
  });

  const currentTree = clone(betaTree?.tree);

  useEffect(() => {
    setCurrentTreeId(currentTree?.id);
  }, [currentTree?.id]);

  const [panoramicLoading, setPanoramicLoading] = useState(false);

  const { moveToNeighbour, selectTree } = useBetaTreeIndex({
    trees: todoTrees,
    selectedTreeId: treeId,
    todoStatuses: undefined,
    setTree: (tree) =>
      typeof tree !== 'undefined' &&
      history.push(
        `/microclimate-input/${managedArea.id}/validation/${(tree as any)?.id}?left=${activeTabs.left}&right=${activeTabs.right}`
      ),
    isMicroclimate: true,
    skippedTrees,
  });

  if (!managedArea?.id || !pipeline?.id || !currentTree?.id) return null;

  const isLoading = () => {
    return !currentTree?.location || betaTree?.loading || contextsLoadingState || panoramicLoading;
  };

  return (
    <LoaderWrapper loading={!!pendingUpdateMicroclimateParameters || isLoading()}>
      <Grid
        fullHeight={true}
        tabcontent={
          currentTree && (
            <div className='microclimate-tight-side-wrapper tight-side-wrapper'>
              <AttributesInput
                tree={currentTree}
                managedAreaId={managedArea.id}
                activeTabs={activeTabs}
                setGenusAndSpecies={betaTree.setGenusAndSpecies}
                updateMicroclimate={betaTree.updateMicroclimate}
                addTreeComment={betaTree.addComment}
                moveToNeighbour={moveToNeighbour}
                updateTree={async (tree_flow_status: TreeFlowStatus.SpeciesValidationQueued | TreeFlowStatus.SpeciesValidationDone) => {
                  await betaTree.updateTree({ tree_flow_status });
                }}
                undo={{
                  onAction: () => console.log('Unimplemented feature -> onAction to history'),
                }}
                isToolbarVisible={isToolbarVisible}
                reloadTrees={reloadTrees}
                getGenuses={betaTree.getGenuses}
                getSpecies={betaTree.getSpecies}
                reloadManagedArea={reloadManagedArea}
              />
            </div>
          )
        }
        infocontent={undefined}
      >
        <div className='location-validation-wrapper microclimate-validation'>
          <div className='viewers'>
            <SingleTree
              tree={currentTree}
              activeTabs={activeTabs}
              setActiveTabs={setActiveTabs}
              selectTree={selectTree}
              setPanoramicLoading={setPanoramicLoading}
              {...{ voxel, setVoxel, pointcloud, setPointcloud }}
            />
          </div>
        </div>
      </Grid>
    </LoaderWrapper>
  );
};

export default MicroclimateValidation;
