import ArchiveModal from '../ArchiveModal/ArchiveModal';
import { Button } from '../inputs';

type ModalProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleArchive: () => void;
};

const ArchiveTreeModal = ({ visible, setVisible, handleArchive }: ModalProps) => {
  return (
    <ArchiveModal visible={visible} setVisible={setVisible} title={'Confirm deletion'}>
      <span style={{ marginBottom: '32px' }}>Do you really want to delete the tree?</span>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Button
          label={'YES'}
          onClick={handleArchive}
          style={{ width: '100%', marginBottom: 0, paddingTop: '4px', paddingBottom: '4px' }}
          classNames={'archive-button'}
        />
        <Button
          label={'NO'}
          onClick={() => setVisible(false)}
          style={{ width: '100%', marginBottom: 0, paddingTop: '4px', paddingBottom: '4px' }}
          classNames={'archive-button'}
        />
      </div>
    </ArchiveModal>
  );
};

export default ArchiveTreeModal;
