import { Vector3 } from 'three';
import Panorama from '../../Panorama';

const PanoramaView = (props: any) => {
  return (
    <>
      <Panorama
        treeId={props.currentTree?.id}
        managedAreaCode={props.currentTree?.managed_area}
        optionalPosition={props.currentTree?.location_local ? new Vector3().fromArray(props.currentTree?.location_local) : null}
        enabledMiniMap={false}
        enableGoogleMapsButton={false}
        enableAddTree={false}
      />
    </>
  );
};

export default PanoramaView;
