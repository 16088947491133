import React from "react";

const Popup = ({
  children,
  title,
  direction = "bottom",
  centerAlign,
  noHover,
  noDelay,
  active,
}) => {
  return (
    <div
      className={`popup-wrapper ${direction} ${noHover ? "" : "hover"} ${
        noDelay ? "" : "delay"
      } ${active ? "active" : ""}`}
    >
      {children}
      {title && (
        <div
          className="popup"
          style={{ textAlign: centerAlign ? "center" : "" }}
        >
          {title.split(/\[BR\]/g).map((line, i) => (
            <span key={i} className="line">
              {line}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Popup;
