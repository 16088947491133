import React, { useState, useEffect, useRef } from 'react';
import marked from 'marked';

const Help = ({ scrollTo }) => {
  const [help, setHelp] = useState('');

  // Load help markdown file from endpoint
  const _handleLoad = async () => {
    const res = await fetch(`/helps/rtms.md`);
    const text = await res.text();
    setHelp(text);
  }

  const scrollIntoView = (id) => {
    var el = document.getElementById(id);
    el && el.scrollIntoView();
  }

  useEffect(() => {
    _handleLoad();
  }, []);

  useEffect(() => {
    scrollTo && scrollIntoView(scrollTo);
  }, [help, scrollTo]);

  return (
    <div className="help-wrapper" dangerouslySetInnerHTML={{ __html: marked(help) }}>
    </div>
  )
}

export default Help;