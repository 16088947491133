import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo } from 'react';
import { Color, MathUtils, Vector2, Vector3 } from 'three';
import ValidationAction from '../../../enums/ValidationAction';
import { EllipseWithNormalAndHeight, useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import { convertValueBetweenRanges } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import EllipseGizmo from '../Gizmos/EllipseGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import ValidationControls from '../ValidationControls/ValidationControls';
import { calculateGirth } from '../ValidationUtils';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import useDefaultZoom from '../hooks/useDefaultZoom';

const CANOPY_ELLIPSE_COLORS = {
  dragPoint: new Color('#222D31'),
  dragPointHover: new Color(SemanticActionsColorMap[ValidationAction.Canopy]),
  dragPointBorder: new Color('#ffffff'),
  ellipseBody: new Color(SemanticActionsColorMap[ValidationAction.Canopy]),
};

const MIN_MAX_ZOOM = {
  min: 10,
  max: 300,
};

const CanopyView = () => {
  const { treeMetrics, visibility, pointCloudModule, validation, helper } = useSemanticScreenContext();
  const zoom = useDefaultZoom();

  const scaledZoom = useMemo(() => {
    if (!treeMetrics) return zoom * 4;
    const girth = calculateGirth(treeMetrics.canopy);
    const scaledZoom = convertValueBetweenRanges({ min: 0, max: girth + 1 }, { min: MIN_MAX_ZOOM.min, max: MIN_MAX_ZOOM.max }, girth);
    return MathUtils.clamp(scaledZoom, MIN_MAX_ZOOM.min, MIN_MAX_ZOOM.max);
  }, [treeMetrics, zoom]);

  const updateCanopyRadius = (axis: 'rX' | 'rY', radius: number): EllipseWithNormalAndHeight => {
    treeMetrics.setCanopy((prev) => ({ ...prev, [axis]: radius }));
    return { ...treeMetrics.canopy, [axis]: radius };
  };

  const updateCanopyCenter = (vector: Vector2) => treeMetrics.setCanopy((prev) => ({ ...prev, dX: vector.x, dY: vector.y }));

  const updateCanopyRotation = (rotation: number) => treeMetrics.setCanopy((prev) => ({ ...prev, rotation }));

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledZoom,
        }}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <TreePointCloud
            treePointCloud={pointCloudModule.pointCloud}
            envPointCloud={pointCloudModule.environmentPointCloud}
            isEnvVisible={pointCloudModule.isEnvironmentVisible}
            pointSize={pointCloudModule.pointSize}
            locationDelta={helper.locationDelta}
          />
          <DragPoint
            name={'tree-location-point'}
            initialPosition={new Vector3()}
            color={new Color('red')}
            draggable={false}
            visibility={true}
            handleSize={0.1}
          />
          {treeMetrics.canopy && (
            <EllipseGizmo
              name={ValidationAction.Canopy}
              ellipse={treeMetrics.canopy}
              colors={CANOPY_ELLIPSE_COLORS}
              visible={visibility.canopy}
              disabled={helper.isEditingDisabled || !!validation.isCanopyCompleted}
              handleSize={0.15}
              updates={{
                updateRadius: updateCanopyRadius,
                updateCenter: updateCanopyCenter,
                updateRotation: updateCanopyRotation,
              }}
            />
          )}
        </Suspense>

        <ValidationControls minPolarAngle={0} maxPolarAngle={0} minZoom={MIN_MAX_ZOOM.min} maxZoom={MIN_MAX_ZOOM.max} name='canopy' />
      </Canvas>
    </div>
  );
};

export default CanopyView;
