import React, { useState, useRef, Fragment } from 'react';
import Wrapper from './Wrapper';

const DropdownWrapper = ({ children, content }) => {
  const wrapper = useRef(null);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 240, y: 80 });

  const _handleToggle = (e) => {
    if (!content) return;
    e.preventDefault();
    e.stopPropagation();
    const { x, y, width, height } = wrapper.current.getBoundingClientRect();
    const pos = { x: x + width / 2, y: y + height };
    setPosition(pos);
    setVisible(true);
  }

  const _handleClose = () => setVisible(false);

  return (
    <Fragment>
      <div ref={wrapper} className="dropdown-handler" onClickCapture={_handleToggle}>
        { children }
      </div>
      { visible && <Wrapper content={content} onClose={_handleClose} position={position} /> }
    </Fragment>
  )
}

export default DropdownWrapper;
