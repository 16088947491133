import React, { useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';

import { useGlobalConfig } from './data';
import { useUser } from './user';

export const Idle = (props) => {

  const { loaded, globalConfig, getGlobalConfig } = useGlobalConfig();
  const { logout } = useUser();
  const timeout = useRef(15);
  const idleTimer = useRef(null);

  const handleOnIdle = event => {
    logout();
    idleTimer.current.pause();
  }

  const handleOnAction = event => {
    //console.log('user did something', event)
  }

  useEffect(() => {
    if (loaded) {
      timeout.current = getGlobalConfig('user-idle-timeout')?.value ?? 15;
    }
  }, [loaded, globalConfig]);

  return (<IdleTimer
    ref={idleTimer}
    timeout={1000 * 60 * timeout.current}
    onIdle={handleOnIdle}
    onAction={handleOnAction}
    debounce={250}>
    {props.children}
  </IdleTimer>);
};
