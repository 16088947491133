import React, { createContext, useContext, useEffect, useState } from 'react';

import { PresentModalParams } from '../@types/Modal';
import { Icon } from '../components';
import { useFadeInOut } from '../layout/FadeInOutHook';

type ModalContext = {
  presentModal?: (content: PresentModalParams) => void;
  dismissModal?: () => void;
  isVisible: boolean;
};

type ModalParams = {
  isVisible: boolean;
  title?: string;
  isMounted: boolean;
  content?: React.ReactNode;
  dismissModal: () => void;
};

export const Context = createContext<ModalContext>({ isVisible: false });

export const useModal = () => useContext(Context);

export const withModal = (Component: any) => (props: any) =>
  <Context.Consumer>{(theme) => <Component {...props} theme={theme} />}</Context.Consumer>;

export const Provider = (props: any) => {
  const { isMounted, isVisible, startFadeIn, startFadeOut } = useFadeInOut(500);
  const [content, setContent] = useState<PresentModalParams | null>(null);

  const presentModal = (content: PresentModalParams) => {
    setContent(content);
    startFadeIn();
  };

  const dismissModal = () => startFadeOut();

  const _handleKeyDown = (e: KeyboardEvent) => e.key === 'Escape' && dismissModal();

  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => window.removeEventListener('keydown', _handleKeyDown);
  }, []);

  return (
    <Context.Provider value={{ isVisible, presentModal, dismissModal }}>
      <Modal {...{ isVisible, isMounted, ...content, dismissModal }} />
      {props.children}
    </Context.Provider>
  );
};

const Modal = ({ isVisible, isMounted, title = 'MODAL TITLE', content = null, dismissModal = () => {} }: ModalParams) => {
  return (
    <div className={`modal-outer-wrapper ${isVisible ? 'visible' : 'hidden'}`}>
      {isMounted && (
        <React.Fragment>
          <div className='backdrop' onClick={dismissModal} />
          <div className='modal-wrapper'>
            <div className='header'>
              <span>{title}</span>
              <div className='close-wrapper' onClick={dismissModal}>
                <Icon icon='times' />
              </div>
            </div>
            <div className='content-wrapper'>{content}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
