import { memo } from 'react';
import { ColumnType } from '../../@types/Table';
import formatter from './helpers';

type CellPropsType = {
  entry: any;
  format?: string;
  column: ColumnType;
  label?: string;
  resolver?: string | (() => null);
  updateTree?: any;
  subTitle?: boolean;
  subTitleAction?: (exportCsvCallback: any) => void;
  selected?: boolean;
};

const TableCell = memo(({ entry, resolver, format, column, label, updateTree, selected }: CellPropsType) => {
  const value = Object.byResolver(entry, resolver);
  return <td>{formatter(column, format, value, label, entry, updateTree, selected)}</td>;
});

export default TableCell;
