const Archive = ({ width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2625_4655)'>
        <path
          d='M5.83301 5H14.1663M5.83301 7.5H14.1663M7.49967 14.1667H12.4997'
          stroke='#E8220C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.50033 9.99984H2.16699C2.03438 9.99984 1.90721 10.0525 1.81344 10.1463C1.71967 10.2401 1.66699 10.3672 1.66699 10.4998V17.8332C1.66699 17.8988 1.67992 17.9638 1.70505 18.0245C1.73018 18.0852 1.76701 18.1403 1.81344 18.1867C1.85987 18.2332 1.91499 18.27 1.97565 18.2951C2.03631 18.3202 2.10133 18.3332 2.16699 18.3332H17.8337C17.8993 18.3332 17.9643 18.3202 18.025 18.2951C18.0857 18.27 18.1408 18.2332 18.1872 18.1867C18.2336 18.1403 18.2705 18.0852 18.2956 18.0245C18.3207 17.9638 18.3337 17.8988 18.3337 17.8332V10.4998C18.3337 10.3672 18.281 10.2401 18.1872 10.1463C18.0934 10.0525 17.9663 9.99984 17.8337 9.99984H17.5003M2.50033 9.99984V2.1665C2.50033 2.0339 2.553 1.90672 2.64677 1.81295C2.74054 1.71918 2.86772 1.6665 3.00033 1.6665H17.0003C17.1329 1.6665 17.2601 1.71918 17.3539 1.81295C17.4476 1.90672 17.5003 2.0339 17.5003 2.1665V9.99984M2.50033 9.99984H17.5003'
          stroke='#E8220C'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_2625_4655'>
          <rect width={width} height={height} fill='#E8220C' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Archive;
