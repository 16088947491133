import React, { useEffect, useState } from 'react';
import useComments from '../../hooks/comments';
import { Button } from '../inputs';
import { Icon } from '../../components';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import Popup from '../Popup';

const CommentTree = ({ onComment = () => {}, onRemoveComment = () => {}, tree, isErrorMode, onCancel }) => {
  const { getCommentArea, getFinalComment, loadCommentsForTree } = useComments();
  const [prevComments, setPrevComments] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [removableCommentId, setRemovableCommentId] = useState(null);
  
  const handleConfirmationPopupState = (commentId) => {
    setRemovableCommentId(commentId);
    setConfirmationPopup(!confirmationPopup);
  };

  useEffect(() => {
    if (isErrorMode || !tree?.id) return;
    
    loadCommentsForTree(tree.id)
      .then((res) => setPrevComments(res))
      .catch(() => setLoadingError(true));
  }, [tree.id, isErrorMode, loadCommentsForTree]);

  const _handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!getFinalComment()) return;

    onComment(tree, {
      value: getFinalComment(),
    }).then(() => loadCommentsForTree(tree.id).then((res) => setPrevComments(res)).catch(() => setLoadingError(true)));
  };

  const _removeComment = () => {
    onRemoveComment(removableCommentId, prevComments.find((pc) => pc.id === removableCommentId));
    setPrevComments(prevComments.filter((pc) => pc.id !== removableCommentId));
  };

  const _handleErrorSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    const comment = getFinalComment();
    if (!comment) return;
    onComment(comment);
  };

  return (
    <div className='comments' style={isErrorMode && { display: 'flex' }}>
      <form onSubmit={isErrorMode ? _handleErrorSubmit : _handleSubmit} style={isErrorMode && { width: '100%' }}>
        {getCommentArea()}
        <Button label='Add comment' primary />
        {onCancel && <Button label='Cancel' onClick={onCancel} />}
      </form>
      {!isErrorMode && (
        <>
          <div className='comments-divider'></div>
          <div>
            Previous comments:
            {loadingError && 'Error downloading previous comments!'}
            <ol className='previous-comments'>
              {prevComments.map((c, i) => (
                <li key={i}>
                  <div className='previous-comment-container'>
                    <div className='comment-details'>
                      {c.value}
                      {c.creator && c.date && (
                        <small>
                          {c.creator} ({new Date(c.date).toLocaleString()})
                        </small>
                      )}
                    </div>
                    <div className='svg-container' onClick={() => handleConfirmationPopupState(c.id)}>
                      <Popup direction='bottom-left' centerAlign={true} noHover={false} noDelay={undefined} active={confirmationPopup}>
                        {confirmationPopup && c.id === removableCommentId && (
                          <ConfirmationPopup action={_removeComment} handleConfirmationPopupState={handleConfirmationPopupState} title={'Do you really want to delete the comment?'}/>
                        )}
                      </Popup>
                      <Icon icon='times' />
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentTree;
