import { Lens } from '@dhmk/zustand-lens';
import { Vector3 } from 'three';
import Number3 from '../@types/Number3';
import Tree from '../@types/Tree';
import { TreeTrunk, TreeTrunkPartial } from '../@types/TreeTrunk';
import { calculateGirth } from '../components/SemanticsValidation/ValidationUtils';
import { EllipseWithNormalAndHeight } from './ActionSlices';
import { DEFAULT_ELLIPSE } from '../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants';

export type Girth = EllipseWithNormalAndHeight & { local_id: string };

export type TrunkWithGirths = {
  id?: number | string;
  tree_id?: number | string;
  local_id: string;
  girths: Girth[];
};

const convertTrunkValuesToGirths = (trunk: TreeTrunkPartial): Girth[] => {
  const girths: Girth[] = [];
  
  if (!trunk.girth_1) {
    girths.push({
      local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    ...DEFAULT_ELLIPSE,
    })
  } else {
    girths.push({
      local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
      diameter: trunk.girth_1 as number,
      height: (trunk.measurement_height_1 || 1) as number,
      normal: new Vector3().fromArray(trunk.normal_vector_1!.coordinates),
      rX: trunk.trunk_ellipse_a_1 as number,
      rY: trunk.trunk_ellipse_b_1 as number,
      dX: trunk.trunk_offset_x_1 as number,
      dY: trunk.trunk_offset_y_1 as number,
      rotation: trunk.trunk_ellipse_direction_1 as number,
      validated: trunk.validated as boolean
    });
  }
  if (trunk.measurement_height_2 && trunk.girth_2) {
    girths.push({
      local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
      diameter: trunk.girth_2 as number,
      height: trunk.measurement_height_2 as number,
      normal: new Vector3().fromArray(trunk.normal_vector_2!.coordinates),
      rX: trunk.trunk_ellipse_a_2 as number,
      rY: trunk.trunk_ellipse_b_2 as number,
      dX: trunk.trunk_offset_x_2 as number,
      dY: trunk.trunk_offset_y_2 as number,
      rotation: trunk.trunk_ellipse_direction_2 as number,
    });
  }

  return girths;
};

export const transformToTrunkWithGirths = (trunks: TreeTrunk[]): TrunkWithGirths[] => {
  const transformedTrunks: TrunkWithGirths[] = trunks?.map((trunk) => ({
    id: trunk.id,
    tree_id: trunk.tree_id,
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    girths: convertTrunkValuesToGirths(trunk),
  }));
  return transformedTrunks ?? [];
};

export type TreeSlice = {
  tree: Tree | null;
  treeTrunks: TrunkWithGirths[];
  treeIndex: number | null;
  location: Number3;
  todoTrees: Tree[];
  viewerPosition: Vector3;

  actions: {
    setTree: (tree: Tree) => void;
    trunkActions: {
      setTreeTrunks: (trunks: TreeTrunk[]) => void;
      addTreeTrunk: (trunk: TrunkWithGirths) => void;
      removeTreeTrunk: (trunkId: string) => void;
      addGirthToTrunk: (trunkId: string, girth: Girth) => void;
      removeGirth: (trunkId: string, girth: Girth) => void;
      updateGirth: (girth: Girth) => void;
    };

    setTodoTrees: (todoTrees: Tree[]) => void;
    setTreeIndex: (i: number) => void;
    setLocation: (position: Number3) => void;
    setViewerPosition: (position: Vector3) => void;
  };
};

const createTreeSlice: Lens<TreeSlice> = (set, get) => ({
  tree: null,

  treeTrunks: [],

  treeIndex: null,

  location: [0, 0, 0],

  todoTrees: [],

  viewerPosition: new Vector3(),

  actions: {
    setTree: (tree) => set({ tree }),

    setTreeIndex: (i) => set({ treeIndex: i }),

    setTodoTrees: (todoTrees) => set({ todoTrees }),

    setLocation: (location) => set({ location }),

    setViewerPosition: (viewerPosition) => set({ viewerPosition }),

    trunkActions: {
      setTreeTrunks: (trunks: TreeTrunk[]) => {
        const transformed = transformToTrunkWithGirths(trunks);

        set({ treeTrunks: transformed });
      },

      addTreeTrunk: (trunk: TrunkWithGirths) => {
        const trunks = get()?.treeTrunks;
        trunks.push(trunk);
        set({
          treeTrunks: trunks,
        });
      },

      removeTreeTrunk: (trunkId: string) => {
        set({
          treeTrunks: get()?.treeTrunks.filter((t) => t.local_id !== trunkId),
        });
      },

      addGirthToTrunk: (trunkId: string, girth: Girth) => {
        const trunks = get().treeTrunks;
        const index = trunks.findIndex((t) => t.local_id === trunkId);
        trunks[index].girths.push(girth);
        set({
          treeTrunks: trunks,
        });
      },

      removeGirth: (trunkId: string, girth: Girth) => {
        const trunks = get().treeTrunks;
        const index = trunks.findIndex((t) => t.local_id === trunkId);
        trunks[index].girths = trunks[index].girths.filter((g) => g.local_id !== girth.local_id);
        set({
          treeTrunks: [...trunks],
        });
      },

      updateGirth: (girth: Girth) => {
        const trunks = get().treeTrunks;
        for (let index = 0; index < trunks.length; index++) {
          const element = trunks[index];
          const girthIndex = element.girths.findIndex((g) => g.local_id === girth.local_id);
          if (girthIndex >= 0) {
            element.girths[girthIndex] = { ...girth, diameter: calculateGirth(girth) };
            break;
          }
        }
        set({
          treeTrunks: [...trunks],
        });
      },
    },
  },
});

export default createTreeSlice;
