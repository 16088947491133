import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs/Tabs';

const AttributesTab: typeof Tabs = styled(Tabs)(({ theme }) => {
  return {
    marginBottom: 2,
    minHeight: 40,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.text.primary,
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      fontSize: 13,
      fontWeight: 700,
      padding: '8px !important',
      height: 'fit-content',
      minHeight: 'fit-content',
    },
  };
});

export default AttributesTab;
