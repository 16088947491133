import { PropsWithChildren } from 'react';

const ToolBarWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        zIndex: 1,
        position: 'absolute',
        overflow: 'visible',
        width: '100%',
        height: '55px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      {children}
    </div>
  );
};

export default ToolBarWrapper;
