import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import { Points, PointsMaterial, Shader, Vector3 } from 'three';
import { calculateClippingPlanes } from '../../../utils/threeJsHelper';

const addShaders = (shader: Shader) => {
  shader.vertexShader = `
      attribute float visibility;
      varying float vVisible;
    ${shader.vertexShader}`.replace(
    `gl_PointSize = size;`,
    `gl_PointSize = size;
    vVisible = visibility;`
  );
  shader.fragmentShader = `
      varying float vVisible;
    ${shader.fragmentShader}`.replace(
    `#include <clipping_planes_fragment>`,
    `
      if (vVisible < 0.5) discard;
      #include <clipping_planes_fragment>`
  );
};

type TreePointCloudProps = {
  section?: { depth: number; lookAt?: Vector3 | null; lookFrom?: Vector3 | null };
  treePointCloud: any;
  envPointCloud: any;
  isEnvVisible: boolean;
  pointSize: number;
  locationDelta: Vector3;
};

const TreePointCloud = ({ section, treePointCloud, envPointCloud, isEnvVisible, pointSize, locationDelta }: TreePointCloudProps) => {
  const pointsMaterialRef = useRef<PointsMaterial>(null);
  const envPointsMaterialRef = useRef<PointsMaterial>(null);
  const envPointsRef = useRef<Points>(null);
  const pointsRef = useRef<Points>(null);

  // SETUP CLIPPING
  useFrame(() => {
    if (!section?.lookAt || !section.lookFrom) return;

    const { nearClipPlane, farClipPlane } = calculateClippingPlanes(section?.lookAt, section.lookFrom, section?.depth);
    const clipPlanes = [nearClipPlane, farClipPlane];

    if (pointsMaterialRef?.current) pointsMaterialRef.current.clippingPlanes = clipPlanes;
    if (envPointsMaterialRef?.current) envPointsMaterialRef.current.clippingPlanes = clipPlanes;
  });

  const treePointsMaterial = useMemo(() => {
    return <pointsMaterial ref={pointsMaterialRef} size={pointSize} vertexColors sizeAttenuation onBeforeCompile={addShaders} />;
    // Add dependencies if you want to change the complete material, so color, size, etc changes are reflected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treePointCloud?.geometry?.attributes?.color, pointSize]);

  const envPointsMaterial = useMemo(() => {
    return <pointsMaterial ref={envPointsMaterialRef} size={pointSize} vertexColors sizeAttenuation onBeforeCompile={addShaders} />;
    // Add dependencies if you want to change the complete material, so color, size, etc changes are reflected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envPointCloud?.geometry?.attributes?.color, pointSize]);

  useEffect(() => {
    if (!treePointCloud || !envPointCloud || !isEnvVisible || !envPointsRef.current) return;

    const treeMins = new Vector3(...treePointCloud.pc.mins);
    const envMins = new Vector3(...envPointCloud.pc.mins);

    const diffBetweenTreeAndEnvironment = envMins.clone().sub(treeMins);
    envPointsRef.current.position.copy(diffBetweenTreeAndEnvironment);
  }, [treePointCloud, envPointCloud, isEnvVisible]);

  useEffect(() => {
    if (!locationDelta || !pointsRef?.current) return;

    pointsRef.current.position.copy(locationDelta.clone());
  }, [locationDelta, pointsRef]);

  return (
    <>
      <points ref={pointsRef} name='TreePointCloud'>
        <bufferGeometry attributes={treePointCloud?.geometry.attributes} />
        {treePointsMaterial}
      </points>
      {envPointCloud && isEnvVisible && (
        <points name='EnvPointCloud' ref={envPointsRef}>
          <bufferGeometry attributes={envPointCloud?.geometry.attributes} />
          {envPointsMaterial}
        </points>
      )}
    </>
  );
};

export default TreePointCloud;
