import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MANAGED_AREA_STATUSES, ManagedAreaStatuses } from "../../hooks/betaHooks/beta-managed-areas-context";
import { DropDownOption } from "../Map/TableFilter";

interface Props {
    options: DropDownOption[];
    value: string | boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onChange: (value: ManagedAreaStatuses) => void;
}

export const CustomSelect = ({
    options,
    value,
    isOpen,
    setIsOpen,
    onChange,

}: Props) => {

    const renderOptions = () => (options.map((option) => {
        return (
            <MenuItem key={option.id} value={option.value}>
                {option.label}
            </MenuItem>
        );
    }));

    const handleClick = (e: any) => {
        e.preventDefault();
        setIsOpen((prev) => !prev)
    };

    return (
        <div className="filter-select-component">
            <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="select-label" style={{
                    color: '#fff',
                }} className="filter-select-label">MA Status</InputLabel>
                <Select
                    labelId="select-label"
                    id="ma-status-select"
                    value={value ? value : MANAGED_AREA_STATUSES.default}
                    onChange={(e) => onChange(e?.target?.value?.toString() as ManagedAreaStatuses)}
                    autoWidth
                    open={isOpen}
                    onClick={(e) => handleClick(e)}
                >
                    {renderOptions()}
                </Select>
            </FormControl>
        </div>
    )
}