import { useState, useRef } from "react";

const inputStyle = {
  width: "40px",
};

const ENTER_KEY = 13;

const RadiusInput = ({
  treeValue,
  setValue,
  calculateCircumference,
  label,
  top,
}) => {
  let style = {
    display: "inline-block",
    position: "absolute",
    right: "5vw",
    zIndex: 99,
    top: "45vh",
  };

  const [inputValue, setInputValue] = useState(
    calculateCircumference(treeValue)
  );
  const inputRef = useRef(null);

  style.top = top;

  const handleChange = (value) => {
    const copyValue = {
      ...treeValue,
    };

    if (value[value.length - 1] === ".") {
      setInputValue(value);
    } else {
      let parsed = parseFloat(value);
      const r = parsed / (2 * Math.PI);
      copyValue.rX = r;
      copyValue.rY = r;

      if (!parsed) {
        copyValue.rX = 0;
        copyValue.rY = 0;
      }

      setInputValue(value);
      setValue(copyValue);
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault();
      e.target.blur();
    }
  };

  if (document.activeElement !== inputRef.current) {
    if (
      inputValue !== calculateCircumference(treeValue).toFixed(2) &&
      inputValue[inputValue.length - 1] !== "."
    ) {
      setInputValue(calculateCircumference(treeValue).toFixed(2));
    }
  }

  return (
    <div style={style}>
      <label>{label}</label>
      <input
        style={inputStyle}
        type="text"
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        onKeyUp={handleKeyUp}
        ref={inputRef}
      />
    </div>
  );
};

export default RadiusInput;
