const Cryptr = require("cryptr");

let decryptedGlobalConfig = null;

const getDecryptedConfig = (configKey) => {
  if (window.DISABLE_RTMS_APP_CONFIG_ENCRYPTION) {
      return window.rtmsAppConfig?.[configKey] || null;
  }

  const globalConfig = window.rtmsAppConfig;
  if (!globalConfig) {
    return false;
  }

  if (!decryptedGlobalConfig) {
    const cryptr = new Cryptr(process.env.REACT_APP_ENCRYPTION_KEY);
    decryptedGlobalConfig = JSON.parse(cryptr.decrypt(globalConfig));
  }

  return decryptedGlobalConfig[configKey];
};

const getVariable = (name) => getDecryptedConfig(name) || process.env[name];

const appConfig = {
  auth: {
    clientId: getVariable("REACT_APP_AZURE_CLIENT"),
    authority: getVariable("REACT_APP_AZURE_AUTHORITY"),
    scope: getVariable("REACT_APP_AZURE_SCOPE"),
    disableAD: getVariable("REACT_APP_DISABLE_AD"),
  },
  server: {
    endpoint: getVariable("REACT_APP_SERVER_ENDPOINT"),
  },
  map: {
    center: {
      lat: getVariable("REACT_APP_MAP_CENTER_LAT"),
      lng: getVariable("REACT_APP_MAP_CENTER_LNG"),
      zoom: getVariable("REACT_APP_MAP_CENTER_ZOOM"),
    },
  },
  defaultProject: getVariable("REACT_APP_DEFAULT_PROJECT"),
  maxUndo: getVariable("REACT_APP_MAX_UNDO") ?? 8,
};

export default appConfig;
