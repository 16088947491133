const TickGrey = ({ width = 20, height = 20, color = '#494B4D' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 18.3334C15.1025 18.3334 18.8333 14.6026 18.8333 10.0001C18.8333 5.39758 15.1025 1.66675 10.5 1.66675C5.89746 1.66675 2.16663 5.39758 2.16663 10.0001C2.16663 14.6026 5.89746 18.3334 10.5 18.3334Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.33337 10.4167L8.83337 12.9167L14.6667 7.08337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TickGrey;
