import { extend, ReactThreeFiber } from '@react-three/fiber';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { BufferGeometry, Color, DoubleSide, Line, Mesh, Vector3 } from 'three';
import useForwardedRef from '../SemanticsValidation/hooks/useForwardedRef';

extend({ Line_: Line });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      line_: ReactThreeFiber.Object3DNode<THREE.Line, typeof Line>;
    }
  }
}

const LineObject = ({ start, end }: { start: Vector3; end: Vector3 }) => {
  const lineBufferGeom = useMemo(() => new BufferGeometry().setFromPoints([start, end]), [end, start]);

  return (
    <line_ geometry={lineBufferGeom}>
      <lineDashedMaterial color='red' linewidth={1.5} dashSize={0.1} gapSize={0.05} linecap='round' linejoin='round' />
    </line_>
  );
};

const Sphere = ({ position, color, size }: { position: Vector3; color: Color; size: number }) => {
  const mesh = useRef<Mesh>(null);

  useEffect(() => {
    if (!mesh.current) return;
    mesh.current.position.copy(position);
  }, [position]);

  return (
    <mesh ref={mesh} position={position}>
      <sphereGeometry args={[size]} attach='geometry' />
      <meshBasicMaterial color={color} attach='material' />
    </mesh>
  );
};

const PlaneHelper = forwardRef<Mesh, { position?: Vector3; normal?: Vector3; color: Color; size: number }>(
  ({ position, normal, color, size }, ref) => {
    const mesh = useForwardedRef<Mesh>(ref);

    useEffect(() => {
      if (!mesh.current || !position) return;
      mesh.current.position.copy(position);
      if (!normal) return;
      mesh.current.lookAt(normal);
      // Add normal to rotate the plane on camera movement
    }, []);

    return (
      <mesh ref={mesh} position={position}>
        <planeGeometry args={[size, size]} attach='geometry' />
        <meshBasicMaterial color={color} attach='material' side={DoubleSide} />
      </mesh>
    );
  }
);

const AxesHelper = ({ size }: { size: number }) => {
  return (
    <group>
      <line_ geometry={new BufferGeometry().setFromPoints([new Vector3(), new Vector3(0, 0, size)])}>
        <lineDashedMaterial color='red' linewidth={1.5} dashSize={0.1} gapSize={0.05} linecap='round' linejoin='round' />
      </line_>
      <line_ geometry={new BufferGeometry().setFromPoints([new Vector3(), new Vector3(0, size, 0)])}>
        <lineDashedMaterial color='blue' linewidth={1.5} dashSize={0.1} gapSize={0.05} linecap='round' linejoin='round' />
      </line_>
      <line_ geometry={new BufferGeometry().setFromPoints([new Vector3(), new Vector3(size, 0, 0)])}>
        <lineDashedMaterial color='green' linewidth={1.5} dashSize={0.1} gapSize={0.05} linecap='round' linejoin='round' />
      </line_>
    </group>
  );
};

export { AxesHelper, PlaneHelper, Sphere, LineObject };
