import { SxProps, useTheme as useMuiTheme } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { useCallback, useMemo } from 'react';

type SliderProps = {
  value?: number;
  onChange?: (sliderValue: number) => void;
  onSave?: (value: number) => void;
  min: number | undefined;
  max: number | undefined;
  step: number;
  vertical?: boolean;
  sx?: SxProps;
  disabled?: boolean;
};

const LineSlider = ({
  value,
  onChange,
  onSave,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step,
  vertical,
  sx,
  disabled = false,
}: SliderProps): JSX.Element => {
  const muiTheme = useMuiTheme();

  const roundToClosestStepMultiple = useCallback(
    (value: number, roundTo: 'floor' | 'ceil') => {
      if (roundTo === 'floor') {
        return Math.floor(value / step) * step;
      }
      return Math.ceil(value / step) * step;
    },
    [step]
  );

  const backgroundRail = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          position: 'absolute',
          left: vertical ? '-21px' : 0,
          right: 0,
          width: '95px',
          height: '2px',
          backgroundColor: '#202324',
          transform: vertical ? 'rotate(270deg)' : '',
        }}
      ></div>
    );
  }, [vertical]);

  const getValues = (newValue: number) => {
    const isMax = newValue >= max;
    const offset = newValue % step === 0 ? 0 : -1;
    const middleOfInterval = roundToClosestStepMultiple((newValue as number) + offset, 'floor') + step / 2;
    const middleOfLastInterval = max - step + step / 2;
    const sliderValue = isMax ? middleOfLastInterval : middleOfInterval;
    const domainValue = roundToClosestStepMultiple(sliderValue, 'ceil') / step;
    return { sliderValue: newValue, domainValue };
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: vertical ? '20px' : '95px',
        height: vertical ? '95px' : '',
        justifyContent: vertical ? 'flex-end' : '',
      }}
    >
      {backgroundRail}
      <MuiSlider
        value={value || 0}
        onChange={(event, newValue) => {
          onChange?.(newValue as number);
        }}
        onChangeCommitted={() => {
          const { domainValue } = getValues(value as number);
          onSave?.(domainValue);
        }}
        size='small'
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        sx={{
          '& .MuiSlider-thumb': {
            height: 0,
            width: 0,
            border: `6px solid ${muiTheme.palette.text.primary}`,
            borderRadius: '1px',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderTop: '4px solid transparent',
            borderBottom: `8px solid ${muiTheme.palette.text.primary}`,
            backgroundColor: 'transparent',
            transform: vertical ? 'rotate(-90deg)' : 'translate(-50%, -5px)',
            ':after, :before': {
              display: 'none',
            },
            left: vertical ? '24px' : 0,
          },
          '& .Mui-active, .Mui-focusVisible, :hover': {},
          '& .MuiSlider-rail': {
            display: 'none!important',
          },
          '& .MuiSlider-track': {
            display: 'none',
          },
          ...sx,
        }}
        orientation={vertical ? 'vertical' : 'horizontal'}
      />
    </div>
  );
};

export default LineSlider;
