import React, {useState} from 'react';
import PieChart from './PieChart';
import { useUser } from '../providers/user';

export const treeStatusData = {
  deleted: {
    name: 'Deleted',
    value: 'deleted'
  },
  completed: {
    name: 'Completed',
    value: 'completed'
  },
  in_progress: {
    name: 'In progress',
    value: 'in_progress'
  },
  pending: {
    name: 'Pending',
    value: 'pending'
  }
}

export const maStatusData = {
  overdue: {
    name: 'Overdue',
    value:'overdue'
  },
  needed_in_2_weeks: {
    name: 'Needed in 2 weeks',
    value:'needed_in_2_weeks'
  },
  needed_in_1_month: {
    name: 'Needed in 1 month',
    value:"needed_in_1_month"
  }
}

const Status = ({ status, value, onClick, isSelected }) => {
  return (
    <div
        className={`status-wrapper${(isSelected ? ' selected' : '')}`}
        onClick={() => {onClick && onClick(status.value)}}
    >
      <span className='value'>{value}</span>
      <span className={`dot ${status.value}`}/>
      <span className='label'>{status.name}</span>
    </div>
  );
};

const ScanStatus = ({
  trees: { pending = 0, inprogress = 0, completed = 0 },
  mas: { overdue = 0, weeks = 0, month = 0} = {},
  onFilterChange
}) => {
  const treesSum = pending + inprogress + completed;

  const [treeFilterSelected, setTreeFilterSelected] = useState();
  const [maFilterSelected, setMAFilterSelected] = useState();

  const getTreeFilterSelected = (newSelected) => {
    return (newSelected === treeFilterSelected) ? null : newSelected;
  }

  const onTreeFilterClick = (statusFilter) => {
    const selected = getTreeFilterSelected(statusFilter);
    setTreeFilterSelected(selected);
    if (onFilterChange){
      onFilterChange(selected, maFilterSelected)
    }
  }

  const isSelectedByTreeFilter = ({value}) => {
    return treeFilterSelected === value;
  }

  return (
    <div className='scan-status-wrapper'>
      <div className='side'>
        <PieChart
          size={160}
          value={[
            {
              value: inprogress / treesSum,
              color: '#F7B500',
            },
            {
              value: pending / treesSum,
              color: '#FF455E',
            },
            {
              value: completed / treesSum,
              color: '#9bd600',
            },
          ]}
        />
        <div className='trees'>
          <div className='sum'>
            <span className='value'>{treesSum}</span>
            <span className='label'>trees</span>
          </div>
          <div className='separator'>
            <div className='indicator' />
            <div className='indicator' />
            <div className='indicator' />
          </div>
          <div className='statuses'>
            <Status value={completed} isSelected={isSelectedByTreeFilter(treeStatusData.completed)} status={treeStatusData.completed} onClick={onTreeFilterClick}/>
            <Status value={inprogress} isSelected={isSelectedByTreeFilter(treeStatusData.in_progress)} status={treeStatusData.in_progress} onClick={onTreeFilterClick}/>
            <Status value={pending} isSelected={isSelectedByTreeFilter(treeStatusData.pending)} status={treeStatusData.pending} onClick={onTreeFilterClick}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanStatus;
