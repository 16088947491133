import { Lens } from '@dhmk/zustand-lens';

export enum ToastType {
  INFO = 'info',
  ERROR = 'error',
}

type Toast = {
  message: string;
  type: ToastType;
};

export type ToastSlice = {
  toasts: Toast[];
  toast: { message: string | null; visible: boolean; type?: ToastType };
  showToast: (message: string, type?: ToastType, timeout?: number) => void;
  addToast: (message: string, type: ToastType, timeout?: number) => void;
};

const createToastSlice: Lens<ToastSlice> = (set, get) => ({
  toast: { message: null, visible: false, type: ToastType.INFO },
  toasts: [],

  showToast: (message, type = ToastType.INFO, timeout = 6000) => {
    set({ toast: { message, visible: true, type } });
    setTimeout(() => {
      set({ toast: { ...get().toast, visible: false } });
    }, timeout);
  },
  addToast: (message, type = ToastType.INFO, timeout = 5000) => {
    set({ toasts: [...get().toasts, { message, type }] });
    setTimeout(() => {
      const toasts = get().toasts.splice(1);
      set({ toasts });
    }, timeout);
  },
});

export default createToastSlice;
