const TopDown = () => {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.83331 8.23246C3.83331 12.0416 8.59498 14.8991 8.59498 14.8991H12.405C12.405 14.8991 17.1666 12.0416 17.1666 8.23246C17.1666 4.4233 14.1816 1.5658 10.5 1.5658C6.81831 1.5658 3.83331 4.4233 3.83331 8.23246Z'
        strokeWidth='0.85'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 1.98248C5.5 6.98248 8.83334 14.8991 8.83334 14.8991M12.9033 1.98248C15.4033 6.98248 12.07 14.8991 12.07 14.8991'
        strokeWidth='0.85'
        strokeLinejoin='round'
      />
      <path
        d='M11.6666 19.4825H9.33331C9.20071 19.4825 9.07353 19.4298 8.97976 19.336C8.88599 19.2423 8.83331 19.1151 8.83331 18.9825V17.4825C8.83331 17.3499 8.88599 17.2227 8.97976 17.1289C9.07353 17.0352 9.20071 16.9825 9.33331 16.9825H11.6666C11.7993 16.9825 11.9264 17.0352 12.0202 17.1289C12.114 17.2227 12.1666 17.3499 12.1666 17.4825V18.9825C12.1666 19.1151 12.114 19.2423 12.0202 19.336C11.9264 19.4298 11.7993 19.4825 11.6666 19.4825V19.4825Z'
        strokeWidth='0.85'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default TopDown;
