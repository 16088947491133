import React from 'react';
import { Skeleton } from '../layout';
import { Icon } from '../components';
import { withRouter } from 'react-router-dom';
const ErrorOccurred = () => {
  return (
    <Skeleton header={{ title: null, user: {} }}>
      <div className="notfound-wrapper">
        <Icon icon='rtms' />
        <h1>Sorry, something went wrong.</h1>
        <p>We're working on it and we'll get it fixed as soon as we can.</p>
      </div>
    </Skeleton>
  )
}

export default withRouter(ErrorOccurred);