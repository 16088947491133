import { useRef } from "react";

const Color = ({
  label,
  value,
  onChange = ({ name, value }) => {},
  onPureChange = (val) => {},
  name,
  disabled,
}) => {
  const _input = useRef(null);
  const _handleClick = () => _input.current && _input.current.focus();
  const _handleChange = ({ target: { value } }) => {
    onPureChange(value);
    onChange({ name, value: value });
  };

  return (
    <div
      className={`color-input-wrapper regular-button-states ${
        disabled ? "disabled" : ""
      } ${value ? "active" : "inactive"}`}
      onClick={_handleClick}
    >
      <span className="label">{label}</span>
      <div className="color" style={{ background: value }}></div>
      <input type="color" onChange={_handleChange} ref={_input} value={value} />
    </div>
  );
};

export default Color;
