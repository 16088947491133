import { useScreenContext } from '../../hooks/betaHooks/semantic-screen-context';
import IconButton from '../inputs/IconButton';
import VerticalSlider from './VerticalSlider';

type SectionControlProps = {
  depth: number;
  height: number;
  setDepth: (value: number) => void;
  setHeight: (value: number) => void;
  disabled: {
    height: boolean;
    depth: boolean;
  };
};

const SectionControl = ({ depth, height, setDepth, setHeight, disabled }: SectionControlProps) => {
  const { helper: { viewerPosition }, section, pointCloudModule: { pointCloud } } = useScreenContext();

  const customeStyle: React.CSSProperties = { width: '20px', textAlign: 'center', color: 'white', fontSize: '10px' };
  const outputConverter = (value: number) => Math.floor(value * 100);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          margin: '14px 25px',
          position: 'relative',
          zIndex: 999,
        }}
      >
        <IconButton icon={'section-control'} onClick={() => section.setVisibility(!section.visibility)} />
        <span>Section controls</span>
      </div>
      <div className={`section-control-container ${section.visibility ? 'open' : 'closed'}`}>
        {section.visibility && (
          <>
            <div className='section-control-select-arrow' />
            <div className='section-control-controls' style={{ display: 'flex' }}>
              <VerticalSlider
                disabled={disabled.height}
                name={'Height'}
                value={height}
                setValue={(value) => !disabled.height && (value >= 0 ? setHeight(value) : setHeight(1))}
                step={0.1}
                max={(pointCloud?.geometry.boundingBox?.max.z ?? 20) - viewerPosition.z}
                min={0}
                inputConverter={(value: string) => parseInt(value) / 100}
                outputConverter={outputConverter}
                customeStyle={customeStyle}
              />
              <VerticalSlider
                disabled={disabled.depth}
                name={'Depth'}
                value={depth}
                setValue={(value) => !disabled.depth && (value >= 0.05 ? setDepth(value) : setDepth(0.05))}
                step={0.05}
                max={1}
                min={0.05}
                prefix={'+-'}
                inputConverter={(value: string) => parseInt(value) / 100}
                outputConverter={outputConverter}
                customeStyle={customeStyle}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SectionControl;
