import { ArrowHelper, Group, Mesh, MeshBasicMaterial, RingGeometry, Vector3 } from "three";
import TreeStatus from "../../../enums/TreeStatus";

export const createAddTreeTreeLock = () => {
  const ring = new Mesh(
    new RingGeometry(.2, .4, 30, 30),
    new MeshBasicMaterial({
      color: 'orange',
      opacity: 1,
      transparent: true
    })
  );

  const arrow = new ArrowHelper(
    new Vector3(0, 0, -1),
    new Vector3(0, 0, 1),
    1,
    'red',
    .2,
    .2
  );

  const g = new Group()

  g.add(ring);
  g.add(arrow);

  return g;
};

export const createOtherTreeLock = (tree: any) => {
  let color = 'red';

  if (tree?.tree_flow_status?.toLowerCase().includes("done")) color = 'green';
  else if (tree?.tree_flow_status?.toLowerCase().includes("deleted")) color = 'gray';
  else if (tree?.tree_flow_status?.toLowerCase().includes("sent_to_field")) color = 'yellow';

  const mesh = new Mesh(
    new RingGeometry(.2, .5, 30, 30),
    new MeshBasicMaterial({
      color,
      opacity: .8,
      transparent: true
    })
  );

  mesh.userData = { type: 'tree', tree };

  return mesh;
};
