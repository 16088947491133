import { ExtrudeGeometry, Mesh, MeshBasicMaterial, Shape, Vector3 } from 'three';

export class CapturePointConnection extends Mesh<any, MeshBasicMaterial> {
  private cp1: Mesh;
  private cp2: Mesh;

  constructor(cp1: Mesh, cp2: Mesh) {
    const fromPosition = new Vector3().fromArray(cp1.position.clone().toArray());
    const toPosition = new Vector3().fromArray(cp2.position.clone().toArray());
    const distance = fromPosition.distanceTo(toPosition);

    // const g = new BoxGeometry(0.4, 0.01, distance);

    const g = new ExtrudeGeometry(
      new Shape().absellipse(0, -.2, 0, 0.4, 0, Math.PI / 2, false, 0), {
        depth: distance,
        bevelEnabled: false
      }
    );

    const m = new MeshBasicMaterial({
      color: 'white',
      opacity: 0.2,
      transparent: true,
    });

    super(g, m);

    this.position.set(fromPosition.x, fromPosition.y, fromPosition.z);
    this.lookAt(toPosition.x, toPosition.y, toPosition.z);

    this.rotation.z = Math.PI / 2;

    this.cp1 = cp1;
    this.cp2 = cp2;

    this.userData = { type: 'capture-point', data: cp2.userData?.data };
  }

  public markHover() {
    if (!this.material) return;

    this.material.opacity = 1;
    this.material.needsUpdate = true;
  }

  public restoreHover() {
    if (!this.material) return;

    this.material.opacity = 0.2;
    this.material.needsUpdate = true;
  }
}
