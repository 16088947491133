import { PropsWithChildren } from 'react';
import { Icon } from '../../components';

type ModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
};

const ArchiveModal = ({ visible, setVisible, title, children }: PropsWithChildren<ModalProps>): JSX.Element => {
  return (
    <div className={`modal-outer-wrapper ${visible ? 'visible' : 'hidden'}`} style={{ padding: '340px' }}>
      <div className={'archive-modal-body'}>
        <div className='backdrop' onClick={() => setVisible(false)} />
        <div className='archive-modal-wrapper'>
          <div className='header'>
            <span>{title}</span>
            <div className='archive-close-wrapper' onClick={() => setVisible(false)}>
              <Icon icon='xCircle' />
            </div>
          </div>
          <div className={'content'}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveModal;
