import React from "react";

const Chart = ({ value = [], size = 120 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 52 52" className="donut">
      {value.map(({ value: val, color }, i) => {
        const offset =
          value.slice(0, i).reduce((acc, { value }) => acc + value, 0) * 100;
        const strokeDasharray = `${val * 100} ${(1 - val) * 100}`
        const strokeDashoffset = 25 + Math.min(100 - offset, offset);
        
        return (
          <circle
            key={i}
            className="donut-segment"
            cx="26"
            cy="26"
            r="15.91549430918954"
            fill="transparent"
            stroke={color}
            strokeWidth="18"
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
          ></circle>
        );
      })}
    </svg>
  );
};

export default Chart;
