import { useEffect } from 'react';
import ValidationAction from '../../../enums/ValidationAction';
import { ToastType } from '../../../store/ToastSlice';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';

const TO_FIXED = 2;

const CrownHeightCard = () => {
  const {
    treeMetrics,
    visibility,
    cards,
    validation,
    actions,
    helper,
    section: { setDefaultDepth, resetSectionTargetToDefault }
  } = useSemanticScreenContext();

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.CROWN_HEIGHT);

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(treeMetrics.canopyHeight),
      METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = cards.alerts.find((a) => a.name === METRICS_NAMES.CROWN_HEIGHT);

      if (!alreadyAlerted?.name) {
        cards.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.CROWN_HEIGHT,
            message: `Crown height must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.CROWN_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      cards.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.CROWN_HEIGHT));
    }
  }, [treeMetrics.canopyHeight]);

  return (
    <AttributeCard
      title='Crown Height'
      color={SemanticActionsColorMap[ValidationAction.CanopyHeight]}
      isExpanded={visibility.canopyHeight}
      onExpand={visibility.setCanopyHeight}
      setCompleted={validation.setIsCrownHeightCompleted}
      disabled={helper.isEditingDisabled}
      completed={validation.isCrownHeightCompleted}
      isActive={helper.activeTool === ValidationAction.CanopyHeight}
      setIsActive={() => {
        setDefaultDepth();
        resetSectionTargetToDefault();
        actions.setActiveTool(ValidationAction.CanopyHeight);
      }}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Canopy lower bound</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              value={Number(treeMetrics.canopyHeight.toFixed(TO_FIXED))}
              step={0.01}
              onChange={(value) => {
                if (helper.isEditingDisabled) return;

                const actualHeight = treeMetrics.height - value;
                if (actualHeight > treeMetrics.height || actualHeight < helper.viewerPosition.z) {
                  cards.addToast('Value cannot be lower than the location and higher than the tree height', ToastType.ERROR);
                  return;
                }

                treeMetrics.setCanopyHeight(value);
              }}
              min={Number(0)}
              max={Number(1000)}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default CrownHeightCard;
