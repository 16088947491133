import { IconElementParams } from '../../@types/Icon';

const SectionControl = ({ color }: IconElementParams) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='20' width='20' height='20' rx='2' transform='rotate(-90 0 20)' fill={color} />
      <path
        d='M5 2.75V5.11445C3.98572 5.43302 3.25 6.38059 3.25 7.5C3.25 8.61941 3.98572 9.56698 5 9.88555V17.25C5 17.6642 5.33579 18 5.75 18C6.16421 18 6.5 17.6642 6.5 17.25V9.88555C7.51428 9.56698 8.25 8.61941 8.25 7.5C8.25 6.38059 7.51428 5.43302 6.5 5.11445V2.75C6.5 2.33579 6.16421 2 5.75 2C5.33579 2 5 2.33579 5 2.75ZM13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25V14.8855C16.0143 14.567 16.75 13.6194 16.75 12.5C16.75 11.3806 16.0143 10.433 15 10.1145L15 2.75C15 2.33579 14.6642 2 14.25 2C13.8358 2 13.5 2.33579 13.5 2.75L13.5 10.1145C12.4857 10.433 11.75 11.3806 11.75 12.5C11.75 13.6194 12.4857 14.567 13.5 14.8855V17.25Z'
        fill='#202324'
      />
    </svg>
  );
};
export default SectionControl;
