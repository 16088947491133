import { useRef, useState } from "react";

const EditorInput = ({
  center,
  value,
  offset,
  zoom,
  onHeightChange,
  label,
  editing,
  setEditing,
  inverse,
  isDark,
  currentEditing,
  diff,
  controlObject,
  repaint,
}) => {
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const style = {
    top: calculateTop(center, value, zoom, offset ?? 0) + "%",
    position: "absolute",
    left: 0,
    background: "none",
    zIndex: 99,
    width: "fit-content",
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    marginTop: "3px"
  };

  const inputStyle = {
    border: "none",
    background: "none",
    fontSize: "13px",
    width: "40px",
  };

  const buttonStyle = {
      background: "none",
      fontSize: "13px",
      margin: "0 5px",
      width: "50px",
      border: "1px solid black",
      borderRadius: "4px"
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      setEditing("");
    }
  };

  if (inverse && diff) {
    const bottom = 100 - calculateTop(center, diff - value, zoom, (offset ?? 0));
    delete style.top;
    delete style.left;
    style.bottom = bottom + "%";
    style.right = `calc(50vw - 32px)`;
    style.marginBottom = "3px"
  }

  if (isDark) {
    inputStyle.color = "white";
    buttonStyle.border = "1px solid white"
    buttonStyle.color = "white"
  }

  if (currentEditing === editing) {
    style.color = "dodgerblue";
    inputStyle.color = "dodgerblue";
  }

  if (editing !== currentEditing && inputRef.current) {
    inputRef.current.blur();
  }

  const onInputChange = (value) => {
    const parsedValue = parseFloat(value);
    if (value[value.length - 1] === ".") {
      onHeightChange(value);
    } else if (!value) {
      onHeightChange("0");
    } else if (parsedValue && typeof parsedValue === "number") {
      onHeightChange(parsedValue);
    }
  };

  if (
    style?.top &&
    (style.top.slice(0, style.top.length - 1) > 97 ||
      style.top.slice(0, style.top.length - 1) < 0)
  ) {
    style.visibility = "hidden";
  } else if (
    style?.bottom &&
    (style.bottom.slice(0, style.bottom.length - 1) > 97 ||
      style.bottom.slice(0, style.bottom.length - 1) < 0)
  ) {
    style.visibility = "hidden";
  }

  const handleButtonClick = () => {
    controlObject.object.visible = visible;
    controlObject.largeObject.visible = visible;
    controlObject.updateRadius(0.1);

    if (visible) {
      controlObject.updateRadius(50);
    }

    setVisible((visible) => !visible);
    repaint();
  };

  return (
    <div style={style} onClick={() => setEditing(editing)}>
      <button onClick={handleButtonClick} style={buttonStyle}>{visible ? 'show' : 'hide'}</button>
      <label>{label}</label>
      <input
        type="text"
        onChange={(e) => onInputChange(e.target.value)}
        onKeyUp={handleEnter}
        value={value}
        style={inputStyle}
        ref={inputRef}
      />
    </div>
  );
};

const calculateTop = (center, value, zoom, offset) => {

  const hChX = center - ((value || 0) + offset);
  const eH = 12 / zoom;
  return 50 + (hChX / eH) * 100;
};

export default EditorInput;
