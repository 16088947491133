import { useEffect, useState } from 'react';
import proj4 from 'proj4';
import * as epsg from 'epsg';
import useApi from './api';

const useProj4Defs = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { handleRequest } = useApi();

  useEffect(() => {
    const load = async () => {
      const res = await handleRequest('/v1/project-config');
      const data = await res.json();

      const srid = data.srid;
      proj4.defs('localProjection', epsg[`EPSG:${srid}`]);
      proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

      setIsLoaded(true);
    };

    load();
  }, [handleRequest]);

  return { isLoaded };
};

export default useProj4Defs;
