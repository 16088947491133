import { forwardRef, HTMLProps, useCallback, useEffect, useState } from 'react';

export interface ResponsiveCanvasProps extends HTMLProps<HTMLCanvasElement> {
  onDimensionChange?: (width: number, height: number) => void;
}

export const ResponsiveCanvas = forwardRef<any, ResponsiveCanvasProps>((props: ResponsiveCanvasProps, ref) => {
  const [dimensions, setDimensions] = useState([0, 0]);
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);

  const updateSize = useCallback(() => {
    if (!divRef) return;

    const { width, height } = divRef?.getBoundingClientRect();

    setDimensions([width, height]);
  }, [divRef, dimensions?.[0], dimensions?.[1]]);

  useEffect(() => {
    if (!divRef) return;

    updateSize();

    const timer = setInterval(() => updateSize(), 1000);

    return () => {
      clearInterval(timer);
    }
  }, [divRef]);

  useEffect(() => {
    if (!props.onDimensionChange) return;

    props.onDimensionChange(dimensions?.[0], dimensions?.[1]);
  }, [dimensions?.[0], dimensions?.[1]]);

  return <div style={{
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black'
  }} ref={setDivRef}>
    <canvas ref={ref} style={{ width: '100%', height: '100%', position: 'absolute' }}/>
  </div>;
});
