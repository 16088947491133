import React, { Doughnut } from "react-chartjs-2";

const ProgressLoader = ({currentCount, targetCount, label}) => {
  const currentPercentage = Math.floor(100 * currentCount / targetCount);
  
  const data = {
    datasets: [
      {
        data: [currentPercentage, 100 - currentPercentage],
        backgroundColor: ['#ffc410', '#333333'],
        borderWidth: 0
      }
    ]
  }

  return (
    <div className="progress-loader">
      <Doughnut data={data} options={{events: [], cutoutPercentage: 80}}/>
      <div className="progress-loader__percentage">{currentPercentage}%</div>
      <div className="progress-loader__label">{label}</div>
    </div>
  )
}

export default ProgressLoader;