import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { BoxGeometry, Color, DoubleSide, Mesh, Vector3 } from 'three';

const DepthRect = ({ position, depth, visible }: { position: Vector3; depth: number; visible: boolean }) => {
  const ref = useRef<Mesh | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.position.copy(position);
  }, [position]);

  useFrame(({ camera }) => {
    if (!ref.current) return;

    ref.current.quaternion.copy(camera.quaternion);
    ref.current.scale.set(40 / camera.zoom, 1, 1);
    ref.current.visible = visible;
  });

  return (
    <>
      <mesh ref={ref} renderOrder={999}>
        <planeGeometry args={[5, depth * 2]} />
        <meshBasicMaterial color={new Color('#FFFFFF')} side={DoubleSide} transparent opacity={0.4} />
        <lineSegments>
          <edgesGeometry args={[new BoxGeometry(5, depth * 2, 0.01)]} />
          <lineBasicMaterial color={new Color('#FFFFFF')} linewidth={2} />
        </lineSegments>
      </mesh>
    </>
  );
};

export default DepthRect;
