import { useState } from 'react';
import Icon from '../Icon';
import Popup from '../Popup';
import ColorPickerContainer from './ColorPickerContainer';

interface Props {
  configItems: Record<string, string>;
  setConfigItems: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

// Popup for display color picker modal
const ColorPickerCogWheel = ({configItems, setConfigItems}: Props) => {
  const [toggleColorPicker, setToggleColorPicker] = useState(false);
  const openColorPicker = () => {
    setToggleColorPicker(!toggleColorPicker);
  };
  return (
    <div>
      <div className='action-wrapper regular-button-states' onClick={() => openColorPicker()} style={{ cursor: 'pointer' }}>
        <Icon icon='cogwheel' />
      </div>
      <div>
        <Popup title={undefined} centerAlign={true} noHover={false} noDelay={undefined} active={toggleColorPicker}>
          {toggleColorPicker && <ColorPickerContainer toggleColorPicker={setToggleColorPicker} configItems={configItems} setConfigItems={setConfigItems} />}
        </Popup>
      </div>
    </div>
  );
};

export default ColorPickerCogWheel;
