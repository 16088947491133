const Tree = ({ width = 13, height = 14, color = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.13923 3.52107L3.19873 4.01752L3.52704 3.97817L3.61935 3.66066L3.13923 3.52107ZM9.86178 3.52119L9.38166 3.66077L9.47393 3.97817L9.80211 4.01762L9.86178 3.52119ZM8.42483 9.30118L8.60408 8.83441L8.32941 8.72894L8.10383 8.91783L8.42483 9.30118ZM4.57517 9.30118L4.89617 8.91783L4.67059 8.72894L4.39592 8.83441L4.57517 9.30118ZM3.61935 3.66066C3.98243 2.41187 5.13562 1.5 6.50049 1.5V0.5C4.67895 0.5 3.143 1.71715 2.65911 3.38148L3.61935 3.66066ZM6.50049 1.5C7.8654 1.5 9.01862 2.41192 9.38166 3.66077L10.3419 3.38162C9.85806 1.71722 8.32207 0.5 6.50049 0.5V1.5ZM9.80211 4.01762C11.0401 4.16643 12 5.22129 12 6.4996H13C13 4.70909 11.6559 3.23325 9.92145 3.02477L9.80211 4.01762ZM12 6.4996C12 7.88031 10.8807 8.9996 9.5 8.9996V9.9996C11.433 9.9996 13 8.4326 13 6.4996H12ZM9.5 8.9996C9.18334 8.9996 8.88155 8.94097 8.60408 8.83441L8.24559 9.76795C8.63567 9.91775 9.05886 9.9996 9.5 9.9996V8.9996ZM8.10383 8.91783C7.66952 9.2815 7.11093 9.5 6.5 9.5V10.5C7.35429 10.5 8.13825 10.1933 8.74583 9.68454L8.10383 8.91783ZM6.5 9.5C5.88907 9.5 5.33048 9.2815 4.89617 8.91783L4.25417 9.68454C4.86175 10.1933 5.64571 10.5 6.5 10.5V9.5ZM4.39592 8.83441C4.11845 8.94097 3.81666 8.9996 3.5 8.9996V9.9996C3.94114 9.9996 4.36433 9.91775 4.75441 9.76795L4.39592 8.83441ZM3.5 8.9996C2.11929 8.9996 1 7.88031 1 6.4996H0C0 8.4326 1.567 9.9996 3.5 9.9996V8.9996ZM1 6.4996C1 5.221 1.96029 4.16595 3.19873 4.01752L3.07973 3.02462C1.34467 3.23258 0 4.70869 0 6.4996H1Z"
        fill={color}
      />
      <path d="M6.75049 10V13" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export default Tree;
