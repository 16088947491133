import { useCallback, useEffect, useState } from 'react';
import { setProjectName, getProjectName } from '../../core/projectSelector/projectSelector';
import { setProjectYear, getProjectYear } from '../../core/projectSelector/projectSelector';
import useApi from '../../hooks/api';
import styles from './ProjectDropdown.module.css';
import ProjectSelectorDropdownInput from './ProjectSelectorDropdonwInput';
import ScanYearSelectorDropdownInput from './ScanYearSelectorDropdownInput';

const ProjectDropdown = () => {
  const [options, setOptions] = useState([]);
  const currentProject = getProjectName();
  const currentYear = getProjectYear();

  const { handleRequest } = useApi();

  useEffect(() => {
    const load = async () => {
      const res = await handleRequest('/v1/projects');
      const data = await res.json();

      if (!data?.length) {
        return;
      }

      const projectNameOptions = data.map(({ project_name }) => ({
        id: project_name,
        label: project_name,
        value: project_name
      })).reverse();

      setOptions(projectNameOptions);
    };

    load();
  }, [handleRequest]);

  const onChange = useCallback(({ value }) => {
    setProjectName(value);
    window.location.href = '/';
  }, []);

  const onYearChange = useCallback(({ value }) => {
    setProjectYear(value);
    window.location.href = '/';
  });

  if (!options) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className='ProjectSelectorDropdown'>
        <ProjectSelectorDropdownInput label={'Project'} searchable={true} options={options} onChange={onChange} value={currentProject} />
      </div>
      <div className='ProjectYearSelectorDropdown'>
        <ScanYearSelectorDropdownInput label={'Latest'} searchable={false} options={[]} onChange={onYearChange} value={currentYear} />
      </div>
    </div>
  );
};

export default ProjectDropdown;
