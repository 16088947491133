import { IconElementParams } from '../../@types/Icon';

const Tools = ({ width = 16, height = 16, color }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.69999 7.07136L1.98666 11.7847C1.86269 11.9085 1.76435 12.0556 1.69725 12.2174C1.63015 12.3793 1.59561 12.5528 1.59561 12.728C1.59561 12.9032 1.63015 13.0768 1.69725 13.2386C1.76435 13.4005 1.86269 13.5475 1.98666 13.6714V13.6714C2.2367 13.9213 2.57577 14.0617 2.92933 14.0617C3.28288 14.0617 3.62196 13.9213 3.87199 13.6714L8.58533 8.95669M11.462 9.20003L14.0473 11.7854C14.2973 12.0354 14.4377 12.3745 14.4377 12.728C14.4377 13.0816 14.2973 13.4207 14.0473 13.6707V13.6707C13.7973 13.9207 13.4582 14.0611 13.1047 14.0611C12.7511 14.0611 12.412 13.9207 12.162 13.6707L8.02266 9.53203M4.48866 3.93603L6.37533 5.82269M4.48866 3.93603L3.07333 4.40669L1.65999 2.05003L2.60266 1.10736L4.95999 2.52136L4.48866 3.93603V3.93603Z'
        stroke={color ?? ''}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.70001 7.07133C6.13734 5.63599 6.24734 3.75266 7.40734 2.59266C8.56734 1.43266 10.7073 1.17866 11.8853 1.88599L9.85868 3.91266L9.67001 5.98666L11.744 5.79866L13.7707 3.77133C14.4787 4.94999 14.224 7.08999 13.064 8.24932C11.904 9.40933 10.0213 9.51933 8.58601 8.95666'
        stroke={color ?? ''}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Tools;
