import { TreeIndexHelper } from '../@types/MicroclimateValidation';
import { useTreeIndex } from './treeIndexing';

export const useMicroClimateTrees = (selectedTreeId: string, trees: any) => {
  const {
    currentTree,
    moveToNeighbour: moveToNeighbor,
    setCurrentIndex,
    selectTree,
  } = useTreeIndex(selectedTreeId, trees, trees) as TreeIndexHelper;

  return {
    trees,
    currentTree,
    moveToNeighbor,
    setCurrentIndex,
    selectTree,
  };
};
