const ConfirmationPopup = ({
  action,
  title,
  handleConfirmationPopupState,
}: {
  action: any;
  title?: string;
  handleConfirmationPopupState: () => void;
}) => {
  const handleActionClosePopup = () => {
    action();
    handleConfirmationPopupState();
  };

  return (
    <div className='confirmation-popup'>
      <div className='confirmation-popup-title'>{title ?? 'Do you really like to do this action?'}</div>
      <div className='confirmation-popup-buttons'>
        <div className='confirmation-popup-button' onClick={() => handleActionClosePopup()}>
          Yes
        </div>
        <div className='confirmation-popup-button' onClick={() => handleConfirmationPopupState()}>
          No
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
