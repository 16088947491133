import { IconElementParams } from '../../@types/Icon';

const RemoveX = ({ color = 'white' }: IconElementParams) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.64332 11.3567L9.00082 8.99999M11.3575 6.64332L9.00082 8.99999M9.00082 8.99999L6.64332 6.64332M9.00082 8.99999L11.3575 11.3567M8.99999 17.3333C13.6025 17.3333 17.3333 13.6025 17.3333 8.99999C17.3333 4.39749 13.6025 0.666656 8.99999 0.666656C4.39749 0.666656 0.666656 4.39749 0.666656 8.99999C0.666656 13.6025 4.39749 17.3333 8.99999 17.3333Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default RemoveX;
