import { Checkbox, FormControlLabel } from "@mui/material";
import { useContext, useState } from "react";
import { BetaManagedAreasContext, FILTERABLE_FIELDS, FilterableFields, MANAGED_AREA_STATUSES, ManagedAreaStatuses } from "../../hooks/betaHooks/beta-managed-areas-context";
import { Button } from "../inputs";
import { CustomSelect as StatusSelect } from "../inputs/Select";
import { ScreenTypes } from "../../hooks/betaHooks/beta-use-tree";

export interface DropDownOption {
    id: string;
    label: string;
    value: ManagedAreaStatuses;
}

interface Props {
    tableFiltersIsOpen: boolean;
    setTableFiltersIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    screenType: ScreenTypes;
}

export const TableFilters = ({
    tableFiltersIsOpen,
    setTableFiltersIsOpen,
    screenType
}: Props) => {

    const [isOpen, setIsOpen] = useState(false);
    const { updateFilters: updateManagedAreaFilters, maFilters } = useContext(BetaManagedAreasContext);

    const handleSelect = (value: ManagedAreaStatuses) => {
        const newFilter = {
            [FILTERABLE_FIELDS.STATUS]: value,
        } as Record<FilterableFields, ManagedAreaStatuses>;

        updateManagedAreaFilters(newFilter);
    };

    const handlePendingFilterChange = () => {
        const newFilter = {
            [FILTERABLE_FIELDS.PENDING_TREES]: !maFilters[FILTERABLE_FIELDS.PENDING_TREES],
        } as Record<FilterableFields, boolean>;

        updateManagedAreaFilters(newFilter);
    };

    const activeItem = maFilters[FILTERABLE_FIELDS.STATUS] || MANAGED_AREA_STATUSES.default;

    if (screenType !== ScreenTypes.VALIDATION) return null;

    if (!tableFiltersIsOpen) {
        return (
            <span style={{
                marginLeft: '8vw',
            }}>
                <Button
                    label='Table Filters'
                    primary
                    onClick={() => setTableFiltersIsOpen(true)}
                />
            </span>
        )
    }

    const options: DropDownOption[] = [
        {
            id: 'all',
            label: 'All Status',
            value: MANAGED_AREA_STATUSES.default,
        },
        {
            id: 'loc_val',
            label: 'Location Validation',
            value: MANAGED_AREA_STATUSES.LOCATION_VALIDATION,
        },
        {
            id: 'seg_val',
            label: 'Segmentation Validation',
            value: MANAGED_AREA_STATUSES.SEGMENTATION_VALIDATION,
        },
        {
            id: 'sem_val',
            label: 'Semantic Validation',
            value: MANAGED_AREA_STATUSES.SEMANTIC_VALIDATION,
        },
    ]

    return (
        <div className="filter-container">
            <StatusSelect
                options={options}
                value={activeItem}
                onChange={handleSelect}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            <FormControlLabel
                label="Has pending trees"
                control={<Checkbox
                    onChange={handlePendingFilterChange}
                    title="Has trees pending"
                    checked={!!maFilters[FILTERABLE_FIELDS.PENDING_TREES]}
                />}
            />
            <Button label="Close" primary icon="fa-times"
                onClick={() => setTableFiltersIsOpen(false)}
            />
        </div>)
}