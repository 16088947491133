import React from 'react';

const Singapore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>rtms</title>
      <g id="Layer_2" data-name="Layer 2">
        <path fill="currentColor" d="M29,10.84c1.89.66,3.86.22,2.69-2.18-.94-1.93-3-.44-4.58.13C27.79,9.61,28.25,10.59,29,10.84Z"/>
        <path fill="currentColor" d="M21.19,12.79l-.1,0L20,12.54a4.81,4.81,0,0,1-.54-.16c-.22-.06-.44-.11-.65-.19a6.76,6.76,0,0,1-.67-.29c-.14-.07-.3-.12-.43-.2a4.49,4.49,0,0,1-.95-.72c-2.52-2.55-3.31-2.78-6.16-.7-.69.49-1.3,1-2.17.73-2.2-.72-4.19-.19-5,2C2.52,15.46.91,17.54.15,20.09c-.52,1.78.4,1.9,1.45,1.58s1.82-.6,2.71.1c-.51.6-.16.94.37,1.23S5,24.49,6,23.92a1.36,1.36,0,0,0,1.35-.8c.39-.13,1-.13.93-.65a.82.82,0,0,0-.93-.66l-.9-.47a1.92,1.92,0,0,1-.14-.29s.1-.1.16-.12c2.49-.83,4,1.27,5.68,2.25C14,24.27,14.81,25.29,16.8,23c2.55-3,6.58-3.24,10.43-3.09.45,0,1.16.44,1.19-.42s-.83-.33-1.19-.62l-.52-.47c.08-.13.13-.29.23-.36.58-.38,1.64-.48,1.63-1.17,0-.91-1-.44-1.53-.53-.16,0-.32-.42-.38-.68a2.14,2.14,0,0,0-.34-.76,1.4,1.4,0,0,0-.36-.33.93.93,0,0,0-.15-.13,2.37,2.37,0,0,0-1.46-.19l0,0-.05,0h0c-.11-.84,1.11-.51,1.27-1l0,0a.65.65,0,0,0,0-.29c-.33-.85-1.3-.37-2-.51s-1.11-.35-1.69-.44a1,1,0,0,0-.43,0c-.36.07-.48.35-.23.89l-.06,0Z"/>
      </g>
    </svg>
  )
}

export default Singapore;