import { memo } from 'react';
import { IconStyle } from '../../@types/Icon';

const Attributes = ({ width = 25, height = 25, color = '#fff' }: IconStyle) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 12C12.1686 12 11.9 12.2686 11.9 12.6V16.2C11.9 16.5313 12.1686 16.8 12.5 16.8C12.8314 16.8 13.1 16.5313 13.1 16.2V12.6C13.1 12.2686 12.8314 12 12.5 12ZM7.70001 10.2C7.70001 9.8686 7.96864 9.59998 8.30001 9.59998C8.63138 9.59998 8.90001 9.8686 8.90001 10.2V16.2C8.90001 16.5313 8.63138 16.8 8.30001 16.8C7.96864 16.8 7.70001 16.5313 7.70001 16.2V10.2ZM16.7 7.19998C16.3686 7.19998 16.1 7.4686 16.1 7.79998V16.2C16.1 16.5313 16.3686 16.8 16.7 16.8C17.0314 16.8 17.3 16.5313 17.3 16.2V7.79998C17.3 7.4686 17.0314 7.19998 16.7 7.19998ZM4.10001 5.99998C4.10001 4.67449 5.17452 3.59998 6.50001 3.59998H18.5C19.8255 3.59998 20.9 4.67449 20.9 5.99998V18C20.9 19.3255 19.8255 20.4 18.5 20.4H6.50001C5.17452 20.4 4.10001 19.3255 4.10001 18V5.99998ZM5.30001 5.99998V18C5.30001 18.6627 5.83726 19.2 6.50001 19.2H18.5C19.1627 19.2 19.7 18.6627 19.7 18V5.99998C19.7 5.33723 19.1627 4.79998 18.5 4.79998H6.50001C5.83726 4.79998 5.30001 5.33723 5.30001 5.99998Z'
        fill={color}
      />
    </svg>
  );
};

export default memo(Attributes);
