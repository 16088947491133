import PointClassStyles from '../../../@types/PointClassStyles';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import ColorPickerCogWheel from '../../ColorPicker/ColorPickerCogWheel';
import RoundCheckbox from '../../inputs/RoundCheckbox';
import PointSizeSlider from '../../Slider/PointSizeSlider';

const PerspectiveToolbar = () => {
  const {
    pointCloudModule: {
      configItems,
      isEnvironmentVisible,
      pointClassStyles,
      pointSize,
      setConfigItems,
      setPointSize,
      setIsEnvironmentVisible,
      toggleOpacity
    }
  } = useSemanticScreenContext();

  return (
    <div style={{ position: 'absolute', right: 0, zIndex: 1, padding: 10, display: 'flex', gap: 16, alignItems: 'center' }}>
      <RoundCheckbox
        label='Canopy'
        value={pointClassStyles.canopy.opacity}
        onChange={() => toggleOpacity("canopy")}
        disabled={false}
        color={configItems?.canopy || pointClassStyles?.canopy?.color || '#08CC91'}
      />
      <RoundCheckbox
        label='Trunk'
        value={pointClassStyles.trunk.opacity}
        onChange={() => toggleOpacity("trunk")}
        disabled={false}
        color={configItems?.trunk || pointClassStyles?.trunk?.color || '#DB8100'}
      />
      <RoundCheckbox
        label='Environment'
        value={isEnvironmentVisible}
        onChange={() => {
          setIsEnvironmentVisible(!isEnvironmentVisible);
          toggleOpacity("environment")
        }}
        disabled={false}
        color={configItems?.environment || pointClassStyles?.environment?.color || '#73BCEC'}
      />
      <PointSizeSlider pointSize={pointSize} setPointSize={setPointSize} />
      <ColorPickerCogWheel configItems={configItems} setConfigItems={setConfigItems} />
    </div>
  );
};

export default PerspectiveToolbar;
