import color from "color";
import { createContext, useContext, useState } from "react";
import TreeFlowStatus from "../enums/TreeFlowStatus";

const STORAGE_KEY = "@configv5";

export const Context = createContext({});

const defaultConfig = JSON.stringify({
  colors: {
    maven: "#a103fc",
    field: "#EE9F3A",
    dbmatch: "#1e7800",
    connection: "#7fb",
    completed: "#00ffa6",
    error: "#f20",
    processing: "#ccc",
    semantic_marker: "#f82",
    unassigned: "#f88",
    inactive: "#0a0a0a",
    inactive_dark: "#333",
    todo: "#E53125",
    done: "#AFFF14",
    deleted: "grey"
  },
  categories: {
    tree_status: {
      label: "Tree status",
    },
    pipeline_status: {
      label: "Pipelines",
    },
    dbmatch: {
      label: "DB matching",
    },
  },
  statuses: {
    maven: {
      category: "tree_status",
      label: "MAVEN II",
      color: "maven",
    },
    validation_todo: {
      category: "tree_status",
      label: "Validation pending",
      color: "todo",
    },
    validation_done: {
      category: "tree_status",
      label: "Validation done",
      color: "done",
    },
    location_validation: {
      category: "tree_status",
      label: "Location validation",
      color: "location_validation",
    },
    semantic_validation: {
      category: "tree_status",
      label: "Semantic validation",
      color: "semantic_validation",
    },
    segmentation_validation: {
      category: "tree_status",
      label: "Segmentation validation",
      color: "segmentation_validation",
    },
    microclimate_validation: {
      category: "tree_status",
      label: "Microclimate_validation",
      color: "Microclimate_validation",
    },
    [TreeFlowStatus.LocationValidationQueued]: {
      category: "tree_status",
      label: "Pending",
      color: "todo",
    },
    sent_to_field: {
      category: "tree_status",
      label: "Sent to field",
      color: "field",
    },
    [TreeFlowStatus.LocationValidationDone]: {
      category: "tree_status",
      label: "Done",
      color: "done",
    },
    [TreeFlowStatus.LocationValidationDeleted]: {
      category: "tree_status",
      label: "Deleted",
      color: "deleted",
    },
    validation_deleted: {
      category: "tree_status",
      label: "Deleted",
      color: "deleted",
    },
    [TreeFlowStatus.MeasurementValidationQueued]: {
      category: "tree_status",
      label: "Pending",
      color: "todo",
    },
    semantic_validation_sent_to_field: {
      category: "tree_status",
      label: "Sent to field",
      color: "field",
    },
    [TreeFlowStatus.MeasurementValidationDone]: {
      category: "tree_status",
      label: "Done",
      color: "done",
    },
    [TreeFlowStatus.MeasurementValidationDeleted]: {
      category: "tree_status",
      label: "Deleted",
      color: "deleted",
    },
    [TreeFlowStatus.SegmentationValidationQueued]: {
      category: "tree_status",
      label: "Pending",
      color: "todo",
    },
    [TreeFlowStatus.SegmentationValidationDone]: {
      category: "tree_status",
      label: "Done",
      color: "done",
    },
    [TreeFlowStatus.SegmentationValidationDeleted]: {
      category: "tree_status",
      label: "Deleted",
      color: "deleted",
    },
    db_match_todo: {
      category: "tree_status",
      label: "Validation pending",
      color: "todo",
    },
    db_match_sent_to_field: {
      category: "tree_status",
      label: "Sent to field",
      color: "field",
    },
    [TreeFlowStatus.Completed]: {
      category: "tree_status",
      label: "Validation done",
      color: "done",
    },
    [TreeFlowStatus.SentToField]: {
      category: "tree_status",
      label: "Sent to field",
      color: "field",
    },
    [TreeFlowStatus.SentToOfflineAnnotation]: {
      category: "tree_status",
      label: "Sent to offline annotation",
      color: "field",
    },
    [TreeFlowStatus.SpeciesValidationQueued]: {
      category: "tree_status",
      label: "Pending",
      color: "todo",
    },
    [TreeFlowStatus.SpeciesValidationRunning]: {
      category: "tree_status",
      label: "Pending",
      color: "todo",
    },
    [TreeFlowStatus.SpeciesValidationDone]: {
      category: "tree_status",
      label: "Validation done",
      color: "done",
    },
    ERROR: {
      category: "pipeline_status",
      label: "Error",
      color: "error",
    },
    PROCESSING: {
      category: "pipeline_status",
      label: "Processing",
      color: "processing",
    },
    connections: {
      category: "dbmatch",
      label: "Connections",
      color: "connection",
    },
    UNASSIGNED: {
      category: "pipeline_status",
      label: "Unassigned",
      color: "unassigned",
    },
  },
  statusesToDisplayOnPreferences: {
    maven: {
      category: "tree_status",
      label: "MAVEN II",
      color: "maven",
    },
    validation_todo: {
      category: "tree_status",
      label: "Validation pending",
      color: "todo",
    },
    validation_done: {
      category: "tree_status",
      label: "Validation done",
      color: "done",
    },
    location_validation_sent_to_field: {
      category: "tree_status",
      label: "Sent to field",
      color: "field",
    },
    sent_to_offline_annotation: {
      category: "tree_status",
      label: "Sent to offline annotation",
      color: "field",
    },
    ERROR: {
      category: "pipeline_status",
      label: "Error",
      color: "error",
    },
    PROCESSING: {
      category: "pipeline_status",
      label: "Processing",
      color: "processing",
    },
    connections: {
      category: "dbmatch",
      label: "Connections",
      color: "connection",
    },
    UNASSIGNED: {
      category: "pipeline_status",
      label: "Unassigned",
      color: "unassigned",
    },
  },
  locationValidationCustomConfidenceLevels: {
    currentMAId: "",
    confidenceMax: 100,
    confidenceMin: 0,
  },
});

// Access to context
export const useConfig = () => useContext(Context);

export const withConfig = (Component) => (props) =>
  (
    <Context.Consumer>
      {(theme) => <Component {...props} theme={theme} />}
    </Context.Consumer>
  );

export const useStaticConfig = () => {
  const config = {
    ...JSON.parse(defaultConfig),
    ...JSON.parse(localStorage.getItem(STORAGE_KEY) || defaultConfig),
  };

  const getConfig = (key) => Object.byPath(config, key);
  return {
    config,
    getConfig,
  };
};

export const Provider = (props) => {
  const localConfig = JSON.parse(
    localStorage.getItem(STORAGE_KEY) || defaultConfig
  );
  const [config, setConfig] = useState({
    ...JSON.parse(defaultConfig),
    ...localConfig,
  });

  const _handleSetUpdate = (config) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
    setConfig(config);
  };

  const _handleUpdate = (key, value) => {
    const _config = { ...config };
    _handleSetUpdate(_config);
  };

  const _handleGetConfig = (key) => Object.byPath(config, key);

  return (
    <Context.Provider
      value={{
        config,
        setConfig: _handleSetUpdate,
        updateConfig: _handleUpdate,
        getConfig: _handleGetConfig,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const POINT_CLASS_DEFAULT_COLOR = {
  20: "#795548",
  21: "#48BB78",
};

const POINT_CLASS_HEX_COLOR = {
  20: "#795548",
  21: "#48BB78",
  22: "#0000ff",
  23: "#b801b9",
  OTHER: "#646464",
};

const POINT_CLASS_LABEL_MAP = {
  20: "trunk",
  21: "canopy",
  trunk: 20,
  canopy: 21,
};

export const getPointClassLabel = (pointClass) => {
  if (!Number.isInteger(pointClass)) return null;

  return POINT_CLASS_LABEL_MAP[pointClass] ?? null;
};
export const getPointLabelDefaultHexColor = (pointClass) => {
  if (Number.isInteger(+pointClass)) return null;

  const classNumber = POINT_CLASS_LABEL_MAP[pointClass];
  return POINT_CLASS_DEFAULT_COLOR[classNumber] ?? null;
};

export const getRgbColorByClassification = (pointClass, hexColorCode) => {
  if (hexColorCode) {
    return color(hexColorCode).rgb().array();
  }

  if (POINT_CLASS_HEX_COLOR[pointClass]) {
    return color(POINT_CLASS_HEX_COLOR[pointClass]).rgb().array();
  }

  return color(POINT_CLASS_HEX_COLOR.OTHER).rgb().array();
};
