import React from 'react';
import Icon from '../Icon';
import Popup from '../Popup';

const MapControls = ({ fitToBounds, zoomIn, zoomOut, onUnselect }) => {
  return (
    <div className="map-controls-wrapper">
      <ActionGroup>
        <Action action={fitToBounds} icon='expand' />
      </ActionGroup>
      <ActionGroup>
        <Action action={zoomIn} icon='plus' />
        <Action action={zoomOut} icon='minus' />
      </ActionGroup>
      { onUnselect && <ActionGroup>
        <Popup title='Unselect MA' direction='right'>
          <Action action={onUnselect} icon='redo-alt' />
        </Popup>
      </ActionGroup> }
    </div>
  )
}

const ActionGroup = ({ children }) => {
  return (
    <div className="action-group">
      { children }
    </div>
  )
}

const Action = ({ icon, action = () => {}, active }) => {
  return (
    <div className={`action-wrapper ${active ? 'active' : ''}`} onClick={action}>
      <Icon icon={icon} />
    </div>
  )
}

export default MapControls;