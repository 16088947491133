import { Lens } from "@dhmk/zustand-lens";
import produce from "immer";
import Number3 from "../@types/Number3";
import Tree from "../@types/Tree";
import { projectLocal } from "../components/SemanticsValidation/ValidationUtils";
import { getCapturePoint } from "../providers/data";

type CapturePoint = {
  position: {
    coordinates: Number3;
  };
};

export type PanoramaSlice = {
  capturePoint: CapturePoint;
  localPosition: { hasChanged: boolean; coordinates: Number3 };
  isPanoramic: boolean;

  actions: {
    viewPanorama: (tree: Tree) => Promise<void>;
    togglePanoramic: () => void;
  };
};

const createPanoramaSlice: Lens<PanoramaSlice> = (set, get, api) => ({
  capturePoint: { position: { coordinates: [0, 0, 0] } },
  localPosition: { hasChanged: false, coordinates: [0, 0, 0] },
  isPanoramic: false,

  actions: {
    viewPanorama: async (tree) => {
      const panoramaImages = await getCapturePoint(tree);

      set({
        capturePoint: {
          position: { coordinates: panoramaImages?.[0]?.origin.coordinates },
        },
      });

      const location = tree?.location?.coordinates.slice(0);
      let position = projectLocal(location);

      if (tree.location_local !== undefined) {
        position = tree.location_local;
      }

      set((s) =>
        produce(s, (draft) => {
          draft.localPosition.coordinates = position;
        })
      );
    },

    togglePanoramic: () => set({ isPanoramic: !get().isPanoramic }),
  },
});

export default createPanoramaSlice;
