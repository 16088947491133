import { withRouter } from 'react-router';
import { HeaderBarParams } from '../../@types/HeaderBar';
import { NavigationTabParams } from '../../@types/NavigationTab';
import { useTheme } from '../../providers/theme';
import Icon from '../Icon';
import ProjectDropdown from '../ProjectDropdown/ProjectDropdown_old';
import Actions from './Actions';
import NavigationTab from './NavTab';

const HeaderBar = ({
  managedArea,
  history,
  navTabs,
  trees,
  headerTitle,
  user,
}: HeaderBarParams): JSX.Element => {
  const { isDark } = useTheme() as { isDark: boolean };

  const _handleHome = () => history.push('/');

  const renderTabs = (tabs: NavigationTabParams[]): JSX.Element[] => {
    if(!tabs){
      return [];
    }
    const items = [];
    for (const [index, tab] of tabs.entries()) {
      if(tab.active){
        const tabItem = generateTab(tab, index);
        tabItem && items.push(tabItem);
      }
    }
    return items;
  };

  const generateTab = (tab: NavigationTabParams, index: number) => {
    switch (tab.type) {
      case 'component':
        return <tab.component {...tab.params} key={index} />;
      case 'link':
        return <NavigationTab {...tab} key={index} />;
      default:
        return null;
    }
  };

  return (
    <div className={`header-bar-wrapper ${isDark ? 'dark' : 'light'}`}>
      <div className='left-side'>
        <div className='logo-wrapper svg-wrapper' onClick={_handleHome}>
          <Icon icon='gh' />
        </div>
        <span className='view-name'>{headerTitle}</span>
      </div>
      <div className='center'>{renderTabs(navTabs)}</div>
      <div className='right-side'>
        {user && <div className='dropdown'>
          <ProjectDropdown />
        </div>}
        {user && <Actions managedArea={managedArea} trees={trees} user={user} />}
      </div>
    </div>
  );
};

export default withRouter(HeaderBar);
