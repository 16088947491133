import appConfig from '../../config';

const KEY = 'project-override';
const PROJECT_YEAR_KEY = 'project-year-override';
export const getProjectName = () => {
  const projectName = localStorage.getItem(KEY);

  if (!projectName) {
    return appConfig.defaultProject || 'lilleb_v2';
  }

  return projectName;
};

export const getProjectYear = () => {
  const projectYear = localStorage.getItem(PROJECT_YEAR_KEY);
  if (!projectYear || isNaN(projectYear)) {
    return null;
  }

  return projectYear;
};

export const setProjectName = (value) => {
  localStorage.setItem(KEY, value);
};

export const setProjectYear = (value) => {
  localStorage.setItem(PROJECT_YEAR_KEY, value);
};