const PointCloud360 = () => {
  return (
    <svg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.83333 3.39915H3.29167M3.29167 3.39915C3.67844 3.39915 4.04937 3.55279 4.32286 3.82629C4.59635 4.09978 4.75 4.47071 4.75 4.85748C4.75 5.24426 4.59635 5.61519 4.32286 5.88868C4.04937 6.16217 3.67844 6.31582 3.29167 6.31582H1M3.29167 3.39915C3.67844 3.39915 4.04937 3.2455 4.32286 2.97201C4.59635 2.69852 4.75 2.32759 4.75 1.94082C4.75 1.55404 4.59635 1.18311 4.32286 0.909619C4.04937 0.636128 3.67844 0.482483 3.29167 0.482483H1M16 8.81582C16 11.5775 12.6417 13.8158 8.5 13.8158C4.35833 13.8158 1 11.5775 1 8.81582M10.1667 0.482483H9.33333C8.67029 0.482483 8.03441 0.745875 7.56557 1.21472C7.09672 1.68356 6.83333 2.31944 6.83333 2.98248V4.64915M10.5833 4.23248V4.64915C10.5833 5.09118 10.4077 5.5151 10.0952 5.82766C9.78262 6.14022 9.35869 6.31582 8.91667 6.31582H8.5C8.05797 6.31582 7.63405 6.14022 7.32149 5.82766C7.00893 5.5151 6.83333 5.09118 6.83333 4.64915V4.23248C6.83333 3.79046 7.00893 3.36653 7.32149 3.05397C7.63405 2.74141 8.05797 2.56582 8.5 2.56582H8.91667C9.35869 2.56582 9.78262 2.74141 10.0952 3.05397C10.4077 3.36653 10.5833 3.79046 10.5833 4.23248ZM12.6667 3.39915V2.14915C12.6667 1.70712 12.8423 1.2832 13.1548 0.970638C13.4674 0.658078 13.8913 0.482483 14.3333 0.482483H14.75C15.192 0.482483 15.616 0.658078 15.9285 0.970638C16.2411 1.2832 16.4167 1.70712 16.4167 2.14915V4.64915C16.4167 5.09118 16.2411 5.5151 15.9285 5.82766C15.616 6.14022 15.192 6.31582 14.75 6.31582H14.3333C13.8913 6.31582 13.4674 6.14022 13.1548 5.82766C12.8423 5.5151 12.6667 5.09118 12.6667 4.64915V3.39915Z'
        strokeWidth='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PointCloud360;
