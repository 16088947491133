import { IconElementParams } from '../../@types/Icon';

const Layout = ({ width = 16, height = 16, color }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5 6.5V14M14 6.5H2M13.6 2H2.4C2.29391 2 2.19217 2.04214 2.11716 2.11716C2.04214 2.19217 2 2.29391 2 2.4V13.6C2 13.7061 2.04214 13.8078 2.11716 13.8828C2.19217 13.9579 2.29391 14 2.4 14H13.6C13.7061 14 13.8078 13.9579 13.8828 13.8828C13.9579 13.8078 14 13.7061 14 13.6V2.4C14 2.29391 13.9579 2.19217 13.8828 2.11716C13.8078 2.04214 13.7061 2 13.6 2V2Z'
        stroke={color ?? ''}
        strokeWidth='1.03'
      />
    </svg>
  );
};

export default Layout;
