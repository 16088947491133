const Brush = ({ width = 20, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 1.66669C5.39751 1.66669 1.66667 5.39752 1.66667 10C1.66667 14.6025 5.39751 18.3334 10 18.3334C14.6025 18.3334 18.3333 14.6025 18.3333 10C18.3333 5.39752 14.6025 1.66669 10 1.66669Z'
        stroke={color}
        strokeWidth='1.03'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.66667 17.64V11.6666L10 5.83331L13.3333 11.6666V17.64'
        stroke={color}
        strokeWidth='1.03'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.66667 11.6667C6.66667 11.6667 7.60584 12.5 8.33334 12.5C9.06084 12.5 10 11.6667 10 11.6667C10 11.6667 10.9392 12.5 11.6667 12.5C12.3942 12.5 13.3333 11.6667 13.3333 11.6667'
        stroke={color}
        strokeWidth='1.03'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Brush;
