import { ColumnType } from '../../@types/Table';
import { DropdownInput } from '../inputs';

type MultiSelectProps = {
  value: string;
  column: ColumnType;
  options: any[];
  entry: any;
  label: string;
};

const MultiSelect = ({ value, column, label, options = [], entry }: MultiSelectProps) => {
  const _handleChange = (value: string) => column?.onChange?.(column.key, value, entry);
  return (
    <div className='select-wrapper'>
      <DropdownInput
        options={options}
        label={label}
        value={value}
        onPureChange={_handleChange}
        small
        optionComponent={undefined}
        leadingIcon={undefined}
        error={undefined}
        warning={undefined}
        clearLabel={undefined}
        showNumberOfOptions={undefined}
        isLimited={undefined}
      />
    </div>
  );
};

export default MultiSelect;
