import { useTheme as useMuiTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import CarretDown from '../Icon/CarretDown';

const iconStyle = { height: 16, minWidth: 16 };

type CardProps = {
  children?: React.ReactNode;
  icon?: JSX.Element;
  title: string;
};

const Card = (props: CardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);

  const muiTheme = useMuiTheme();

  return (
    <div className='card' style={{ backgroundColor: muiTheme.palette.background.paper }}>
      <div className='header'>
        {props.icon && <i style={iconStyle}>{props.icon}</i>}
        <p style={{ color: muiTheme.palette.text.primary }}>{props.title}</p>
        <i
          style={iconStyle}
          className={`expand-button ${isExpanded ? 'expanded' : 'collapsed'}`}
          onClick={() => setIsExpanded((val) => !val)}
        >
          <CarretDown color={muiTheme.palette.text.primary} />
        </i>
      </div>
      <Collapse in={isExpanded} unmountOnExit>
        <div className='body'>{props.children}</div>
      </Collapse>
    </div>
  );
};

export default Card;
