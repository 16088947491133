import { useCallback } from 'react';

const useScale = () => {
  const getScaledValue = useCallback((zoom: number) => (1 / zoom) * 50, []);

  return {
    getScaledValue,
  };
};

export default useScale;
