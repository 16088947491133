import { useCallback, useMemo, useState } from 'react';
import * as _ from 'lodash';

const sortableMAStringProperties = ['location', 'code', 'status', 'oic'];

const sortableMANumberProperties = ['tree_number', 'pending', 'validated', 'accepted', 'sent_to_field', 'deleted', 'manually_corrected'];

const sortableTreeStringProperties = ['status', 'tms_category', 'comment'];

const sortableTreeNumberProperties = ['confidence_level', 'location_confidence', 'ml_instance_confidence', 'semantic_confidence', 'id'];

const sortableTreeBooleanProperties = ['manually_corrected'];

const microclimateProperties = [
  'species_code',
  'species_latin',
  'land_use',
  'percent_crown_missing',
  'crown_dieback',
  'crown_light_exposure',
  'uncertain',
  'completed',
  'genus_probability',
];

export interface SortTableProps {
  sortableProperty: {
    key: string;
    direction: 1 | -1 | undefined;
  };
}

export interface BetaTableSorterValue {
  sortedPipelines: any[];
  sortedTrees: any[];
  tableSort: {
    key: string;
    direction: 1 | -1 | undefined;
    type: 'managedArea' | 'trees';
  } | null;

  sortTable: (props: SortTableProps) => Promise<void>;
}

export interface UseBetaTableSorterProps {
  pipelines: any[];
  trees: any[];
  managedAreaId?: string;
}

const defaultSortTableState: BetaTableSorterValue['tableSort'] = {
  key: 'status',
  direction: undefined,
  type: 'managedArea',
};

const useBetaTableSorter = ({ pipelines, trees, managedAreaId }: UseBetaTableSorterProps): BetaTableSorterValue => {
  const [tableSort, setTableSort] = useState<BetaTableSorterValue['tableSort']>(defaultSortTableState);

  const sortedPipelines = useMemo(() => {
    if (!pipelines.length || managedAreaId) return [];

    let sortedPipelines: any[] = [];

    if (!tableSort?.key || !tableSort?.direction) {
      sortedPipelines = [];

      return sortedPipelines;
    }

    if (managedAreaId) {
      if (tableSort.direction === 1) {
        sortedPipelines = _.sortBy(_.cloneDeep(pipelines), [tableSort.key]);
      } else if (tableSort.direction === -1) {
        sortedPipelines = _.sortBy(_.cloneDeep(pipelines), [tableSort.key]).reverse();
      }
    }

    return sortedPipelines;
  }, [pipelines, tableSort, managedAreaId]);

  const sortedTrees = useMemo(() => {
    if (!trees?.length) return [];

    let sortedTrees: any[] = [];

    if (!tableSort?.key || !tableSort?.direction) {
      sortedTrees = [];

      return sortedTrees;
    }

    if (managedAreaId) {
      if (tableSort.direction === 1) {
        if (microclimateProperties.includes(tableSort.key)) {
          sortedTrees = _.sortBy(_.cloneDeep(trees), [(obj) => obj.microclimate?.[tableSort.key]]);
        } else {
          sortedTrees = _.sortBy(_.cloneDeep(trees), [tableSort.key]);
        }
      } else if (tableSort.direction === -1) {
        if (microclimateProperties.includes(tableSort.key)) {
          sortedTrees = _.sortBy(_.cloneDeep(trees), [(obj) => obj.microclimate?.[tableSort.key]]).reverse();
        } else {
          sortedTrees = _.sortBy(_.cloneDeep(trees), [tableSort.key]).reverse();
        }
      }
    }

    return sortedTrees;
  }, [trees, tableSort, managedAreaId]);

  const sortTable = useCallback(
    async (props: SortTableProps) => {
      const { key, direction } = props.sortableProperty;

      if (!tableSort?.key || key !== tableSort?.key || direction !== tableSort.direction) {
        setTableSort({
          key,
          direction,
          type: managedAreaId ? 'trees' : 'managedArea',
        });
      }
    },
    [tableSort?.key, tableSort?.direction, managedAreaId]
  );

  return {
    sortedPipelines,
    sortedTrees,
    tableSort,

    sortTable,
  };
};

export default useBetaTableSorter;
