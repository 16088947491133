import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import * as _ from 'lodash';
import { useEffect } from 'react';
import ValidationAction from '../../../enums/ValidationAction';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';

const TO_FIXED = 2;

const TreeHeightCard = () => {
  const { treeMetrics, visibility, cards, validation, actions, helper, section, pointCloudModule } = useSemanticScreenContext();

  const min = pointCloudModule.pointCloud?.geometry?.boundingBox?.min.z ?? METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].min;
  const max = pointCloudModule.pointCloud?.geometry?.boundingBox?.max.z ?? METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].max;

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.TREE_HEIGHT);

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(treeMetrics.height),
      METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = cards.alerts.find((a) => a.name === METRICS_NAMES.TREE_HEIGHT);

      if (!alreadyAlerted?.name) {
        cards.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.TREE_HEIGHT,
            message: `Tree height should be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].min} and ${METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].max
              } ${METRICS_VALUE_LIMITS[METRICS_NAMES.TREE_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      cards.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.TREE_HEIGHT));
    }
  }, [treeMetrics.height]);

  return (
    <AttributeCard
      title='Tree height'
      color={SemanticActionsColorMap[ValidationAction.Height]}
      isExpanded={visibility.height}
      onExpand={visibility.setHeight}
      setCompleted={validation.setIsTreeHeightCompleted}
      disabled={helper.isEditingDisabled}
      completed={validation.isTreeHeightCompleted}
      isActive={helper.activeTool === ValidationAction.Height}
      setIsActive={() => {
        actions.setActiveTool(ValidationAction.Height);
        section.setDefaultDepth();
        section.resetSectionTargetToDefault();
      }}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Height from ground</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              // Correct height with coordinate system delta after initial load
              value={Number((treeMetrics.height - helper.viewerPosition.z).toFixed(TO_FIXED))}
              step={0.01}
              onChange={(value: number) => {
                if (helper.isEditingDisabled) return;
                const newState = value + helper.viewerPosition.z;
                treeMetrics.setHeight(newState);
              }}
              min={Number(min?.toFixed(TO_FIXED))}
              max={Number(((max ?? 0) - helper.viewerPosition.z).toFixed(TO_FIXED))}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default TreeHeightCard;
