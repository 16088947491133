import { CSSProperties, useState } from 'react';
import { IconType } from '../../@types/Icon';
import Icon, { IconComponentKey } from '../Icon';

type IconButtonProps = {
  icon: IconComponentKey | IconType;
  disabled?: boolean;
  onClick?: () => void;
  active?: boolean;
  style?: CSSProperties;
  tooltip?: string;
  tooltipDirection?: string;
};

const IconButton = (props: IconButtonProps): JSX.Element => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <i
        style={{
          ...props.style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          cursor: props.disabled ? 'not-allowed' : 'pointer',
          backgroundColor: props.active ? 'white' : 'initial',
        }}
        onClick={props.onClick}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        <Icon icon={props.icon} style={{ color: props.active ? '#202324' : props.disabled ? '#494B4D' : 'white' }} />
      </i>
      {props.tooltip && (
        <span className={`tooltip${props.tooltipDirection ? `-${props.tooltipDirection}` : ''} ${isTooltipVisible ? 'visible' : ''}`}>
          {props.tooltip}
        </span>
      )}
    </div>
  );
};

export default IconButton;
