import { useLocation } from 'react-router-dom';
import { LeftRightOptions, SelectParams, SideKey, ToolBarProps } from '../@types/ToolBar';
import RoundCheckbox from './inputs/RoundCheckbox';
import TabSelector from './TabSelector';

const ToolBar = ({
  activeTabs,
  side,
  tabs,
  setActiveTab,
  isEnvironmentEnabled,
  setIsEnvironmentEnabled,
  pointSize,
  setPointSize,
}: ToolBarProps) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search.replace('?', ''));

  const _handleChange = ({ side, value: val }: SelectParams) => {
    const _value: LeftRightOptions = { ...activeTabs };
    _value[side] = val;
    setActiveTab(_value);

    Object.keys(activeTabs).forEach((key) => query.set(key, _value[key as SideKey]));
    window.history.replaceState(null, '', `${location.pathname}?${query.toString()}`);
  };

  return (
    <div className='view-toolbar__wrapper'>
      <div className='view-toolbar'>
        <div className='view-toolbar__item-wrapper'>
          <TabSelector active={activeTabs[side]} side={side} tabs={tabs} onSelect={_handleChange} />
        </div>
        <div className='view-toolbar__item-wrapper'>
          {activeTabs[side] === 'pointcloud' && (
            <>
              <RoundCheckbox
                label='Environment'
                color={'#73BCEC'}
                disabled={false}
                value={isEnvironmentEnabled}
                onChange={setIsEnvironmentEnabled}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToolBar;
