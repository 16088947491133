import fileDownload from 'js-file-download';
import { convertTreeToCsv } from './csvDefinition';

const VALUE_SEPARATOR = ';';
const LINE_SEPARATOR = '\n';

export const exportCsv = async (managedAreas, handleRequest) => {
  const managedAreasIds = managedAreas
    .map((ma) => `mas=${ma.id}`)
    .join('&');
  const treesResponse = await handleRequest(`/v1/trees?${managedAreasIds}`);
  const trees = (await treesResponse.json()).sort((a, b) => (a.managed_area > b.managed_area) ? 1 : -1);
  const csvData = generateRelevantData(trees);
  const csv = generateCsv(csvData);

  const csvName = managedAreas.length === 1 ?  `${managedAreas[0].code}_microclimate` : `completed_mas_microclimate_${generateMasCsvsDateFormat()}`;
  fileDownload(csv, `${csvName}.csv`);

};

const generateRelevantData = (trees) => {
  return trees.map(convertTreeToCsv);
};

const generateCsv = (dataArray) => {
  const csvHeader = Object.keys(dataArray[0]).join(VALUE_SEPARATOR);
  const csvData = dataArray.map((tree) => Object.values(tree).join(VALUE_SEPARATOR));

  const csvObject = [csvHeader, ...csvData];

  return csvObject.join(LINE_SEPARATOR);
};

const generateMasCsvsDateFormat = () => {
  const d = new Date();
  const datestring = `${d.getDate()}${(d.getMonth()+1)}${d.getFullYear()}_${d.getHours()}_${d.getMinutes()}`;

  return datestring;
}
