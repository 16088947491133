import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { KeyOfMicroclimate, SavedMicroclimateValues } from '../@types/MicroclimateValidation';

type SavedMicroclimateContext = {
  values?: SavedMicroclimateValues;
  saveMicroclimateValues?: (values: SavedMicroclimateValues | undefined) => void;
  selectedParams: KeyOfMicroclimate[];
  setSelectedParams?: Dispatch<SetStateAction<KeyOfMicroclimate[]>>;
  clear?: () => void;
};

export const Context = createContext<SavedMicroclimateContext>({ selectedParams: [] });

export const useSavedMicroclimateValues = () => useContext(Context);

export const Provider = ({ children }: { children: ReactNode }) => {
  const [values, setValues] = useState<SavedMicroclimateValues>();
  const [selectedParams, setSelectedParams] = useState<KeyOfMicroclimate[]>([]);

  const saveMicroclimateValues = (values?: SavedMicroclimateValues) => setValues(values);

  const clear = () => {
    setValues(undefined);
    setSelectedParams([]);
  };

  useEffect(() => {
    return () => {
      setValues(undefined);
      setSelectedParams([]);
    };
  }, []);

  return (
    <Context.Provider value={{ values, saveMicroclimateValues, selectedParams, setSelectedParams, clear }}>{children}</Context.Provider>
  );
};
