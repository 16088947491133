import { useCallback, useState } from 'react';
import { clone } from 'lodash';

export enum TreeActions {
  COMMENT = 'comment',
  REMOVE_COMMENT = 'removeComment',
  NAVIGATION = 'navigation',
  DELETE = 'delete',
  CREATE = 'create',
  SET_TMS_CATEGORY = 'setTmsCategory',
  MARK_AS_DONE = 'markAsDone',
  MARK_AS_COMPLETED = 'markAsCompleted',
  SET_GENUS_AND_SPECIES = 'setGenusAndSpecies',
  MARK_AS_UNCERTAIN = 'markAsUncertain',
  SEND_TO_ANNOTATION = 'sendToAnnotation',
  UPDATE = 'update',
}

export interface TreeAction {
  treeId: string | null;
  createdAt: Date;
  action: TreeActions,
  payload: any
}

export const useBetaTreeHistory = (callback: (action: TreeAction) => Promise<any>) => {
  const [stack, setStack] = useState<TreeAction[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const add = useCallback(async (treeId: string | null, action: TreeActions, payload: any | null) => {
    // setStack((prev) => [...prev, { treeId, action, payload: clone(payload), createdAt: new Date() }]);

    setStack([{ treeId, action, payload: clone(payload), createdAt: new Date() }]);
  }, []);

  const undo = useCallback(async () => {
    return new Promise((resolve, reject) => {
      setStack((prev) => {
        if (prev.length === 0) {
          return [];
        }

        const last = prev[prev.length - 1];

        try {
          setLoading(true);
          setError(null);

          callback(last)
            .then(resolve)
            .catch(reject);
        } catch (e) {
          setError(e as any);
        } finally {
          setLoading(false);
        }

        return prev.slice(0, prev.length - 1)
      });
    })
  }, [stack]);

  return {
    add,
    undo,
    stack,
    error,
    loading,

    isUndoAvailable: !!stack?.length
  }
};
