import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalConfig } from '../providers/data';
import useApi from "./api";
import { DropdownInput, TextInput } from '../components/inputs';

const useComments = () => {
  const { handleRequest } = useApi();
  const { loaded, getGlobalConfig } = useGlobalConfig();
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [preDefinedComments, setPredefinedComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState('Other');

  useEffect(() => {
    if (loaded) {
      setPredefinedComments([
        ...getGlobalConfig('predefined-comments').value.map((comment) => ({ value: comment, label: comment })),
        { value: 'Other', label: 'Other' },
      ]);
    }
  }, [getGlobalConfig, loaded]);

  const getFinalComment = useCallback((isOptional = false) => {
    if (!isOptional && selectedComment === 'Other' && !comment) {
      setError('This field is required');
      return false;
    }

    const finalComment = selectedComment === 'Other' ? comment : selectedComment;
    setComment('');
    return finalComment;
  }, [comment, selectedComment]);

  const onCommentDropdownChange = ({value}) => {
    setSelectedComment(value);
  };

  const loadCommentsForTree = useCallback((treeID) => {
    return new Promise((resolve, reject) => {
      handleRequest(`v1/comments/${treeID}`)
      .then((res) => res.json())
      .then((res) => {
        resolve(res)
      })
      .catch((e)=> reject(e))
    })
  },[handleRequest])

  const getCommentArea = () => (<>
    {preDefinedComments && <DropdownInput options={preDefinedComments} onChange={onCommentDropdownChange} value={selectedComment}/>}
    {selectedComment === 'Other' && <TextInput label='Comment' onPureChange={setComment} value={comment} error={error} autoFocus />}
  </>);


  return {
    getCommentArea,
    getFinalComment,
    loadCommentsForTree
  };
}

export default useComments;