import { useRef } from 'react';

import { DatePicker } from '.';

const DateRangePicker = ({
  label = '',
  from = new Date(),
  to = new Date(),
  onChangeFrom = () => {},
  onChangeTo = () => {},
  autoFocus,
}) => {
  const ref = useRef();

  const _handleFromChange = (date) => {
    onChangeFrom(date);
    autoFocus && ref.current?.click();
  };

  return (
    <div className="date-range-picker-wrapper">
      {label && <span className="label">{label}</span>}
      <div className="date-range-picker-calendars-wrapper">
        <DatePicker
          label="From:"
          onChange={_handleFromChange}
          value={from}
          maxDate={to}
        />
        <DatePicker
          label="To:"
          onChange={onChangeTo}
          value={to}
          minDate={from}
          buttonRef={ref}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
