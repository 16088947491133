import Icon from '../Icon';

type UncertainProps = {
  value: boolean;
};

const Uncertain = ({ value }: UncertainProps) => {
  return value ? (
    <div style={{ display: 'flex' }}>
      <Icon icon={'valid'} />
      <span style={{ marginLeft: '10px' }}>Confident</span>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <Icon icon={'invalid'} />
      <span style={{ marginLeft: '10px' }}>Uncertain</span>
    </div>
  );
};

export default Uncertain;
