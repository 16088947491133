import { CommentTree } from "../components/modals";
import TreeFlowStatus from "../enums/TreeFlowStatus";
import ValidationTool from "../enums/ValidationTool";

export const validationActions = ({
  onUndo,
  isUndoAvailable,
  tree,
  updateTree,
  markAsDone = () => void 0,
  showTodoAction,
  removeTree,
  sendToField,
  commentTree,
  removeTreeComment = () => {},
  onSkip = () => {},
  numberOfTrees = 0,
  dismissModal,
  presentModal,
  saveDisabled,
  deleteHidden,
  showComment,
  disabled,
  onRotation,
  validationTool,
  changeTool,
  isSemantics,
  showDeleteConfirmation,
  onSetTmsCategory,
  showTmsButtons,
  showTmsL3Button,
  showNavigationArrows,
  isLocationVal = false,
}) => [
  !deleteHidden && {
    icon: "trash",
    id: "trash",
    disabled: !numberOfTrees || disabled,
    action: () => removeTree(tree),
    color: "red",
    showConfirmationPopup: true,
  },
  !deleteHidden && {
    type: "separator",
  },
  !!showTmsL3Button && !!tree && sendToField && {
    label: 'L3',
    id: "l3",
    action: () => onSetTmsCategory('l3', tree),
    tooltip: "Set TMS category to L3",
    color: "green",
    hasChecked: (tree?.tms_category === 'l3'),
    disabled: (tree?.tms_category === 'l3') || !numberOfTrees || disabled,
    showConfirmationPopup: true,
    confirmationPopupText: 'Do you really want to set L3?'
  },
  !!showTmsL3Button && !!tree && sendToField && {
    type: "separator",
  },
  /* isSemantics && {
    icon: "eye",
    id: "view",
    color: "primary",
    activeColor: "primary",
    tooltip: "View",
    isActive: validationTool === ValidationTool.View || !validationTool,
    action: () => changeTool(ValidationTool.View),
  },
  isSemantics && {
    type: "separator",
  },
  isSemantics && {
    icon: "paint-brush",
    id: "brush",
    color: "green",
    activeColor: "green",
    tooltip: "Reclassify",
    isActive: validationTool === ValidationTool.Reclassify,
    action: () => changeTool(ValidationTool.Reclassify),
  },
  isSemantics && {
    type: "separator",
  },
  isSemantics && {
    icon: "bug",
    id: "error",
    color: "red",
    activeColor: "red",
    tooltip: "Mark errors",
    isActive: validationTool === ValidationTool.Error,
    action: () => changeTool(ValidationTool.Error),
  },
  isSemantics && {
    type: "separator",
  },*/
  isSemantics && {
    icon: "eye",
    id: "inspection_view",
    color: "primary",
    activeColor: "green",
    tooltip: "Inspection View",
    isActive: validationTool === ValidationTool.Inspection_View,
    action: () => changeTool(ValidationTool.Inspection_View, true),
  },
  isSemantics && {
    icon: "paint-brush",
    id: "brush",
    color: "green",
    activeColor: "green",
    tooltip: "Reclassify",
    isActive: validationTool === ValidationTool.Reclassify,
    action: () => changeTool(ValidationTool.Reclassify, true),
  },
  isSemantics && {
    type: "separator",
  },
  onUndo && {
    icon: "undo-alt",
    id: "undo",
    disabled: !isUndoAvailable,
    action: onUndo,
    tooltip: "Undo",
  },
  showComment && {
    icon: "comment",
    id: "comment",
    disabled: disabled,
    color: "primary",
    hasNotification: tree?.comment?.length > 0,
    action: () =>
      presentModal({
        title: "Add comment to tree",
        content: (
          <CommentTree
            key={`comment-tree-modal-${tree.id}`}
            onComment={commentTree}
            onRemoveComment={removeTreeComment}
            tree={tree}
          />
        ),
      }),
  },
  showComment && {
    type: "separator",
  },
  updateTree && showTodoAction && {
    icon: "pen",
    id: "pen",
    disabled: !numberOfTrees || saveDisabled || disabled || (!!showTmsButtons && !tree?.tms_category) || !tree?.comment?.length,
    action: async () => {
      await updateTree('sent_to_offline_annotation', TreeFlowStatus.SentToOfflineAnnotation);
    },
    tooltip: (() => {
      if (!tree?.comment?.length) return 'Please give a comment';
      if ((!!showTmsButtons && !tree?.tms_category)) return 'Must select TMS category';

      return 'Send to offline annotation';
    })(),
    color: "primary",
  },
  updateTree && showTodoAction && {
    type: "separator",
  },
  markAsDone && (isSemantics || isLocationVal ) && {
    icon: "check",
    id: "check",
    disabled: saveDisabled || disabled || (!!showTmsButtons && !isSemantics && !tree?.tms_category),
    action: () => markAsDone(tree),
    tooltip: "Mark as done",
    color: "green",
  },
  markAsDone && (isSemantics || isLocationVal ) && {
    type: "separator",
  },
  !!showTmsButtons && !!tree && sendToField && {
    label: 'L0',
    id: "l0",
    action: () => onSetTmsCategory('l0', tree),
    tooltip: "Set TMS category to L0",
    color: "green",
    hasChecked: (tree?.tms_category === 'l0'),
    disabled: (tree?.tms_category === 'l3') || disabled,
  },
  !!showTmsButtons && !!tree && sendToField && {
    label: 'L1',
    id: "l1",
    action: () => onSetTmsCategory('l1', tree),
    tooltip: "Set TMS category to L1",
    color: "green",
    hasChecked: (tree?.tms_category === 'l1'),
    disabled: (tree?.tms_category === 'l3') || disabled,
  },
  !!showTmsButtons && !!tree && sendToField && {
    label: 'L2',
    id: "l2",
    action: () => onSetTmsCategory('l2', tree),
    tooltip: "Set TMS category to L2",
    color: "green",
    hasChecked: (tree?.tms_category === 'l2'),
    disabled: (tree?.tms_category === 'l3') || disabled,
  },
  !!showTmsButtons && !!tree && sendToField && {
    type: "separator",
  },
  !!showNavigationArrows && {
    icon: "arrow-left",
    id: "previous",
    disabled: !numberOfTrees || disabled,
    action: onSkip(-1),
  },
  !!showNavigationArrows && {
    icon: "arrow-right",
    id: "next",
    disabled: !numberOfTrees || disabled,
    action: onSkip(1),
  },
];
