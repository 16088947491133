import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Icon } from '..';
import { useUser } from '../../providers/user';
import Popup from '../Popup';
import { useData, useGlobalConfig } from '../../providers/data';
import { useConfig } from '../../providers/config';
import { Button, TextInput } from '../inputs';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

const UserDropdown = ({ name, managedArea, trees }) => {
  const { logout, hasRole } = useUser();
  const history = useHistory();
  const {getPipeline} = useData();
  const [canSetLocationConfidence, setCanSetLocationConfidence] = useState(false);

  useEffect(() => {
    if (managedArea) {
      const isLocationConfidenceSettingsAvailable = getPipeline(managedArea.id)?.current_manual_step === 'location_validation';
      setCanSetLocationConfidence(isLocationConfidenceSettingsAvailable)
    }
  }, [getPipeline, managedArea])

  const _handleLogout = () => {
    //instance.logout();
    //setApiToken(null);
    logout();
  }

  const _handlePreferences = path => () => history.push(`/preferences/${path}`);

  return (
    <div className="user-dropdown-wrapper">
      <div className="user-wrapper">
        <img src={`https://eu.ui-avatars.com/api/?name=${name}`} alt={name} />
        <div>
          <span className="name">{ name }</span><br/>
        </div>
      </div>
      <div className="separator" />
      <Action label='Personal preferences' icon='user-cog' action={_handlePreferences('personal')} />
      { hasRole('admin') && <Action label='Administration' icon='users-cog' action={_handlePreferences('administration')} /> }
      { hasRole('admin') && <Action label='Administrational parameters' icon='tags' action={_handlePreferences('administrational-paramenters')} /> }
      <div className="separator" />
      {canSetLocationConfidence && <LocationConfidenceHandler managedArea={managedArea} {...trees} disableInputs={!hasRole('admin')} />}
      <Action label='Log out' icon='sign-out-alt' primary action={_handleLogout} />
    </div>
  )
};

const Action = ({ label, icon, primary, action }) => {
  return (
    <div className="dropdown-action-wrapper" onClick={action}>
      <div className="icon-wrapper">
        <Icon icon={icon} />
      </div>
      <span className={`${primary ? 'primary' : ''} label`}>{ label }</span>
    </div>
  )
}

const LocationConfidenceHandler = ({managedArea, trees, updateTree, disableInputs}) => {
  const { config, getConfig, updateConfig } = useConfig();
  const { getGlobalConfig, loaded } = useGlobalConfig();
  const locationGlobalConfidenceMax = (getGlobalConfig('location-validation-confidence-level-max')?.value ?? 100);
  const locationGlobalConfidenceMin = (getGlobalConfig('location-validation-confidence-level-min')?.value ?? 0);
  const { currentMAId, max: maxConfidenceLevel, min: minConfidenceLevel } = getConfig('locationValidationCustomConfidenceLevels');

  useEffect(() => {
    if (loaded && managedArea.id !== currentMAId) {
      config.locationValidationCustomConfidenceLevels = {
        max: locationGlobalConfidenceMax,
        min: locationGlobalConfidenceMin,
        currentMAId: managedArea.id
      };

      updateConfig(config);
    }
  }, [config, config.locationValidationCustomConfidenceLevels, currentMAId, locationGlobalConfidenceMax, locationGlobalConfidenceMin, managedArea, updateConfig, loaded])

  const _handleConfidenceLevels = ({name, value}) => {
    if ((value >= 0 && value <= 100) || !value) {
      config.locationValidationCustomConfidenceLevels = Object.assign(
        config.locationValidationCustomConfidenceLevels,
        {
          [name]: value
        }
      );
  
      updateConfig(config);
    }
  }

  const _handleAutomaticValidation = (confidenceFilter) => {
    const todos = trees.filter(it => it.status === 'location_validation_todo' || it.tree_flow_status ===  TreeFlowStatus.LocationValidationQueued);
    const treesToBeValidated = todos.filter(confidenceFilter);
    updateTree(treesToBeValidated.map(tree => tree.id), {
      tree_flow_status: TreeFlowStatus.LocationValidationDone,
      manual_treelocation_changes: false,
    });
  
    // check if postValidation needed (no more todos)
    if (todos.length - treesToBeValidated.length === 0) {

    }
  }

  const _getTreesBelowConfidenceLevel = (tree) => tree.location_confidence <= minConfidenceLevel / 100;
  const _getTreesAboveConfidenceLevel = (tree) => tree.location_confidence >= maxConfidenceLevel / 100;
  
  return (
    <>
      <div className="wrapper-plain">
        <span className="label">Location confidence settings for the selected MA</span>
        <Popup title={'Location of trees can be automatically validated. [BR] MIN: Trees below this confidence level will be deleted. [BR] MAX: Trees above this confidence level will be confirmed.'} direction="left">
          <div className="question">
            <Icon icon="question" />
          </div>
        </Popup>
      </div>
      <div className="confidence-level-settings">
      <span>MIN level:</span>
        <TextInput
          min="0"
          max="100"
          type="number"
          name="min"
          label={locationGlobalConfidenceMin}
          value={minConfidenceLevel}
          onChange={_handleConfidenceLevels}
          disabled={disableInputs}
        />
        <Button onClick={() => _handleAutomaticValidation(_getTreesBelowConfidenceLevel)} destructive label="Delete trees"/>
      </div>
      <div className="confidence-level-settings">
        <span>MAX level:</span>
        <TextInput
          min="0"
          max="100"
          type="number"
          name="max"
          label={locationGlobalConfidenceMax}
          value={maxConfidenceLevel}
          onChange={_handleConfidenceLevels}
          disabled={disableInputs}
        />
        <Button onClick={() => _handleAutomaticValidation(_getTreesAboveConfidenceLevel)} primary label="Confirm trees"/>
      </div>
      <div className="separator" />
    </>
  )
}

export default UserDropdown;