const Deselect = ({ width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66659 5.0001H16.6666M3.33325 5.00843L3.34159 4.99927M3.33325 10.0084L3.34159 9.99927M3.33325 15.0084L3.34159 14.9993M6.66659 10.0001H16.6666M6.66659 15.0001H16.6666'
        stroke='#818385'
        strokeWidth='1.21667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Deselect;
