import { useState, useEffect } from 'react';
import { useUser } from '../providers/user';
import { handleRequest, getUrl } from './api';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const { token } = useUser();


  const _handleLoad = async () => {
    const res = await handleRequest(token)(`/v1/users?q=${new Date().getTime()}`).then(res => res.json());

    setUsers(res.map(user => ({ ...user, managed_areas: user.managed_areas ? user.managed_areas?.map(ma => parseInt(ma)) : []})));
  }

  const _handleUserUpdate = (id, diff = {}) => null;

  const _handleUsersUpdate = (changes = []) => {
    const _users = [...users];

    changes.forEach(({ id, diff = {} }) => {
      const index = _users.findIndex(user => user.id == id);
      if (_users[index]) {
        _users[index] = { ..._users[index], ...diff };

        const bodyString = JSON.stringify(Object.keys(diff).reduce((prev, key) => (typeof diff[key] !== 'undefined') ? { ...prev, [key]: diff[key] } : prev, {})); 
        handleRequest(token)(`/v1/users/${id}`, { 
          method: 'PATCH',
          body: bodyString,
          headers: { 'Content-Type': 'application/json' }
        });
        
      }
    });

    setUsers(_users);
  }

  useEffect(() => {
    _handleLoad();
  }, [])
  return {
    users: users,
    updateUser: _handleUserUpdate,
    updateUsers: _handleUsersUpdate,
  }
}

export default useUsers;
