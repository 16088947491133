
import { useLocation, useHistory } from 'react-router-dom';

export interface BetaQueryParamsValue {
  getQueryParams: () => URLSearchParams;
  setQueryParams: (params: Record<string, any>) => void;
}

export const useBetaQueryParams = (): BetaQueryParamsValue => {

  const location = useLocation();
  const history = useHistory();

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const setQueryParams = (params: Record<string, any>) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(params).forEach((param) => {
      const [key, value] = param;

      if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return { getQueryParams, setQueryParams };
};