import { StyledPointClass } from '../@types/PointClassStyles';
import TreePointCloud from '../@types/TreePointCloud';

const getPointClassName = (value: number) => ([21, 23].includes(value) ? 'canopy' : 'trunk');

const colorHexStringToRgbArray = (hex: string) => {
  if (hex.length !== 7) {
    throw new Error('Only six-digit hex colors are allowed (eq: #1122BB).');
  }

  let tmp = hex.substring(1).match(/.{1,2}/g);
  if (tmp === null) {
    throw new Error('Invalid hex color format.');
  }

  return [parseInt(tmp[0], 16), parseInt(tmp[1], 16), parseInt(tmp[2], 16)];
};

const writeColor = (laz: TreePointCloud, color: string, pclass: keyof typeof StyledPointClass) => {
  const newColors = new Float32Array(laz?.pc.pointCount * 3);

  const oldColors = laz!.geometry.getAttribute('color').array;
  const classifications = laz!.geometry.getAttribute('classification').array;
  const rgb = colorHexStringToRgbArray(color);
  for (const [index, pointClass] of (classifications as any).entries()) {
    if (StyledPointClass[pclass] === pointClass) {
      newColors[3 * index] = rgb[0] / 255;
      newColors[3 * index + 1] = rgb[1] / 255;
      newColors[3 * index + 2] = rgb[2] / 255;
    } else {
      newColors[3 * index] = oldColors[3 * index];
      newColors[3 * index + 1] = oldColors[3 * index + 1];
      newColors[3 * index + 2] = oldColors[3 * index + 2];
    }
  }
  return newColors;
};

export { colorHexStringToRgbArray, getPointClassName, writeColor };
