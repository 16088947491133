enum TreeFlowStatus {
LocationProposalDone = 'LOCATION_PROPOSAL_DONE',
LocationProposalError = 'LOCATION_PROPOSAL_ERROR',
LocationProposalDeleted = 'LOCATION_PROPOSAL_DELETED',

LocationValidationQueued = 'LOCATION_VALIDATION_QUEUED',
LocationValidationRunning = 'LOCATION_VALIDATION_RUNNING',
LocationValidationDeleted = 'LOCATION_VALIDATION_DELETED',
LocationValidationDone = 'LOCATION_VALIDATION_DONE',

SegmentationCalculationQueued = 'SEGMENTATION_CALCULATION_QUEUED',
SegmentationCalculationRunning = 'SEGMENTATION_CALCULATION_RUNNING',
SegmentationCalculationError = 'SEGMENTATION_CALCULATION_ERROR',
SegmentationCalculationDeleted = 'SEGMENTATION_CALCULATION_DELETED',
SegmentationCalculationDone = 'SEGMENTATION_CALCULATION_DONE',

SegmentationValidationQueued = 'SEGMENTATION_VALIDATION_QUEUED',
SegmentationValidationRunning = 'SEGMENTATION_VALIDATION_RUNNING',
SegmentationValidationDeleted = 'SEGMENTATION_VALIDATION_DELETED',
SegmentationValidationDone = 'SEGMENTATION_VALIDATION_DONE',

MeasurementCalculationQueued = 'MEASUREMENT_CALCULATION_QUEUED',
MeasurementCalculationRunning = 'MEASUREMENT_CALCULATION_RUNNING',
MeasurementCalculationError = 'MEASUREMENT_CALCULATION_ERROR',
MeasurementCalculationDeleted = 'MEASUREMENT_CALCULATION_DELETED',
MeasurementCalculationDone = 'MEASUREMENT_CALCULATION_DONE',

MeasurementValidationQueued = 'MEASUREMENT_VALIDATION_QUEUED',
MeasurementValidationRunning = 'MEASUREMENT_VALIDATION_RUNNING',
MeasurementValidationDeleted = 'MEASUREMENT_VALIDATION_DELETED',
MeasurementValidationDone = 'MEASUREMENT_VALIDATION_DONE',

SpeciesCalculationQueued = 'SPECIES_CALCULATION_QUEUED',
SpeciesCalculationRunning = 'SPECIES_CALCULATION_RUNNING',
SpeciesCalculationError = 'SPECIES_CALCULATION_ERROR',
SpeciesCalculationDone = 'SPECIES_CALCULATION_DONE',

SpeciesValidationQueued = 'SPECIES_VALIDATION_QUEUED',
SpeciesValidationRunning = 'SPECIES_VALIDATION_RUNNING',
SpeciesValidationDone = 'SPECIES_VALIDATION_DONE',

SentToOfflineAnnotation = 'SENT_TO_OFFLINE_ANNOTATION',
SentToOnlineAnnotationQueued = 'SENT_TO_ONLINE_ANNOTATION_QUEUED',
SentToOnlineAnnotationRunning = 'SENT_TO_ONLINE_ANNOTATION_RUNNING',
SentToOnlineAnnotationDone = 'SENT_TO_ONLINE_ANNOTATION_DONE',
SentToField = 'SENT_TO_FIELD',
Completed = 'COMPLETED',
};

export default TreeFlowStatus;