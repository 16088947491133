import { IconType } from '../../@types/Icon';
import Icon from '../Icon';

type IconButtonProps = {
  icon: IconType;
  onClick?: (entry: any) => void;
  entry: any;
};

const IconButton = ({ icon, onClick, entry }: IconButtonProps) => {
  const _handleClick = () => onClick && onClick(entry);
  return (
    <button onClick={_handleClick}>
      <Icon icon={icon} />
    </button>
  );
};

export default IconButton;
