import {
  BufferGeometry,
  Group,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  RingGeometry,
  SphereGeometry,
  Vector3
} from 'three';

export class TreeLock extends Group {
  private realTreeLock = new Mesh(
    new RingGeometry(.2, .5, 30, 30),
    new MeshBasicMaterial({
      color: 'cyan',
      opacity: .8,
      transparent: true
    })
  );

  private originalTreeLock = new Mesh(
    new SphereGeometry(.05),
    new MeshBasicMaterial({
      color: 'red',
      opacity: .5,
      transparent: true
    })
  );

  private connection: Object3D | null = null;

  constructor() {
    super();

    this.add(this.originalTreeLock);
    this.add(this.realTreeLock);
  }

  private static createConnection(cp1: Object3D, cp2: Object3D) {
    return new Line(
      new BufferGeometry().setFromPoints([
        cp1.position.clone(),
        cp2.position.clone()
      ]),
      new LineBasicMaterial({
        color: 'white'
      })
    );
  }

  public initPosition(v: Vector3) {
    this.setRealTreeLockPosition(v);
    this.setOriginalTreeLockPosition(v);
  }

  public setRealTreeLockPosition(v: Vector3) {
    this.realTreeLock.position.fromArray(v.clone().toArray());

    this.update();
  }

  public setOriginalTreeLockPosition(v: Vector3) {
    this.originalTreeLock.position.fromArray(v.clone().toArray());

    this.update();
  }

  private update() {
    if (this.connection) this.remove(this.connection);
    this.connection = TreeLock.createConnection(this.originalTreeLock, this.realTreeLock);
    this.add(this.connection);

    const distance = Math.sqrt(
      Math.pow(this.originalTreeLock.position.x - this.realTreeLock.position.x, 2)
      + Math.pow(this.originalTreeLock.position.y - this.realTreeLock.position.y, 2)
      + Math.pow(this.originalTreeLock.position.z - this.realTreeLock.position.z, 2)
    )

    distance > .2
      ? this.originalTreeLock.visible = true
      : this.originalTreeLock.visible = false;

    distance > .2
      ? this.connection.visible = true
      : this.connection.visible = false;
  }
}
