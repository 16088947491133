const Resume = ({ width = 21, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 18.3333C15.1023 18.3333 18.8333 14.6024 18.8333 9.99999C18.8333 5.39762 15.1023 1.66666 10.5 1.66666C5.89759 1.66666 2.16663 5.39762 2.16663 9.99999C2.16663 14.6024 5.89759 18.3333 10.5 18.3333Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M14.3192 8.05584C13.675 6.74751 12.2025 5.83334 10.49 5.83334C8.32337 5.83334 6.54171 7.29501 6.33337 9.16668'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5783 8.10167H14.1666C14.2323 8.10167 14.2973 8.08873 14.358 8.06361C14.4186 8.03848 14.4737 8.00165 14.5202 7.95522C14.5666 7.90879 14.6034 7.85367 14.6286 7.79301C14.6537 7.73235 14.6666 7.66733 14.6666 7.60167V6.25M6.68079 11.3892C7.32579 13.0242 8.79745 14.1667 10.51 14.1667C12.6766 14.1667 14.4566 12.3392 14.6666 10'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.42171 11.3517H6.83337C6.70077 11.3517 6.57359 11.4043 6.47982 11.4981C6.38605 11.5919 6.33337 11.719 6.33337 11.8517V13.6667'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Resume;
