const WarningRed = ({ width = 20, height = 20, color = '#DB200B' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.7025 17.5H3.29746C2.01579 17.5 1.21413 16.1134 1.85246 15.0025L8.55496 3.34588C9.19663 2.23171 10.8041 2.23171 11.445 3.34588L18.1475 15.0025C18.7858 16.1134 17.9833 17.5 16.7025 17.5Z'
        fill={color}
      />
      <path d='M10 7.5V10.8333' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M10 14.1751L10.0083 14.1659' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default WarningRed;
