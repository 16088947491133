import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from "three";

const tempObject = new THREE.Object3D();

const Shape = ({
  data,
  materialProps = { color: "gray", opacity: 1, transparent: false },
}) => {
  const ref = useRef();
  const coords = data.voxels;
  const size = data.voxel_size * 10;

  useFrame(() => {
    coords.forEach(([x, y, z], id) => {
      tempObject.position.set(x, y, z);
      tempObject.scale.set(1, 1, 1);
      tempObject.updateMatrix();
      ref.current.setMatrixAt(id, tempObject.matrix);
    });

    ref.current.instanceMatrix.needsUpdate = true;

    ref.current.rotation.x = 1.5 * Math.PI;
    ref.current.position.y = -80;
  });

  return (
    <instancedMesh
      ref={ref}
      args={[null, null, coords.length]}
      scale={[size, size, size]}
    >
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshPhongMaterial attach="material" {...materialProps} />
    </instancedMesh>
  );
};

export default Shape;
