import { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import Icon from '../Icon';
import Popup from '../Popup';
import { Button, Slider } from '../inputs';

import mapbox from 'mapbox-gl';
import { generateHeaders, getUrl, getUrlForEndpoint } from '../../hooks/api';
import { withTheme } from '../../providers/theme';
import { withUser } from '../../providers/user';

// Components
import MapControls from './MapControls';

// Helpers
import generateLayer from './generateLayer';

// Contants
import { BOUNDS, COLUMNS, GEOM_COLUMNS, ID_COLUMNS, STYLES } from './config';
import { TableFilters } from './TableFilter';

// Dummy access token: needed as long as we don't create custom styles
mapbox.accessToken = 'pk.dummy.key';

class Map extends PureComponent {
  state = {
    lat: 48,
    lng: 9,
    zoom: 5,
    referenceWindSpeed: 80,
    safetyFactorThreshold: 1.5,
    isMiniMapHidden: false,
  };

  _resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      this.updateRatio();
    }
  });

  _popupVisible = false;
  _popupLayer = null;
  _popup = new mapbox.Popup({
    closeOnClick: false,
    closeButton: false,
  });
  _loaded = false;

  _start = null;

  // Refs
  _map;
  _container;

  _sources = {};
  _sourceVisible = {};
  _layers = [];
  _layerVisible = {};

  _position = {};

  _cameraPosition = null;

  _hoverId = null;
  _registeredOnClickListeners = {};

  constructor() {
    super();
    const el = document.createElement('div');
    el.id = 'location-marker';

    const grad = document.createElement('div');
    grad.width = 500;
    grad.height = 500;
    grad.innerHTML = `
    <svg id="location-marker-svg" height="500" width="500" viewBox="0 0 500 500">
		    <polygon fill="red" points="250,60 100,400 400,400" class="triangle" />
	  </svg>`;
    grad.id = 'location-gradient';
    el.appendChild(grad);

    this._cameraPosition = new mapbox.Marker(el);
    this._cameraPosition.setLngLat({ lng: 0, lat: 0 });
  }

  //Map methods
  getCenter = () => this._map.getCenter();
  getZoom = () => this._map.getZoom();

  getPopup = () => this._popup;
  getCameraPosition = () => {
    return this._cameraPosition;
  };
  getMap = () => this._map;

  getBounds = (features = []) => {
    // TODO!
  };

  /**
   * Center map on coordinates
   * @param {[[lng, lat], ...]} coords - The bbox of the features
   */
  focusOnBBox = (coords = []) => {
    if (!this._map) return setTimeout(() => this.focusOnBBox(coords), 500);
    // Getting center
    const bounds = new mapbox.LngLatBounds();
    Array.groupByTwo(coords.slice().flat(100)).forEach((coords) => coords.every((coord) => !!coord) && bounds.extend(coords));
    this._map?.fitBounds(bounds, { padding: 96 });
  };

  focusOnPoint = (coords, level = this.props?.minimap ? 18.7 : 20) => {
    if (!this._map) return setTimeout(() => this.focusOnPoint(coords, level), 500);
    // Getting center
    const bounds = new mapbox.LngLatBounds();
    Array.groupByTwo(coords.slice().flat(100)).forEach((coords) => coords.every((coord) => !!coord) && bounds.extend(coords));
    this._map?.flyTo({ center: bounds.getCenter(), zoom: level });
  };

  // Get map style based on config
  getStyle = () => {
    if (this.props.satellite) return STYLES.satellite;
    if (this.props.elevated) return STYLES.elevated;
    if (this.props.theme.isDark) return STYLES.dark;
    return STYLES.light;
  };

  /**
   * Toggle layer visibilities
   * @param {*} layer
   */
  handleLayerVisibility = (layers = this.props.layerVisible) => {
    Object.keys(layers).forEach((layerId) => {
      if (this._layers[layerId]) {
        if (!layers[layerId] && layerId === this._popupLayer) this._handlePopupClose();
        this.toggleLayer(layerId, layers[layerId]);
      }
    });
  };

  handleSourceVisibility = (sources = this.props.sourceVisible) => {
    Object.keys(sources).forEach((sourceId) => {
      this.toggleSource(sourceId, sources[sourceId]);
    });
  };

  toggleLayer = (layerId, visible) => {
    this._layerVisible[layerId] = visible;
    this._map.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none');
  };

  toggleSource = (sourceId, visible) => {
    this._sourceVisible[sourceId] = visible;

    Object.keys(this._layers).forEach((key) => {
      let layer = this._layers[key];
      if (layer.source === sourceId) {
        let layerVisible = visible && (this._layerVisible[layer.id] || this._layerVisible[layer.id] === undefined);

        this._map.setLayoutProperty(layer.id, 'visibility', layerVisible ? 'visible' : 'none');
      }
    });
  };

  miniMapHiddeButton = (active) => {
    return (
      <Popup title='Hide minimap' direction='right'>
        <div className='mini-map-hidden-button'>
          <div className={`action-wrapper ${active ? 'active' : ''}`}>
            <Icon icon={'map-marker-alt'} color='#000000'/>
          </div>
        </div>
      </Popup>
    );
  };

  onToggleMiniMap = () => {
    this.setState((prevState) => ({
      isMiniMapHidden: !prevState.isMiniMapHidden,
    }));
  };

  // Data methods
  clearSources() {
    this._sources = [];
  }

  /**
   * Add source to table
   * @param ID source the id of the source
   * @param ID table - the path to the table
   */
  addSource = (source, table, data) => {
    if (this._sources[source]) return console.error(`[MAP] [WARNING]: Tried to add same source multiple times: ${source}`);
    this._sources[source] = table;

    if (data)
      return this._map.addSource(source, {
        data,
        type: 'geojson',
      });

    const url = getUrlForEndpoint(
      `/v1/mvt/${table}/{z}/{x}/{y}?geom_column=${GEOM_COLUMNS[table]}&columns=${COLUMNS[table]}${
        ID_COLUMNS[table] ? '&id_column=' + ID_COLUMNS[table] : ''
      }&cacheBuster=${Date.now()}&latest=1`
    );

    this._map.addSource(source, {
      type: 'vector',
      tiles: [url],
      minzoom: BOUNDS[table][0],
      maxzoom: BOUNDS[table][1],
    });
  };

  killCodes = {};

  setDataForSource = (sourceId, data) => {
    if (this.killCodes[sourceId]) clearTimeout(this.killCodes[sourceId]);
    if (!this._loaded) this.killCodes[sourceId] = setTimeout(() => this.setDataForSource(sourceId, data), 500);
    const source = this._map?.getSource(sourceId);
    source?.setData?.(data);
  };

  /**
   * Parse raw layer input
   * @param { the raw layer input } layers
   */
  parseLayers = (layers) =>
    layers.forEach((layer) => {
      this.addLayer(layer);
    });

  /**
   * Add layer to map
   * @param inputLayer the layer input object
   * @param id the id of the layer
   */
  addLayer = (layer) => {
    const { below } = layer;
    layer = generateLayer(layer, this._sources);

    // If layer is an array recurse over it
    if (Array.isArray(layer)) {
      return layer.forEach((layer) => this.addLayerToMap(layer, below));
    } else {
      this.addLayerToMap(layer, below);
    }
  };

  updateLayerFilter = (layer) => {
    if (!layer.filter) return;
    try {
      // avoiding "style not loaded" error. it will be loaded later.
      this._map.setFilter(layer.id, ['all', layer.filter]);
    } catch {}
  };
  updateLayerStyle = (layer) => {
    this._map.setPaintProperty(layer.id, 'fill-color', [
      'case',
      ['boolean', ['feature-state', 'active'], false],
      '#0ff',
      ['in', ['get', 'code'], ['literal', layer.highlight || []]],
      '#ffc410',
      ['boolean', ['feature-state', 'hover'], false],
      layer.color,
      layer.color,
    ]);
  };
  updateLayerColor = (layer) => {
    const { paint } = generateLayer(layer, this._sources);
    Object.keys(paint).forEach((colorKey) => this._map.setPaintProperty(layer.id, colorKey, paint[colorKey]));
  };

  addLayerToMap(layer, below) {
    this._layers[layer.id] = layer;

    // Setting map local variable
    const map = this._map;

    // Add layer to map
    map.addLayer(layer, below);

    if (layer.filter) map.setFilter(layer.id, ['all', layer.filter]);

    if (layer.onClick || layer.popup || layer.clickable) {
      this.updateOnClickHandlerForLayer(map, layer);
      this.updateOnHoverHandlerForLayer(map, layer);
      this.setupCursorForLayer(map, layer);
    }
  }

  updateOnClickHandlerForLayer(map, layer) {
    this.updateHandlerForLayer(map, layer, 'click', this._handleClick(layer));
  }

  updateOnHoverHandlerForLayer(map, layer) {
    this.updateHandlerForLayer(map, layer, 'mousemove', this._handleMouseMoveForHover(layer));
    this.updateHandlerForLayer(map, layer, 'mouseleave', this._handleMouseLeaveForHover(layer));
  }

  _handleMouseMoveForHover = (layer) => (e) => {
    const currentHoverId = e.features[0].id;

    if (this._hoverId !== currentHoverId) this._handleLayerHover(false, layer, this._hoverId);

    this._hoverId = currentHoverId;

    if (this._hoverId) this._handleLayerHover(true, layer, this._hoverId);
  };

  _handleMouseLeaveForHover = (layer) => (e) => {
    this._handleLayerHover(false, layer, this._hoverId);
    this._hoverId = null;
  };

  updateHandlerForLayer(map, layer, event, handler) {
    if (!map) return;

    if (!this._registeredOnClickListeners[layer.id]) {
      this._registeredOnClickListeners[layer.id] = {};
    }

    const handlersForLayer = this._registeredOnClickListeners[layer.id];

    if (handlersForLayer[event]) {
      map.off(event, layer.id, handlersForLayer[event]);

      // to make sure that GC collects the function object
      handlersForLayer[event] = null;
    }

    handlersForLayer[event] = handler;
    map.on(event, layer.id, handlersForLayer[event]);
  }

  setupCursorForLayer(map, layer) {
    map.on('mousemove', layer.id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layer.id, () => {
      map.getCanvas().style.cursor = this.props.crosshair ? 'crosshair' : '';
    });
  }
  _resizeTimeout;
  updateRatio = () => {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = setTimeout(() => this._map?.resize(), 100);
  };

  /**
   * Setting hover feature state
   * @param {boolean} hover the next hover state
   * @param {any} layer the layer object
   * @param {string} id the feature id
   */
  _handleLayerHover = (hover, layer, id) => {
    layer.onHover?.(hover ? id : null);

    this._map.setFeatureState(
      {
        sourceLayer: layer['source-layer'],
        source: layer.source,
        id,
      },
      { hover: hover }
    );
  };

  /**
   * Setting active featurestate for all layers
   */
  _prevActives = {};
  _handleActive = () => {
    const { active = {} } = this.props;
    Object.keys(this._layers).map((layerId) => {
      const layer = this._layers[layerId];
      if (this._prevActives[layerId])
        this._map.setFeatureState(
          {
            sourceLayer: layer['source-layer'],
            source: layer.source,
            id: this._prevActives[layerId],
          },
          { active: false }
        );
      if (active[layerId])
        this._map.setFeatureState(
          {
            sourceLayer: layer['source-layer'],
            source: layer.source,
            id: active[layerId],
          },
          { active: true }
        );
    });

    this._prevActives = { ...active };
  };

  showPopup = (id, centre, feature) => {
    const layer = this.props.layers.find((item) => item.id === id);
    if (layer) {
      const Popup = layer.popup;
      this._popupVisible = true;
      this._popupLayer = id;
      this.getPopup()
        .setLngLat(centre)
        .setHTML(ReactDOMServer.renderToString(<Popup feature={feature} onClose={this._handlePopupClose} />));
      const close = document.querySelector('#popup-close-wrapper');
      close.removeEventListener('click', this._handlePopupClose);
      close.addEventListener('click', this._handlePopupClose);
    }
  };

  closePopup = () => {
    //this._handlePopupClose();
  };

  /**
   * Layer click callback
   * @param {*} layer
   */
  _handleClick =
    ({ popup: Popup, onClick, centerOnClick, id, clickable }) =>
    (e) => {
      if (e.defaultPrevented) return;
      e.preventDefault();

      // if (id !== 'mas') e.preventDefault();
      // The target feature
      const feature = e.features[0];
      const coordinates = Array.groupByTwo(feature.geometry.coordinates.slice().flat(100));

      // Getting center
      const bounds = new mapbox.LngLatBounds();
      coordinates.forEach((coords) => coords.every((coord) => !!coord) && bounds.extend(coords));
      const centre = bounds.getCenter();

      // fit to feature
      if (centerOnClick) this._map.fitBounds(bounds, { padding: 96 });

      // Calling onClick method
      if (clickable) this.props.onSelect(centre, feature, id);
      if (onClick) {
        onClick(centre, feature, id);
      }
      if (Popup) {
        this.showPopup(id, centre, feature);
      }
    };

  _handlePopupClose = () => {
    // This is very bad, but working
    this.getPopup().setLngLat({ lng: 0, lat: 0 });
    this._popupVisible = false;
    document.querySelector('#popup-close-wrapper').removeEventListener('click', this._handlePopupClose);
  };

  /**
   * Mapbox's style loaded, add sources and layers
   * PRIVATE
   */
  _handleStyleLoad = () => {
    // Sources
    const sources = this.props.sources || {};
    this.clearSources();
    sources.forEach((source) => this.addSource(source.id, source.source, source.data));

    this.parseLayers(this.props.layers || {});
    this.handleLayerVisibility();
    this._handleActive();

    this._map.on('click', (e) => {
      this.props.onPureClick?.(e);
      if (e.defaultPrevented) return;
    });

    this._map.on('zoom', this._handleZoomEnd);

    this.getPopup().addTo(this._map);
    this.getCameraPosition().addTo(this._map);
    this._loaded = true;
  };

  _handleZoomEnd = () => {
    if (!this._popupVisible) return;
    if (this.getZoom() < 15) this._handlePopupClose();
  };

  updateStyle = () => {
    this._map.setStyle(this.getStyle());
  };

  handleCameraPositionUpdate = (pos) => {
    if (!pos) return this._cameraPosition.setLngLat({ lng: 0, lat: 0 });

    const coords = pos.coords;
    const angle = pos.fov || 60;

    if (!coords) return;

    const svg = document.getElementById('location-marker-svg');
    const fov = document.getElementById('location-gradient');

    if (svg) {
      svg.style.transform = `scale(1.0) scaleX(${3 * Math.tanh((angle / Math.PI / 180) * Math.PI)}) translateY(20px)`;
      svg.innerHTML = `<polygon fill="red" points="250,0 0,500 500,500" class="triangle" />`.trim();
    }

    if (fov) fov.style.transform = `translate(-50%, -50%) rotate(${180 - pos.rotationDeg?.y || 0}deg)`;

    this._cameraPosition.setLngLat({ lng: coords[0], lat: coords[1] });
  };

  componentDidMount = () => {
    requestAnimationFrame(() => {
      if (!this._container) return console.error('[MAP] [ERROR]: missing container');
      this._start = new Date().getTime();
      this._resizeObserver.observe(this._container);

      this._map = new mapbox.Map({
        container: this._container,
        style: this.getStyle(),
        center: [this.state.lng, this.state.lat],
        zoom: this.state.zoom,
        dragRotate: false,
        boxZoom: false,
        antialias: true,
        transformRequest: (url) => {
          const token = this.props.user?.token;

          let proxyUrl;
          if (url.match(/^https:\/\/api\.mapbox\.com/)) {
            const mapboxUrl = new URL(url);
            mapboxUrl.searchParams.delete('access_token');

            proxyUrl = `/v1/proxy/mapbox?url=${mapboxUrl.toString()}`;
          } else {
            proxyUrl = url;
          }

          return {
            url: getUrl(token)(proxyUrl),
            headers: generateHeaders(token),
          };
        },
      });
      const scale = new mapbox.ScaleControl({
        maxWidth: 80,
        unit: 'metric',
      });
      this._map.addControl(scale);
      scale.onAdd(this._map);

      this._map.on('style.load', this._handleStyleLoad);
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.theme.isDark !== this.props.theme.isDark ||
      this.props.satellite !== prevProps.satellite ||
      this.props.elevated !== prevProps.elevated
    )
      this.updateStyle();
    if (JSON.stringify(prevProps.active) !== JSON.stringify(this.props.active)) {
      this._handleActive();
    }
    if (JSON.stringify(prevProps.cameraPosition) !== JSON.stringify(this.props.cameraPosition))
      this.handleCameraPositionUpdate(this.props.cameraPosition);
    if (JSON.stringify(prevProps.sourceVisible) !== JSON.stringify(this.props.sourceVisible)) this.handleSourceVisibility();
    if (JSON.stringify(prevProps.layerVisible) !== JSON.stringify(this.props.layerVisible)) this.handleLayerVisibility();
    if (prevProps.crosshair !== this.props.crosshair) {
      this._map.getCanvas().style.cursor = this.props.crosshair ? 'crosshair' : '';
    }
    this.props.layers.forEach((layer) => {
      const prevLayer = prevProps.layers.find((lyr) => lyr.id === layer.id);
      if (JSON.stringify(prevLayer?.filter) !== JSON.stringify(layer?.filter)) this.updateLayerFilter(layer);
      if (JSON.stringify(prevLayer?.highlight) !== JSON.stringify(layer?.highlight)) this.updateLayerStyle(layer);
      if (JSON.stringify(prevLayer?.color) !== JSON.stringify(layer?.color)) this.updateLayerColor(layer);
      if (prevLayer.onClick !== layer.onClick) this.updateOnClickHandlerForLayer(this._map, layer);
      if (prevLayer.onHover !== layer.onHover) this.updateOnHoverHandlerForLayer(this._map, layer);

      if (this._map?.getSource('trees') && this.props.needReload) {
        const url = getUrlForEndpoint(
          `/v1/mvt/trees/{z}/{x}/{y}?geom_column=${GEOM_COLUMNS['trees']}&columns=${COLUMNS['trees']}${
            ID_COLUMNS['trees'] ? '&id_column=' + ID_COLUMNS['trees'] : ''
          }&cacheBuster=${Date.now()}&latest=1`
        );

        this._map.getSource('trees').setTiles([url]);

        const sourceCache = this._map.style.sourceCaches['trees'];
        sourceCache.clearTiles();
        sourceCache.update(this._map.transform);

        const newStyle = this._map.getStyle();
        newStyle.sources.trees.tiles = [url];
        this._map.setStyle(newStyle);

        for (const id in sourceCache._tiles) {
          sourceCache._tiles[id].expirationTime = Date.now() - 1;
          sourceCache._reloadTile(id, 'reloading');
        }

        sourceCache._cache.reset();
        this._map.triggerRepaint();
      }
    });
  };

  _handleZoom = (direction = 1) => {
    this._map.flyTo({
      center: this.getCenter(),
      zoom: this.getZoom() + (direction / Math.abs(direction)) * 0.5,
    });
  };

  _handleZoomIn = () => this._handleZoom();
  _handleZoomOut = () => this._handleZoom(-1);

  _handleFitToBounds = () => this.props.onFocusReset?.(this.getCenter());

  render = () => (
    <div className={this.props.isMiniMapHiddenButton ? '' : 'map '}>
      <div onClick={this.onToggleMiniMap}>
        {this.props.isMiniMapHiddenButton && this.miniMapHiddeButton(this.props.isMiniMapHiddenButton)}
      </div>
    <div className={'map ' + (this.state.isMiniMapHidden ? 'map-overlay hidden ' : this.props.minimap ? 'map-overlay' : '')}>
      <div className={'map ' + (this.props.minimap ? 'map-overlay-inner' : '')}>
        <div className='map-outer-wrapper'>
          <MapControls
            fitToBounds={this._handleFitToBounds}
            zoomIn={this._handleZoomIn}
            zoomOut={this._handleZoomOut}
            onUnselect={this.props.onUnselect}
          />
          <div ref={(el) => (this._container = el)} className='map-wrapper'></div>
          <div className='powered'>
            <span>powered by</span>
            <Icon icon='gh' />
          </div>
          <div className='map-actions-wrapper'>
            <div className='action-wrapper'>
              {this.props.secondary && <Button {...this.props.secondary} />}
              {!this.props?.managedAreaId && 
              <TableFilters
                tableFiltersIsOpen={this.props.tableFiltersIsOpen}
                setTableFiltersIsOpen={this.props.setTableFiltersIsOpen}
                screenType={this.props.screenType}
              />}
            </div>
            <div className='action-wrapper map-right-action-wrapper'>
              {this.props.sliders && (
                <div className='slider-container'>
                  {this.props.sliders?.map((slider, i) => (
                    <Slider key={i} {...slider} />
                  ))}
                </div>
              )}
              {this.props.action && this.props.action}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );

  static defaultProps = {
    onMove: () => {},
  };
}

Map.defaultProps = {
  sources: [],
  sourceVisible: {},
  layers: [],
  layerVisible: {},
  onSelect: () => {},
  action: null,
  secondary: null,
  minimap: false,
  isMiniMapHiddenButton: false,
};

export default withUser(withTheme(Map));
