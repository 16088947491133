import XYCoords from '../@types/XYCoords';
import XZYCoords from '../@types/XZYCoords';

const getFirstInterSection = (from1: XYCoords, to1: XYCoords, from2: XYCoords, to2: XYCoords) => {
  const dX = to1.x - from1.x;
  const dY = to1.y - from1.y;

  const determinant = dX * (to2.y - from2.y) - (to2.x - from2.x) * dY;
  if (determinant === 0) return null; // parallel lines

  const lambda = ((to2.y - from2.y) * (to2.x - from1.x) + (from2.x - to2.x) * (to2.y - from1.y)) / determinant;
  const gamma = ((from1.y - to1.y) * (to2.x - from1.x) + dX * (to2.y - from1.y)) / determinant;

  // check if there is an intersection
  if (!(0 <= lambda && lambda <= 1) || !(0 <= gamma && gamma <= 1)) {
    return null;
  }

  return {
    x: from1.x + lambda * dX,
    y: from1.y + lambda * dY,
  };
};

const areVectorsEqual = (first: XZYCoords, second: XZYCoords, decimal = 40) => {
  return (
    Number(first.x).toFixed(decimal) === Number(second.x).toFixed(decimal) &&
    Number(first.y).toFixed(decimal) === Number(second.y).toFixed(decimal) &&
    Number(first.z).toFixed(decimal) === Number(second.z).toFixed(decimal)
  );
};

const isLastLineIntersectingPreviousLines = (twoDimensionPointsInLine: { x: number; y: number }[]) => {
  if (twoDimensionPointsInLine.length < 4) return null;

  const origin = twoDimensionPointsInLine[twoDimensionPointsInLine.length - 2];
  const direction = twoDimensionPointsInLine[twoDimensionPointsInLine.length - 1];

  const originalPoints = twoDimensionPointsInLine.slice(0, -1);
  for (let index = 0; index < originalPoints.length; index++) {
    if (index === originalPoints.length - 2) break;

    const startOfLine = originalPoints[index];
    const endOfLine = originalPoints[index + 1];

    const intersection = getFirstInterSection(origin, direction, startOfLine, endOfLine);
    if (intersection) return intersection;
  }

  return null;
};

const isPointInPolygon = (pg: { x: number; y: number }[], p: { x: number; y: number }) => {
  // If a line from point to infinity crosses polygon edges an odd number of times
  // then the point is inside the polygon
  let odd = false;
  for (let i = 0, j = pg.length - 1; i < pg.length; i++) {
    const currentIsAbove = pg[i].y > p.y;
    const previousIsAbove = pg[j].y > p.y;
    const xDiff = pg[j].x - pg[i].x;
    const yDiff = pg[j].y - pg[i].y;
    if (currentIsAbove !== previousIsAbove && p.x < (xDiff * (p.y - pg[i].y)) / yDiff + pg[i].x) {
      odd = !odd;
    }
    j = i;
  }
  return odd;
};

const cartesianToSpherical = (x: number, y: number, z: number) => {
  const radius = Math.sqrt(x * x + y * y + z * z);
  const phi = Math.acos(y / radius);
  const theta = Math.atan2(z, x);
  return { radius, phi, theta };
};

export { isLastLineIntersectingPreviousLines, isPointInPolygon, areVectorsEqual, cartesianToSpherical };
