import { IconElementParams } from '../../@types/Icon';

const Expand = ({ width = 16, height = 16, color }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 2H10M14 2L8 8M14 2V6' stroke={color ?? 'white'} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14 8.66667V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H7.33333'
        stroke={color ?? 'white'}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Expand;
