import React, {useMemo, useState} from 'react';
import { Map, Table } from '../../components';
import useUsers from '../../hooks/users';
import { useUser } from '../../providers/user';
import { Grid, LoaderWrapper } from '../../layout';
import { useData } from '../../providers/data';
import useBetaTableSorter from '../../hooks/betaHooks/beta-use-table-sorter';

const sources = [
  { id: 'trees', source: 'trees', name: 'Trees' },
  { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
  // {id: 'las', source: 'gh_singapore.tiles', name: 'Scan tiles'},
  { id: 'maven', source: 'maven_trees' },
];

const layers = [
  // {
  //   id: 'las',
  //   source: 'las',
  //   type: 'fill-outline',
  //   color: '#082',
  //   opacity: .02,
  // },
  {
    id: 'mas',
    source: 'mas',
    type: 'fill',
    color: '#082',
    opacity: 0.32,
  },
];

/*
const ROLES = [
  {
    label: 'Supervisor',
    value: 'SUPERVISOR',
  },
  {
    label: 'Officer',
    value: 'OFFICER',
  },
  {
    label: 'Guest',
    value: 'GUEST',
  },
];

const MOCK_USERS = [
  {
    id: 'A001',
    name: 'Tom wayne',
    role: 'SUPERVISOR',
  },
  {
    id: 'TZB12',
    name: 'Chad Normann',
    role: 'OFFICER',
  },
  {
    id: 'TZB13',
    name: 'Teszt Elek',
    role: 'GUEST',
  },
];*/

const generateConfig = (usersoptions, updateOfficer = () => {}) => ({
  columns: [
    // {
    //   title: 'Location name',
    //   key: 'location',
    //   resolver: 'description',
    //   sortable: true,
    // },
    {
      title: 'Area code',
      key: 'code',
      resolver: 'code',
      format: 'CODE',
      sortable: true,
    },
    {
      title: 'Deadline',
      key: 'deadline',
      format: 'DATE',
      resolver: 'next_inspection',
    },
    {
      title: 'OIC',
      resolver: ({ officer_in_charge }) => parseInt(officer_in_charge) || 'null',
      onChange: (key, user, pipeline) => updateOfficer(user, pipeline),
      format: 'SELECT',
      options: [
        { label: 'Unassigned', value: 'null' },
        ...usersoptions
      ],
    },
    {
      title: 'Status',
      key: 'status',
      resolver: (ma) => {
        if (!ma.officer_in_charge) return 'UNASSIGNED'
        if (
          ma.location_proposal === 'error' ||
          ma.semantic_extraction === 'error'
        )
          return 'ERROR';
        if (
          ma.location_proposal === 'todo' ||
          ma.semantic_extraction === 'todo' ||
          ma.location_proposal === 'started' ||
          ma.semantic_extraction === 'started' ||
          ma.processing
        )
          return 'PROCESSING';
        return ma.current_manual_step || 'completed';
      },
      format: 'STATUS',
      sortable: true,
    },
    // {
    //   title: 'Date of assignment',
    //   key: 'assignment',
    //   resolver: () => new Date(`2021. 02. ${Math.floor(Math.random() * 31)}`),
    //   format: 'DATE',
    // },
  ],
  selection: (entry) => null,
});

const OfficerInCharge = (props) => {
  const {pipelines, managedAreas, contextsLoadingState, trees, managedArea} = props
  const { users } = useUsers();
  const { user } = useUser();
  const userOptions = users.map(user => ({ label: (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : user.user_name, value: parseInt(user.id) }));

  const [toasts, setToasts] = useState([]);

  const {
    tableSort, 
    sortTable
  } = useBetaTableSorter({
    pipelines, trees, managedAreaId: managedArea?.id
  })

  const showToast = (msg, type) => {
    setToasts((toasts) => [...toasts, { msg, type }]);
    setTimeout(() => setToasts((toasts) => toasts.slice(1)), 5000);
  };

  const _handleOfficer = async (officer_id, pipeline) => {
    const managed_area = pipeline.managed_area;
    const managed_area_id = managedAreas.find(({code}) => code === managed_area).id;

    props.setOfficerInCharge({managedAreaId: managed_area_id, officerId: parseInt(officer_id), inspectionOfficer: parseInt(user.id)}).then(res => {
      if (!res.success && res.error) showToast("Couldn't save assignment, try again a bit later", 'error');
    }).catch(() => {
      showToast("Something went wrong", 'error');
    })
  }

  const tableData = useMemo(() => (props?.selection[1] ? trees : pipelines) || [], [pipelines, props?.selection, trees]);

  const isLoading = () => !pipelines?.length || !managedAreas?.length || contextsLoadingState;

  return (
    <LoaderWrapper loading={isLoading()}>
      <Grid
        infocontent={<Table tableSort={tableSort} sortTable={(sortableProperty) => sortTable({sortableProperty})} data={tableData} config={generateConfig(userOptions, _handleOfficer)} />}
      >
        <Map sources={sources} layers={layers} layerVisible={{ mas: true }} />
        {Boolean(toasts.length) && (
          <div className="toast-wrapper">
            {toasts.map((toast, index) => <div key={index} className={`toast ${toast.type} ${toasts.length -1  === index ? 'last' : ''}`}>{toast.msg}</div>)}
          </div>
        )}
      </Grid>
    </LoaderWrapper>
  );
};

export default OfficerInCharge;
