import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { Object3D, Vector3 } from 'three';
import SpriteText from 'three-spritetext';

const Label = ({ position, text, visible }: { position: Vector3; text: string; visible: boolean }) => {
  const labelRef = useRef<Object3D | null>(null);

  const textSprite = useMemo(() => {
    const label = new SpriteText(text);
    label.backgroundColor = '#202324';
    label.borderRadius = 4;
    label.borderWidth = 1;
    label.borderColor = 'white';
    label.padding = 5;
    label.fontFace = 'Inter';
    return label;
  }, [text]);

  useFrame(({ camera }) => {
    if (!labelRef.current) return;

    labelRef.current.position.copy(new Vector3(0, 0, position.z));
    labelRef.current.quaternion.copy(camera.quaternion).invert();
    labelRef.current.up.copy(camera.up);
    labelRef.current.lookAt(camera.position);
    labelRef.current.scale.set(1.1 / camera.zoom, 1.1 / camera.zoom, 0.05);
    labelRef.current.visible = visible;
  });

  return (
    <mesh>
      <object3D ref={labelRef}>{textSprite && <primitive object={textSprite} />}</object3D>
    </mesh>
  );
};

export default Label;
