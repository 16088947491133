const Checkmark = ({ width = 20, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.33334 10.4167L8.83334 12.9167L14.6667 7.08337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 18.3333C15.1025 18.3333 18.8333 14.6025 18.8333 9.99996C18.8333 5.39746 15.1025 1.66663 10.5 1.66663C5.89749 1.66663 2.16666 5.39746 2.16666 9.99996C2.16666 14.6025 5.89749 18.3333 10.5 18.3333Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Checkmark;
