import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Popup from '../Popup';

const Action = ({
  icon,
  dropdownContent,
  title,
  image,
  action = () => {},
  alert,
}: {
  icon: any;
  dropdownContent: any;
  title: any;
  image: any;
  action: any;
  alert: any;
}) => {
  return (
    <Dropdown content={dropdownContent}>
      <Popup title={title} centerAlign={undefined} noHover={undefined} noDelay={undefined} active={undefined}>
        <div className='action-wrapper regular-button-states' onClick={action}>
          {image ? <img src={image} alt='user' /> : <Icon icon={icon || 'coffee'} />}
          {alert && <span className='alert' />}
        </div>
      </Popup>
    </Dropdown>
  );
};

export default Action;
