import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useUser } from "./user";
import { getUrl, handleRequest } from "../hooks/api";
import proj4 from "proj4";
import { treeStatusData, maStatusData } from "../components/ScanStatus";
import moment from "moment";
import { sortTrees } from "../core/neighbourTrees";
import { CommentType } from "../enums/CommentType";
import { sorter } from "../utils/sorter";
import { getProjectYear } from "../core/projectSelector/projectSelector";
import useStore from "../store/useStore";
import useSyncTrunks from '../hooks/useSyncTrunks';
import toast from "react-hot-toast";

export const Context = createContext({});

// Access to context
export const useData = () => useContext(Context);
export const withData = (Component) => (props) =>
(
  <Context.Consumer>
    {(theme) => <Component {...props} theme={theme} />}
  </Context.Consumer>
);

// Constants
const SRID = 4326;

export const getCapturePoint = async (tree, token) => {
  return [];

  if (!tree) return;
  const scanYear =  getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : ('latest=1');
  const location = JSON.parse(tree?.location)?.coordinates || tree?.locationParsed?.coordinates;

  const res = await handleRequest(token)(
    `/v1/capture_point/${location[0]},${location[1]}?${queryYear}`
  );

  const data = await res.json();

  return Array.isArray(data) ? data : [];
};

export const getSingleTreeImages = async (tree, token) => {
  if (!tree) return;

  let location = proj4(
    "EPSG:4326",
    "localProjection",
    JSON.parse(tree?.location)?.coordinates?.slice(0) || []
  );

  if (tree.location_local !== undefined) {
    location = tree.location_local;
  }

  const url = `/v1/location/images?x=${location[0]}&y=${location[1]}&z=${location[2]}&key=${token}`;

  const res = await handleRequest(token)(url);
  const data = (await res.json())?.best_images;

  return Array.isArray(data) ? data : [];
};

export const Provider = (props) => {
  const [data, setData] = useState({});
  const { token, user, rtmsToken } = useUser();
  const { managedAreas = [], pipelines = [], loaded = false } = data;
  const scanYear =  getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : (props.latestPipelineOnly ? 'latest=1' : '');
  const URLS = [
    `/v1/query/managed_areas?columns=id,code,frontend_aoi_bbox`,
    `/v1/pipelines/get?${queryYear}`,
  ];
  const _handleLoad = async () => {
    try {
      const [managedAreas, pipelines] = await Promise.all(
        (
          await Promise.all(
            URLS.map((url) => handleRequest(token, rtmsToken)(url))
          )
        ).map((res) => res.json())
      );

      if (managedAreas?.statusCode === 401 || pipelines?.statusCode === 401)
        return;

      setData({
        managedAreas: Array.isArray(managedAreas) ? managedAreas : [],
        pipelines: Array.isArray(pipelines) ? pipelines : [],
        loaded: true,
      });
    } catch (error) {
      console.log("failed to load pipelines");
    }
  };

  const _handleManagedArea = (id) =>
    managedAreas?.find((area) => String(area.id) === String(id)) || null;

  const _handlePipeline = useCallback(
    (id) =>
      pipelines?.find((pipeline) => String(pipeline.id) === String(id)) || null,
    [pipelines]
  );

  const _handlePostValidation = ({ code, id }, step) => {
    const pipeline = data?.pipelines?.find((pipeline) => pipeline.id === id);

    handleRequest(token, rtmsToken)(
      `/v1/pipelines/start-post-validation?done_step=${step}&managed_area=${code}&managed_area_id=${id}&scan_interval=${pipeline?.scan_interval}`,
      { method: "POST" }
    );
    const index = data?.pipelines?.findIndex((pipeline) => pipeline.id === id);
    if (data?.pipelines?.[index]) {
      const pipelines = [...data.pipelines];
      pipelines[index].current_manual_step =
        step === "db_match" ? "completed" : `${step}_done`;
      pipelines[index].processing = true;
      setData({ ...data, pipelines });
    }
  };

  const _handleUpdateManagedArea = async (id, change) => {
    const _mas = [...managedAreas];
    const index = _mas.findIndex((ma) => ma.id === id);
    if (index === -1) return { success: false };

    return new Promise((resolve, reject) => {
      handleRequest(token)(`/v1/managed_areas/update?id=${id}`, {
        method: "PATCH",
        body: JSON.stringify(
          Object.keys(change).reduce(
            (prev, key) =>
              typeof change[key] !== "undefined"
                ? { ...prev, [key]: change[key] }
                : prev,
            {}
          )
        ),
        headers: { "Content-Type": "application/json" },
      }).then(res => res.json())
      .then((res) => {
        if (res.error) resolve({ success: false, error: { message: 'Something went wrong'} });

        _mas[index] = { ..._mas[index], ...change };
        setData({ ...data, managedAreas: _mas });
        resolve({ success: true })
      }).catch(error => {
        resolve({ success: false, error: { message: 'Something went wrong'} });
      })
    });
  };

  const _setOfficerInCharge = async (managed_area_id, officer_id, inspection_officer) => {
    const res = await _handleUpdateManagedArea(managed_area_id, {
      officer_in_charge: officer_id,
      inspection_officer: inspection_officer,
    });

    if (!res.success && res.error) {
      return { success: false, error: res.error}
    }

    const _pipelines = [...pipelines];
    const index = _pipelines.findIndex((pl) => pl.id === managed_area_id);
    if (index === -1) return { success: false };

    _pipelines[index] = {
      ..._pipelines[index],
      ...{ officer_in_charge: officer_id },
    };
    setData({ ...data, pipelines: _pipelines });

    return { success: true };
  };

  const _handleReload = () => {
    _handleLoad();
  };

  useEffect(() => {
    if (!user || !token) return;

    _handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, props.disableIntialFetch]);

  const filterPipelinesByStatus = useCallback(
    (treeStatusFilter, maStatusFilter) => {
      if (pipelines?.length) {
        if (treeStatusFilter || maStatusFilter) {
          const today = moment().startOf("day");
          return pipelines.filter((pipeline) => {
            let f = true;

            if (treeStatusFilter === "completed") {
              if (
                pipeline.location_proposal === "error" ||
                pipeline.semantic_extraction === "error"
              )
                return false;
              if (
                pipeline.location_proposal === "todo" ||
                pipeline.semantic_extraction === "todo" ||
                pipeline.location_proposal === "started" ||
                pipeline.semantic_extraction === "started" ||
                pipeline.processing
              )
                return false;
              return !pipeline.current_manual_step;
            }

            if (treeStatusFilter) {
              let propName;
              switch (treeStatusFilter) {
                case "in_progress":
                  propName = "inprogress";
                  break;
                default:
                  propName = treeStatusFilter;
              }
              f = !!pipeline[propName];
            }

            if (f && maStatusFilter) {
              let minDay;
              let maxDay;
              switch (maStatusFilter) {
                case maStatusData.overdue.value:
                  maxDay = 0;
                  break;
                case maStatusData.needed_in_2_weeks.value:
                  minDay = 0;
                  maxDay = 14;
                  break;
                case maStatusData.needed_in_1_month.value:
                  minDay = 14;
                  maxDay = 30;
                  break;
                default:
                  maxDay = 0;
              }

              const deadline = pipeline["next_inspection"]
                ? moment(pipeline["next_inspection"]).startOf("day")
                : null;
              const daysDifferenceFromToday = deadline
                ? deadline.diff(today, "days")
                : -1;

              if (
                !(
                  (typeof minDay == "number" &&
                    daysDifferenceFromToday <= maxDay &&
                    daysDifferenceFromToday > minDay) ||
                  (typeof minDay == "undefined" &&
                    daysDifferenceFromToday <= maxDay)
                )
              ) {
                f = false;
              }
            }

            return f;
          });
        }
      }
      return pipelines;
    },
    [pipelines]
  );

  return (
    <Context.Provider
      value={{
        managedAreas,
        getManagedArea: _handleManagedArea,
        pipelines,
        loaded,
        getPipeline: _handlePipeline,
        updateManagedArea: _handleUpdateManagedArea,
        setOfficerInCharge: _setOfficerInCharge,
        reload: _handleReload,
        startPostValidation: _handlePostValidation,
        filterPipelinesByStatus,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const TREE_FIELDS = {
  id: "id",
  managed_area: "manager_area",
  comment: "comment",
  managed_area_id: "managed_area_id",
  status: "status",
  location_confidence: "location_confidence",
  ensemble_confidence: "ensemble_confidence",
  semantic_confidence: "semantic_confidence",
  ml_instance_confidence: "ml_instance_confidence",
  ml_semseg_confidence: "ml_semseg_confidence",
  confidence_girth_ellipse: "confidence_girth_ellipse",
  proposal_probability: "proposal_probability",
  height: "height",
  trunk_height: "trunkHeight",
  crown_height: "crownHeight",
  girth_1_0m: "girth1",
  scientific_name: "scientific_name",
  manual_scientific_name: "manual_scientific_name",
  maven_id: "maven_id",
  canopy_ellipse_a: "canopy_ellipse_a",
  canopy_ellipse_b: "canopy_ellipse_b",
  canopy_ellipse_direction: "canopy_ellipse_direction",
  crown_excentricity: "crown_excentricity",
  crown_frontal_area: "crown_frontal_area",
  canopy_width_reduction: "canopy_width_reduction",
  canopy_height_reduction: "canopy_height_reduction",
  rooting_depth: "rooting_depth",
  t_r_ratio: "t_r_ratio",
  safety_factor: "safety_factor",
  safety_factors: "safety_factors",
  trunk_diameter: "trunk_diameter",
  manual_treelocation_changes: "manual_treelocation_changes",
  maven_tree_id: "maven_tree_id",
  oic_id: "oic_id",
  oic_first_name: "oic_first_name",
  oic_last_name: "oic_last_name",
  next_inspection: "next_inspection",
  inspection_officer: "inspection_officer",
  inspection_first_name: "inspection_first_name",
  inspection_last_name: "inspection_last_name",
  validation_date: "validation_date",
};

const emptyCb = () => { };

const handleCommentUpdate = (comments, old) => {
  if (comments?.length >= 0 && comments) {
    const lastComment = comments[comments.length - 1];

    if (old === null) old = [];
    const isUndoAction =
      lastComment === undefined ? true : comments.length < old.length;

    return {
      isCommentUpdate: true,
      lastValue: lastComment?.value ?? null,
      isUndoAction,
      commentType: lastComment?.comment_type ?? CommentType.comment,
    };
  }

  return {};
};

export const useTrees = (
  managedArea,
  withSafetyFactors = false,
  validationStep='',
  latestPipelineOnly = true,
  cb = emptyCb,
) => {
  const [pendingUpdateMicroclimateParameters, setPendingUpdateMicroclimateParameters] = useState(false);
  const [pendingUpdateTree, setPendingUpdateTree] = useState(false);

  const [trees, setTrees] = useState([]);
  const [loaded, setLoaded] = useState(null);
  const [needReload, setNeedReload] = useState(false);
  const { token, rtmsToken } = useUser();
  const [defaultTreeOrder, setDefaultTreeOrder] = useState([])
  const scanYear =  getProjectYear();
  const queryYear = !!scanYear ? `&scanYear=${scanYear}` : (latestPipelineOnly ? '?latest=1' : '');

  const { setTodoTrees, setTree } = useStore((s) => s.tree.actions)
  const syncTrunks = useSyncTrunks();

  useEffect(() => {
    const _handleLoad = async (managedArea) => {
      if (!managedArea) {
        setTrees([]);
        cb([]);
        return;
      }

      setLoaded(false);
      try {
        const res = await handleRequest(
          token,
          rtmsToken
        )(`/v1/trees?mas=${managedArea.id}&q=${new Date().getTime()}${!!validationStep.length ? `&vs=${validationStep}` : ''}${queryYear}`).then(
          (res) => res.json()
        );

        const trees = res.map((tree) => {
          const location = JSON.parse(tree.location || "{}");

          return {
            ...tree,
            location: location,
            geometry: location,
            coordinates: location.coordinates.slice(0, 2),
          };
        });

        const mavenAndCapturePointsPromises = [
          fillTreesWithMavenData(trees, handleRequest(token)),
          getCapturePoints(managedArea, token),
        ];

        const [treesWithMavenData, capturePoints] = (
          await Promise.allSettled(mavenAndCapturePointsPromises)
        ).map((promise) => promise.value);

        let sortedTrees = sortTrees(
          treesWithMavenData,
          capturePoints,
          true
        ).map((tree, index) => ({ ...tree, index }));

        if (withSafetyFactors) {
          sortedTrees = selectSafetyFactorsByWindSpeed(sortedTrees, 80.0);
        }

        setTrees(sortedTrees);
        setDefaultTreeOrder(sortedTrees)
        setLoaded(true);
        cb(sortedTrees);
      } catch (error) {
        console.log("Failed to load trees: ", error);
      }
    };

    _handleLoad(managedArea);

  }, [cb, managedArea?.id, rtmsToken, token, withSafetyFactors]);

  const updateTreeWithTrunks =  useCallback( (id, trunks) => {
    return syncTrunks(id, trunks).then((result) => {
      setTrees((trees) => {
        const index = trees.findIndex((tree) => String(tree.id) === String(id));
        if (!trees[index]) return;
        trees[index].tree_trunks = result
        return trees;
      })
    })
  }, [syncTrunks])

  const _handleTreeUpdate = useCallback(
    async (id, change = {}, preventUpdate, postPatch, optionalCallback) => {
      try {
        const _trees = [...trees];
        const index = _trees.findIndex((tree) => String(tree.id) === String(id));

        if (!_trees[index]) {
          optionalCallback?.(null);
          return;
        }

        const promises = []
        const changesToSend = Object.assign({}, change);
        delete changesToSend.safety_factor;
        const { isCommentUpdate, lastValue, isUndoAction, commentType } =
            handleCommentUpdate(changesToSend.comment, _trees[index].comment);

        if (isCommentUpdate) {
          changesToSend.comment = {
            comment: lastValue,
            comment_type: commentType
          };
          const commentPayload = {
            ...changesToSend.comment,
            isUndoAction,
          };
          promises.push(handleRequest(token, rtmsToken, true)(`/v1/add-tree-comment?id=${id}`, {
            method: "POST",
            body: JSON.stringify(commentPayload),
            headers: { "Content-Type": "application/json" },
          }));
        }

        if (changesToSend.trunks) {
          promises.push(updateTreeWithTrunks(id, changesToSend.trunks))
          delete changesToSend.trunks;
          delete change.trunks;
        }

        if (!(isCommentUpdate && Object.keys(changesToSend).length === 1)) {
          promises.push(handleRequest(token, rtmsToken, true)(`/v1/trees/update?id=${id}`, {
            method: "PATCH",
            body: JSON.stringify(
                Object.keys(changesToSend).reduce(
                    (prev, key) =>
                        typeof changesToSend[key] !== "undefined"
                            ? { ...prev, [key]: changesToSend[key] }
                            : prev,
                    {}
                )
            ),
            headers: { "Content-Type": "application/json" },
          }));
        }

        let changesToStoreForTree = Object.assign({}, change);
        delete changesToStoreForTree.location;
        if (postPatch) {
          changesToStoreForTree = {...changesToStoreForTree, ...postPatch};
        }

        _trees[index] = { ..._trees[index], ...changesToStoreForTree };
        if (!preventUpdate) setTrees(_trees);

        const result = await Promise.all(promises)

        optionalCallback?.();

        return result;
      } catch (e) {
        optionalCallback?.(e);
        throw e;
      }
    },
    [token, trees, updateTreeWithTrunks]
  );

  const updateTree = useCallback(
    async (id, change, postPatch) => {
      setPendingUpdateTree(true);

      return new Promise(async (resolve1, reject1) => {
        try {
          if (!Array.isArray(id)) {
            return await _handleTreeUpdate(id, change, undefined, postPatch, (err) => err ? reject1(err) : resolve1());
          }
          else {
            const _trees = [...trees];

            await Promise.all(id.map((tree) => {
              return new Promise((resolve2, reject2) => {
                const id = tree?.id || tree;
                const index = _trees.findIndex(
                    (tree) => String(tree.id) === String(id)
                );
                const _change = { ...change };
                delete _change.location;
                _handleTreeUpdate(id, change, true, {}, (err) => err ? reject2() : resolve2());
                _trees[index] = { ..._trees[index], ..._change };
              });
            }));

            setTrees(_trees);
            setNeedReload(true);

            resolve1();
          }
        } catch (e) {
          reject1(e);

          alert(`Unable to update/save tree: ${e?.message?.message || 'Something went wrong'}`);
        } finally {
          setPendingUpdateTree(false);
        }
      });
    },
    [_handleTreeUpdate, trees]
  );

  const modifyCurrentTree = useCallback(
    (id, change) => {
      const _trees = [...trees];
      const index = _trees.findIndex((tree) => String(tree.id) === String(id));
      const _change = { ...change };
      _trees[index] = { ..._trees[index], ..._change };
      setTrees(_trees);
    },
    [trees]
  );

  const updateMicroclimateParameters = useCallback(
    async (id, microclimateParameters) => {
      setPendingUpdateMicroclimateParameters(true);
      setPendingUpdateTree(true);

      try {
        await handleRequest(token, rtmsToken, true)(`/v1/microclimate?id=${id}`, {
          method: "PATCH",
          body: JSON.stringify(microclimateParameters),
          headers: { "Content-Type": "application/json" },
        });

        modifyCurrentTree(id, { microclimate: microclimateParameters });
      } catch (e) {
        toast.error(`Unable to update microclimate parameters: ${e.message?.message || e.message}`);
        throw e;
      } finally {
        setPendingUpdateMicroclimateParameters(false);
        setPendingUpdateTree(false);
      }
    },
    [modifyCurrentTree, token]
  );

  const updateTreeTmsCategory = useCallback(async (id, category) => {
    setPendingUpdateTree(true);

    try {
      await handleRequest(token)(`/v1/trees/update?id=${id}`, {
        method: "PATCH",
        body: JSON.stringify({ tms_category: category }),
        headers: { "Content-Type": "application/json" },
      });

      modifyCurrentTree(id, { tms_category: category });
    } catch (e) {
      throw e;
    } finally {
      setPendingUpdateTree(false);
    }
  }, [modifyCurrentTree, token]);

  const _handleTreeAdd = async (e, managedArea) => {
    const { lng, lat } = e.lngLat;
    if (!managedArea) return;

    try {
      setPendingUpdateTree(true);

      const data = await handleRequest(token)(
        `/v1/trees/${lng},${lat},${SRID}?managed_area=${managedArea?.code
        }&managed_area_id=${managedArea?.id}&q=${new Date().getTime()}`,
        { method: "POST" }
      );
      const [tree] = await data.json();

      setTrees([...trees, tree]);

      return tree;
    } catch (e) {
      console.warn("FAILED TO ADD NEW TREE: ", e);
    } finally {
      setPendingUpdateTree(false);
    }
  };

  const handleStartSemanticExtraction = async (tree_ids, maId) => {
    try {
      await handleRequest(token)(
        `/v1/start-semantic-extraction?tree_ids=${tree_ids.join(
          ","
        )}&managed_area_id=${maId}`,
        { method: "POST" }
      );
    } catch (e) {
      console.warn("FAILED TO START SEMANTIC EXTRACTION: ", e);
    }
  };

  const filterTreesByStatus = useCallback(
    (treeStatusFilter) => {
      if (trees && treeStatusFilter) {
        return trees.filter((tree) => {
          let status;
          switch (tree.tree_flow_status) {
            case "deleted":
              status = treeStatusData.deleted.value;
              break;
            case "pending":
              status = treeStatusData.pending.value;
              break;
            case "completed":
              status = treeStatusData.completed.value;
              break;
            default:
              status = treeStatusData.pending.value;
          }
          return treeStatusFilter === status;
        });
      }
      return trees;
    },
    [trees]
  );

  const decorateTrees = useCallback((updaterCallback) => {
    setTrees((trees) => updaterCallback(trees));
  }, []);

  const saveError = async (payload) => {
    const response = await handleRequest(token)(
      `/v1/trees/${payload.treeId}/error`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      }
    );
    const content = await response.json();
    const isError = Object.keys(content).length === 0;
    if (isError) {
      return Promise.reject({ msg: "Could not save error." });
    }
    return Promise.resolve(content);
  };

  const saveLAZ = async (payload) => {
    setPendingUpdateTree(true);

    try {
      const response = await handleRequest(token)(`/v1/trees/las`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      });
      const content = await response.json();
      const isError = !content || Object.keys(content).length === 0;
      if (isError) {
        return Promise.reject({ msg: "Could not save LAZ file." });
      }
      return Promise.resolve(content);
    } catch (e) {
      throw e;
    } finally {
      setPendingUpdateTree(false);
    }
  };

  const getTreeKeyPath = useCallback((key) => {
    if (['species_latin', 'land_use', 'percent_crown_missing', 'crown_dieback', 'crown_light_exposure', 'uncertain'].includes(key)) {
      return `microclimate.${key}`
    }
    return key
  }, [])

  const sortTreesByDirection = async (key, direction) => {
    let sortedTrees = []
    const path = getTreeKeyPath(key)
    if (!direction) {
      sortedTrees = defaultTreeOrder
    } else {
      sortedTrees = [...trees].sort((a, b) => {
        let aItem = Object.byPath(a, path)
        let bItem = Object.byPath(b, path)
        return sorter(aItem, bItem, direction)
      })
    }
    setTrees(sortedTrees)

/*
    setTodoTrees(sortedTrees)
*/
    setTree(sortedTrees[0])
  }

  return {
    trees: trees || [],
    loaded,
    updateTree,
    modifyCurrentTree,
    pendingUpdateMicroclimateParameters,
    pendingUpdateTree,
    updateMicroclimateParameters,
    updateTreeTmsCategory,
    getCapturePoint: (tree) => getCapturePoint(tree, token),
    onTreeAdd: _handleTreeAdd,
    filterTreesByStatus,
    decorateTrees,
    needReload,
    setNeedReload,
    handleStartSemanticExtraction,
    saveError,
    saveLAZ,
    sortTreesByDirection
  };
};

export const fillTreesWithMavenData = async (trees, handleRequest) => {
  const mavenIds = trees.map((tree) => tree.maven_id).filter((x) => !!x);

  if (mavenIds.length === 0) {
    return trees;
  }

  const mavenTrees = await fetchDataFromTable(
    "maven_trees",
    "id,tree_id,common_nm,spsc_nm",
    null,
    { column: "id", values: mavenIds },
    handleRequest
  );

  const mavenTreesHashTable = {};
  mavenTrees.forEach((tree) => (mavenTreesHashTable[tree.id] = tree));

  const joinedData = trees.map((tree) => {
    const matchedMavenTree = tree.maven_id
      ? mavenTreesHashTable[tree.maven_id]
      : {
        tree_id: null,
        common_nm: null,
        spsc_nm: null,
      };

    return {
      ...matchedMavenTree,
      ...tree,
    };
  });
  return joinedData;
};

export const fetchDataFromTable = async (
  table,
  column,
  eqFilter = { column: "", value: "" },
  inFilter = { column: "", values: [] },
  handleRequest
) => {
  const url = `/v1/query/${table}?columns=${column}${eqFilter?.column
    ? `&eq_column=${eqFilter.column}&eq_value=${eqFilter.value}`
    : ""
    }${inFilter?.column
      ? `&in_column=${inFilter.column}&in_values=${inFilter.values.join(",")}`
      : ""
    }&q=${new Date().getTime()}`;

  let data = [];
  try {
    const res = await handleRequest(url);

    if (res.status !== 200) {
      throw new Error();
    }

    data = await res.json();
  } catch (e) { }

  return data;
};

const MAVEN_FIELDS = [
  "id",
  "loc_cd",
  "common_nm",
  "spsc_nm",
  "grth_size",
  "height",
  "sent_to_field",
  "tree_id",
  "status",
];

export const useMavenTrees = (managedArea, cb = () => { }) => {
  const [trees, setTrees] = useState([]);
  const [loaded, setLoaded] = useState(null);
  const { token, rtmsToken } = useUser();

  const _handleLoad = async (managedArea) => {
    if (!managedArea) return;
    setLoaded(false);
    try {
      const res = await handleRequest(
        token,
        rtmsToken
      )(
        `/v1/query/maven_trees?columns=${MAVEN_FIELDS.join(
          ","
        )}&eq_column=loc_cd&eq_value=${managedArea}&transform_columns=geom&q=${new Date().getTime()}`
      ).then((res) => res.json());
      const trees = res.map((tree) => ({
        ...tree,
        location: JSON.parse(tree.geom || "{}"),
        geometry: JSON.parse(tree.geom || "{}"),
        girth_1_0m: tree.grth_size,
      }));
      setTrees(trees);
      setLoaded(true);
      cb(trees);
    } catch (error) {
      console.warn("FAILED TO SET MAVENS: ", error);
    }
  };

  const _handleMavenTree = async (id, change) => {
    const _trees = [...trees];
    const index = _trees.findIndex((tree) => String(tree.id) === String(id));
    if (!_trees[index]) return;

    const { isCommentUpdate, lastValue, isUndoAction } = handleCommentUpdate(
      change.comment,
      _trees[index].comment
    );

    if (isCommentUpdate) {
      change.comment = lastValue;

      handleRequest(token)(`/v1/add-tree-comment?id=${id}`, {
        method: "POST",
        body: JSON.stringify({ comment: change.comment, isUndoAction }),
        headers: { "Content-Type": "application/json" },
      });
    }

    handleRequest(token)(
      `/v1/maven_field?id=${id}&comment=${lastValue}&q=${new Date().getTime()}`,
      { method: "PATCH" }
    );

    _trees[index] = { ..._trees[index], ...change };
    setTrees(_trees);
  };

  useEffect(() => {
    _handleLoad(managedArea?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedArea?.code]);

  const _handleMavenTreeStatusUpdate = (id, change, preventUpdate) => {
    const _trees = [...trees];
    const index = _trees.findIndex((tree) => String(tree.id) === String(id));
    if (!_trees[index]) return;

    try {
      handleRequest(token)(`/v1/maven_trees/update_status?id=${id}`, {
        method: "PATCH",
        body: JSON.stringify(change),
        headers: { "Content-Type": "application/json" },
      });
    } catch (e) {
      console.log("Failed to save changes");
    }

    _trees[index] = { ..._trees[index], ...change };
    if (!preventUpdate) setTrees(_trees);
  };

  return {
    trees: trees || [],
    loaded,
    updateTree: _handleMavenTree,
    updateStatus: _handleMavenTreeStatusUpdate,
  };
};

export const useMavenTree = (treeId) => {
  const [mavenTree, setMavenTree] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const { token, rtmsToken } = useUser();

  useEffect(() => {
    const _handleLoad = async (treeId) => {
      setLoaded(false);
      try {
        const res = await handleRequest(
          token,
          rtmsToken
        )(
          `/v1/query/maven_trees?columns=${MAVEN_FIELDS.join(
            ","
          )}&eq_column=tree_id&eq_value=${treeId}&transform_columns=geom&q=${new Date().getTime()}`
        ).then((res) => res.json());

        const tree = res[0];
        setMavenTree({
          ...tree,
          status: "maven",
          location: JSON.parse(tree.geom || "{}"),
          geometry: JSON.parse(tree.geom || "{}"),
          girth_1_0m: tree.grth_size,
        });
        setLoaded(true);
      } catch (error) {
        console.warn("FAILED TO SET MAVENS: ", error);
      }
    };

    treeId && _handleLoad(treeId);
  }, [token, treeId]);

  return { tree: mavenTree, loaded };
};

export const useTree = (treeid) => {
  const { token, rtmsToken } = useUser();
  const [tree, setTree] = useState(null);

  const _handleTreeLoad = async () => {
    const res = await handleRequest(
      token,
      rtmsToken
    )(
      `/v1/query/trees?columns=${Object.keys(
        TREE_FIELDS
      ).join()}&eq_column=id&eq_value=${treeid}&transform_columns=location&geojson_columns=crown_excentricity&q=${new Date().getTime()}`
    ).then((res) => res.json());
    const [tree] = res.map((tree) => ({
      ...tree,
      location: JSON.parse(tree.location || "{}"),
      geometry: JSON.parse(tree.location || "{}"),
    }));
    setTree(tree);
  };

  useEffect(() => {
    if (treeid) _handleTreeLoad();
  }, [treeid]);

  return { tree };
};

export const getCapturePoints = async (managedArea, token) => {
  let capturePoints = [];
  const scanYear =  getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : ('latest=1');
  try {
    // const res = await handleRequest(token)(`/v1/capture_points/${managedArea?.id || managedArea}?q=${new Date().getTime()}&${queryYear}`);
    const res = await handleRequest(token)(`/v1/capture_points_v2_by_managed_area/${managedArea?.code}?q=${new Date().getTime()}&${queryYear}`);

    const capturePointsRaw = await res.json();

    capturePoints = capturePointsRaw
      .sort((a, b) => a?.data?.[0]?.timestamp - b?.data?.[0]?.timestamp)
      .map((point, i) => {
        return {
          coordinates: point.position_4326?.coordinates?.slice(0, 2),
          id: i,
          location: point.position_4326?.coordinates,
          origin: point.position?.coordinates?.slice(0, 2),
          snapshot_id: point.snapshot_id,
          timestamp:  point?.data?.[0]?.timestamp
        };
      });
  } catch (e) { }

  return capturePoints;
};

export const getVoxel = async (tree, token) => {
  if (!tree) {
    return null;
  }

  const url = getUrl(token)(
    // `/v1/proxy/blob?url=tasks/${tree.managed_area}/voxels/${tree.id}.json`
      `/v1/proxy_v2/tree-data?url=${tree.voxel}`
  );

  try {
    const res = await handleRequest(token, '', false, false)(url);

    if (res.status !== 200) {
      throw new Error();
    } else {
      return await res.json();
    }
  } catch (e) {
    return null;
  }
};

export const fetchFeatureCollection = async (name) => {
  try {
    const res = await fetch(`/geojson/${name}`);

    if (res.status !== 200) {
      throw new Error();
    } else {
      return await res.json();
    }
  } catch (e) {
    return null;
  }
};

export const selectSafetyFactorsByWindSpeed = (trees, windSpeed) => {
  return trees.map((tree) => ({
    ...tree,
    safety_factor: getSafetyFactorByWindSpeed(tree, windSpeed),
  }));
};

const getSafetyFactorByWindSpeed = (tree, windSpeed) => {
  if (tree.safety_factors) {
    const item = tree.safety_factors?.find(
      (item) => item.windSpeed == windSpeed
    );
    return item?.safetyFactor;
  }
  return null;
};

export const useGlobalConfig = () => {
  const [globalConfig, setGlobalConfig] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const { token, rtmsToken } = useUser();

  useEffect(() => {
    const _handleLoad = async () => {
      setLoaded(false);

      try {
        const res = await handleRequest(token)(
          `/v1/config?q=${new Date().getTime()}`
        ).then((res) => res.json());

        setGlobalConfig(res);
        setLoaded(true);
      } catch (error) {
        console.warn("FAILED TO LOAD GLOBAL CONFIG: ", error);
      }
    };

    token && _handleLoad();
  }, [token]);

  const updateGlobalConfig = (newConfig) => {
    const _handleUpdate = async () => {
      setLoaded(false);

      try {
        const res = await handleRequest(token)(
          `/v1/config?q=${new Date().getTime()}`,
          {
            method: "PATCH",
            body: JSON.stringify(newConfig),
            headers: { "Content-Type": "application/json" },
          }
        );

        if (res.status !== 200) {
          throw new Error();
        }
      } catch (error) {
        console.warn("FAILED TO SAVE GLOBAL CONFIG: ", error);
      }

      setLoaded(true);
      setGlobalConfig(newConfig);
    };

    _handleUpdate();
  };

  const getGlobalConfig = useCallback(
    (name) =>
      globalConfig?.find?.((config) => config.config_name === name)?.value,
    [globalConfig]
  );

  return { globalConfig, loaded, updateGlobalConfig, getGlobalConfig };
};
