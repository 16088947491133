import React, { cloneElement, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid } from '.';
import {
  Controls, Map,
  MapControls, Panorama, Table
} from '../components';
import { Button } from '../components/inputs';
import Proximity from '../components/modals/Proximity';
import TreePopup from '../components/TreePopup';
import useApi from '../hooks/api';
import { usePanoramaChangeOnMap } from '../hooks/panoramicViewToMap';
import { useConfig } from '../providers/config';
import { useMavenTree } from '../providers/data';
import { useModal } from '../providers/modal';
import { useTheme } from '../providers/theme';
import {useBetaTree} from "../hooks/betaHooks/beta-use-tree";

const generateTableConfig = (
  selection,
  search = '',
  selectedTree,
  maFilter = () => true,
  treeFilterForTable = () => true,
  tableFilter,
  customColumns,
  isProximityDataDone,
  proximityAlertTreeIDs
) => ({
  defaultSortColumn: isProximityDataDone && proximityAlertTreeIDs.length > 0 ? { columnKey: 'proximity', direction: -1 } : null,
  filter:
    tableFilter ||
    ((data) =>
    {
      return selection
      ? data?.status &&
          treeFilterForTable(data)
      : maFilter(data) && data?.managed_area?.toLowerCase().includes(search?.toLowerCase())
    }),

  columns:
    customColumns?.(selection, isProximityDataDone, proximityAlertTreeIDs) ??
    (selection
      ? [
          {
            title: 'RTMS ID',
            key: 'id',
            resolver: 'id',
            sortable: true,
          },
          {
            title: 'Managed area',
            key: 'MA',
            resolver: 'managed_area',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Confidence level',
            key: 'confidence',
            resolver: ['location_confidence', 'ml_instance_confidence', 'ml_semseg_confidence', 'confidence_girth_ellipse'],
            format: 'PERCENTAGE',
            sortable: true,
          },
          {
            title: 'Status',
            resolver: 'status',
            key: 'status',
            format: 'STATUS',
            sortable: true,
          },
          {
            title: 'Comment',
            key: 'comment',
            resolver: ({ comment }) => (comment ? comment.map(({ value }) => value).join(', ') : '-'),
            sortable: true,
          },
        ]
      : [
        /*Removed in PRD-370*/
        /*{
          title: 'Location name',
          key: 'location',
          resolver: 'description',
          sortable: true,
        },*/
          {
            title: 'Area code',
            key: 'code',
            resolver: 'code',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Status',
            key: 'status',
            // resolver: (ma) => {​​​​​​​
            //   if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error') return 'ERROR';
            //   if (ma.location_proposal === 'todo' || ma.semantic_extraction === 'todo') return 'PROCESSING';
            //   if (ma.location_proposal === 'started' || ma.semantic_extraction === 'started') return 'PROCESSING';
            //   else return ma.current_manual_step;
            // },​​​​​​​
            resolver: (ma) => {
              if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error' || ma.segmentation === 'error') return 'ERROR';
              if (
                ma.location_proposal === 'todo' ||
                ma.semantic_extraction === 'todo' ||
                ma.segmentation === 'todo' ||
                ma.location_proposal === 'started' ||
                ma.semantic_extraction === 'started' ||
                ma.segmentation === 'started' ||
                ma.processing
              )
                return 'PROCESSING';
              return ma.current_manual_step || 'completed';
            },
            format: 'STATUS',
            sortable: true,
          },
          /*Removed in PRD-370*/
          /*{
            title: 'TI month',
            key: 'ti_month',
            resolver: 'next_inspection',
            format: 'MONTH',
            sortable: true,
          },*/
          {
            title: 'OIC',
            key: 'oic',
            resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
            sortable: true,
          },
          /*Removed in PRD-370*/
          /*{
            title: 'Scheduled',
            key: 'scheduled',
            resolver: (ma) => ma.scheduled || '-',
            sortable: true,
          },*/
        {
          title: 'Tree number',
          key: 'tree_number',
          resolver: 'tree_number',
          sortable: true,
        },
        {
          title: 'Pending',
          key: 'pending',
          resolver: 'pending',
          sortable: true,
        },
        {
          title: 'Validated',
          key: 'validated',
          resolver: 'validated',
          sortable: true,
        },
        {
          title: 'Accepted',
          key: 'accepted',
          resolver: 'accepted',
          sortable: true,
        },
        {
          title: 'Sent to Field',
          key: 'sent_to_field',
          resolver: 'sent_to_field',
          sortable: true,
        },
        {
          title: 'Deleted',
          key: 'deleted',
          resolver: 'deleted',
          sortable: true,
        },
        {
          title: 'Manually corrected',
          key: 'manually_corrected',
          resolver: 'manually_corrected',
          sortable: true,
        },
          /*Removed in PRD-370*/
          /*{
            title: '%',
            key: 'progress',
            format: 'PERCENTAGE',
            resolver: (ma) => ma.percentage || '-',
            sortable: true,
          },
          {
            title: 'SLA',
            key: 'sla',
            resolver: 'last_inspection',
            format: 'DATE',
            sortable: true,
          },*/
        ]),
  selection: (entry) => {
    return selectedTree && entry && parseInt(selectedTree.id) === parseInt(entry.id);
  },
});

const MASelectorMap = ({
  location,
  match,
  history,
  basePath,
  mapRef,
  selection,
  treeStatusFilter,
  managedAreaFilters,
  customColumns,
  maStatusFilter,
  resetFocus,
  focusOnMA,
  deletedTrees,
  setCurrentTreeId,
  pipelineFilter,
  layerFilter = [],
  layerSources,
  sliders,
  onShouldReload,
  startAction,
  treePopup,
  controls,
  tableHeader,
  treeFilterForTable,
  tableFilter,
  needReload,
  setNeedReload,
  setSortByProximityFirst,
  proximityAlertTreeIDs,
  setProximityAlertTreeIDs,
  updateTree,
  fullHeight,
  screenType,
  trees,
  currentManagedArea,
  pipeline,
  pipelines,
  contextsLoadingState,
  onTreesReloadRequest,
  isMicroClimate,
  managedArea,
  sortTable,
  tableSort,
  todoTrees
}) => {
  const [maSearch, setMASearch] = useState('');
  const [searchTreeId, setSearchTreeId] = useState(null);
  const [maFilter, setMaFilter] = useState(null);
  const [selectedTree, setSelectedTree] = useState(null);
  const [tableFiltersIsOpen, setTableFiltersIsOpen] = useState(false);
  const { isDark } = useTheme();
  const { handleRequest } = useApi();

  useEffect(() => {
    if (needReload) setNeedReload(false);
  }, [needReload, setNeedReload]);

  const _updateSelectedTree = useCallback((tree) => {
    setSelectedTree(tree);
    setCurrentTreeId?.(tree?.id);
  }, [setCurrentTreeId])

  const _handleTreeSelectForMap = (centerCoord, tree, layerId) => {
    if (!tree) return;
    // Coordinates are needed because the tree object from map doesn't contain it
    tree.location = { coordinates: [centerCoord.lng, centerCoord.lat] };
    _updateSelectedTree(tree);
  };

  const _handleTreeSelectForPanorama = (tree) => {
    if (!tree) return;
    _updateSelectedTree(tree);
  };

  const _handleMASelect = (centerCoord, layer, layerId) => {
    history.push(`/${basePath}/${layer.id}`);
    _updateSelectedTree(null);
  };

  useEffect(() => {
    if (!match.params.MA && maSearch) setMASearch('');
  }, [match.params.MA]);

  const { presentModal, dismissModal } = useModal();
  const [isProximityCheckAvailable, setIsProximityCheckAvailable] = useState(false);
  const [isProximityDataDone, setIsProximityDataDone] = useState(false);

  const onCheckProximity = useCallback(async (distance) => {
    const res = await handleRequest(`/v1/trees-proximity?ma=${pipeline.id}&distance=${distance}`);
    const data = await res.json();

    dismissModal();
    setProximityAlertTreeIDs(data);
    setIsProximityDataDone(true);
    setSortByProximityFirst(true);
  }, [dismissModal, handleRequest, pipeline?.id, setProximityAlertTreeIDs, setSortByProximityFirst]);

  const proximityHandler = useCallback(() => {
    presentModal({
      title: 'Search for trees that have another tree in the given distance',
      content: <Proximity onCancel={dismissModal} getProximity={onCheckProximity}/>
    })
  }, [dismissModal, onCheckProximity, presentModal]);

  useEffect(() => {
    setIsProximityCheckAvailable(pipeline?.current_manual_step === 'location_validation');
    setIsProximityDataDone(false);
    setProximityAlertTreeIDs([]);
    setSortByProximityFirst(false);
  }, [pipeline?.current_manual_step, currentManagedArea?.code, setSortByProximityFirst, setProximityAlertTreeIDs]);

  const { getConfig } = useConfig();
  const [isSatellite, setIsSatellite] = useState(false);
  const [sourceVisible, setSourceVisible] = useState({
    mas: true,
    las: false,
    trees: true,
    maven: true,
    safety_source: true,
    hyperleaf: true,
  });

  const maFilterForLayers = (pipelineFilter || treeStatusFilter || maStatusFilter) ? [
    'case',
    ['in',
      ['get', 'code'],
      ['literal', (pipelineFilter) ?
        pipelines?.filter(pipelineFilter)?.map((pipeline) => pipeline?.managed_area) :
        pipelines?.map((pipeline) => pipeline?.managed_area)
      ]
    ],
    true,
    false,
  ] : true;

  const maLayer =
    {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      onClick: _handleMASelect,
      opacity: 0.80,
      below: layerFilter[0]?.id,
      filter: maFilterForLayers,
      highlight: maFilter
    };

  const popup =
    treePopup ||
    (({ feature, ...props }) => {
      return <TreePopup tree={feature} {...props} />;
    });

  const generateFilter = (status, filter = true, statuses) => {

    const rtmsTreeFilter = statuses
      ? ['in', ['get', 'tree_flow_status'], ['literal', statuses]]
      : ['==', ['get', 'tree_flow_status'], status];

    return [
      'case',
      [
        'in',
        ['get', 'id'],
        ['literal', deletedTrees.map((tree) => parseInt(tree))],
      ],
      false,
      status === 'maven' || rtmsTreeFilter,
      filter,
      false,
    ];
  };

  const filterLayers = layerFilter.map((layer) => {
    // custom filter modifications
    if (layer.source === 'safety_source' || layer.source === 'hyperleaf') {
      return {
      ...layer
      }
    }

    const config = getConfig(`statuses.${layer.id}`);
    const filter = generateFilter(layer.id, layer.filter, layer.statuses);
    return {
      ...layer,
      onClick: _handleTreeSelectForMap,
      popup: popup,
      filter,
      label: config?.label || 'Unnamed',
      color: getConfig(`colors.${config?.color}`) || '#08f',
    };
  });

  const layers = [...filterLayers, maLayer];
  const [layerVisible, setLayerVisible] = useState(
    layers.reduce((prev, curr) => ({ ...prev, [curr['id']]: true }), {})
  );

  const _handleStart = () => {
    if (!startAction.step) return

    let treeId = null;

    const findTreeId = startAction.treeId ?? selectedTree?.id;


    if (todoTrees?.length) {
      treeId = todoTrees.find(t => findTreeId && t?.id === findTreeId)?.id ?? todoTrees[0]?.id ?? trees?.[0]?.id;
    } else {
      treeId = trees?.[0]?.id;
    }

    history.push(
      `${location.pathname}/${startAction.step}/${treeId}`.replace('//', '/')
    );
  };

  const _handleUnselect = () => history.push(`/${basePath}`);

  // Panorama - for any map position
  const [isPanoramaSelected, setPanoramaSelected] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const isPanoramaVisible =
    isPanoramaSelected && (selectedTree || selectedPoint);

  const _handleOnMapClick = useCallback((e) => {
    if (isPanoramaSelected && !selectedTree && !selectedPoint) {
      setSelectedPoint([e.lngLat.lng, e.lngLat.lat]);
    }
  }, [isPanoramaSelected, selectedPoint, selectedTree]);

  useEffect(() => {
    if (!isPanoramaSelected) {
      setSelectedPoint(null);
      _updateSelectedTree(null);
    }
  }, [_updateSelectedTree, isPanoramaSelected]);

  useEffect(() => {
    if (selectedTree) {
      setSelectedPoint(null);
    }
  }, [selectedTree])

  const mapControls = [
    {
      id: 'map-controller',
      label: 'Basic',
      component: (
        <MapControls
          layers={filterLayers.filter(layer => !(layer.noFilter === true))}
          layersVisible={layerVisible}
          onLayerVisibleChange={setLayerVisible}
          showLayerFilters
          sources={layerSources}
          sourcesVisible={sourceVisible}
          onSourceVisibleChange={setSourceVisible}
          showSourceFilters
          showViewOptions
          isSatellite={isSatellite}
          setIsSatellite={setIsSatellite}
          isPanoramaVisible={isPanoramaVisible}
          isPanoramaSelected={isPanoramaSelected}
          setPanoramaSelected={setPanoramaSelected}
          maSearch={maSearch}
          setMASearch={setMASearch}
          MAFilter={pipelineFilter}
        />
      ),
    },
  ];

  useEffect(() => {
    focusOnMA?.();
  }, []);

  useEffect(() => {
    setCurrentTreeId(null);
    const search = window.location.search;
    const searchParams = new URLSearchParams(search.slice(1, search.length));
    const treeId = searchParams.get('tree');
    if (!trees?.length || !treeId) return;
    const tree = trees?.find((tree) => tree.id === treeId);

    _updateSelectedTree(tree);
  }, [trees?.length]);

  const ma = pipelines.find(
    (pipeline) => pipeline.managed_area.toLowerCase() === maSearch.toLowerCase()
  );

  const { tree: searchedMavenTree } = useMavenTree(searchTreeId);
  useEffect(() => {
      if (!ma && maSearch.length === 11) setSearchTreeId('' + maSearch);
  }, [maSearch]);

/*   useEffect(() => {

    if (searchedMavenTree) {

      const treee = searchedMavenTree;
      const centre = {
        lat: treee.location.coordinates[1],
        lng: treee.location.coordinates[0],
      };
      const feature = { id: treee.id, properties: treee };

      mapRef.current?.showPopup(treee.status, centre, feature);
      mapRef.current?.focusOnPoint(treee.location?.coordinates || []);
    }
  }, [searchedMavenTree]); */

  useEffect(() => {
    if (ma) history.push(`/${basePath}/${ma.id}`);
  }, [ma, history]);

  useEffect(() => {
    onShouldReload && onShouldReload();
  }, [isSatellite, isDark]);

  const { cameraPosition, handlePanoramicViewChange } = usePanoramaChangeOnMap(
    mapRef
  );

  const forwardedTableConfig = useMemo(() => {
    return generateTableConfig(
      selection[1],
      maSearch,
      selectedTree,
      pipelineFilter,
      treeFilterForTable,
      tableFilter,
      customColumns,
      isProximityDataDone,
      proximityAlertTreeIDs
    );
  }, [
    maSearch,
    pipelineFilter,
    selectedTree,
    selection,
    customColumns,
    tableFilter,
    treeFilterForTable,
    isProximityDataDone,
    proximityAlertTreeIDs,
  ]);

  const onClickForTable = useMemo(() => {
    const _handleEntryClick = (entry, index) => {
      !selection[1] && history.push(`/${basePath}/${entry.id}`);
    }

    const _handleTreeRowClick = (tree, index) => {
      _updateSelectedTree(tree);

      // For Store based operations
      /*const foundIndex = todoTrees.findIndex((t) => t.id === tree.id)
      if (foundIndex >= 0) {
        setTreeIndex(foundIndex);
        setTree(todoTrees[foundIndex % todoTrees.length]);
      }*/

      const centre = {
        lat: JSON.parse(tree?.location)?.coordinates?.[1],
        lng: JSON.parse(tree?.location)?.coordinates?.[0],
      };
      const feature = { id: tree.id, properties: tree };
      mapRef.current?.showPopup(tree.tree_flow_status, centre, feature);
      mapRef.current?.focusOnPoint(JSON.parse(tree?.location)?.coordinates || []);
    };

    return selection[1] ? _handleTreeRowClick : _handleEntryClick;
  }, [_updateSelectedTree, basePath, history, mapRef, selection]);

  const tableData = useMemo(() => (selection[1] ? trees : pipelines) || [], [pipelines, selection, trees]);

  const isNewTableVersion = useMemo(() => {
    return location.pathname.split('/').includes('microclimate-input') || pipeline?.current_manual_step === 'semantic_validation' || pipeline?.current_manual_step === 'segmentation_validation'
  }, [location.pathname, pipeline])


  const betaTreeHook = useBetaTree({
    ignoreAutoPull: true
  })

  return (
    <Grid
      fullHeight={fullHeight}
      defaultLayout={{ x: 0.0, y: 0.0 }}
      tabcontent={<Controls tabs={[...(controls || []), ...(mapControls || [])].map(child => {
        if (React.isValidElement(child.component)) return {
          ...child,
          component: cloneElement(child.component, { map: mapRef, mapRef, setMaFilter }),
        }
        return child;
      })} />}
      infocontent={
        <Fragment>
          {tableHeader}
          <Table
            key="MAP"
            data={tableData}
            config={forwardedTableConfig}
            onClick={onClickForTable}
            preventAutoFocus
            updateTree={async (id, data) => {
              await betaTreeHook.updateTreeWithId(id, data)
              onTreesReloadRequest?.();
            }}
            isNewTableVersion={isNewTableVersion}
            contextsLoadingState={contextsLoadingState}
            sortTable={sortTable}
            tableSort={tableSort}
            pipeline={pipeline}
          />
        </Fragment>
      }
    >
      <div className="validation-overview-wrapper">
        {isPanoramaVisible && (
          <div className="panorama-map-container">
            <Panorama
              position={JSON.parse(selectedTree?.location)?.coordinates?.slice(0, 2) || selectedPoint}
              background={isDark ? 0x000000 : 0xf8f8f8}
              onTreeClick={_handleTreeSelectForPanorama}
              getConfig={getConfig}
              onViewChange={handlePanoramicViewChange}
              selTree={selectedTree}
              selectedMA={currentManagedArea}
            />
          </div>
        )}
        <Map
          needReload={needReload}
          minimap={isPanoramaVisible}
          key="validationMap"
          _ref={mapRef}
          sources={layerSources}
          sourceVisible={sourceVisible}
          layers={layers}
          onUnselect={selection[1] && _handleUnselect}
          onPureClick={_handleOnMapClick}
          layerVisible={{
            ...layerVisible,
            mas: layerVisible.mas && sourceVisible.mas,
            maven: layerVisible.maven && sourceVisible.trees,
            sent_to_field: layerVisible.sent_to_field && sourceVisible.trees,
            validation_todo: layerVisible.validation_todo && sourceVisible.trees,
            validation_done: layerVisible.validation_done && sourceVisible.trees,
            location_validation_deleted: layerVisible.validation_deleted && sourceVisible.trees,
            /* location_validation_todo:
              layerVisible.location_validation_todo && sourceVisible.trees,
            location_validation_done:
              layerVisible.location_validation_done && sourceVisible.trees,
            semantic_validation_todo:
              layerVisible.semantic_validation_todo && sourceVisible.trees,
            semantic_validation_done:
              layerVisible.semantic_validation_done && sourceVisible.trees,
            db_match_todo: layerVisible.db_match_todo && sourceVisible.trees,
            completed: layerVisible.completed && sourceVisible.trees,*/
            safety_labels: layerVisible.safety_labels && sourceVisible.safety_source && sourceVisible.trees,
            below: layerVisible.below && sourceVisible.trees,
            above: layerVisible.above && sourceVisible.trees,
          }}
          cameraPosition={isPanoramaVisible && cameraPosition}
          active={{
            mas: currentManagedArea,
            ...filterLayers.reduce(
              (prev, { id }) => ({ ...prev, [id]: selectedTree?.id }),
              {}
            ),
          }}
          satellite={isSatellite}
          onFocusReset={resetFocus}
          sliders={sliders}
          managedAreaFilters={managedAreaFilters}
          action={<>
          {isProximityCheckAvailable && todoTrees?.length ? <Button label="Proximity check" primary={true} onClick={proximityHandler} /> : null}
              {startAction?.isAvailable && todoTrees?.length ?
                <Button label="Start" primary={true} onClick={_handleStart}/> : null}
            </>

          }
          onShouldReload={onShouldReload}
          crosshair={isPanoramaSelected && !isPanoramaVisible}
          tableFiltersIsOpen={tableFiltersIsOpen}
          setTableFiltersIsOpen={setTableFiltersIsOpen}
          screenType={screenType}
          managedAreaId={managedArea?.id}
        />
      </div>
    </Grid>
  );
};

export default memo(withRouter(MASelectorMap));
