import ValidationAction from '../../enums/ValidationAction';

const map = {
  [ValidationAction.Height]: '#DB200B',
  [ValidationAction.CanopyHeight]: '#FF24BD',
  [ValidationAction.FirstBifurcationPoint]: '#00AEEF',
  [ValidationAction.LeaningVector]: '#00FF80',
  [ValidationAction.Girth]: '#00D1CF',
  [ValidationAction.Canopy]: '#FFCC00',
};

export default map;
