import React from 'react';

const PADDING = 12;

const Wrapper = ({ position: { x = 0, y = 0 }, onClose = () => {}, content }) => {
  const wrapperPosition = Math.max(160 + PADDING, Math.min(x, window.innerWidth - 160 - PADDING));
  const arrowPosition = x - wrapperPosition;
  return (
    <div className="dropdown-backdrop">
      <div className="backdrop" onClick={onClose} />
      <div className="dropdown-wrapper" style={{ left: wrapperPosition, top: y }}>
        <div className="arrow" style={{ left: arrowPosition }} />
        {content}
      </div>
    </div>
  )
}

export default Wrapper;
