import { Vector3 } from 'three';

const MAX_TRUNKS = 6;
const MAX_GIRTH_PER_TRUNK = 2;

const DEFAULT_ELLIPSE = {
  dX: 0,
  dY: 0,
  rX: 0.1,
  rY: 0.1,
  rotation: 0,
  diameter: null,
  normal: new Vector3(0, 0, 1),
  height: 1,
};

const METRICS_NAMES = {
  TREE_HEIGHT: 'tree_height',
  NUMBER_OF_STEMS: 'number_of_stems',
  GIRTH: 'girth',
  DBH: 'dbh',
  LIMB_DIAMETER: 'limb_diameter',
  CROWN_WIDTH: 'crown_width',
  CROWN_HEIGHT: 'crown_height',
  CROWN_CIRCUMFERENCE: 'crown_circumference',
  FIRST_BIFURCATION_HEIGHT: 'first_bifurcation_height',
  LEANING_ANGLE: 'leaning_angle',
} as const;

const METRICS_VALUE_LIMITS = {
  [METRICS_NAMES.TREE_HEIGHT]: {
    min: 0,
    max: 115,
    unit: "meter"
  },
  [METRICS_NAMES.NUMBER_OF_STEMS]: {
    min: 1,
    max: 15,
    unit: "quantity"
  },
  [METRICS_NAMES.GIRTH]: {
    min: 0,
    max: 31,
    unit: "meter"
  },
  [METRICS_NAMES.DBH]: {
    min: 0,
    max: 10,
    unit: "meter"
  },
  [METRICS_NAMES.LIMB_DIAMETER]: {
    min: 0,
    max: 10,
    unit: "meter"
  },
  [METRICS_NAMES.CROWN_WIDTH]: {
    min: 0,
    max: 50,
    unit: "meter",
  },
  [METRICS_NAMES.CROWN_HEIGHT]: {
    min: 0,
    max: 115,
    unit: "meter"
  },
  [METRICS_NAMES.CROWN_CIRCUMFERENCE]: {
    min: 0,
    max: 160,
    unit: "meter",
  }, 
  [METRICS_NAMES.FIRST_BIFURCATION_HEIGHT]: {
    min: 0,
    max: 115,
    unit: "meter",
  },
  [METRICS_NAMES.LEANING_ANGLE]: {
    min: 0,
    max: 90,
    unit: "degree",
  },
};

export { DEFAULT_ELLIPSE, MAX_GIRTH_PER_TRUNK, MAX_TRUNKS, METRICS_NAMES, METRICS_VALUE_LIMITS };
