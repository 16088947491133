import React from "react";
import Icon from "../Icon";

const ClickableBadge = ({
  onClick = () => {},
  selected,
  disabled,
  icon,
  activeColor = "red",
}) => {
  let color;

  if (disabled) color = "grey";
  else {
    color = selected ? activeColor : "black";
  }

  return (
    <div
      className={`clickable-badge ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <Icon icon={icon} color={color} className="icon" />
    </div>
  );
};

export default ClickableBadge;
