import { useMemo } from 'react';
import { clamp } from 'three/src/math/MathUtils';
import { useScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import config from '../config';

const useDefaultZoom = () => {
  const {
    pointCloudModule: { pointCloud },
  } = useScreenContext();

  const zoom = useMemo(
    () =>
      clamp((pointCloud?.geometry?.boundingSphere?.radius as any) | config.minZoomDefault, config.minZoomDefault, config.maxZoomDefault),
    [pointCloud]
  );

  return zoom;
};

export default useDefaultZoom;
