import { useState, useEffect, createContext, useContext } from 'react';
import { useUser } from '../providers/user';
import useApi from './api';

const generateDate = (date) => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return `${month + 1}. ${day}. ${year}.`;
}

const Context = createContext({ notifications: [] });
export const Provider = ({ children }) => {
  const { user } = useUser();
  const { handleRequest } = useApi();
  const [notifications, setNotifications] = useState([]);

  const _handleRead = (id) => {
    const index = notifications.findIndex(noti => noti.id === id);
    if (!index < 0) return;
    const _notifications = [...notifications];
    _notifications[index].read = true;
    setNotifications(_notifications);

    handleRequest(`/v1/notifications/${id}`, { method: 'PATCH' });
  }

  useEffect(() => {
    const _handleLoad = async () => {
      if (!user) return;
      const notifications = await handleRequest(`/v1/notifications`).then(res => res.json());
      if (!Array.isArray(notifications)) return;
      setNotifications(notifications.map(notification => ({ ...notification, date: new Date(notification.created) })));
    };

    _handleLoad();
    const interval = setInterval(_handleLoad, 60 * 1000); // 1 minute
    return () => clearInterval(interval)
  }, [handleRequest, user]);

  return (
    <Context.Provider value={{ notifications, onRead: _handleRead }}>{children}</Context.Provider>
  )
}

export const useNotifications = () => {
  const { notifications, onRead } = useContext(Context);
  return {
    notifications,
    generateDate,
    onRead,
    notificationsGrouped: notifications.reduce((acc, notification) => {
      const val = {...acc};
      const date = generateDate(notification.date);
      if (!val[date]) val[date] = [];
      val[date].push(notification);

      return val;
    }, {}),
    isNewNotification: notifications.reduce((acc, noti) => acc || !noti.read, false),
  }
};