import { OrbitControls, OrbitControlsProps } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { MOUSE, Vector3 } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { useScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import config from '../config';

type Props = { name?: string; normal?: Vector3 | null; target?: Vector3 | null } & Pick<
  OrbitControlsProps,
  'minZoom' | 'maxZoom' | 'minAzimuthAngle' | 'maxAzimuthAngle'
>;

const SectionCameraControls = (props: Props): JSX.Element => {
  const {
    pointCloudModule: { pointCloud },
    section: { setLookAt, setLookFrom },
    helper: { azimuthAngle },
    actions: { setAzimuthAngle }
  } = useScreenContext();

  const camera = useThree((s) => s.camera);
  const controls = useThree((s) => s.controls) as OrbitControlsImpl;

  const rotationRef = useRef(false);

  useEffect(() => {
    if (!props.target || !props.normal || !controls) return;

    const target = props.target as Vector3;
    const normal = props.normal as Vector3;

    const copyOfNormal = new Vector3().copy(normal);
    const position = new Vector3().addVectors(target, copyOfNormal.multiplyScalar(config.defaultCameraDistance));
    camera.position.copy(position);
    camera.lookAt(target);

    const direction = new Vector3();
    direction.subVectors(position, target).normalize(); // Vector pointing from point1 to point2
    const angle = direction.angleTo(new Vector3(0, 0, 1));

    controls.maxPolarAngle = angle;
    controls.minPolarAngle = angle;

    setLookFrom(position);
    setLookAt(target);
    // Adjust lookAt and lookFrom positions only if control is created, or props have changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls?.domElement, props.normal, props.target, setLookAt, setLookFrom]);

  useFrame(({ controls }) => {
    if (rotationRef.current) return;

    const orbit = controls as OrbitControlsImpl;
    if (azimuthAngle.current?.toFixed(10) === orbit.getAzimuthalAngle()?.toFixed(10)) return;

    orbit.setAzimuthalAngle(azimuthAngle.current);
    orbit.update();
  });

  return (
    <OrbitControls
      enablePan={true}
      rotateSpeed={config.rotateSpeed}
      minZoom={props.minZoom ?? config.minZoomDefault}
      maxZoom={props.maxZoom ?? config.maxZoomDefault}
      makeDefault
      dampingFactor={1}
      camera={camera}
      target={props.target ?? pointCloud?.geometry.boundingSphere?.center}
      onChange={() => {
        if (!controls || !rotationRef.current) return;
        const orbit = controls as OrbitControlsImpl;
        setAzimuthAngle(orbit.getAzimuthalAngle());
      }}
      onStart={() => {
        rotationRef.current = true;
      }}
      onEnd={() => (rotationRef.current = false)}
      mouseButtons={{
        RIGHT: MOUSE.ROTATE,
        MIDDLE: MOUSE.DOLLY,
      }}
    />
  );
};

export default SectionCameraControls;
