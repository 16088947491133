import color from 'color';

const border = bg => color(bg).darken(0.4).hex();
const activeColor = c => color(c).lighten(2).hex();

// fillColor: [
//   'interpolate',
//   ['linear'],
//   ['zoom'],
//   2, 'rgba(255,  0, 0, 0.6)', // blue-green
//   20, 'rgba(0, 255, 0, 0.6)', // yellow
// ],

export const generateFill = (color, opacity = 0.4, highlight = []) => ({
  fillColor: [
    'case',
    ['boolean', ['feature-state', 'active'], false],
    activeColor(color),
    ['in', ['get', 'code'], ['literal', highlight]],
    '#ffc410',
    ['boolean', ['feature-state', 'hover'], false],
    color,
    color,
  ],
  fillOutlineColor: [
    'case',
    ['boolean', ['feature-state', 'active'], false],
    '#0ff',
    border(color),
  ],
  fillOpacity: [
    'case',
    ['boolean', ['feature-state', 'active'], false],
    0.64,
    ['in', ['get', 'code'], ['literal', highlight]],
    0.6,
    ['boolean', ['feature-state', 'hover'], false],
    opacity * 0.6,
    opacity,
  ],
});

export const generateOutline = (color, opacity = 0.4) => ({
  lineColor: color,
  lineWidth: 1,
  lineOpacity: opacity * 2,
});

// Always used with trees
export const generateCircle = (color, opacity) => {
  const circleColor = color || '#030303';
  const circleStrokeColor = border(color);

  return {
    circleRadius: [
      'interpolate',
      ['linear'], ['zoom'],
      10, 1,
      18, 4,
      22, 12,
    ],
    circleOpacity: [
      'interpolate',
      ['linear'], ['zoom'],
      15, 0,
      16, 1,
    ],
    circleColor: circleColor,
    // circleColor: [
    //   'case',
    //   ['boolean', ['feature-state', 'active'], false],
    //   '#0ff',
    //   circleColor,
    // ],
    circleStrokeOpacity: [
      'interpolate',
      ['linear'], ['zoom'],
      15, 0,
      16, 1,
    ],
    // circleStrokeColor,
    circleStrokeColor: [
      'case',
      ['boolean', ['feature-state', 'active'], false],
      '#0ff',
      circleStrokeColor,
    ],
    circleStrokeWidth: [
      'case',
      ['boolean', ['feature-state', 'active'], false],
      5,
      1
    ],
  };
}

const generateLine = (color) => ({
  lineColor: [
    'case',
    ['boolean', ['feature-state', 'active'], false],
    '#0ff',
    ['interpolate', ['linear'], ['get', 'probability'], 0.1, '#f28', 0.7, color]
  ],
  lineWidth: [
    'case',
    ['boolean', ['feature-state', 'active'], false],
    3, 2,
  ],
  lineOpacity: [ 
    'case',
    ['boolean', ['feature-state', 'active'], false],
    1, .6
    // TODO: opacity based on probabilty
  ]
})

/**
 * Generate layer styles
 * @param { type: string, color: sting } layer 
 */
const generateStyles = ({ type, color, opacity }) => {
  const style = type === 'line' ? generateLine(color) : (type === 'fill' ? generateFill(color, opacity) : (type === 'line' ? generateOutline(color, opacity) : generateCircle(color, opacity)));

  return Object.toKebab(style);
}

export default generateStyles;