import { extend, MeshProps, ReactThreeFiber } from '@react-three/fiber';
import { forwardRef, PropsWithChildren, useImperativeHandle, useRef } from 'react';
import { BufferGeometry, Color, Line, Material, Mesh } from 'three';
import useForwardedRef from '../../hooks/useForwardedRef';

extend({ Line_: Line });
declare global {
  namespace JSX {
    interface IntrinsicElements {
      line_: ReactThreeFiber.Object3DNode<THREE.Line, typeof Line>;
    }
  }
}

type Props = {
  colors: { bodyColor?: Color; outlineColor?: Color };
} & PropsWithChildren &
  MeshProps;

const EllipseBody = forwardRef<any, Props>(({ colors, children, position, rotation, ...otherProps }: Props, fwdRef) => {
  const ref = useForwardedRef(fwdRef) as any;

  const bodyRef = useRef<Mesh>(null);
  const outlineRef = useRef<Line<BufferGeometry, Material>>(null);

  useImperativeHandle(ref, () => ({
    get body() {
      return bodyRef.current;
    },
    get outline() {
      return outlineRef.current;
    },
  }));

  return (
    <>
      <mesh {...otherProps} ref={bodyRef} position={position} rotation={rotation} renderOrder={100} frustumCulled={false}>
        <bufferGeometry />
        <meshBasicMaterial color={colors.bodyColor} transparent opacity={0.4} depthTest={false} depthWrite={false} />
        {children}
      </mesh>
      <line_ ref={outlineRef} position={position} rotation={rotation}>
        <bufferGeometry />
        <lineBasicMaterial color={colors.bodyColor ?? '#ffffff'} depthTest={false} depthWrite={false} transparent opacity={1} />
      </line_>
    </>
  );
});

export default EllipseBody;
