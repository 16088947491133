import { UndoMicroclimateFunctions } from '../../../@types/MicroclimateAttributes';
import { MicroclimateParams } from '../../../@types/MicroclimateValidation';
import Tree from '../../../@types/Tree';
import { GetGenusesOptions, GetSpeciesOptions } from '../../../hooks/betaHooks/beta-use-tree';
import PropertiesCard from '../PropertiesCard';
import TreeClassificationGenusSpeciesCard from "../TreeClassificationCard/TreeClassificationGenusSpeciesCard";

type AttributesTabProps = {
  tree: Tree;
  undo: UndoMicroclimateFunctions;
  reloadTrees: () => Promise<any[] | undefined>;
  updateMicroclimate: (options: MicroclimateParams) => Promise<any>;
  setGenusAndSpecies: (genusId: number | null, speciesId?: number | null) => Promise<any>;
  getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
  getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
  disableGenusAndSpecies: boolean;
};

const AttributesTab = (props: AttributesTabProps): JSX.Element => {

  const handleGenusAndSpeciesChange = async (genusId: number | null, speciesId?: number | null) => {
    const payload = { ...props.tree.microclimate, uncertain: false };
    await props.updateMicroclimate(payload);
    await props.setGenusAndSpecies(genusId, speciesId);
    await props.reloadTrees();
    props.undo.onAction('UPDATE', props.tree.id, props.tree.microclimate, payload);
  };

  return (
    <div className='microclimate-inputs-wrapper'>
      <PropertiesCard
        genusConfidence={props.tree.genus_confidence} speciesConfidence={props.tree.species_confidence} markedAsUncertain={!!props.tree.microclimate.uncertain} />
      <TreeClassificationGenusSpeciesCard
        cardValue={props.tree}
        onGenusAndSpeciesChange={handleGenusAndSpeciesChange}
        genusConfidenceLockEnabled={false} /* turned off in PRD-686 */
        genusConfidenceLock={.6}
        genusConfidenceValue={props.tree.genus_confidence}
        getGenuses={props.getGenuses}
        getSpecies={props.getSpecies}
        disabled={props.disableGenusAndSpecies}
      />
    </div>
  );
};

export default AttributesTab;
