import React, { useState, useCallback, useEffect, useRef } from 'react';

import { fetchDataFromTable, fillTreesWithMavenData, useData, useTrees } from '../../providers/data';
import useApi from '../../hooks/api';
import SingleTree from './SingleTree';
import TreePopup from '../../components/TreePopup';

import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import CmaFilter, { generateFilter } from './CmaFilter';

class FilterDescription {
  constructor(label, column) {
    this.label = label;
    this.column = column;
    this.value = null;
  }
}

class ListFilter extends FilterDescription {
  constructor(label, column) {
    super(label, column);
    this.type = 'dropdown';
  }
}

class DateRangeFilter extends FilterDescription {
  constructor(label, column) {
    super(label, column);
    this.type = 'daterange';
    this.value = [null, null];
  }
}

class RangeFilter extends FilterDescription {
  constructor(label, column) {
    super(label, column);
    this.type = 'range';
    this.value = [null, null];
  }
}

const filterGroupsForMAs = [
  {
    group: 'Organization Hierarchy',
    inputs: [
      new ListFilter('Division', 'division_cd'),
      new ListFilter('Branch', 'branch_cd'),
      new ListFilter('Section', 'section_cd'),
      new ListFilter('Work Unit'),
      new ListFilter('Work Unit Officer'),
      new ListFilter('Validation Officer', 'officer_in_charge'),
    ],
  },
  {
    group: 'Managed Area Parameters',
    inputs: [
      new ListFilter('Location Name', 'loc_desc'),
      new ListFilter('Sub-location Name', 'sub_loc_desc'),
      new ListFilter('Location Class', 'location_class'),
      new ListFilter('Location Code', 'loc_cd'),
      new ListFilter('Inspection Frequency', 'frequency'),
      new DateRangeFilter('Last Validation Date'),
      new DateRangeFilter('Last Inspection Date', 'last_inspection_date'),
      new DateRangeFilter('Next Inspection Date', 'next_inspection_date'),
    ],
  },
];

const filterGroupForTrees = [
  {
    group: 'Tree Parameters',
    inputs: [
      new ListFilter('NParks Tree ID', 'tree_id'),
      new ListFilter('Species', 'spsc_nm'),
      new ListFilter('Species Type', 'common_nm'),
      new RangeFilter('Tree Girth', 'girth_1_0m'),
      new RangeFilter('Tree Height', 'height'),
    ],
  },
];

const generateTabs = ({ path, onPathChange, selectedTreeId }) => [
  {
    title: 'Tree inspector',
    icon: 'tree',
    active: /trees/.test(path),
    disabled: !/trees/.test(path) && !selectedTreeId,
    onClick: () => onPathChange('trees/' + selectedTreeId),
  },
];

const completedPipelineFilter = (pipeline) =>
  pipeline.current_manual_step === null;

const CompletedMAsRouter = ({ location, history, match }) => {
  const { handleRequest } = useApi();
  const { pipelines, getManagedArea } = useData();
  const mapRef = useRef(null);

  const [maFilterState, setMaFilterState] = useState(filterGroupsForMAs);
  const [relevantMAData, setRelevantMAData] = useState([]);

  const [treeFilterState, setTreeFilterState] = useState(filterGroupForTrees);
  const [relevantTreeData, setRelevantTreeData] = useState([]);

  // Fetching data
  useEffect(() => {
    (async () => {
      const relevantMAs = await loadRelevantMAData(pipelines, handleRequest);
      setRelevantMAData(relevantMAs);

      const completedMAIds = relevantMAs.map((x) => x.id);

      const relevantTrees = await loadRelevantTreeData(
        completedMAIds,
        handleRequest
      );
      setRelevantTreeData(relevantTrees);
    })();
  }, [handleRequest, pipelines]);

  // ---- pipeline stuff
  const maFilterArray = [
    ...maFilterState[0].inputs,
    ...maFilterState[1].inputs,
  ];

  const filteredMAIDs = relevantMAData
    .filter(generateFilter(maFilterArray))
    .map((x) => x.id);

  const pipelineFilter = (pipeline) =>
    filteredMAIDs.includes(pipeline.managed_area_id);

  // ---- tree stuff
  const managedArea = getManagedArea(match.params.MA);
  const trees = useTrees(managedArea);

  const [
    relevantTreeDataForFilteredMAs,
    setRelevantTreeDataForFilteredMAs,
  ] = useState([]);

  useEffect(() => {
    setRelevantTreeDataForFilteredMAs(
      relevantTreeData.filter((tree) =>
        filteredMAIDs.includes(tree.managed_area_id)
      )
    );
  }, [maFilterState, relevantTreeData]);

  const treeFilterArray = treeFilterState[0].inputs;

  const filteredTreeIds = relevantTreeDataForFilteredMAs
    .filter(generateFilter(treeFilterArray))
    .map((x) => x.id);

  const treeFilterForLayer = [
    'in',
    ['get', 'id'],
    ['literal', filteredTreeIds.map((id) => Number(id))],
  ];

  const treeFilterForTable = (tree) => filteredTreeIds.includes(tree.id);

  const treeLayers = [
    {
      id: 'completed',
      source: 'trees',
      type: 'circle',
      filter: treeFilterForLayer,
    },
  ];

  // ---- other stuff
  const _handleTab = (path) => history.push(`/cma/${path}`);

  const [selectedTreeId, setSelectedTreeId] = useState(null);

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      requestAnimationFrame(() => {
        const _handleDetails = () => history.push(`/cma/trees/${feature.id}`);

        // TODO: popup handling needs to be refactored, because the button inside the popup
        // won't call onClick method passed by react.
        const button = document.querySelector('#tree-details-handler');
        button.onclick = _handleDetails;
      });

      // todo: spsc_nm should come rather from useTree() hook
      const mavenTreeData = relevantTreeData.find(
        (tree) => Number(tree.id) === Number(feature.id)
      );

      return (
        <TreePopup
          tree={{
            ...feature,
            properties: { 
              ...feature.properties, 
              ...mavenTreeData, 
              maven_tree_id: mavenTreeData.tree_id, 
            },
          }}
          {...props}
          isDetailsVisible
        />
      );
    },
    [history, relevantTreeData]
  );

  const additionalMapControl = [
    {
      id: 'map-filter',
      label: 'Filter',
      component: (
        <CmaFilter
          {...{
            relevantMAData,
            maFilterArray,
            maFilterState,
            setMaFilterState,

            relevantTreeData: relevantTreeDataForFilteredMAs,
            treeFilterArray: treeFilterState[0].inputs,
            treeFilterState,
            setTreeFilterState,
          }}
        />
      ),
    },
  ];

  const tableColumns = (selection = null) => {
    return selection !== null
    ? [
        {
          title: 'Tree ID',
          key: 'id',
          resolver: ({ id, maven_tree_id }) => maven_tree_id ? maven_tree_id : id,
          sortable: true,
        },
        {
          title: 'Species',
          key: 'species',
          resolver: ({ scientific_name }) => scientific_name || '-',
          sortable: true,
        },
        {
          title: 'Girth',
          key: 'girth',
          resolver: ({ girth_1_0m }) => girth_1_0m && `${girth_1_0m.toFixed(2)}m` || '-',
          sortable: true,
        },
        {
          title: 'Height',
          key: 'height',
          resolver: ({ height }) => height && `${height.toFixed(2)}m` || '-',
          sortable: true,
        },
        {
          title: 'Validation officer',
          key: 'validation_officier',
          resolver: ({ oic_id, oic_first_name, oic_last_name }) => oic_id ? `${oic_first_name} ${oic_last_name}` : '-',
          sortable: true,
        },
        {
          title: 'Validation date',
          key: 'validation_date',
          resolver: 'validation_date', 
          format: 'DATE', 
          sortable: true,
        },
        {
          title: 'Inspection officer',
          key: 'inspection_officer',
          resolver: ({ inspection_officer, inspection_first_name, inspection_last_name }) => inspection_officer ? `${inspection_first_name} ${inspection_last_name}` : '-',
          sortable: true,
        },
        {
          title: 'Inspection date',
          key: 'inspection_date',
          resolver: 'next_inspection', 
          format: 'DATE', 
          sortable: true,
        },

      ]
      : null;
  }

  return (
    <SkeletonWithManagedAreaMap
      location={location}
      history={history}
      match={match}
      basePath="cma"
      title="Virtual Tree Inspector"
      trees={trees}
      mapRef={mapRef}
      treeStatusFilter='completed'
      customColumns={tableColumns}
      pipelineFilter={pipelineFilter}
      tabs={generateTabs({
        path: location.pathname,
        onPathChange: _handleTab,
        selectedTreeId,
      })}
      screens={[
        {
          title: `Virtual Tree Inspector`,
          path: '/cma/trees/:tree',
          Component: SingleTree,
        },
      ]}
      layerFilter={treeLayers}
      treePopup={treePopup}
      onTreeSelection={setSelectedTreeId}
      controls={additionalMapControl}
      treeFilterForTable={treeFilterForTable}
    />
  );
};

const loadRelevantMAData = async (pipelines, handleRequest) => {
  const completedPipelines = pipelines.filter(completedPipelineFilter);
  const completedMAInfo = completedPipelines.map((ma) => ({
    id: ma.managed_area_id,
    loc_id: ma.loc_id,
    sub_loc_id: ma.sub_loc_id,
    officer_in_charge: ma.officer_in_charge,
  }));

  // todo: some of these logics could be moved into the API
  const locationData = await fetchDataFromTable(
    'locations',
    'id,loc_desc,frequency,location_class,loc_cd,last_inspection_date,next_inspection_date,division_cd',
    null,
    { column: 'id', values: completedMAInfo.map((x) => x.loc_id) },
    handleRequest
  );

  const sublocationData = await fetchDataFromTable(
    'sublocations',
    'id,sub_loc_desc,frequency,location_class,loc_cd,last_inspection_date,next_inspection_date,division_cd',
    null,
    {
      col: 'id',
      values: completedMAInfo
        .filter((x) => x.sub_loc_id)
        .map((x) => x.sub_loc_id),
    },
    handleRequest
  );

  const relevantMADataRaw = completedMAInfo.map((ma) => {
    const matchingLocation = locationData.find((x) => x.id === ma.loc_id);
    const matchingSublocation = sublocationData.find(
      (x) => x.id === ma.sub_loc_id
    );
    // if there is a sublocation, its attributes overwrites the location's attributes
    return { ...matchingLocation, ...matchingSublocation, ...ma };
  });

  const relevantMAData = relevantMADataRaw.map((x) => ({
    ...x,
    last_inspection_date: x.last_inspection_date
      ? new Date(x.last_inspection_date)
      : null,
    next_inspection_date: x.next_inspection_date
      ? new Date(x.next_inspection_date)
      : null,
  }));

  return relevantMAData;
};

const loadRelevantTreeData = async (completedMAIds, handleRequest) => {
  const trees = await fetchDataFromTable(
    'trees',
    'id,height,girth_1_0m,maven_id,managed_area_id,manual_scientific_name',
    { column: 'status', value: 'completed' },
    { column: 'managed_area_id', values: completedMAIds },
    handleRequest
  );

  const joinedData = await fillTreesWithMavenData(trees, handleRequest);

  return joinedData;
};



export default CompletedMAsRouter;
