import { memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnType } from '../../@types/Table';
import Tree from '../../@types/Tree';

import TableCell from './Cell';
import { tableSorter } from './helpers';
import NoResult from './NoResult';

import Row from './Row';
import Title from './Title';
import { LoaderWrapper } from '../../layout';
import { SortTableProps } from '../../hooks/betaHooks/beta-use-table-sorter';
import { MANAGED_AREA_STATUSES } from '../../hooks/betaHooks/beta-managed-areas-context';

type TableConfigType = {
  defaultSortColumn: {
    columnKey: string;
    direction: number;
  } | null;
  filter: any;
  columns: ColumnType[];
  selection: (entry: any) => any;
};

const generateRow =
  (
    config: TableConfigType,
    onClick: (tree: Tree, index: any) => void,
    wrapper: RefObject<HTMLDivElement>,
    updateTree: any,
    isNewTableVersion?: boolean,
  ) =>
    (entry: any, index: number) => {
      const columns = config.columns || [];
      const isSelected = (config.selection && config.selection(entry)) || false;

      const _handleClick = () => onClick && onClick(entry, index);

      return (
        <Row
          key={entry.id}
          wrapper={wrapper}
          index={index}
          selected={isSelected}
          onClick={_handleClick}
          isNewTableVersion={!!isNewTableVersion}
        >
          {columns.map((column) => (
            <TableCell
              {...column}
              column={column}
              entry={entry}
              key={`${entry.id}-${column.uniqueKey ?? column.key}`}
              updateTree={updateTree}
              selected={isSelected}
            />
          ))}
        </Row>
      );
    };

type TableProps = {
  data: any[];
  config: any;
  onClick?: (e: any) => void;
  preventAutoFocus?: boolean;
  updateTree?: boolean;
  isNewTableVersion?: boolean;
  contextsLoadingState: boolean;
  sortTable: (sortableProperty: SortTableProps['sortableProperty']) => void;
  tableSort: SortTableProps['sortableProperty'];
  pipeline: any;
};

const Table = ({ data, config, onClick, preventAutoFocus, updateTree, isNewTableVersion, contextsLoadingState, sortTable, tableSort, pipeline }: TableProps) => {
  const columns = useMemo(() => config.columns || [], [config.columns]);
  const wrapper = useRef(null);

/*   const _handleSort = useCallback(
    (columnKey: string) => {
      if (sort[columnKey] === -1) {
        setSort({});
        return;
      }
      if (sort[columnKey] === 1) {
        setSort({ [columnKey]: -1 });
        return -1;
      }
      setSort({ [columnKey]: 1 });
      return 1;
    },
    [sort]
  ); */

  const filteredData = useMemo(() => data?.filter(config.filter || (() => true)), [config.filter, data]);

  useEffect(() => {
    if (!preventAutoFocus) onClick?.(filteredData?.[0]);
  }, [filteredData, filteredData?.length, onClick, preventAutoFocus]);

  const tableRows = useMemo(() => {
    const sortKey = tableSort?.key;
    const sortDir = tableSort?.direction;
    const column = columns.find((column: ColumnType) => column.key === sortKey);

    const result =
      filteredData?.length > 0 || data?.length === 0 ? (
        tableSorter(filteredData, sortDir, column, Object.byResolver).map(
          generateRow(config, onClick!, wrapper, updateTree, isNewTableVersion)
        )
      ) : (
        <NoResult />
      );

    return result;
  }, [columns, filteredData, data?.length, config, onClick, updateTree, isNewTableVersion, tableSort]);

  const getColumnKey = (key: any, manualStep: any) => {
    if (key === "confidence_level") {
      switch (manualStep) {
        case MANAGED_AREA_STATUSES.LOCATION_VALIDATION: 
          return 'location_confidence'
        case MANAGED_AREA_STATUSES.SEGMENTATION_VALIDATION:
          return 'ml_instance_confidence'
        default:
          return 'confidence_girth_ellipse'
      }
    }

    if (key === "confidence_level_2") {
      switch (manualStep) {
        case MANAGED_AREA_STATUSES.SEGMENTATION_VALIDATION:
          return 'ml_semseg_confidence'
        default:
          return null
      }
    }

    return key;
  }

  return (
    <div className={`table-wrapper ${isNewTableVersion ? ' modern' : ''}`} ref={wrapper}>
      <LoaderWrapper loading={!!contextsLoadingState}>
        <table cellSpacing={0} cellPadding={0}>
          <tbody style={{ position: 'relative' }}>{tableRows}</tbody>
          <thead>
            <tr>
              {columns.map((column: ColumnType, i: number) => (
                <Title
                  {...column}
                  data={data}
                  onSort={() => {
                    let direction: 1 | -1 | undefined = undefined;

                    const columnKey = getColumnKey(column.key, pipeline?.current_manual_step);

                    if (!tableSort?.key || tableSort.key !== columnKey) {
                      direction = 1;
                    } else {
                      if ( !tableSort?.direction ) direction = 1;
                      else if ( tableSort?.direction === 1) direction = -1;
                      else direction = undefined
                    }
                    
                    sortTable({
                      key: columnKey,
                      direction
                    });
                  }}
                  sort={tableSort}
                  key={column.uniqueKey ?? getColumnKey(column.key, pipeline?.current_manual_step)}
                  id={getColumnKey(column.key, pipeline?.current_manual_step)}
                  subTitle={column.subTitle!}
                  subTitleAction={column.subTitleAction!}
                  isNewTableVersion={!!isNewTableVersion}
                />
              ))}
            </tr>
          </thead>
        </table>
      </LoaderWrapper>
    </div>
  );
};

export default memo(Table);
