import { IconElementParams } from '../../@types/Icon';

const ToggleToolbar = ({ width = 16, height = 16 }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 1C3.34315 1 2 2.34315 2 4C2 5.65685 3.34315 7 5 7H11C12.6569 7 14 5.65685 14 4C14 2.34315 12.6569 1 11 1H5ZM6 4C6 4.55228 5.55228 5 5 5C4.44772 5 4 4.55228 4 4C4 3.44772 4.44772 3 5 3C5.55228 3 6 3.44772 6 4ZM5 8C3.34315 8 2 9.34315 2 11C2 12.6569 3.34315 14 5 14H11C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8H5ZM11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12Z' />
    </svg>
  );
};

export default ToggleToolbar;
