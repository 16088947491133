import React, {useCallback, useMemo, useState} from 'react';
import {NewPanoramaView} from "./NewPanoramaView";
import {useHistory, useLocation} from "react-router";
import {Map} from "../index";
import * as THREE from "three";
import {MathUtils, Vector3} from "three";
import Popup from "../Popup";
import Icon from "../Icon";

const Panorama = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [panoramaContext, setPanoramaContext] = useState(null);

    const [isAddTreeMode, setIsAddTreeMode] = useState(false);

    const [cameraPropsForGoogleMaps, setCameraPropsForGoogleMaps] = useState(null);

    const exactSnapshotId = useMemo(() => new URLSearchParams(location.search).get('snapshotid'), [location.search])

    const handleCpaturePointClick = useCallback((snapshot_id) => {
        const s = new URLSearchParams(location.search);
        s.set('snapshotid', snapshot_id);

        history.replace({search: s.toString()})
    }, [location])

    const radiansToDegrees = (r) => r * 180 / Math.PI;

    const ViewGoogleMapsButton = ({active, coordinates, cameraPropsForGoogleMaps}) => {
        const viewMapUrl = useCallback(() => {
            const [lng, lat] = coordinates;

            const { phi, theta, fov } = cameraPropsForGoogleMaps;

            const phiInDegrees = (radiansToDegrees(phi + Math.PI / 2) % 360) * -1;
            const thetaInDegrees = (radiansToDegrees(theta + Math.PI / 2) % 360) * -1;

            const str = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat}%2C${lng}&heading=${thetaInDegrees}&pitch=${phiInDegrees}&fov=${fov || 80}`;
            window.open(str, '_blank');
        }, [coordinates, cameraPropsForGoogleMaps, panoramaContext]);

        return <Popup title='View on google maps' direction='right'>
            <div className='action-group'>
                <div className={`action-wrapper ${active ? 'active' : ''}`} onClick={viewMapUrl}>
                    <Icon icon={'map'}/>
                </div>
            </div>
        </Popup>
    };

    const AddTreeButton = (props) => {
        return <Popup title='Add new tree' direction='right'>
            <div className='action-group'>
                <div className={`action-wrapper ${isAddTreeMode ? 'active' : ''}`} onClick={props?.onClick}>
                    <Icon icon={'tree'}/>
                </div>
            </div>
        </Popup>
    };

    return <div className='panorama-view-wrapper'>
        <NewPanoramaView
            treeId={props.treeId}
            managedAreaCode={props.managedAreaCode}
            exactSnapshotId={exactSnapshotId}
            onCapturePointClick={(item) => handleCpaturePointClick(item?.object?.userData?.data?.snapshot_id)}
            onInitialCapturePointDetected={(item) => {
                if (props.mapRef?.current && panoramaContext?.capturePoint?.position_4326) {
                    props.mapRef?.current?.focusOnPoint?.(panoramaContext?.capturePoint?.position_4326?.coordinates || [0, 0], 17);
                }
                    
                handleCpaturePointClick(item.snapshot_id);
            }}
            onTreeClick={(item) => props?.onTreeChange?.(item?.object?.userData?.tree?.id)}
            optionalPosition={props.optionalPosition}
            addTreeMode={isAddTreeMode}
            onAddTree={props.onAddTree}
            onContextChanged={(data) => {
                props.mapRef?.current?.focusOnPoint?.(data?.capturePoint?.position_4326?.coordinates || [0, 0], 17);
                setPanoramaContext(data);
            }}
            onViewChange={props.onViewChange}
            setCameraPropsForGoogleMaps={setCameraPropsForGoogleMaps}
        />

        {props.enabledMiniMap && !!panoramaContext?.capturePoint?.position_4326 && <Map
            minimap={true}
            active={{
                location_validation_todo: panoramaContext?.tree?.id,
                location_validation_done: panoramaContext?.tree?.id,
                mas: panoramaContext?.tree?.managed_area,
            }}
            _ref={props.mapRef}
            cameraPosition={{
                coords: panoramaContext?.capturePoint?.position_4326?.coordinates || [0, 0],
                fov: panoramaContext.camera.fov || 90,
                rotationDeg: new THREE.Vector2(MathUtils.radToDeg(panoramaContext.camera.rotation.x) || 0, MathUtils.radToDeg(panoramaContext.camera.rotation.z) || 0)
            }}
            sources={props.mapSources || []}
            layers={props.mapLayers || []}
            isMiniMapHiddenButton={true}
            needReload={false}
        />}

        <div className="map-controls-validation">
            {props.enableAddTree && <AddTreeButton
                onClick={() => setIsAddTreeMode(!isAddTreeMode)}
            />}
            {props.enableGoogleMapsButton && !!panoramaContext?.camera && <ViewGoogleMapsButton
                active={false}                
                coordinates={panoramaContext?.capturePoint?.position_4326?.coordinates}
                cameraPropsForGoogleMaps={cameraPropsForGoogleMaps}
            />}
        </div>
    </div>;

//   return (
//     <PanoramicView
//       {...props}
//       trees={props.trees || trees}
//       capturePoint={{
//         position: { coordinates: images?.[0]?.origin.coordinates || [0, 0, 0] },
//       }}
//       onLocationChange={({ images, location }) => {
//         alert('Handle Location change');
//       }}
//       cameras={cameras}
//       images={images}
//       nearImages={nearImages}
//     />
//   );
};

export default Panorama;
