export const numberSorter = (a: string, b: string) => parseFloat(a) - parseFloat(b);
export const stringSorter = (a: string, b: string) => String(a).localeCompare(String(b));
export const dateSorter = (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime();

const getSorterByType = (a: string, b: string, format?: string) => {
  let sorter;
  if (format === 'DATE' || format === 'MONTH' || format === 'DATETIME') {
    sorter = dateSorter;
  } else {
    const areBothNumbers = !isNaN(parseFloat(a)) && !isNaN(parseFloat(b));
    sorter = areBothNumbers ? numberSorter : stringSorter;
  }
  return sorter;
};

export const sorter = (a: string, b: string, direction: number, format?: string) =>
  getSorterByType(a, b, format)(a ?? '', b ?? '') * direction;
