import { useTheme as useMuiTheme } from '@mui/material';
import { useCallback } from 'react';
import Card from "../../Card/Card";
import Leaf from '../../Icon/Leaf';

interface Props {
    genusConfidence: number | null;
    speciesConfidence: number | null;
    markedAsUncertain: boolean;
}

const PropertiesCard = ({ genusConfidence, speciesConfidence, markedAsUncertain }: Props) => {

    const muiTheme = useMuiTheme();

    const getConfidenceText = useCallback((confidence: number | null) => {
        if (confidence) {
            if (confidence < 1) {
                return `${confidence * 100}%`;
            }
            return "Set Manually";
        } else {
            return '-';
        }
    }, []);

    return (
        <Card
            title="Properties"
            icon={<Leaf color={muiTheme.palette.text.primary} />}
        >
            <div className='properties-card-container'>
                <p>Genus Confidence: <span>{getConfidenceText(genusConfidence)}</span></p>
                <p>Species Confidence: <span>{getConfidenceText(speciesConfidence)}</span></p>
                <p>Uncertain: {markedAsUncertain ? 'true' : 'false'}</p>
            </div>
        </Card>
    )
};

export default PropertiesCard;