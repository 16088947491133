const Visibility = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M10 11.6667C10.442 11.6667 10.866 11.4911 11.1785 11.1786C11.4911 10.866 11.6667 10.4421 11.6667 10C11.6667 9.55801 11.4911 9.13409 11.1785 8.82153C10.866 8.50897 10.442 8.33337 10 8.33337C9.55798 8.33337 9.13406 8.50897 8.8215 8.82153C8.50894 9.13409 8.33334 9.55801 8.33334 10C8.33334 10.4421 8.50894 10.866 8.8215 11.1786C9.13406 11.4911 9.55798 11.6667 10 11.6667Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 10C15.9258 12.4925 13.0983 15 10 15C6.90167 15 4.07417 12.4925 2.5 10C4.41583 7.63167 6.66 5 10 5C13.34 5 15.5842 7.63167 17.5 10Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Visibility;
