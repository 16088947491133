import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Finished, ProgressControls, SegmentationEditor } from '../../components';
import appConfig from '../../config';
import { validationActions } from '../../core/progressActions';
import { useModal } from '../../providers/modal';
import { useHistory } from 'react-router-dom';
import { LoaderWrapper } from '../../layout';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';
import { CommentTypes, useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import ValidationTool from '../../enums/ValidationTool';
import ValidationAction from '../../enums/ValidationAction';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import TreeStatus from '../../enums/TreeStatus';
import { SegmentationScreenContextProvider, useSegmentationScreenContext } from '../../hooks/betaHooks/segmentation-screen-context';

const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

export const RECLASS_TOOLS = {
  BRUSH: 'BRUSH',
  POLYGON: 'POLYGON',
};

export const ERROR_TOOLS = {
  POINT: 'POINT',
  LINE: 'LINE',
  POLYGON: 'POLYGON',
}

const SegmentationValidation = ({
  trees,
  match,
  startPostValidation,
  selection,
  setCurrentTreeId,
  onTreeAdd,
  treeId,
  managedArea,
  contextsLoadingState,
  layerSources,
  reFetchManagedAreaAndTrees,
  skippedTrees,
  currentTree,
  moveToNeighbour,
  setCurrentIndex,
  selectTree,
  relevantTrees,
  todos,
  presentModal,
  dismissModal,
  betaTree
}) => {
  const {
    actions,
    helper,
    reclass,
    pointCloudModule: {
      originalPointCloud: originalLas,
      setOriginalPointCloud: setOriginalLas,
      pointCloud: las,
      setPointCloud: setLas,
      environmentPointCloud: environmentLas,
      isEnvironmentNotFound,
      environmentPointCloudLoading,
      isEnvironmentVisible,
      setIsEnvironmentVisible,
      handleLasLoad,
    },
    section: { setDefaultDepth }
  } = useSegmentationScreenContext();

  const betaManagedAreaContext = useBetaManagedAreaContext();
  const [finished, setFinished] = useState(false);

  const [rotationHandler, setRotationHandler] = useState(null);
  const init = useRef(false);

  const _handleTSEJob = () => {
    startPostValidation(selection[0], 'segmentation_validation');
    setFinished(true);
  };

  useEffect(() => {
    if (!currentTree) return;

    setDefaultDepth();

    betaTree.setTree({
      ...currentTree,
      location: JSON.parse(currentTree?.location),
    });
  }, [betaTree?.setTree, currentTree?.id]);

  // THESE CAN BE DELETED???
  /*   const handleReclassUndo = () => {
    let geometry;

    if (reclassPoints.length) {
      setReclassPoints((curr) => curr.slice(0, -1));
    } else {
      switch (reclassStates.length) {
        case 0:
          return;
        case 1:
          geometry = originalLas.geometry;
          break;
        default:
          geometry = reclassStates[reclassStates.length - 2]?.state;
      }

      setLas({ ...las, geometry });
      const states = reclassStates.slice(0, -1);
      setReclassStates(states);
    }
  }; */

  /*   const handleUndo = () => {
    switch (actionMode) {
      case ValidationTool.Error:
        return handleErrorUndo();
      case ValidationTool.Reclassify:
        return handleReclassUndo();
      default:
        return onUndo();
    }
  }; */
  // THESE CAN BE DELETED???








  const _handleInit = () => {
    init.current = true;
    if (!currentTree) window.history.pushState(null, null, generateUrl(currentTree?.id));
    else {
      const index = todos.findIndex((tree) => String(tree.id) === String(currentTree));
      if (index >= 0) setCurrentIndex(index);
    }
  };

  useEffect(() => {
    if (currentTree?.id) setCurrentTreeId(currentTree.id);
    if (!selection[0]?.code) return;
    if (currentTree?.id && !init.current) _handleInit();
    else if (currentTree?.id && init.current) window.history.pushState(null, null, generateUrl(currentTree?.id));

    reclass.setActionMode(ValidationTool.Inspection_View);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code]);

/*   const _handleTreeLoaded = () => {
    const tree = currentTree;

    heightPrev.current = tree.height;
    setHeight(tree.height);

    canopyHeightPrev.current = tree.crown_height;
    setCanopyHeight(tree.crown_height);

    trunkHeightPrev.current = tree.trunk_height;
    setTrunkHeight(tree.trunk_height);

    girth1Prev.current = parseGirth(tree);
    setGirth1(girth1Prev.current);

    const crownExcentricity = tree.crown_excentricity
      ? Array.isArray(tree.crown_excentricity)
        ? tree.crown_excentricity
        : JSON.parse(tree.crown_excentricity).coordinates
      : [0, 0];

    canopyPrev.current = {
      dX: crownExcentricity[0] || 0,
      dY: crownExcentricity[1] || 0,
      rX: tree.canopy_ellipse_a || 1.8,
      rY: tree.canopy_ellipse_b || 2.2,
      rotation: tree.canopy_ellipse_direction || 0.1,
      diameter: null,
    };
    setCanopy(canopyPrev.current);
  }; */

  const updateGeometry = ({ geometry, changes }) => {
    if (!changes.indices.length) return;

    let shouldUpdateGeometry = true;
    reclass.setReclassStates((curr) => {
      curr.push({ state: geometry, changes });

      const changeLimitReached = curr.length >= appConfig.maxUndo;
      const overChangeLimit = curr.length > appConfig.maxUndo;
      if (changeLimitReached) {
        helper.showToast(`Save changes before adding more!`, 'error');
        if (overChangeLimit) {
          curr.pop();
          shouldUpdateGeometry = false;
        }

        return curr;
      }

      const showPointCloudChangeCountBeforeLimitValue = appConfig.maxUndo - 3;
      const closeToChangeLimit = curr.length >= showPointCloudChangeCountBeforeLimitValue;
      if (closeToChangeLimit) {
        helper.showToast(`Changes left before save: ${appConfig.maxUndo - curr.length}`, 'warning');
      }

      if (curr) return curr;
    });

    if (!shouldUpdateGeometry) return;

    setLas({ ...las, geometry, pc: { ...las.pc, geometry } });
  };

  const changeActionMode = (newMode, isSemantics) => {
    const mode = newMode;

    resetGeometry();

    reclass.setActionMode((current) =>
      !isSemantics && (!ValidationTool[mode] || current === ValidationTool[mode]) ? ValidationTool.Inspection_View : ValidationTool[mode]
    );
  };

  const resetGeometry = () => {
    reclass.setReclassPoints([]);
    reclass.setReclassStates([]);
    setLas(originalLas);
  };

  const _handleSaveLAZ = async () => {
    const payload = {
      lastUrl: currentTree.tree_segmentation_laz,
      blobName: currentTree.tree_segmentation_laz,
      changes: reclass.reclassStates.map((rcs) => rcs.changes),
      treeId: currentTree?.id,
    };

    try {
      await betaTree.saveLaz(payload);
      reclass.setReclassPoints([]);
      reclass.setReclassStates([]);
      setOriginalLas(las);
      helper.showToast('LAZ file saved successfully!');
    } catch (err) {
      helper.showToast('Failed to save LAZ file!', 'error');
      console.error('Error during LAZ update:', err);
    }
  };

  const handleCompletedOnlineAnnotatedTrees = useCallback(async () => {
    if (todos.length > 0) return null;

    trees.forEach(async (t) => {
      if (t.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationDone) {
        await betaTree.updateTreeWithId(t.id, { tree_flow_status: TreeFlowStatus.SegmentationValidationDone }, false);
      }
    });
  }, [todos, betaTree, trees]);

  const environmentToggleProps = useMemo(() => {
    return {
      label: environmentPointCloudLoading ? 'Loading environment' : 'Show environment',
      value: isEnvironmentVisible,
      onPureChange: () => setIsEnvironmentVisible((prev) => !prev),
      disabled: environmentPointCloudLoading || !!isEnvironmentNotFound,
    };
  }, [environmentLas?.pointcloud, isEnvironmentNotFound, isEnvironmentVisible, environmentPointCloudLoading, currentTree]);

  if (!managedArea?.id) return null;

  return (
    <LoaderWrapper loading={!currentTree?.id || helper.isLoading}>
      <Finished
        finished={todos.length === 0}
        onStart={_handleTSEJob}
        done={finished}
        managedAreaId={managedArea.id}
        reloadManagedAreas={reFetchManagedAreaAndTrees}
        handleCompletedOnlineAnnotatedTrees={handleCompletedOnlineAnnotatedTrees}
      >
        <div className='semantics-editor-wrapper'>
          <div className='viewers'>
            <SegmentationEditor
              currentTree={currentTree}
              selection={selection}
              onTreeAdd={onTreeAdd}
              // Not needed props
              environmentToggleProps={environmentToggleProps} // this is used in index.js, calculate/memoize data there
              loading={las === null} // this check can be done from the store

              setRotationHandler={setRotationHandler}
              saveLAZ={_handleSaveLAZ}
              updateGeometry={updateGeometry}
              selectTree={selectTree}
              layerSources={layerSources}
            />
          </div>
          <ProgressControls
            max={trees?.length}
            value={trees?.length - relevantTrees?.length}
            hideProgressBar={false}
            actions={validationActions({
              // onUndo: handleUndo,
              isUndoAvailable: actions.canUndo,
              tree: currentTree,
              markAsDone: async (tree, status) => {
                await betaTree.markAsDoneSegmentation();
                await betaTree.addComment({
                  comment: 'OK',
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
                await betaManagedAreaContext.fetchTrees();
                await moveToNeighbour(1);
              },
              updateTree: async (status, tree_flow_status) => {
                await betaTree.updateTree({ status, tree_flow_status });
                await betaManagedAreaContext.fetchTrees();
                await moveToNeighbour(1);
              },
              showTodoAction: true,
              commentTree: async (_, comment) => {
                await betaTree.addComment({
                  comment: comment.value,
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
              },
              removeTreeComment: async (cid) => {
                await betaTree.removeComment(cid);
              },
              removeTree: async (args, status) => {
                await betaTree.deleteTreeSegmentation();
                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour(1);
              },
              sendToField: () => alert('Unimplemented feature'),
              onSkip: actions._handleSkip,
              onRotation: rotationHandler,
              numberOfTrees: todos.length,
              validationTool: reclass.actionMode,
              changeTool: changeActionMode,
              dismissModal,
              presentModal,
              showComment: true,
              isSemantics: true,
              showDeleteConfirmation: true,
              onSetTmsCategory: async (category) => {
                if (category === 'l3') {
                  await betaTree.updateTree({
                    tms_category: 'l3',
                    status: TreeStatus.SentToField,
                    tree_flow_status: TreeFlowStatus.SentToField,
                  });
                } else {
                  await betaTree.setTMSCategory(category);
                }
                await betaManagedAreaContext.fetchTrees();

                if (category === 'l3') {
                  moveToNeighbour(1);
                }
              },
              showTmsButtons: true,
              showTmsL3Button: true,
              showNavigationArrows: false,
            })}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};


const ContextWrappedSegmentationValidation = (props) => {
  // props.treeId is available and is coming from SkeletonWithManagedAreaMap,
  // but the setting of it is not handled for changing trees in the semantic component
  // reliably the pathname could be used
  const { location: { pathname } } = props;
  const match = pathname.match(/(\d+)$/);
  const treeId = match ? match[1] : "";

  const betaTree = useBetaTree({
    treeId,
    managedAreaId: props.managedArea?.id,
    managedAreaCode: props.managedArea?.code,
  });
  const { tree: currentTree, updateTree, loading } = betaTree

  const todos = props.trees
  .filter(
    (tree) =>
      tree.tree_flow_status === TreeFlowStatus.SegmentationValidationQueued ||
      tree.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotation
  )
  .map((tree, index) => ({ ...tree, index }));

  const history = useHistory();

  const { setCurrentIndex, moveToNeighbour, selectTree, relevantTrees } = useBetaTreeIndex({
    trees: todos,
    currentTreeId: treeId,
    todoStatuses: [TreeFlowStatus.SegmentationValidationQueued, TreeFlowStatus.SentToOnlineAnnotation],
    setTree: (tree) => history.push(`/validation/${props.managedArea.id}/segmentation/${tree.id}`),
    skippedTrees: props.skippedTrees,
  });

  const { presentModal, dismissModal } = useModal();

  const hookProps = {
    currentTree,
    setCurrentIndex,
    moveToNeighbour,
    selectTree,
    relevantTrees,
    presentModal,
    dismissModal
  };

  return (
    <SegmentationScreenContextProvider
      currentTree={currentTree}
      setCurrentIndex={setCurrentIndex}
      updateTree={updateTree}
      moveToNeighbour={moveToNeighbour}
      dismissModal={dismissModal}
      saveError={props.saveError}
      loading={loading}
      contextsLoadingState={props.contextsLoadingState}
    >
      <SegmentationValidation {...{...props, ...hookProps, todos, betaTree }} />
    </SegmentationScreenContextProvider>
  );
}

export default ContextWrappedSegmentationValidation;
