import React, { useRef, useState, useEffect } from 'react';
import Icon from '../Icon';

const className = (className) => className || '';

const TextInput = ({ autoFocus, type, value, onChange = () => {}, label, onPureChange = () => {}, name, error, warning, disabled, leadingIcon, onFocus, onBlur, ...props }) => {
  const input = useRef(null); 
  const [isFocused, setIsFocused] = useState(false);

  const _handleChange = ({ target: { value }}) => {
    onPureChange(value);
    onChange({ name, value })
  }

  const _handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const _handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  useEffect(() => {
    autoFocus && setTimeout(() => input && input.current.focus(), 100);
  }, [input, autoFocus])

  return (
    <div className={`input-wrapper ${className(warning && 'warning')} ${className(error && 'error')} ${isFocused ? 'focused' : 'blured'} ${leadingIcon ? 'icon' : ''} ${disabled ? 'disabled' : ''}`}>
      { leadingIcon && <Icon icon={leadingIcon} />}
      <input
        autoFocus={autoFocus || false}
        ref={input}
        type={type}
        value={value || ''}
        onChange={_handleChange}
        onFocus={_handleFocus}
        onBlur={_handleBlur}
        placeholder={label}
        disabled={disabled}
        {...props}
      />
      { (error || warning) && <span className="alert">{ error || warning }</span> }
    </div>
  )
}

export default TextInput;
