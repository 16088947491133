import React, { useCallback } from 'react';

import { Calendar, Button } from '.';
import { useFadeInOut} from '../../layout/FadeInOutHook';

const DatePicker = ({
  label = '',
  value = new Date(),
  onChange = () => {},
  minDate,
  maxDate,
  buttonRef,
}) => {
  const {isMounted, isVisible, startFadeIn, startFadeOut} = useFadeInOut(500);

  const _showCalendar = (e) => {
    e.preventDefault();

    startFadeIn();
    setTimeout(() => window.addEventListener('click', _hideCalendar), 0);
  };

  const _hideCalendar = useCallback(() => {
    startFadeOut();
    window.removeEventListener('click', _hideCalendar);
  }, []);

  const _handleDateSelection = (date) => {
    _hideCalendar();
    onChange(date);
  };

  const _handleCalendarClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className="date-picker-wrapper">
      <span>{label}</span>
      <Button
        reference={buttonRef}
        label={value?.toLocaleDateString?.() || '-'}
        onClick={isVisible ? _hideCalendar : _showCalendar}
      />

      {isMounted && (
        <div
          className={`date-picker-calendar-wrapper ${
            isVisible ? 'visible' : ''
          }`}
        >
          <div className="label">{label}</div>
          <Calendar
            value={value}
            onClick={_handleCalendarClick}
            onChange={_handleDateSelection}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
