import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationTabParams } from '../../@types/NavigationTab';
import Icon from '../Icon';

const NavigationTab = (navTab: NavigationTabParams): JSX.Element => {
  const { icon, path = '', disabled, title, active, onClick = () => {}, exact, titleOnHover } = navTab;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const _handleAction = (e: any) => {
    if (path && !path.includes(':')) return;
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div className='svg-wrapper' onMouseEnter={() => setIsTooltipVisible(true)} onMouseLeave={() => setIsTooltipVisible(false)}>
      <NavLink
        onClick={_handleAction}
        activeClassName='active'
        style={{ color: 'inherit', textDecoration: 'inherit' }}
        className={`vertical-tab-wrapper regular-button-states ${disabled ? 'disabled' : ''} ${!path ? 'button' : 'link'} ${
          active ? 'force-active' : ''
        }`}
        to={path}
        exact={[...path].pop() === '/' || exact}
      >
        {icon && <Icon icon={icon} />}
        {title?.length && <span>/ &nbsp;&nbsp;{title}</span>}
      </NavLink>
      {isTooltipVisible && <span className={`tooltip ${titleOnHover ? 'visible' : ''}`}>{titleOnHover}</span>}
    </div>
  );
};

export default NavigationTab;
