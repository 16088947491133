import { MathUtils, PerspectiveCamera, Vector3 } from 'three';
import { fromEvent, Subject, Subscription } from 'rxjs';

export class PanoramaControl {
  public subject = new Subject<[number, number, number]>();


  private subscriptions: Subscription[] = [];
  private active = false;

  private camera: PerspectiveCamera;
  private canvas: PerspectiveCamera;

  private onPointDownMouseX = 0;
  private onPointDownMouseY = 0;
  private onPointDownLon = 0;
  private onPointDownLat = 0;
  private lon = 0;
  private lat = 0;
  private phi = 0;
  private theta = 0;
 
  private zeroVector = new Vector3(0, 1, 0);

  constructor(camera: PerspectiveCamera, canvas: any) {
    this.camera = camera;
    this.canvas = canvas;

    this.camera.lookAt(this.camera.position.clone().add(this.zeroVector.clone()))

    this.subscriptions.push(
      fromEvent(canvas, 'pointerdown').subscribe(() => this.active = true),
      fromEvent(canvas, 'pointerup').subscribe(() => this.active = false),
      fromEvent(canvas, 'mouseleave').subscribe(() => this.active = false),

      fromEvent(canvas, 'pointerdown').subscribe((event: any) => this.handleOnPointerDown(event)),
      fromEvent(canvas, 'pointermove').subscribe((event: any) => this.handleOnPointerMove(event)),
      fromEvent(canvas, 'pointerup').subscribe((event: any) => this.handleOnPointerMove(event)),
      fromEvent(canvas, 'wheel').subscribe((event: any) => this.handleWheel(event)),
    );
  }

  private handleWheel(event: WheelEvent) {
    this.camera.fov = MathUtils.clamp(this.camera.fov + (event.deltaY * 0.1), 10, 110);
    this.camera.updateProjectionMatrix();
    this.subject.next([this.phi, this.theta, this.camera.fov]);
  }

  private handleOnPointerDown(event: PointerEvent) {
    this.onPointDownMouseX = event.clientX;
    this.onPointDownMouseY = event.clientY;
    this.onPointDownLon = this.lon;
    this.onPointDownLat = this.lat;
  }

  private handleOnPointerMove(event: PointerEvent) {
    if (!event.isPrimary) return;
    if (!this.active) return;

    this.lon = (this.onPointDownMouseX - event.clientX) * 0.1 * (this.camera.fov / 60) + this.onPointDownLon;
    this.lat = (event.clientY - this.onPointDownMouseY) * 0.1 * (this.camera.fov / 60) + this.onPointDownLat;

    this.update();
  }

  public update() {
    this.lat = MathUtils.clamp(this.lat, -89.999999999999, 89.999999999999);
    this.phi = MathUtils.degToRad(-90 - this.lat);
    this.theta = MathUtils.degToRad(-90 - this.lon);

    const t = new Vector3().set(
      Math.sin(this.phi) * Math.cos(this.theta),
      Math.sin(this.phi) * Math.sin(this.theta),
      Math.cos(this.phi) * -1
    )

    this.camera.lookAt(t.clone());
    this.subject.next([this.phi, this.theta, this.camera.fov]);
  }

  public lookAt(v: Vector3) {
    const vN = v.clone().normalize();
    const [x, y, z] = [vN.x, vN.y, vN.z];

    this.lon = MathUtils.radToDeg(Math.atan2(x, y));
    this.lat = 90 - MathUtils.radToDeg(Math.acos(z))
    this.update();
  }

  public dispose() {
    for (const item of this.subscriptions) {
      item.unsubscribe();
    }
  }
}
