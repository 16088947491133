import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils';
import Ellipse from '../../@types/Ellipse';
import Number3 from '../../@types/Number3';
import Tree from '../../@types/Tree';
import { DEFAULT_ELLIPSE } from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants';
import { calculateGirth, parseCanopy } from '../../components/SemanticsValidation/ValidationUtils';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import TreeStatus from '../../enums/TreeStatus';
import ValidationAction from '../../enums/ValidationAction';
import { Girth, transformToTrunkWithGirths, TrunkWithGirths } from '../../store/TreeSlice';
import { convertXYZToPostGisPoint } from '../../utils/postgisHelper';
import { SegmentationScreenContextValue, useSegmentationScreenContext } from './segmentation-screen-context';
import { usePointCloud } from './use-point-cloud';

const DEFAULT_SECTION_DEPTH = 0.15;

export const getDefaultGirth = (): Girth => {
  return {
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    ...DEFAULT_ELLIPSE,
  };
};

export const getDefaultCanopy = () => {
  return {
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0,
    diameter: null,
    normal: new Vector3(0, 0, 1),
    height: 1,
  };
};

const getDefaultTrunk = (tree_id: string) => {
  return {
    tree_id,
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    girths: [getDefaultGirth()],
  };
}

export type EllipseWithNormalAndHeight = Ellipse & { normal: Vector3; height: number };

export enum ToastType {
  INFO = 'info',
  ERROR = 'error',
}

type Toast = {
  message: string;
  type: ToastType;
};

export interface AttributeCardAlert {
  name: string;
  message: string;
}

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

type MetricsToDB = {
  are_girth_axes_valid: boolean;
  height: number;
  crown_height: number;
  trunk_height: number;
  canopy_ellipse_a: number;
  canopy_ellipse_b: number;
  canopy_ellipse_direction: number;
  crown_excentricity: number[];
  girth_1_0m_ellipse_direction: number;
  first_bifurcation_point: string;
  status: TreeStatus;
  tree_flow_status: TreeFlowStatus;
  trunks?: TrunkWithGirths[];
  number_of_stems: number;
};

export interface SemanticScreenContextValue {
  pointCloudModule: ReturnType<typeof usePointCloud>;

  section: {
    depth: number;
    lookAt: Vector3 | null;
    lookFrom: Vector3 | null;
    target: Vector3;
    normal: Vector3;
    defaultTarget: Vector3 | null;
    defaultNormal: Vector3 | null;
    visibility: boolean;
    setDepth: StateSetter<number>;
    setLookAt: StateSetter<Vector3 | null>;
    setLookFrom: StateSetter<Vector3 | null>;
    setTarget: StateSetter<Vector3>;
    setNormal: StateSetter<Vector3>;
    setDefaultTarget: StateSetter<Vector3 | null>;
    setDefaultNormal: StateSetter<Vector3 | null>;
    setDefaultDepth: () => void;
    resetSectionTargetToDefault: () => void;
    setVisibility: StateSetter<boolean>;
  };

  treeMetrics: {
    height: number;
    canopyHeight: number;
    firstBifurcationPoint: Vector3;
    canopy: EllipseWithNormalAndHeight;
    leaningVector: { leanVector: Vector3 | null; startVector: Vector3 | null };
    trunks: TrunkWithGirths[];
    numberOfStems: number;
    setHeight: StateSetter<number>;
    setCanopyHeight: StateSetter<number>;
    setFirstBifurcationPoint: StateSetter<Vector3>;
    setCanopy: StateSetter<EllipseWithNormalAndHeight>;
    setLeaningVector: StateSetter<{ leanVector: Vector3 | null; startVector: Vector3 | null }>;
    setNumberOfStems: StateSetter<number>;
  };

  trunkActions: {
    shownGirth: Girth;
    largestTrunk: TrunkWithGirths;
    isRemoveGirthActionValid: boolean;
    setShownGirth: StateSetter<Girth>;
    // addTreeTrunk: (trunk: TrunkWithGirths) => void;
    // removeTreeTrunk: (trunkId: string) => void;
    // addGirthToTrunk: (trunkId: string, girth: Girth) => void;
    removeGirth: (trunkId: string, girth: Girth) => void;
    updateGirth: (girth: Girth) => void;
  };

  visibility: {
    height: boolean;
    canopyHeight: boolean;
    firstBifurcationPoint: boolean;
    girth: boolean;
    canopy: boolean;
    leaningVector: boolean;
    setHeight: StateSetter<boolean>;
    setCanopyHeight: StateSetter<boolean>;
    setFirstBifurcationPoint: StateSetter<boolean>;
    setGirth: StateSetter<boolean>;
    setCanopy: StateSetter<boolean>;
    setLeaningVector: StateSetter<boolean>;

    resetToolVisibility: () => void;
  };

  cards: {
    toasts: Toast[];
    addToast: (message: string, type?: ToastType, timeout?: number) => void;
    alerts: AttributeCardAlert[];
    setAlerts: React.Dispatch<React.SetStateAction<AttributeCardAlert[]>>;
  };

  validation: {
    areAttributesCompleted: boolean;
    isTreeHeightCompleted: boolean;
    isCrownHeightCompleted: boolean;
    isFirstBifurcationCompleted: boolean;
    isFirstBifurcationMidPointCompleted: boolean;
    isCanopyCompleted: boolean;
    isLeanAngleCompleted: boolean;
    isTrunkDetailsCompleted: boolean;
    setIsTreeHeightCompleted: StateSetter<boolean>;
    setIsCrownHeightCompleted: StateSetter<boolean>;
    setIsFirstBifurcationCompleted: StateSetter<boolean>;
    setIsFirstBifurcationMidPointCompleted: StateSetter<boolean>;
    setIsCanopyCompleted: StateSetter<boolean>;
    setIsLeanAngleCompleted: StateSetter<boolean>;
    setIsTrunkDetailsCompleted: StateSetter<boolean>;

    handleApproveAllActions: () => void;
    handleDeselectAllActions: () => void;
  };

  actions: {
    setActiveTool: StateSetter<ValidationAction | null>;
    setActionCompleted: (action: ValidationAction) => void;
    setDraggedTool: StateSetter<ValidationAction | null>;
    setAzimuthAngle: (angle: number) => number;
    setViewerPosition: StateSetter<Vector3>;
    setLocation: StateSetter<Number3>;
  };

  helper: {
    locationDelta: Vector3;
    treeLocation: Vector3;
    isEditingDisabled: boolean;
    activeTool: ValidationAction | null;
    draggedTool: ValidationAction | null;
    azimuthAngle: React.MutableRefObject<number>
    viewerPosition: Vector3;
    location: Number3;
    convertMetricsToDb: (tree: Tree) => MetricsToDB;
  };
}

export interface SemanticScreenContextProviderProps extends PropsWithChildren {
  currentTree: Partial<Tree> | null;
}

export const SemanticScreenContext = createContext<SemanticScreenContextValue>(null as any);

export const useSemanticScreenContext = () => useContext(SemanticScreenContext);

/**
 * There are components which use either the semantic or the segmentation states,
 *   to prevent from hardcoding these logics and using props, this way we can use the available one on the fly.
 */
export const useScreenContext = (): SemanticScreenContextValue | SegmentationScreenContextValue => {
  const semanticContext = useSemanticScreenContext();
  const segmentationContext = useSegmentationScreenContext();

  return semanticContext ?? segmentationContext;
};

export const SemanticScreenContextProvider = ({ children, currentTree }: SemanticScreenContextProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [alerts, setAlerts] = useState<AttributeCardAlert[]>([]);

  const [activeTool, setActiveTool] = useState<ValidationAction | null>(null);
  const [draggedTool, setDraggedTool] = useState<ValidationAction | null>(null);

  const [viewerPosition, setViewerPosition] = useState(new Vector3());
  const [location, setLocation] = useState<Number3>([0, 0, 0]);

  // CARD RELATES TREE STATES
  const [height, setHeight] = useState<number>(3);
  const [canopyHeight, setCanopyHeight] = useState<number>(1);
  const [firstBifurcationPoint, setFirstBifurcationPoint] = useState<Vector3>(new Vector3());
  const [canopy, setCanopy] = useState<EllipseWithNormalAndHeight>(getDefaultCanopy());
  const [leaningVector, setLeaningVector] = useState<{ leanVector: Vector3 | null; startVector: Vector3 | null }>({
    leanVector: null,
    startVector: null,
  });

  // TRUNK STATES
  const [defaultTrunk, setDefaultTrunk] = useState(getDefaultTrunk(""));
  const [numberOfStems, setNumberOfStems] = useState<number>(0);
  const [trunks, setTrunks] = useState<TrunkWithGirths[]>([defaultTrunk]);
  const [shownGirth, setShownGirth] = useState<Girth>(defaultTrunk.girths[0]); // currently always the largest girth is shown
  const [largestTrunk, setLargestTrunk] = useState<TrunkWithGirths>(defaultTrunk);

  const isRemoveGirthActionValid = shownGirth.local_id !== defaultTrunk.girths[0].local_id;

  useEffect(() => {
    // PRD-654
    // until the multi girth feature is out, when a girth is modified it get's a validated flag
    //   whenever a girth has this, it will be the "largest" girth
    //   this is a fix for cases with multiple trunks. modifying the largest girth to the smallest will not change to the other trunk!
    const validatedGirth = trunks.flatMap(trunk => trunk.girths).filter(girth => girth.validated);

    let actualLargestGirth: Girth;
    if (validatedGirth.length > 0) {
      actualLargestGirth = validatedGirth[0]; // only one girth can have the validated flag
    } else {
      // if no trunk is available, then we use the default one, trunks always have at least one girth. cases:
      //   - the default trunk is created in this way
      //   - when parsing trunks where there's no girth data, a default girth is added (convertTrunkValuesToGirths)
      //   - if there's only one girth in the trunk (the default one), it's checked to not to be removed
      actualLargestGirth = trunks.flatMap(trunk => trunk.girths)
        .reduce((max, girth) => (girth.diameter && max.diameter && girth.diameter > max.diameter ? girth : max));
    }
    setShownGirth(actualLargestGirth);

    // currently we have one girth per trunk, that's why [0] is used
    setLargestTrunk(trunks.find(trunk => trunk?.girths[0]?.local_id === actualLargestGirth?.local_id) as TrunkWithGirths);
  }, [trunks])

  // VISIBILITY STATES
  const [heightVisibility, setHeightVisibility] = useState(true);
  const [canopyHeightVisibility, setCanopyHeightVisibility] = useState(true);
  const [firstBifurcationPointVisibility, setFirstBifurcationPointVisibility] = useState(true);
  const [girthVisibility, setGirthVisibility] = useState(true);
  const [canopyVisibility, setCanopyVisibility] = useState(true);
  const [leaningVectorVisibility, setLeaningVectorVisibility] = useState(true);
  const [sectionVisibility, setSectionVisibility] = useState(false);

  // COMPLETION STATES OF REVIEWED VALUES
  const [isTreeHeightCompleted, setIsTreeHeightCompleted] = useState(false);
  const [isCrownHeightCompleted, setIsCrownHeightCompleted] = useState(false);
  const [isFirstBifurcationCompleted, setIsFirstBifurcationCompleted] = useState(false);
  const [isFirstBifurcationMidPointCompleted, setIsFirstBifurcationMidPointCompleted] = useState(false);
  const [isCanopyCompleted, setIsCanopyCompleted] = useState(false);
  const [isLeanAngleCompleted, setIsLeanAngleCompleted] = useState(false);
  const [isTrunkDetailsCompleted, setIsTrunkDetailsCompleted] = useState(false);

  // POINTCLOUD STATES
  const [depth, setDepth] = useState(DEFAULT_SECTION_DEPTH);
  const [lookAt, setLookAt] = useState<Vector3 | null>(null);
  const [lookFrom, setLookFrom] = useState<Vector3 | null>(null);
  const [target, setTarget] = useState(viewerPosition || new Vector3());
  const [normal, setNormal] = useState(new Vector3(0, 0, 1));
  const [defaultTarget, setDefaultTarget] = useState<Vector3 | null>(null);
  const [defaultNormal, setDefaultNormal] = useState<Vector3 | null>(null);
  const setDefaultDepth = () => setDepth(DEFAULT_SECTION_DEPTH);
  const resetSectionTargetToDefault = () => {
    setTarget(new Vector3(0, 0, 0));
    setNormal(new Vector3(0, 0, 1));
  };
  const azimuthAngle = useRef<number>(0);

  const setAzimuthAngle = (angle: number) => (azimuthAngle.current = angle);

  const addToast = (message: string, type = ToastType.INFO, timeout = 5000) => {
    setToasts([...toasts, { message, type }]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.slice(1));
    }, timeout);
  };

  const isEditingDisabled = currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone;

  const areAttributesCompleted =
    isTreeHeightCompleted &&
    isCrownHeightCompleted &&
    isFirstBifurcationCompleted &&
    isFirstBifurcationMidPointCompleted &&
    isCanopyCompleted &&
    // isLeanAngleCompleted &&
    isTrunkDetailsCompleted;

  const pointCloudModule = usePointCloud({ currentTree: currentTree as Tree });

  const setAllActionsTo = (value: boolean) => {
    setIsTreeHeightCompleted(value);
    setIsCrownHeightCompleted(value);
    setIsFirstBifurcationCompleted(value);
    setIsFirstBifurcationMidPointCompleted(value);
    setIsCanopyCompleted(value);
    // setIsLeanAngleCompleted(value);
    setIsTrunkDetailsCompleted(value);
  };

  const handleApproveAllActions = () => setAllActionsTo(true);
  const handleDeselectAllActions = useCallback(() => setAllActionsTo(false), []);

  const setAllVisibilityTo = (value: boolean) => {
    setHeightVisibility(value);
    setCanopyHeightVisibility(value);
    setFirstBifurcationPointVisibility(value);
    setGirthVisibility(value);
    setCanopyVisibility(value);
    setLeaningVectorVisibility(value);
  };

  const resetToolVisibility = () => setAllVisibilityTo(true);

  const setActionCompleted = (action: ValidationAction) => {
    switch (action) {
      case ValidationAction.Height:
        return setIsTreeHeightCompleted(true);
      case ValidationAction.CanopyHeight:
        return setIsCrownHeightCompleted(true);
      case ValidationAction.FirstBifurcationPoint:
        setIsFirstBifurcationCompleted(true);
        setIsFirstBifurcationMidPointCompleted(true);
        return;
      case ValidationAction.Canopy:
        return setIsCanopyCompleted(true);
      /*         case ValidationAction.LeaningVector:
              return setIsLeanAngleCompleted(true); */
      case ValidationAction.Girth:
        return setIsTrunkDetailsCompleted(true);
      default:
        return;
    }
  };

  const loadTreeStatus = (tree: Partial<Tree> | null) => {
    setHeight(typeof tree?.height === 'number' ? tree.height : 3);
    setCanopyHeight(typeof tree?.crown_height === 'number' ? tree.crown_height : 1);
    if (tree?.first_bifurcation_point) {
      const { coordinates } = JSON.parse(tree?.first_bifurcation_point as unknown as string);
      setFirstBifurcationPoint(new Vector3(...coordinates));
    } else {
      setFirstBifurcationPoint(new Vector3());
    }
    setCanopy({ ...getDefaultCanopy(), ...parseCanopy(tree as Tree) });
    setLeaningVector({
      leanVector: null, // don't see related data in tree, only lean_direction
      startVector: null, //   and lean_direction is not used in the codebase
    });
    setDepth(DEFAULT_SECTION_DEPTH);
    setTarget(new Vector3(viewerPosition.x, viewerPosition.y, tree?.trunk_height || 1));
    setNumberOfStems(!currentTree?.number_of_stems ? 0 : currentTree.number_of_stems);

    const newDefaultTrunk = getDefaultTrunk(tree?.id as string);
    setDefaultTrunk(newDefaultTrunk);
    setTrunks(tree?.tree_trunks?.length ? transformToTrunkWithGirths(tree.tree_trunks) : [newDefaultTrunk]);
  };

  useEffect(() => {
    handleDeselectAllActions();
    setAlerts([]);
    setActiveTool(ValidationAction.Height);
    loadTreeStatus(currentTree);
    setAllVisibilityTo(true);
  }, [currentTree, handleDeselectAllActions]);

  const locationDelta = useMemo(() => {
    const { pointCloud } = pointCloudModule;

    if (!currentTree?.id || !currentTree?.location_local || !pointCloud?.pc?.mins) return new Vector3();
    const mins = new Vector3().fromArray(pointCloud?.pc?.mins);

    const treeLocation = new Vector3().fromArray(currentTree.location_local);

    const initialPosition = mins.clone().sub(treeLocation?.clone() || new Vector3());

    return initialPosition;
  }, [currentTree?.id, currentTree?.location_local, pointCloudModule]);

  const treeLocation = useMemo(() => {
    const { pointCloud } = pointCloudModule;

    if (!currentTree?.id || !currentTree?.location_local || !pointCloud?.pc?.mins) return new Vector3();

    const treeLocation = new Vector3().fromArray(currentTree.location_local);

    return treeLocation.clone();
  }, [currentTree?.id, currentTree?.location_local, pointCloudModule]);

  // currently only once girth is available per trunk

  // const addGirthToTrunk = (trunkId: string, girth: Girth) => {
  //   const index = trunks.findIndex((t) => t.local_id === trunkId);
  //   const newTrunks = [...trunks];
  //   newTrunks[index].girths.push(girth);
  //   setTrunks(newTrunks);
  // };

  // currently trunks can not be added

  // const addTreeTrunk = (trunk: TrunkWithGirths) => {
  //   const newTrunks = [...trunks];
  //   newTrunks.push(trunk);
  //   setTrunks(newTrunks);
  // };

  const removeGirth = (trunkId: string, girth: Girth) => {
    const index = trunks.findIndex((t) => t.local_id === trunkId);
    trunks[index].girths = trunks[index].girths.filter((g) => g.local_id !== girth.local_id);
    const allGirths = trunks.flatMap((trunk) => trunk.girths);

    if (allGirths.length === 0) {
      trunks[index].girths.push(defaultTrunk.girths[0]);
    }
    setTrunks([...trunks]);
  };

  const updateGirth = (newGirth: Girth) => {
    for (let index = 0; index < trunks.length; index++) {
      const trunk = trunks[index];
      const girthIndex = trunk.girths.findIndex((g) => g.local_id === newGirth.local_id);
      if (girthIndex >= 0) {
        trunk.girths[girthIndex] = { ...newGirth, diameter: calculateGirth(newGirth), validated: true };
        break;
      }
    }
    setTrunks([...trunks]);
  };

  const convertMetricsToDb = (tree: Tree): MetricsToDB => {
    const locationVector = new Vector3().fromArray(location);
    const localFirstBifPoint = new Vector3().copy(firstBifurcationPoint).sub(viewerPosition);
    const worldFirstBifPoint = new Vector3().copy(localFirstBifPoint).add(locationVector);

    return {
      // currently the shownGirth is also the largest girth
      ...(shownGirth && {
        girth_1_0m: calculateGirth(shownGirth),
        girth_1_0m_offset_x: shownGirth.dX,
        girth_1_0m_offset_y: shownGirth.dY,
        girth_1_0m_ellipse_a: shownGirth.rX,
        girth_1_0m_ellipse_b: shownGirth.rY,
        girth_1_0m_ellipse_direction: shownGirth.rotation,
      }),
      are_girth_axes_valid: true,
      height: Number(height) - viewerPosition.z,
      crown_height: Number(canopyHeight),
      trunk_height: localFirstBifPoint.z,
      canopy_ellipse_a: canopy.rX,
      canopy_ellipse_b: canopy.rY,
      canopy_ellipse_direction: radToDeg(canopy.rotation),
      crown_excentricity: [canopy.dX, canopy.dY],
      first_bifurcation_point: convertXYZToPostGisPoint(worldFirstBifPoint),
      status: tree.status,
      tree_flow_status: tree.tree_flow_status,
      trunks: trunks,
      number_of_stems: numberOfStems,
    };
  };

  const value: SemanticScreenContextValue = {
    pointCloudModule,

    section: {
      depth,
      lookAt,
      lookFrom,
      target,
      normal,
      defaultTarget,
      defaultNormal,
      visibility: sectionVisibility,
      setDepth,
      setLookAt,
      setLookFrom,
      setTarget,
      setNormal,
      setDefaultTarget,
      setDefaultNormal,
      setDefaultDepth,
      resetSectionTargetToDefault,
      setVisibility: setSectionVisibility
    },

    treeMetrics: {
      height,
      canopyHeight,
      firstBifurcationPoint,
      canopy,
      leaningVector,
      trunks,
      numberOfStems,
      setHeight,
      setCanopyHeight,
      setFirstBifurcationPoint,
      setCanopy,
      setLeaningVector,
      setNumberOfStems,
    },

    trunkActions: {
      shownGirth,
      largestTrunk,
      isRemoveGirthActionValid,
      setShownGirth,
      // addTreeTrunk,
      // addGirthToTrunk,
      removeGirth,
      updateGirth,
    },

    visibility: {
      height: heightVisibility,
      canopyHeight: canopyHeightVisibility,
      firstBifurcationPoint: firstBifurcationPointVisibility,
      girth: girthVisibility,
      canopy: canopyVisibility,
      leaningVector: leaningVectorVisibility,
      setHeight: setHeightVisibility,
      setCanopyHeight: setCanopyHeightVisibility,
      setFirstBifurcationPoint: setFirstBifurcationPointVisibility,
      setGirth: setGirthVisibility,
      setCanopy: setCanopyVisibility,
      setLeaningVector: setLeaningVectorVisibility,

      resetToolVisibility,
    },

    cards: {
      toasts,
      addToast,
      alerts,
      setAlerts,
    },

    validation: {
      areAttributesCompleted,
      isTreeHeightCompleted,
      isCrownHeightCompleted,
      isFirstBifurcationCompleted,
      isFirstBifurcationMidPointCompleted,
      isCanopyCompleted,
      isLeanAngleCompleted,
      isTrunkDetailsCompleted,
      setIsTreeHeightCompleted,
      setIsCrownHeightCompleted,
      setIsFirstBifurcationCompleted,
      setIsFirstBifurcationMidPointCompleted,
      setIsCanopyCompleted,
      setIsLeanAngleCompleted,
      setIsTrunkDetailsCompleted,

      handleApproveAllActions,
      handleDeselectAllActions,
    },

    actions: {
      setActiveTool,
      setActionCompleted,
      setDraggedTool,
      setAzimuthAngle,
      setViewerPosition,
      setLocation,
    },

    helper: {
      locationDelta,
      treeLocation,
      isEditingDisabled,
      activeTool,
      draggedTool,
      azimuthAngle,
      viewerPosition,
      location,
      convertMetricsToDb,
    },
  };

  return <SemanticScreenContext.Provider value={value}>{children}</SemanticScreenContext.Provider>;
};
