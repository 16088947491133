import { useState } from 'react';
import { Header, VerticalTabs } from '../components';
import { Route } from 'react-router';
import { useUser } from '../providers/user';
import { withRouter } from 'react-router-dom';
import HeaderBar from '../components/HeaderBar/HeaderBar';

const MICROCLIMATE_VALIDATION_VIEW = /.*\/(microclimate-input)\/.*\d\/(validation)\//;
const SEMANTIC_VALIDATION_VIEW = /.*\/(validation)\/.*\d\/(semantics)\//

const isTreeValidationViewWithStatusBar = (path) => {
  if (MICROCLIMATE_VALIDATION_VIEW.test(path)) return true;
  if (SEMANTIC_VALIDATION_VIEW.test(path)) return true;
  return false;
};

const Skeleton = ({ children, tabs = [], header = {}, location, managedArea, phases }) => {
  const { user } = useUser();
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);

  const isTreeView = isTreeValidationViewWithStatusBar(location.pathname);

  return (
    /*TODO temp removed new header, should clarify which one neededd*/
/*
    <div className={`skeleton-outer-wrapper ${header && header.useNewHeader ? 'new-header' : ''}`}>
*/
    <div className={`skeleton-outer-wrapper new-header`}>
        <HeaderBar
          {...header}
          isStatusBarAvailable={isTreeView}
          isStatusBarVisible={isStatusBarVisible}
          setIsStatusBarVisible={setIsStatusBarVisible}
          user={user}
        />
      <div className={`skeleton-wrapper ${!header.navTabs?.length && tabs.length ? 'tabs-visible' : 'tabs-hidden'}`}>
        {!header.useNewHeader && tabs?.length > 0 && <VerticalTabs tabs={tabs} />}
        <div className={`skeleton-content ${header.navTabs?.length ? 'full-height' : ''}`}>
          {tabs
            .filter((tab) => tab && tab.path && tab.component)
            .map((route) => (
              <Route key={route.path} path={route.path} exact={[...(route.path || '')].pop() === '/'} component={route.component} />
            ))}
          {children}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Skeleton);
