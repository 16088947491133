import { useEffect, useMemo, useState } from "react";

type Props = {
  placeholder?: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  outputConverter?: (value: number) => number;
  inputConverter?: (value: string) => number;
  customeStyle?: React.CSSProperties;
};

export const PlainNumberInput = ({
  placeholder,
  value,
  onChange,
  min,
  max,
  step,
  prefix,
  inputConverter,
  outputConverter,
  customeStyle,
}: Props) => {
  const minValue: number = min && !Number.isNaN(min) ? min : 0;
  const maxValue: number = max && !Number.isNaN(max) ? max : Number.POSITIVE_INFINITY;

  // in case of 0 and than 0000 input, the prop remained 0 => handle the shown state in component
  const [inputValue, setInputValue] = useState(`${outputConverter ? outputConverter(value) : value}`);

  const getPrecision = (num: number) => `${num}`.includes('.') ? `${num}`.split('.')[1].length : 0;
  const neededPrecision = useMemo(() => getPrecision(step ?? 0.1), [step]);

  // helpers
  const removeLeadingZeros = (numberString: string) => numberString.startsWith("0.") ? numberString : numberString.replace(/^0+(?!$)/, '');
  const removeTrailingZerosAfterPrecision = (numberStr: string, precision: number) => {
    const regex = new RegExp(`^(\\d+)(\\.\\d{0,${precision}})?`);
    const match = numberStr.match(regex);
    return match ? match[0] : numberStr;
  }
  const handleCommaPrecision = (numberString: string) => numberString.startsWith('.') ? numberString.substring(0, 3) : numberString;
  const formatNumber = (numberStr: string) => handleCommaPrecision(removeLeadingZeros(removeTrailingZerosAfterPrecision(numberStr, neededPrecision)))

  useEffect(() => {
    if (!!value && value !== Number(inputValue)) setInputValue(`${value}`);
  }, [value]);

  return (
    <>
      {prefix && <span>{prefix}</span>}
      <input
        className='reset-input number-input'
        style={customeStyle}
        placeholder={placeholder ?? '?'}
        type='number'
        step={step ?? 1}
        value={inputValue}
        min={minValue}
        max={maxValue}
        onChange={(e) => {
          const val = inputConverter ? inputConverter(e.target.value) : parseFloat(e.target.value);
          if (val > maxValue || val < minValue) return;
          setInputValue(formatNumber(e.target.value));
          onChange(val);
        }}
      />
    </>
  );
};
