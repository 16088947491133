import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';

import { handleRequest } from '../hooks/api';
import appConfig from '../config';

const isAd = !['1', 'true'].includes(appConfig.auth.disableAD);

const STORAGE_KEY = `@api_key_v2.1_${isAd ? 'AD' : 'GENERAL'}`;

export const Context = createContext({});

// Access to context
export const useUser = () => useContext(Context);
export const withUser = (Component) => (props) => (
  <Context.Consumer>
    {(user) => <Component {...props} user={user} />}
  </Context.Consumer>
);

export const Provider = (props) => {
  const [user, setUser] = useState(null);
  const [config, setConfig] = useState({
    token: localStorage.getItem(STORAGE_KEY) || null,
  });
  const [rtmsToken, setRTMSToken] = useState(null);

  const _handleSetApitoken = (token) => {
    setConfig({ ...config, token });

    if (!token) {
      setUser(prev => ({ lastLogin: prev.lastLogin }));
      setRTMSToken(null);
    }

    if (token) localStorage.setItem(STORAGE_KEY, token);
    else localStorage.removeItem(STORAGE_KEY);
  };

  const { instance, accounts } = useMsal();

  const _handleLoad = () => {
    if (accounts.length > 0) {
      setUser(prev => ({ ...(accounts[0]), lastLogin: prev?.lastLogin }));

      _handleLoadUser(config.token).then(loadedUser => {
        if (loadedUser.role) setUser(prev => ({ ...(accounts[0] || {}), ...loadedUser, lastLogin: prev?.lastLogin }));
      });

      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [
            appConfig.auth.scope,
          ],
        })
        .then((response) => {
          if (response.accessToken) _handleSetApitoken(response.accessToken);
          return null;
        })
        .catch((error) => {
          try {
            var username = accounts[0]?.username;
            var currentAccount = instance.getAccountByUsername(username);
            var silentRequest = {
              scopes: ['Mail.Read'],
              account: currentAccount,
              forceRefresh: false,
            };
            instance
              .acquireTokenSilent(silentRequest)
              .then((response) => {
                if (response.accessToken)
                  _handleSetApitoken(response.accessToken);
              })
              .catch((error) => {
                _handleSetApitoken(null);
              });
          } catch (error) {
            console.error('Failed to retreive api token', error);
          }
        });
    } else {
      setConfig({ ...config, token: null });
    }
  }

  useEffect(() => {
    let interval
    if (isAd) {
      _handleLoad();
      interval = setInterval(() => _handleLoad(), 5 * 60 * 1000);
    }
    else _handleLoadUser(config.token).then(user => {
      if (user.role) setUser(prev => ({ ...user, lastLogin: prev?.lastLogin }));
    });

    return () => clearInterval(interval);    
  }, [accounts.length, config.token]);

  const _handleLoadUser = async (token) => {
    const user = await handleRequest(token, rtmsToken)(`/v1/user`).then((res) =>
      res.json()
    );
    return user;
  };

  const intervalRef = useRef(null);

  const _handleUserLogin = async (token) => {

    const loginTime = new Date().toISOString();
    const bodyString = JSON.stringify({ login_time: loginTime }); 

    const response = await handleRequest(token || config.token, rtmsToken)(`/v1/user`, { 
      method: 'PATCH',
      body: bodyString,
      headers: { 'Content-Type': 'application/json' }
    });

    const resp = await response.json();

    setUser(prev => ({ ...prev, lastLogin: resp?.lastLogin }));
    setRTMSToken(resp?.rtmsToken);
  };

  const _handleLogout = () => {
    instance.logout();
    _handleSetApitoken(null);
  }

  const _hasRole = (role) => {
    return user?.roles?.includes(role);
  }

  const _hasAnyRole = (roles) => {
    for(let i = 0; i < roles.length; i++) {
      if (user?.roles?.includes(roles[i])) return true;
    }
    return false;
  }

  return (
    <Context.Provider
      value={{ 
        ...config, 
        setApiToken: _handleSetApitoken, user, 
        rtmsToken, 
        loginUser: _handleUserLogin, 
        logout: _handleLogout,
        hasRole: _hasRole, 
        hasAnyRole: _hasAnyRole, 
        }}>
      {props.children}
    </Context.Provider>
  );
};
