import { Button, TextInput } from '../inputs';
import React, { useState } from 'react';

const Proximity = ({ onCancel, getProximity }) => {
  const [distance, updateDistance] = useState('30');

  return (
    <div className="proximity-modal">
      <span>Set the distance (in cm) to get the possible duplications:</span>
      <TextInput type="number" value={distance} onChange={({value}) => updateDistance(value)} />
      <div className="button-wrapper">
        <Button destructive label='Cancel' onClick={onCancel}/>
        <Button primary label='Get possibly wrong tree locations' onClick={() => getProximity(distance)}/>
      </div>
    </div>
  )
};

export default Proximity