import { useMemo } from 'react';
import { Color } from 'three';
import { useTheme } from '../../../providers/theme';

const ViewBackground = () => {
  const { isDark } = useTheme();
  const bgColor = useMemo(() => (isDark ? new Color(0x000000) : new Color(0xffffff)), [isDark]);

  return <color attach='background' args={[bgColor.r, bgColor.g, bgColor.b]} />;
};

export default ViewBackground;
