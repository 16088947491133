import { useMemo, useState } from 'react';
import Tree from '../../@types/Tree';
import * as _ from 'lodash';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

export type TodoStatus =
  | TreeFlowStatus.LocationValidationQueued
  | TreeFlowStatus.SegmentationValidationQueued
  | TreeFlowStatus.MeasurementValidationQueued
  | TreeFlowStatus.SentToOnlineAnnotationQueued;

export interface GetManuallySelectedTreeProps {
  selectedTreeId: string;
  trees: Partial<Tree>[];
}

export interface BetaTreeIndexValue {
  relevantTrees: Partial<Tree>[];
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectTree: (selectedTreeId: string) => void;
  moveToNeighbour: (direction?: number) => {
    index: number;
    nextIndex: number;
    treeId: string | undefined;
  };
}

export interface UseBetaTreeIndexProps {
  trees: Partial<Tree>[];
  selectedTreeId: string;
  todoStatuses?: TodoStatus[];
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
  proximityAlertTreeIDs?: string[];
  sortByProximityFirst?: boolean;
  useTodoTreesOnly?: boolean;
  isMicroclimate?: boolean;
  skippedTrees?: string[];
}

export const useBetaTreeIndex = ({
  trees,
  selectedTreeId,
  todoStatuses,
  setTree,
  proximityAlertTreeIDs,
  sortByProximityFirst = false,
  useTodoTreesOnly = true,
  isMicroclimate = false,
  skippedTrees,
}: UseBetaTreeIndexProps): BetaTreeIndexValue => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const relevantTrees = useMemo(() => {
    let rTrees: Partial<Tree>[] = trees || [];

    if (isMicroclimate) {
      rTrees = trees.filter((tree) => !tree.microclimate?.completed);
    } else if (useTodoTreesOnly && todoStatuses?.length) {
      rTrees = trees.filter((tree) => tree?.tree_flow_status && todoStatuses.includes(tree.tree_flow_status as TodoStatus));
    }

    if (sortByProximityFirst && proximityAlertTreeIDs) {
      rTrees = [
        ...rTrees.filter((it) => it?.id && proximityAlertTreeIDs.includes(it.id)),
        ...rTrees.filter((it) => it?.id && !proximityAlertTreeIDs.includes(it.id)),
      ];
    }

    return rTrees;
  }, [useTodoTreesOnly, todoStatuses?.length, trees, sortByProximityFirst, proximityAlertTreeIDs, isMicroclimate]);


  const moveToNeighbour = (direction = 1) => {
    let rTrees = _.cloneDeep(relevantTrees);

    console.log("rTrees1", rTrees);
    console.log("skippedTrees", skippedTrees);

    if (direction === 1 && skippedTrees?.length && skippedTrees?.length !== relevantTrees?.length) {
      rTrees = rTrees.filter((t) => !skippedTrees?.includes(t?.id as string));
    } else rTrees = _.cloneDeep(relevantTrees);

    console.log("rTrees2", rTrees);

    console.log("currentIndex", currentIndex);

    const index = rTrees.findIndex((tree) => tree.id === selectedTreeId);

    console.log("index", index);

    const nextIndex = index !== null && index !== -1 ? (index + direction + rTrees.length) % rTrees.length : 0;

    console.log("nextIndex", nextIndex);

    const treeId = rTrees[nextIndex]?.id;

    console.log("treeId", treeId);

    console.log("nextTree: ", rTrees[nextIndex])

    setCurrentIndex(nextIndex);
    setTree(rTrees[nextIndex]);

    return { index, nextIndex, treeId };
  };

  const selectTree = (selectedTreeId: string) => {
    const index = relevantTrees.findIndex((tree) => Number(tree.id) === Number(selectedTreeId));

    if (index > -1) {
      setCurrentIndex(index);
      setTree(relevantTrees[index]);
    }
  };

  return {
    relevantTrees,

    setCurrentIndex,
    selectTree,
    moveToNeighbour,
  };
};
