import { Lens } from "@dhmk/zustand-lens";
import produce from "immer";
import ReclassEvent from "../@types/ReclassEvent";
import TreePointCloud from "../@types/TreePointCloud";
import appConfig from "../config";
import ReclassTool from "../enums/ReclassTool";
import { handleRequest } from "../hooks/api";

export type ReclassifySlice = {
  tool: ReclassTool;
  brushRadius: number;
  points: THREE.Intersection[];
  eventStack: ReclassEvent[];
  source: number;
  target: number;

  actions: {
    setTool: (tool: ReclassTool) => void;
    setBrushRadius: (radius: number) => void;
    setPoints: (points: THREE.Intersection[]) => void;
    saveLAZ: (token: string, setLasAsOriginal: () => void) => void;
    handleUndo: (
      las: TreePointCloud | null,
      originalLas: TreePointCloud | null,
      setLas: (las: TreePointCloud | null) => void
    ) => void;
    setSource: (source: number) => void;
    setTarget: (target: number) => void;
    pushToEventStack: (data: ReclassEvent) => void;
    reset: () => void;
  };
};

const createReclassifySlice: Lens<ReclassifySlice> = (set, get) => ({
  tool: ReclassTool.Brush,

  brushRadius: 12,

  points: [],

  eventStack: [],

  source: 20,

  target: 21,

  actions: {
    setTool: (tool: ReclassTool) => {
      if (tool === get().tool) return;
      set({ tool: tool, points: [] });
    },

    setBrushRadius: (radius) => set({ brushRadius: radius }),

    setPoints: (points: THREE.Intersection[]) => set({ points: points }),

    // TODO: Create custom hook from this and SemanticValidation._handleSaveLAZ function
    saveLAZ: async (token: string, setLasAsOriginal: () => void) => {
      alert('Yoooo 2');

      // TODO Get these from Store
      const selection = "DummySelectionCode";
      const treeId = 0;

      const payload = {
        lastUrl: `tasks/${selection}/tree_segmentation_clips/${treeId}.laz`, // TODO
        blobName: `${treeId}.laz`,
        changes: get().eventStack.map((rcs) => rcs.changes),
      };

      try {
        const response = await handleRequest(token, null)(`/v1/trees/las`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: { "Content-Type": "application/json" },
        });

        const content = await response.json();
        const isError = !content || Object.keys(content).length === 0;
        if (isError) throw new Error("Could not save LAZ file!");

        set({ points: [], eventStack: [] });
        setLasAsOriginal();
        //TODO showToast("LAZ file saved successfully!");
      } catch (err) {
        //TODO showToast("Failed to save LAZ file!", "error");
        console.error("Error during LAZ update:", err);
      }
    },

    setSource: (source: number) => set({ source }),

    setTarget: (target: number) => set({ target }),

    handleUndo: (
      las: TreePointCloud | null,
      originalLas: TreePointCloud | null,
      setLas: (las: TreePointCloud | null) => void
    ) => {
      let geometry;

      if (get().points.length) {
        set({ points: get().points.slice(0, -1) });
      } else {
        switch (get().eventStack.length) {
          case 0:
            return;
          case 1:
            geometry = originalLas?.geometry;
            break;
          default:
            geometry = get().eventStack[get().eventStack.length - 2]?.geometry;
        }

        //@ts-ignore
        setLas({ ...las, geometry });
        // set({
        //   las: { ...las, geometry },
        // });
        set({ eventStack: get().eventStack.slice(0, -1) });
      }
    },

    pushToEventStack: (data: ReclassEvent) => {
      set((state) =>
        produce(state, (draft) => {
          draft.eventStack.push(data);
          if (
            draft.eventStack.length &&
            draft.eventStack.length > appConfig.maxUndo
          ) {
            draft.eventStack.shift();
          }
        })
      );
    },

    reset: () => {
      set({ points: [], eventStack: [] });
      //TODO dismissModal
    },
  },
});

export default createReclassifySlice;
