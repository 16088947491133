import { IconElementParams } from '../../@types/Icon';

const Invalid = ({ width = 16, height = 16, color }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8.14294' cy='8.5' r='8' fill={color ?? '#FF0000'} />
      <path
        d='M9.46295 8.50026L11.9379 6.02535C12.2113 5.7519 12.2113 5.30856 11.9379 5.03554L11.6079 4.7056C11.3344 4.43206 10.891 4.43206 10.618 4.7056L8.1432 7.18042L5.66829 4.70509C5.39484 4.43164 4.95151 4.43164 4.67848 4.70509L4.34803 5.03503C4.07458 5.30856 4.07458 5.7519 4.34803 6.02492L6.82337 8.50026L4.34855 10.9751C4.07501 11.2486 4.07501 11.692 4.34855 11.965L4.67848 12.2949C4.95193 12.5684 5.39527 12.5684 5.66829 12.2949L8.1432 9.82L10.618 12.2949C10.8916 12.5684 11.3349 12.5684 11.6079 12.2949L11.9379 11.965C12.2113 11.6914 12.2113 11.2481 11.9379 10.9751L9.46295 8.50026Z'
        fill='white'
      />
    </svg>
  );
};

export default Invalid;
