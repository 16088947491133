const ExclamationMark = ({ width = 20, height = 20, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99996 5.83334V10.8333M9.99996 14.175L10.0083 14.1658M9.99996 18.3333C14.6025 18.3333 18.3333 14.6025 18.3333 10C18.3333 5.39751 14.6025 1.66667 9.99996 1.66667C5.39746 1.66667 1.66663 5.39751 1.66663 10C1.66663 14.6025 5.39746 18.3333 9.99996 18.3333Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ExclamationMark;
