import React from 'react';
import Shape from './Shape';

const Crown = ({ data }) => {
  return (
    <Shape
      data={data}
      materialProps={{ color: 'green', opacity: 0.7, transparent: true }}
    />
  );
};

export default Crown;
