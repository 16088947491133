import LineSlider from './LineSlider';

type PointSizeSliderProps = {
  pointSize?: number;
  setPointSize?: React.Dispatch<React.SetStateAction<number>>;
};

const PointSizeSlider = ({ pointSize = 1, setPointSize }: PointSizeSliderProps) => {
  return (
    <div>
      <div className='slider-label'>
        <span>Point size</span>
        <span>{pointSize} pt</span>
      </div>

      <LineSlider value={pointSize} onChange={(value) => setPointSize && setPointSize(prev => prev = value)} min={0.5} max={3} step={0.5} sx={{ padding: '7px' }} />
    </div>
  );
};

export default PointSizeSlider;
