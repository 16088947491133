import {
  BackSide,
  DoubleSide,
  Group,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  Texture,
  Vector3
} from 'three';
import { PanoramaImageSphere } from './panorama-image-sphere';
import { sortBy } from 'lodash';
import { generateUUID } from 'three/src/math/MathUtils';

export interface LoadOptions {
  image: PanoramaImageSphere;
  token: string;
}

export class SpherePanoramaObject extends Group {
  public image: PanoramaImageSphere | undefined
  public layerMeta: any;
  public token: string = '';
  public session: string = '';
  public items: Mesh[] = [];

  private signal: AbortController = new AbortController();

  public async createImageLoader(url: string, token: string) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)

    const response = await fetch(url, {
      headers,
      signal: this.signal.signal
    })

    const blob = await response.blob();

    const img = new Image();
    img.src = URL.createObjectURL(blob)

    const texture = new Texture();
    texture.image = img;
    texture.needsUpdate = true;

    return texture;
  }

  public createCamParams() {
    if (!this.image) return;

    const vectors = this.image.createTileVectors(0.000001);

    // @ts-ignore
    const [a, b, c, d] = vectors;
    const ca = a.clone().sub(c.clone()).normalize();
    const cd = d.clone().sub(c.clone()).normalize();
    const cross = ca.clone().cross(cd.clone());
    const center = a.clone().add(d.clone()).divideScalar(2);

    return { center, cross, a, b, c, d };
  }

  private async downloadLayerMetadata(options: LoadOptions) {
    try {
      const headers = new Headers()
      headers.set('Authorization', `Bearer ${options.token}`)

      const jsonUrl = options.image.url
        .replace('panoramas', 'panorama-tiles')
        .replace(/\.(jpeg|jpg)/, '/tiles.json')

      const response = await fetch(jsonUrl, {
        headers,
        signal: this.signal.signal
      })

      if (!response.ok) {
        return null
      }

      return await response.json();
    } catch (err) {
      return null
    }
  }

  public async load(options: LoadOptions) {
    this.signal = new AbortController();

    this.session = generateUUID();
    const currentSession = this.session;

    this.image = options.image;
    this.token = options.token;
    this.layerMeta = await this.downloadLayerMetadata(options);

    const pc = this.createCamParams();

    if (!pc) return;

    this.scale.x = -1;
    const centerOfCamZero = pc.cross.clone();

    this.up.fromArray(options.image.directionVector.clone().normalize().cross(options.image.upVector.clone().normalize()).toArray())
    this.lookAt(centerOfCamZero.clone());
    //this.rotateX(MathUtils.degToRad(9.4));

    /*for MLS images*/
    if(this.up.dot(new Vector3(0,0,1)) < 0){
      this.rotateZ(MathUtils.degToRad(180));
    }

    this.layerMeta
      ? await this.loadLayers(currentSession)
      : await this.loadFallback(currentSession)
  }

  public async loadLayers(currentSession: string) {
    const oL = sortBy(this.layerMeta.layers, (item) => -item.layer)

    return Promise.all(oL.map((layer: any) => {
      const deltaRotationX = ((Math.PI * 2) / layer.nx);
      const deltaRotationY = ((Math.PI) / layer.ny);

      return Promise.all(layer.items.map(async (item: any) => {
        const prefix = this.image?.url
          ?.replace('panoramas', 'panorama-tiles')
          .replace(/\.(jpeg|jpg)/, '/')

        const url = `${prefix}${item.path}`
        const texture = await this.createImageLoader(url, this.token)

        if (currentSession !== this.session) return;

        const geometry = new SphereGeometry(
          100 + layer.layer,
          100,// layer.nx * 100,
          100,// layer.ny * 100,
          Math.PI * (3 / 2) + (deltaRotationX * item.x),
          deltaRotationX,
          (deltaRotationY * item.y),
          deltaRotationY,
        );

        const material = new MeshBasicMaterial({
          map: texture,
          side: BackSide,
          depthTest: false,
          depthWrite: false,
          visible: false
        });

        // This is necessary to prevent blinking (layer fight)
        requestAnimationFrame(() => {
          material.visible = true;
        })

        const mesh = new Mesh(
          geometry,
          material
        )

        this.add(mesh)
      }))

    }))
  }

  public async loadFallback(currentSession: string) {
    const texture = await this.createImageLoader(this.image?.url as string, this.token);

    if (currentSession !== this.session) return;

    const geometry = new SphereGeometry(10, 1000, 1000, Math.PI * (3 / 2));

    const material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      depthTest: false,
      depthWrite: false,
    });

    const mesh = new Mesh(
      geometry,
      material
    );

    this.add(mesh)
  }

  public unload() {
    this.signal.abort();
    this.remove(...this.children)
  }
}
