import * as _ from "lodash";

export interface Props {
  trees: any[];
  history: any;
  currentTreeId: any;
  managedAreaId: string;
  direction: 1 | -1;
  activeTabs: {
    left: string;
    right: string;
  };
  skippedTrees: string[];
}

export const navigateMicroclimateTrees = ({
  trees,
  history,
  currentTreeId,
  managedAreaId,
  direction,
  activeTabs,
  skippedTrees,
}: Props) => {
  if (!/microclimate-input\/\d/.test(window.location.pathname) && !trees?.length) {
    return;
  }

  let filteredTrees = _.cloneDeep(trees);

  if (direction === 1 && skippedTrees?.length && skippedTrees?.length < trees?.length) {
    filteredTrees = filteredTrees.filter(t => !skippedTrees.includes(t?.id));
  } else {
    filteredTrees = _.cloneDeep(trees)
  }

    
  const currentIndex = filteredTrees.findIndex((item: any) => String(item.id) === String(currentTreeId));
  let nextIndex = Math.min(Math.max(0, currentIndex + direction), filteredTrees?.length);
  if (currentIndex === filteredTrees?.length - 1 && direction === 1) {
    nextIndex = 0;
    } else if (currentIndex === 0 && direction === -1) {
      nextIndex = filteredTrees?.length - 1;
      }
        
  const nextTree = filteredTrees?.[nextIndex];

  history.push(`/microclimate-input/${managedAreaId}/validation/${nextTree?.id}?left=${activeTabs.left}&${activeTabs.right}`);
};
