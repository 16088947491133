/** Returns with a sorted tree array, next to the road (capture points), on one side, and backwads on the other.
 *
 * TODO: REFACTOR! Ugly as hell, but works. Tests can ensure this.
 *
 * @param {array} trees
 * @param {array} points
 * @param {boolean} startOnLeftSide
 */
export const sortTrees = (trees = [], points = [], startOnLeftSide = false) => {
  let result;

  if (points.length === 0) {
    result = trees;
  } else if (points.length === 1) {
    const O = points[0].coordinates;

    const treesWithAngles = trees.map((tree) => {
      const X = subtract(tree.coordinates, O);
      return { ...tree, X, angle: (Math.atan2(X[1], X[0]) * 180) / Math.PI };
    });

    treesWithAngles.sort((a, b) => a.angle - b.angle);

    result = treesWithAngles;
  } else {
    const treesWithDistances = assignTreesToPoints(trees, points);
    const treesWithRelativeIds = calculateRelativeIds(treesWithDistances);

    const treesWithSides = treesWithRelativeIds.map((tree) => {
      const currentPointIndex = tree.distances.findIndex(
        (point) => point.id === tree.closestPoint.id
      );
      let isLeftSide = false;

      if (currentPointIndex === 0) {
        const nextPointIndex = 1;
        const A = tree.closestPoint.coordinates;
        const B = tree.distances[nextPointIndex].coordinates;
        const X = tree.coordinates;

        isLeftSide = getSideOnStartPoint(A, B, X);
      } else if (currentPointIndex === tree.distances.length - 1) {
        const prevPointIndex = currentPointIndex - 1;

        const A = tree.closestPoint.coordinates;
        const B = tree.distances[prevPointIndex].coordinates;
        const X = tree.coordinates;

        isLeftSide = !getSideOnStartPoint(A, B, X);
      } else {
        const O = tree.closestPoint.coordinates;
        const A = tree.distances[currentPointIndex - 1].coordinates;
        const B = tree.distances[currentPointIndex + 1].coordinates;
        const X = tree.coordinates;

        const A_ = subtract(A, O);
        const B_ = subtract(B, O);
        const X_ = subtract(X, O);

        let angleA_ = (Math.atan2(A_[1], A_[0]) * 180) / Math.PI;
        const angleB_ = (Math.atan2(B_[1], B_[0]) * 180) / Math.PI;
        let angleX_ = (Math.atan2(X_[1], X_[0]) * 180) / Math.PI;

        if (angleA_ < angleB_) {
          angleA_ += 360;
          angleX_ = angleX_ < 0 ? angleX_ + 360 : angleX_;
        }

        if (angleX_ > angleB_ && angleX_ < angleA_) {
          isLeftSide = true;
        }
      }

      return {
        ...tree,
        isLeftSide,
        relativeId: relIdWithSide(
          tree.relativeId,
          isLeftSide,
          tree.distances.length + 1,
          startOnLeftSide
        ),
      };
    });

    treesWithSides.sort((a, b) => {
      return a.relativeId - b.relativeId;
    });

    result = treesWithSides;
  }

  return result;
};

const relIdWithSide = (relId, side, maxId, startOnLeftSide) =>
  (!side && startOnLeftSide) || (side && !startOnLeftSide)
    ? maxId * 2 - relId
    : relId;

const subtract = (A, B) => [A[0] - B[0], A[1] - B[1]];

export const assignTreesToPoints = (trees = [], points = []) => {
  const treesWithDistances = trees.map((tree) => {
    const distances = points.map((point) => ({
      ...point,
      distance: getDistance(tree.coordinates, point.coordinates),
    }));

    const closestPoint = distances.reduce((prev, curr) =>
      prev.distance < curr.distance ? prev : curr
    );

    return {
      ...tree,
      distances,
      closestPoint,
    };
  });

  return treesWithDistances;
};

const getDistance = (a, b) => (a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2;

const calculateRelativeIds = (treesWithDistances) => {
  return treesWithDistances.map((tree) => {
    const currentPointIndex = tree.distances.findIndex(
      (point) => point.id === tree.closestPoint.id
    );

    let relativeId;

    if (currentPointIndex === 0) {
      const nextPointIndex = 1;
      const A = tree.closestPoint.coordinates;
      const B = tree.distances[nextPointIndex].coordinates;
      const X = tree.coordinates;

      relativeId = getIdOnStartPoint(A, B, X);
    } else if (currentPointIndex === tree.distances.length - 1) {
      const prevPointIndex = currentPointIndex - 1;

      const A = tree.closestPoint.coordinates;
      const B = tree.distances[prevPointIndex].coordinates;
      const X = tree.coordinates;

      relativeId = currentPointIndex - getIdOnStartPoint(A, B, X);
    } else {
      const prevPointIndex = currentPointIndex - 1;
      const nextPointIndex = currentPointIndex + 1;

      const d_ab = getDistance(
        tree.closestPoint.coordinates,
        tree.distances[prevPointIndex].coordinates
      );
      const d_bc = getDistance(
        tree.closestPoint.coordinates,
        tree.distances[nextPointIndex].coordinates
      );

      const d_ax = tree.distances[prevPointIndex].distance;
      const d_xc = tree.distances[nextPointIndex].distance;

      relativeId =
        d_ax / d_ab / (d_ax / d_ab + d_xc / d_bc) / 4 +
        tree.closestPoint.id -
        1;
    }

    return { ...tree, relativeId };
  });
};

function getIdOnStartPoint(A, B, X) {
  // values to transform to origo
  const dx = A[0];
  const dy = A[1];

  const x1 = B[0] - dx;
  const y1 = B[1] - dy;

  // line angle
  const alpha = Math.atan2(y1, x1);

  // tree coords transformed to origo
  const xTree = X[0] - dx;
  const yTree = X[1] - dy;

  const rTree = Math.sqrt(xTree ** 2 + yTree ** 2);
  const angleTree = Math.atan2(yTree, xTree);

  // transformed tree
  const angleTreeTransformed = angleTree - alpha;
  const xTreeTransformed = rTree * Math.cos(angleTreeTransformed);

  return xTreeTransformed / Math.sqrt(x1 ** 2 + y1 ** 2);
}

function getSideOnStartPoint(A, B, X) {
  // values to transform to origo
  const dx = A[0];
  const dy = A[1];

  const x1 = B[0] - dx;
  const y1 = B[1] - dy;

  // line angle
  const alpha = Math.atan2(y1, x1);

  // tree coords transformed to origo
  const xTree = X[0] - dx;
  const yTree = X[1] - dy;

  const rTree = Math.sqrt(xTree ** 2 + yTree ** 2);
  const angleTree = Math.atan2(yTree, xTree);

  // transformed tree
  const angleTreeTransformed = angleTree - alpha;
  const yTreeTransformed = rTree * Math.sin(angleTreeTransformed);

  return yTreeTransformed > 0;
}
