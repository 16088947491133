import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Panorama, TreeMover } from '../../components';
import { Button } from '../../components/inputs';
import { useConfig } from '../../providers/config';
import { useTheme } from '../../providers/theme';
import useApi from '../../hooks/api';
import * as THREE from 'three';
import LASLoader from '../../utils/LASLoader';
import { useData } from '../../providers/data';
import ProgressLoader from '../../components/ProgressLoader';
import {useBetaTree} from "../../hooks/betaHooks/beta-use-tree";
import {useBetaManagedAreaContext} from "../../hooks/betaHooks/beta-managed-area-context";
import {Vector3} from "three";
import TreeFlowStatus from '../../enums/TreeFlowStatus';

const loadingManager = new THREE.LoadingManager();

const TreeAdd = ({ match, trees, loaded, selection, handleStartSemanticExtraction }) => {
  const history = useHistory();
  const { getConfig } = useConfig();
  const { isDark } = useTheme();

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const betaTree = useBetaTree({
    treeId: match.params.tree,
    managedAreaCode: betaManagedAreaContext?.managedArea?.code,
    managedAreaId: betaManagedAreaContext?.managedArea?.id
  });

  const currentTree = betaTree.tree;

  const [treePosition, setTreePosition] = useState(
      currentTree?.location_local?.slice?.(0) || []
  );

  useEffect(() => {
    setTreePosition(currentTree?.location_local?.slice?.(0) || []);
  }, [currentTree?.id]);

  const _handleRemove = async () => {
    await betaTree.updateTree( { status: 'location_validation_deleted', tree_flow_status: TreeFlowStatus.LocationValidationDeleted });
    history.goBack();
  };

  const _handleUpdate = async () => {
    await betaTree.updateTree( {
      status: 'location_validation_done',
      tree_flow_status: TreeFlowStatus.LocationValidationDone,
      location: treePosition,
      manual_treelocation_changes: true,
    });

    if (betaManagedAreaContext.pipeline.current_manual_step !== 'location_validation') {
      handleStartSemanticExtraction([currentTree?.id], betaManagedAreaContext.pipeline.id);
    }
    history.goBack();
  };

  const [lasError, setLasError] = useState(null);
  const { handleRequest, getUrl, requestHeader } = useApi();
  const [availablePointClouds, updateAvailablePointClouds] = useState([]);
  const [activePcIndex, updateActivePcIndex] = useState(0);
  const [loadingState, setLoadingState] = useState([0, 100]);
  const lastLoaded = useRef(0);

  const _handleLasLoad = useCallback((path, pcIndex) => {
    setLasError(null);
    const currentId = currentTree?.id;
    const url = getUrl('/v1/proxy_v2/exported-data?url=' + path);
    setLoadingState([0, 100]);

    const loader = new LASLoader(loadingManager, [currentTree?.location_local[0], currentTree?.location_local[1]]);

    loader.setRequestHeader(requestHeader);
    loader.load(
      url,
      (pointcloud) => {
        if (currentId !== currentTree?.id) return;
        updateAvailablePointClouds(pcs => {
          const newPcs = [...pcs];
          newPcs[pcIndex].pointcloud = pointcloud;

          return newPcs;
        });

        setLasError(null);
      },
      (e) => {
        if (new Date().getTime() - lastLoaded.current > 300) {
          setLoadingState([e.loaded, e.total])
          lastLoaded.current = new Date().getTime();
        }
      },
      () => {
        updateAvailablePointClouds(old => [...old.slice(0, pcIndex), ...old.slice(pcIndex + 1)]);

        setLasError(true);
      }
    );
  }, [currentTree?.id, currentTree?.location_local, getUrl, requestHeader]);

  const getLas = useCallback(async () => {
    //const { coordinates } = currentTree?.location_local;
    const response = await handleRequest( '/v1/location/las_tiles/' +  currentTree?.location_local[0] + ',' + currentTree?.location_local[1] + ',4326');
    const responseJson = await response.json();

    updateAvailablePointClouds(responseJson.map(res => (
      {
        path: res.laz_path,
        pointcloud: null
      }
    )));

/* Removed PRD-598 */
/*     if (!responseJson?.[0]?.scan_name) {
      alert('OutOfManagedArea');
      _handleRemove();
      return;
    } */

    const lazUrl = responseJson[0].laz_path;

    _handleLasLoad(lazUrl, 0);
  }, [_handleLasLoad, currentTree?.location_local, handleRequest]);

  useEffect(() => {
    getLas();
  }, [currentTree?.id]);

  const _switchActivePointCloud = useCallback(({offset}) => {
    if (availablePointClouds.length > 0) {
      const newIndex = (activePcIndex + offset + availablePointClouds.length) % availablePointClouds.length;
      updateActivePcIndex(newIndex);

      if (!availablePointClouds[newIndex].pointcloud) {
        _handleLasLoad(availablePointClouds[newIndex].path, newIndex);
      } else if (offset === 0) {
        setLasError(null);
      }
    }
  }, [_handleLasLoad, activePcIndex, availablePointClouds]);

  useEffect(() => {
    if (lasError !== null) _switchActivePointCloud({offset: 0});
  }, [_switchActivePointCloud, lasError]);

  if (!currentTree) return null

  return (
    <div className="location-validation-wrapper">
      <div className="viewers">
        <div className="panorama-view-wrapper">
          {!!treePosition && <Panorama
              treeId={currentTree?.id}
              managedAreaCode={selection[0]?.code}
              optionalPosition={new Vector3().fromArray(treePosition)}
              enabledMiniMap={false}
          />}
        </div>
        <div className="vertical-stack">
          <TreeMover
            treeLoaded={loaded}
            pointcloud={availablePointClouds[activePcIndex]?.pointcloud ?? null}
            error={lasError}
            background={isDark ? 0x000000 : 0xf8f8f8}
            position={treePosition}
            onPositionChange={setTreePosition}
            loaderBtn={availablePointClouds.length > 1 ? <div className="next-pointcloud-btn"><Button onClick={() => _switchActivePointCloud({offset: 1})} label="View another pointcloud"/></div> : null}
            loader={<ProgressLoader label={loadingState[0] / loadingState[1] > 0.95 ? 'Processing' : 'Downloading'} currentCount={loadingState[0]} targetCount={loadingState[1]}/>}
          />
        </div>
      </div>
      <div className="location-actions-wrapper">
        <div className="action-wrapper">
          <Button label="Cancel" onClick={_handleRemove} />
        </div>
        <div className="action-wrapper">
          <Button label="Save" primary onClick={_handleUpdate} />
        </div>
      </div>
    </div>
  );
};

export default TreeAdd;
