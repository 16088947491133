export const convertTreeToCsv = (tree: any, index: number) => {
  return {
    ID: index + 1,
    'Species (Latin)': tree.microclimate.species_latin,
    'i-Tree Code': tree.microclimate.species_code,
    'DBH1 (cm)': num(tree.trunk_diameter * 100),
    'DBH2 (cm)': '',
    'DBH3 (cm)': '',
    'DBH4 (cm)': '',
    'DBH5 (cm)': '',
    'DBH6 (cm)': '',
    'Land use': tree.microclimate.land_use,
    'Total tree height (meter)': num(tree.height, 2),
    'Height to live top (meter)': num(tree.height, 2),
    'Height to crown base (meter)': num(tree.trunk_height, 2),
    'crown (n-s)': num(tree.canopy_ellipse_a, 2),
    'crown (e-w)': num(tree.canopy_ellipse_b, 2),
    'Percent crown missing': tree.microclimate.percent_crown_missing,
    'Crown dieback': tree.microclimate.crown_dieback,
    'Crown light exposure': tree.microclimate.crown_light_exposure,
    'greeHill ID': tree.id,
  };
};

const MISSING_NUMBER_FALLBACK = '';

const num = (num?: number, decimals = 1) => {
  if (!num || isNaN(num)) return MISSING_NUMBER_FALLBACK;
  return num?.toFixed(decimals);
};
