import { useEffect, useMemo, useState } from 'react';
import { Camera, Raycaster, Scene, Vector2, WebGLRenderer } from 'three';
import { CSS2DParameters, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { WebGLRendererParameters } from 'three/src/renderers/WebGLRenderer';
import { fromEvent } from 'rxjs';
import { ResponsiveCanvas } from './responsive-canvas';

interface OnRenderOptions<Cam extends Camera> {
  render: (scene: Scene, camera: Cam) => void;
}

export interface UseThreeSceneOptions<Cam extends Camera> {
  onRender: (options: OnRenderOptions<Cam>) => void;
  enableCssRenderer?: boolean;

  webGLRendererOptions?: Omit<WebGLRendererParameters, 'canvas'>;
  cssRendererOptions?: Omit<CSS2DParameters, 'element'>;
}

export function useResponsiveThreeRenderer<Cam extends Camera>(options: UseThreeSceneOptions<Cam>) {
  const [ready, setReady] = useState(false);
  const [dimensions, setDimensions] = useState([0, 0]);
  const [aspectRatio] = useMemo(() => [dimensions[0] / dimensions[1]], [dimensions[0], dimensions[1]]);
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>();
  const pointer = useMemo(() => new Vector2(), []);
  const rayCaster = useMemo(() => new Raycaster(), []);

  const webGlRenderer = useMemo(() => !!canvasRef && new WebGLRenderer({
    canvas: canvasRef,
    antialias: true,
    ...options.webGLRendererOptions
  }), [!!canvasRef]);

  const cssRenderer = useMemo(() => options?.enableCssRenderer ? new CSS2DRenderer(options.cssRendererOptions) : null, [options?.enableCssRenderer]);

  // Set Size & Resize
  useEffect(() => {
    if (!webGlRenderer) return;
    webGlRenderer.localClippingEnabled = true;
    webGlRenderer.setSize(dimensions[0], dimensions[1]);

    if (options?.enableCssRenderer && cssRenderer) cssRenderer.setSize(dimensions[0], dimensions[1]);

    webGlRenderer.setPixelRatio(window.devicePixelRatio);
  }, [!!webGlRenderer, !!cssRenderer, dimensions[0], dimensions[1]]);

  // Render loop
  useEffect(() => {
    if (options?.enableCssRenderer && !cssRenderer) return;
    if (!webGlRenderer) return;

    let play = true;

    const animate = () => {
      if (!play) {
        return;
      }

      options.onRender({
        render: (scene, camera) => {
          webGlRenderer.render(scene, camera);
          if (options?.enableCssRenderer && cssRenderer) cssRenderer.render(scene, camera);
          rayCaster.setFromCamera(pointer, camera);
        }
      });

      requestAnimationFrame(() => animate());
    }

    animate();

    return () => {
      play = false;
    }
  }, [webGlRenderer, cssRenderer])

  // Set default color
  useEffect(() => {
    if (!webGlRenderer) return;
    if (options?.enableCssRenderer && !cssRenderer) return;

    // webGlRenderer.setClearColor(0x1b2432);
    webGlRenderer.setClearColor('black');
  }, [webGlRenderer, cssRenderer])

  useEffect(() => {
    if (!webGlRenderer) return;
    setReady(true);
  }, [!!webGlRenderer])

  const canvasDOMElement = <ResponsiveCanvas
    ref={setCanvasRef}
    onDimensionChange={(w, h) => setDimensions([w, h])}
  />;

  useEffect(() => {
    if (!webGlRenderer) return;
    if (!cssRenderer) return;

    cssRenderer.domElement.style.position = 'absolute';
    cssRenderer.domElement.style.top = '0px';
    cssRenderer.domElement.style.left = '0px';
    webGlRenderer?.domElement?.parentElement?.appendChild(cssRenderer.domElement);
  }, [webGlRenderer, cssRenderer]);

  useEffect(() => {
    if (!canvasRef) return;
    const subscriber = fromEvent(canvasRef, 'pointermove')
      .subscribe((event: any) => {
        pointer.set(
          (event.offsetX / canvasRef.offsetWidth) * 2 - 1,
          -(event.offsetY / canvasRef.offsetHeight) * 2 + 1
        );
      });

    return () => {
      subscriber.unsubscribe();
    };
  }, [!!canvasRef]);

  return {
    ready,
    width: dimensions[0],
    height: dimensions[1],
    aspectRatio,
    canvasDOMElement,
    webGlRenderer,
    cssRenderer,
    frontRenderer: cssRenderer || webGlRenderer,
    canvasRef,
    rayCaster,
    destroy: () => {
      if (webGlRenderer) webGlRenderer.dispose();
      if (cssRenderer) cssRenderer.domElement.remove();
    }
  }
}
