import { CSSProperties, PropsWithChildren } from 'react';

type TabPanelProps = {
  name: string;
  index: number;
  value: number;
  style?: CSSProperties;
};

const TabPanel = (props: PropsWithChildren<TabPanelProps>) => {
  const { children, value, index, name, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
      className='tab-panel'
      style={props.style}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
