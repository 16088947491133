import React from 'react';
import Calendar from 'react-calendar';

const CalendarInput = ({
  value = new Date(),
  onChange = () => {},
  onClick,
  minDate,
  maxDate,
}) => {
  return (
    <div className="calendar-input-outer-wrapper" onClick={onClick}>
      <div className="calendar-wrapper">
        <Calendar
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

export default CalendarInput;
