import React from 'react';
import { useHistory } from 'react-router';
import { useNotifications } from '../../hooks';

const NotificationsDropdown = () => {
  const { notificationsGrouped, generateDate, onRead } = useNotifications();
  const now = generateDate(new Date());
  return (
    <div className="notifications-dropdown-wrapper">
      <span className="title">Notifications</span>
      <div className="notifications">
        {
          Object.keys(notificationsGrouped)
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
            .map(date => <DateWrapper onRead={onRead} now={now} notifications={notificationsGrouped[date]} date={date} key={date} />)
        }
      </div>
    </div>
  )
};

const DateWrapper = ({ notifications, date, now, onRead }) => {
  return (
    <div className="date-notifications">
      <span className="date">{ date === now ? 'Today' : date }</span>
      { notifications.map((notification, i) => <Notification onRead={onRead} {...notification} key={`${i}-${notification.date.getDate()}`} />)}
    </div>
  )
}

const CONFIG = {
  MA_ASSIGNED: {
    label: '__TARGET__ has been assigned to you',
    url: '/validation/__ACTION_TARGET__',
  },
  MA_PENDING: {
    label: '__TARGET__ is waiting for validation',
    url: '/validation/__ACTION_TARGET__',
  },
  MA_IN_PROGRESS: {
    label: '__TARGET__ is in progress',
    url: '/validation/__ACTION_TARGET__',
  },
  DATA_TO_BE_OVERDUE: {
    label: 'Data upload __TARGET__ is about to be overdue in __AMOUNT__ days',
    url: '/validation/__ACTION_TARGET__',
  },
  TO_BE_OVERDUE: {
    label: '__TARGET__ will be overdue in __AMOUNT__ days',
    url: '/validation/__ACTION_TARGET__',
  },
  TO_BE_ASSIGNED: {
    label: '__TARGET__ to be assigned',
    url: '/overview/officers',
  },
};

const Notification = ({ label, date, id, content: { type, target, targetName, amount }, read, onRead, action: { target: actionTarget, type: actionType } }) => {
  const history = useHistory();
  const isNew = !read;

  const _handleRead = () => onRead(id);

  const _handleOpen = () => {
    if (actionType === 'OPEN') {
      const url = CONFIG[type]?.url?.replace('__ACTION_TARGET__', actionTarget);
      history.push(url);
    }
    _handleRead();
  };

  return (
    <div className="notification-wrapper">
      <span onClick={_handleOpen} className="label">{ CONFIG[type]?.label?.replace('__TARGET__', targetName ? targetName : target).replace('__AMOUNT__', amount) }</span>
      <div className="side" onClick={_handleRead}>
        { isNew && <span className="alert-dot" /> }
      </div>
    </div>
  )
}

export default NotificationsDropdown;