import { ColumnType } from '../../@types/Table';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import { sorter } from '../../utils/sorter';
import { Button } from '../inputs';
import Completed from "./Completed";
import IconButton from './IconButton';
import MultiSelect from './MultiSelect';
import Select from './Select';
import Status from './Status';
import Uncertain from './Uncertain';

const dateFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dateTimeFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  const hour = date.getHours();
  const minutes = date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${hour}:${minutes}`;
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const monthFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

export const tableSorter = (data: any[], sortDir: any, column: ColumnType, getValueByResolver: (entry: any, resolver: any) => any) => {
  if (column && data) {
    const sortCb = (a: string, b: string) => {
      const valueA = getValueByResolver(a, column.resolver);
      const valueB = getValueByResolver(b, column.resolver);

      return sorter(valueA, valueB, sortDir, column.format);
    };

    return [...data].sort(sortCb);
  } else {
    return data || [];
  }
};

const formatter = (
  column: ColumnType,
  format?: string,
  value?: string | number | Date,
  label?: string,
  entry?: any,
  updateTree?: (id: string, fieldsToUpdate: Object) => void,
  selected?: boolean
): JSX.Element | string => {
  switch (format) {
    case 'TITLE':
      return <span className='title'>{String(value)}</span>;
    case 'DATE':
      return <span className='date'>{dateFormatter(value as Date)}</span>;
    case 'DATETIME':
      return <span className='date'>{dateTimeFormatter(value as Date)}</span>;
    case 'MONTH':
      return <span className='date'>{monthFormatter(value as Date)}</span>;
    case 'STATUS':
      return <Status status={value as string} />;
    case 'ICONBUTTON':
      return <IconButton {...column} entry={entry} />;
    case 'CODE':
      return <pre className='code'>{String(value)}</pre>;
    case 'PERCENTAGE':
      return (
        <span className='value percentage'>
          {value !== null ? `${(Math.round(Number(value as string) * 1000) / 10).toFixed(1)}%` : '-'}
        </span>
      );
    case 'SELECT':
      return <Select entry={entry} options={column.options!} value={value as string} column={column} />;
    case 'MULTISELECT':
      return <MultiSelect entry={entry} label={label!} options={column.options!} value={value as string} column={column} />;
    case 'REVERT_STATUS_BUTTON':
      return entry.tms_category === 'l3' || entry.tree_flow_status === TreeFlowStatus.LocationValidationDeleted || entry.tree_flow_status === TreeFlowStatus.LocationValidationDone ? (
        <Button
          label='Revert to todo'
          primary={true}
          style={{ margin: 0, padding: 4, width: 'fit-content', fontSize: 14, fontWeight: ' 400!important' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const requestParams = {
              status: 'location_validation_todo',
              tree_flow_status: TreeFlowStatus.LocationValidationQueued,
              tms_category: null,
            }
            return updateTree?.(entry.id, requestParams);
          }}
        />
      ) : (
        <></>
      );
    case 'UNCERTAIN':
      return <Uncertain value={!value} />;
    case 'COMPLETED':
      return <Completed value={!!value} />;
    case 'ESS_ID':
      return (
        <div style={{ display: 'flex', justifyContent: selected ? 'space-between' : 'normal' }}>
          <span className='value text' style={{ marginTop: selected ? '6px' : '' }}>
            {String(value)}
          </span>
          {selected && column.clickNavigation ? <IconButton icon={'expand'} onClick={column.clickNavigation} entry={entry} /> : ''}
        </div>
      );
    case 'ESS_STATUS':
      if (value === 'To Do') {
        return (
          <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(236, 177, 74, 0.5)' }}>
            <span className='label'>To Do</span>
          </div>
        );
      }
      return (
        <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(5, 139, 98, 0.5)' }}>
          <span className='label'>Done</span>
        </div>
      );
    case 'CONFIDENCE':
      if (!value) {
        return '-';
      }
      if (value === 1) {
        return (
          <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(236, 177, 74, 0.5)' }}>
            <span className='label'>Set manually</span>
          </div>
        );
      }
      return `${Number(value) * 100}%`;
    default:
      return <span className='value text'>{String(value)}</span>;
  }
};

export default formatter;
