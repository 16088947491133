import React, { Fragment, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Route } from '../layout';
import { MobileOverlay } from '../components';
import { useUser } from '../providers/user';

import Login from './Login';
import Home from './Home';
import NotFound from './NotFound';
import ErrorOccurred from './ErrorOccurred';
import ErrorMultiLogin from './ErrorMultiLogin';

import Validation from './Validation';
import Completed from './Completed';
import TaskOverview from './TaskOverview';
import Preferences from './Preferences';
import MicroclimateInput from './MicroclimateDataInput';
import useProj4Defs from '../hooks/useProj4Defs';

import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@mui/material/styles';
import { useTheme } from '../providers/theme';
import { darkThemeOptions as darkMuiThemeOptions, lightThemeOptions as lightMuiThemeOptions } from '../styles/MuiTheme';

const Navigation = () => {
  const { token } = useUser();

  const { isDark } = useTheme();
  const muiTheme = useMemo(() => createMuiTheme(isDark ? darkMuiThemeOptions : lightMuiThemeOptions), [isDark]);

  return (
    <Fragment>
      <MuiThemeProvider theme={muiTheme}>
        <MobileOverlay />
        <Router>
          <Switch>
            {/* <Route title='Login' path='/' component={Login} /> */}
            <Route title="Login" path="/login" component={Login} />
            <Route
              title="An error occurred"
              exact
              path="/error-occurred"
              component={ErrorOccurred}
            />
            <Route
              title="Multi login not allowed"
              exact
              path="/error-multi-login"
              component={ErrorMultiLogin}
            />
            {token && <AuthenticatedRoutes />}
            <Redirect from="*" to="/login" />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Fragment>
  );
};

const AuthenticatedRoutes = () => {
  const { isLoaded } = useProj4Defs();

  if (!isLoaded) {
    return null;
  }

  return (
    <Switch>
      <Route
        title="Validation"
        path="/validation/:MA?"
        component={Validation}
      />
      <Route
        title="Virtual Tree Inspector"
        path="/cma/:MA?"
        component={Completed}
      />
      <Route
        title="Task Overview"
        path="/overview/:MA?"
        component={TaskOverview}
      />
      <Route title="Preferences" path="/preferences" component={Preferences} />
      <Route
        title="Microclimate data input"
        path="/microclimate-input/:MA?"
        component={MicroclimateInput}
      />

      <Route exact path="/" component={Home} />
      <Route title="Not found" path="*" component={NotFound} />
    </Switch>
  );
};

export default Navigation;
