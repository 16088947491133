import DebouncedColorPicker from './DebouncedColorPicker';

type ColorPickerProps = {
  color: string;
  onColorChange: (color: string) => void;
};

const ColorPicker = ({ color, onColorChange }: ColorPickerProps) => {
  return <DebouncedColorPicker color={color} onChange={onColorChange} delay={100} colorPickerType={'picker'} />;
};

export default ColorPicker;
