import React from 'react';
import { Skeleton, Route } from '../../layout';

import Personal from './Personal';
import Administrative from './Administrative';
import Administrational from './AdministrationalParameters';
import { useUser } from '../../providers/user';

const generateTabs = (hasRole) => [
  {
    title: 'Personal preferences',
    icon: 'user-cog',
    path: `/preferences/personal`,
    exact: true,
  },
  hasRole('admin') && {
    title: 'Administrative preferences',
    icon: 'users-cog',
    path: `/preferences/administration`,
    exact: true,
  },
  hasRole('admin') && {
    title: 'Administrational paramenters',
    icon: 'tags',
    path: `/preferences/administrational-paramenters`,
    exact: true,
  },
];

const Preferences = () => {
  const { hasRole } = useUser();
  return (
    <Skeleton
      header={{
        user: {},
        title: 'Preferences',
      }}
      tabs={generateTabs(hasRole)}
    >
      <Route path='/preferences/personal' component={Personal} />
      { hasRole('admin') && <Route path='/preferences/administration' component={Administrative} /> }
      { hasRole('admin') && <Route path='/preferences/administrational-paramenters' component={Administrational} /> }
    </Skeleton>
  )
}

export default Preferences;
