import React from 'react';

const Edit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>rtms</title>
      <g id="Layer_2" data-name="Layer 2">
        <path fill="currentColor" d="M22.42,7.58a1.41,1.41,0,0,0-1.88-.09c-.08.06,0,0-1.24,1.22l3.16,3.16,1.13-1.12a1.41,1.41,0,0,0,0-2Z"/>
        <path fill="currentColor" d="M12.83,15.24l-1.86,4a.72.72,0,0,0,.14.79.7.7,0,0,0,.8.14l4-1.85c.15-.08-.32.37,5.57-5.45L18.31,9.71c-5.84,5.83-5.4,5.37-5.48,5.53Z"/>
        <path fill="currentColor" d="M17.07,19.16a2,2,0,0,1-.59.41l-4,1.86a2.11,2.11,0,0,1-2.8-2.81l1.86-4a1.81,1.81,0,0,1,.42-.6l6-6v-6A2.12,2.12,0,0,0,15.85,0H2.11A2.12,2.12,0,0,0,0,2.11V21.89A2.12,2.12,0,0,0,2.11,24H15.85A2.12,2.12,0,0,0,18,21.89v-3.6ZM3.75,4.55h10.5a.7.7,0,1,1,0,1.4H3.75a.7.7,0,0,1,0-1.4Zm0,3.75H12a.7.7,0,1,1,0,1.4H3.75a.7.7,0,1,1,0-1.4Zm-.7,4.45a.7.7,0,0,1,.7-.7h6a.7.7,0,1,1,0,1.4h-6A.7.7,0,0,1,3.05,12.75Z"/>
      </g>
    </svg>
  )
}

export default Edit;