import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import Navigation from './routes';
import reportWebVitals from './reportWebVitals';
import Providers from './providers';
import './core/library';

import appConfig from './config';
import { PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: appConfig.auth.clientId,
    authority: appConfig.auth.authority,
    navigateToLoginRequestUrl: false,
    redirectUri: '/login',
    postLogoutRedirectUri: '/',
  }
});

// Prototypes    
String.prototype.toPath = function() {
  return this.split(/\[([^}]+)\]|\./g).filter(segment => !!segment);
}

Object.byPath = function(object, path) {
  path = Array.isArray(path) ? path : String(path).toPath();
  return path.reduce((prev, key) => (prev && typeof prev[key] !== undefined) ? prev[key] : null, object);
};

Object.byResolver = function(object, resolver) {
  return typeof(resolver) === 'function' ? resolver(object) : Object.byPath(object, resolver);
};

Object.toKebab = function(object) {
  return Object.keys(object).reduce((prev, key) => ({ ...prev, [key.split(/(?=[A-Z])/).join('-').toLowerCase()]: object[key]}), {})
};

Array.groupByTwo = function(array) {
  return array.reduce((acc, val, ind, array) => {
    const { data, prev } = acc;
    if (prev) data.push([prev, val])

    return { data, prev: prev ? null : val }
  }, { data: [], prev: null }).data;
};

ReactDOM.render(
  <React.StrictMode>
    <Providers pca={msalInstance}>
      <Navigation />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
