import { RoundCheckboxProps } from '../../@types/RoundCheckbox';
import Icon from '../Icon';

const RoundCheckbox = ({ label, value, onChange, onPureChange, disabled, color, icon }: RoundCheckboxProps) => {
  const _handleChange = () => {
    if (disabled) return;
    if (onPureChange) onPureChange();
    if (onChange) onChange(!value);
  };

  return (
    <div className='round-checkbox-wrapper' onClick={_handleChange} tabIndex={1}>
      {icon ? (
        <Icon icon={icon} color={color} />
      ) : (
        <div
          className={`round-checkbox ${disabled ? 'disabled' : ''}`}
          style={{
            background: value ? color : 'transparent',
            borderColor: color,
          }}
        ></div>
      )}

      {label?.length && <span className='label'>{label}</span>}
    </div>
  );
};

export default RoundCheckbox;
