import { IconElementParams } from '../../@types/Icon';

const Valid = ({ width = 16, height = 16, color }: IconElementParams) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8.14294' cy='8.5' r='8' fill={color ?? '#00BD4C'} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0181 7.29773L7.3381 11.9737C7.2403 12.0716 7.12413 12.1493 6.9963 12.2023C6.86847 12.2553 6.73148 12.2825 6.5931 12.2825C6.45472 12.2825 6.31768 12.2553 6.18984 12.2023C6.06201 12.1493 5.9459 12.0716 5.84811 11.9737L3.46206 9.5907C3.36194 9.4934 3.28222 9.37719 3.22744 9.24878C3.17266 9.12037 3.14392 8.98232 3.14297 8.84271C3.14202 8.70311 3.16886 8.56475 3.22189 8.43561C3.27492 8.30646 3.35308 8.18917 3.45187 8.09052C3.55065 7.99186 3.66805 7.91382 3.79727 7.86096C3.92648 7.80811 4.06495 7.78146 4.20455 7.78259C4.34416 7.78373 4.4821 7.81266 4.61044 7.86761C4.73877 7.92257 4.85495 8.00247 4.95211 8.10272L6.5931 9.74072L10.5281 5.80872C10.7256 5.61113 10.9934 5.50009 11.2727 5.5C11.5521 5.49991 11.82 5.61077 12.0176 5.80823C12.2152 6.00568 12.3262 6.27352 12.3263 6.55286C12.3264 6.83219 12.2155 7.10014 12.0181 7.29773Z'
        fill='white'
      />
    </svg>
  );
};

export default Valid;
