import { Group, Mesh, MeshBasicMaterial, PlaneGeometry, Texture } from 'three';
import { PanoramaImageTile } from './panorama-image-tile';


export interface LoadOptions {
  images: PanoramaImageTile[];
  token: string;
}

export class TilePanoramaObject extends Group {
  public objects: Mesh<PlaneGeometry, MeshBasicMaterial>[] = [];

  public async createImageLoader(url: string, token: string) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)

    const response = await fetch(url, { headers })
    const blob = await response.blob();

    const img = new Image();
    img.src = URL.createObjectURL(blob)

    const texture = new Texture();
    texture.image = img;
    texture.needsUpdate = true;

    return texture;
  }

  public unload() {
    for (const item of this.objects) {
      item.material.dispose();
      item.geometry.dispose();
      item.removeFromParent();
    }
  }

  public load(options: LoadOptions) {
    this.unload();
    this.objects = [];

    return Promise.all(options.images.map((image) => {
      return new Promise(async (resolve: any, reject) => {
        const mesh = image.createObject(200);

        const texture = await this.createImageLoader(image.url, options.token);

        mesh.material.map = texture.clone();
        mesh.material.needsUpdate = true;
        this.add(mesh);
        this.objects.push(mesh as any);

        resolve();
      });
    }));
  }
}
