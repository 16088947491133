import React, { useEffect, useState } from "react";
import Icon from "./Icon";
import Popup from "./Popup";
import { useTheme } from "../providers/theme";
import { useUser } from "../providers/user";
import { useModal } from "../providers/modal";
import { withRouter } from "react-router-dom";
import { UserDropdown } from "../components/Dropdowns";
import Dropdown from "./Dropdown";

import { Preferences } from "./modals";
import Help from "./Help";
import { useNotifications } from "../hooks";
import { formatDateTime } from "../utils/dateUtils";
import ProjectDropdown from "./ProjectDropdown/ProjectDropdown_old";

const METAKEYS = {
  ALT: "altKey",
  CTRL: "ctrlKey",
  CMD: "metaKey",
};

const getName = (user) => (!user ? "NA" : user?.name);

const getLastLogin = (user) => {
  const lastLogin = user.lastLogin;
  const dateLogin = lastLogin ? new Date(lastLogin) : null;
  return dateLogin ? formatDateTime(dateLogin) : "-";
};

const GENERATE_ACTIONS = ({ presentModal = () => {}, user, history, location, dismissModal, setApiToken, isNewNotification, managedArea, trees }) =>
  [
    user && {
      dropdownContent: <UserDropdown name={getName(user)} managedArea={managedArea} trees={trees} lastLogin={getLastLogin(user)} />,
      image: `https://eu.ui-avatars.com/api/?name=${getName(user)}`,
      title: "User preferences",
      action: () =>
        presentModal({
          title: "User preferences",
          content: <Preferences history={history} dismissModal={dismissModal} />,
        }),
      accelerator: ["CMD+Comma", "CTRL+KeyU"],
    },
    /(location|semantics)/gm.test(location.pathname) && {
      icon: "question",
      title: "Help",
      accelerator: "F1",
      action: () =>
        presentModal({
          title: "Help",
          content: <Help scrollTo={location.pathname.includes("semantics") ? "semantics" : "location"} />,
        }),
    },
  ].filter((_) => !!_);

const Action = ({ icon, dropdownContent, title, image, action = () => {}, alert }) => {
  return (
    <Dropdown content={dropdownContent}>
      <Popup title={title}>
        <div className="action-wrapper regular-button-states" onClick={action}>
          {image ? <img src={image} /> : <Icon icon={icon || "coffee"} />}
          {alert && <span className="alert" />}
        </div>
      </Popup>
    </Dropdown>
  );
};

const Header = ({ title = "PAGE TITLE", user = null, history, location, trees, managedArea }) => {
  const { isNewNotification } = useNotifications();
  const { presentModal, dismissModal } = useModal();
  const { isDark } = useTheme();
  const { setApiToken } = useUser();
  const [actions, setAction] = useState(
    GENERATE_ACTIONS({
      presentModal,
      user,
      history,
      location,
      dismissModal,
      setApiToken,
      isNewNotification,
      trees,
      managedArea,
    })
  );

  useEffect(() => {
    setAction(
      GENERATE_ACTIONS({
        presentModal,
        user,
        history,
        location,
        dismissModal,
        setApiToken,
        isNewNotification,
        trees,
        managedArea,
      })
    );
  }, [dismissModal, history, isNewNotification, location, trees, managedArea, presentModal, setApiToken, user]);

  //const actions = GENERATE_ACTIONS({ presentModal, user, history, location, dismissModal, setApiToken, isNewNotification, managedArea });
  const _checkAccelerator = (action, e) => {
    if (Array.isArray(action.accelerator)) return action.accelerator.forEach((accelerator) => _checkAccelerator({ ...action, accelerator }, e));
    const keys = action.accelerator.split("+");
    const meta = keys.map((key) => METAKEYS[key]).filter((meta) => !!meta)[0];
    const keyCode = keys.filter((key) => !METAKEYS[key])[0];
    if (e.code === keyCode && (meta ? e[meta] : true)) {
      e.preventDefault();
      e.stopPropagation();
      action.action();
    }
  };

  const _handleKeyDown = (e) => actions.filter((action) => !!action.accelerator).forEach((action) => _checkAccelerator(action, e));

  useEffect(() => {
    window.addEventListener("keydown", _handleKeyDown);
    return () => window.removeEventListener("keydown", _handleKeyDown);
  }, []);

  const _handleHome = () => history.push("/");

  return (
    <nav className={isDark ? "dark" : "light"}>
      <div className="logo-wrapper regular-button-states" onClick={_handleHome}>
        <Icon icon="gh" />
        <div className="caption">
          <h1>iTM</h1>
          <hr />
          <h2>internal validator tool</h2>
        </div>
      </div>
      <div className="title-wrapper">{title}</div>
      <div className="project-selector">{user && <ProjectDropdown />}</div>
      <div className="actions-wrapper">
        {actions.map((action) => (
          <Action key={`${action.title}-${action.icon}`} {...action} />
        ))}
      </div>
    </nav>
  );
};

export default withRouter(Header);
