enum ValidationAction {
  Height = 'height',
  CanopyHeight = 'canopyHeight',
  FirstBifurcationPoint = 'firstBifurcationPoint',
  Girth = 'girth',
  Canopy = 'canopy',
  LeaningVector = 'leaningVector',
}

export default ValidationAction;
