import { ManagedAreaStatuses } from "../hooks/betaHooks/beta-managed-areas-context";

const getHeaderTitle = (status: ManagedAreaStatuses) => {
  const str = status.replaceAll('_', ' ');

  let newStr: string[] = [];

      // Split the input string into an array of words
      newStr = str.split(" ");

      // Iterate through each word in the array
      for (var i = 0, x = newStr.length; i < x; i++) {
          // Capitalize the first letter of each word and concatenate it with the rest of the word
          newStr[i] = newStr[i][0].toUpperCase() + newStr[i].substr(1);
      }
  
      // Join the modified array of words back into a string
      return newStr.join(" ");
}

export default getHeaderTitle;