import * as THREE from 'three';
/*
import SpriteText from 'three-spritetext';

const spacingUnit = 1; // The unit representing 1m in the coorindate system
const helperSizes = [0.3, 1, 3, 5];

function createTextLabel(position: THREE.Vector3, text: string) {
  const label = new SpriteText(text);
  label.backgroundColor = '#202324';
  label.borderRadius = 4;
  label.borderWidth = 1;
  label.borderColor = 'white';
  label.padding = 5;
  label.fontFace = 'Inter';
  label.position.copy(position);
  label.scale.set(1.125, 0.6, 1);

  return label;
}

// Size of the grid
const renderGrid = (scene: THREE.Scene, position: THREE.Vector3, { gridSize = 15 }: { gridSize: number }) => {
  const group = new THREE.Group();

  const verticalGridSpacing = 0.5 * spacingUnit; // Spacing between grid lines - 0.5 unit
  const horizontalGridSpacing = 1 * spacingUnit; // Spacing between grid lines - 1 unit
  const halfSize = (gridSize * spacingUnit) / 2;

  const gridGeometry = new THREE.BufferGeometry();
  const gridVertices = [];

  // Generate vertical grid lines
  for (let i = -halfSize; i <= halfSize; i += verticalGridSpacing) {
    gridVertices.push(-halfSize, 0, i);
    gridVertices.push(halfSize, 0, i);
  }

  // Generate horizontal grid lines
  for (let i = -halfSize; i <= halfSize; i += horizontalGridSpacing) {
    gridVertices.push(i, 0, -halfSize);
    gridVertices.push(i, 0, halfSize);
  }

  gridGeometry.setAttribute('position', new THREE.Float32BufferAttribute(gridVertices, 3));
  const gridMaterial = new THREE.LineDashedMaterial({
    color: 0x808080,
    dashSize: 0.1,
    gapSize: 0.1,
    linewidth: 1.5,
  }); // Grey color for grid lines, adjust dashSize and gapSize as desired
  gridMaterial.transparent = true; // Enable transparency for dashed lines
  const gridLineObject = new THREE.LineSegments(gridGeometry, gridMaterial);
  gridLineObject.computeLineDistances(); // Compute line distances for dashed lines

  group.add(gridLineObject);

  // Add helper lines
  const helperGridGeometry = new THREE.BufferGeometry();
  const heplperGridVertices = [];

  for (let i = 0; i < helperSizes.length; i++) {
    const scaledPosZ = helperSizes[i] * spacingUnit - halfSize;

    heplperGridVertices.push(-halfSize, 0, scaledPosZ);
    heplperGridVertices.push(halfSize, 0, scaledPosZ);

    const unitTextLabel = createTextLabel(new THREE.Vector3(-halfSize, 0, scaledPosZ), `${helperSizes[i]} m`);

    group.add(unitTextLabel);
  }

  helperGridGeometry.setAttribute('position', new THREE.Float32BufferAttribute(heplperGridVertices, 3));

  const helperGridMaterial = new THREE.LineBasicMaterial({ color: 0xff0000, linewidth: 2 }); // Grey color for grid lines
  const helperGridLineObject = new THREE.LineSegments(helperGridGeometry, helperGridMaterial);

  group.add(helperGridLineObject);

  // Align group to center
  group.position.set(position.x, position.y, position.z + halfSize);

  // Add group to scene
  scene.add(group);

  const tiltAngle = 180 * (Math.PI / 180); // 180 degrees in radians
  const tiltQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(tiltAngle / 2, tiltAngle, tiltAngle));

  return {
    remove: () => {
      scene.remove(group);
    },
    syncRotation: (camera: THREE.Camera) => {
      group.quaternion.fromArray(camera.quaternion.clone().toArray());
      group.quaternion.multiply(tiltQuaternion);
    },
    syncPosition: (position: THREE.Vector3, camera: THREE.OrthographicCamera) => {
      group.position.set(0, 0, position.z + halfSize);
    },
  };
};

 */

const renderGrid = (scene: THREE.Scene, position: THREE.Vector3, { gridSize = 15 }: { gridSize: number }) => {
  const group = new THREE.Group();

  const grid = new THREE.GridHelper(100, 100);
  group.position.fromArray(position.clone().toArray());

  group.add(grid);
  scene.add(group);

  const tiltAngle = 180 * (Math.PI / 180); // 180 degrees in radians
  const tiltQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(tiltAngle / 2, 0, 0));

  return {
    remove: () => {
      grid.dispose();
      group.remove(grid);
    },
    syncRotation: (camera: THREE.Camera) => {
      group.quaternion.fromArray(camera.quaternion.clone().toArray());
      group.quaternion.multiply(tiltQuaternion);
    },
    syncPosition: (position: THREE.Vector3, camera: THREE.OrthographicCamera) => null,
  };
}

export default renderGrid;

