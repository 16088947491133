import { useState } from 'react';
import { IconType } from '../../@types/Icon';
import PointClassStyles from '../../@types/PointClassStyles';
import Icon from '../Icon';
import ColorPicker from './ColorPicker';
import ColorPickerConfigItems from './ColorPickerConfigButtons';
import ColorPickerInput from './ColorPickerInput';

interface Props {
  toggleColorPicker: React.Dispatch<React.SetStateAction<boolean>>
  configItems: Record<string, string>
  setConfigItems: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

const ColorPickerContainer = ({ toggleColorPicker, configItems, setConfigItems }: Props) => {
  const [selectedConfigItem, setSelectedConfigItem] = useState<keyof PointClassStyles>('canopy');

  const setColorForSelectedConfigItem = (color: string) => {
    setConfigItems((prevState) => {
      const newConfigItems = Object.assign({}, prevState);
      newConfigItems[selectedConfigItem] = color;

      localStorage.setItem('pointcloud-colors', JSON.stringify(newConfigItems))

      return newConfigItems;
    });
  };

  return (
    <div className='cp-viewport-settings'>
      <div className='cp-upper'>
        <div className='cp-title'>Viewport Color Settings</div>
        <div className='cp-remove' onClick={() => toggleColorPicker(false)}>
          <Icon icon={'removeX' as IconType} />
        </div>
      </div>
      <div className='cp-work-area'>
        <div className='cp-picker'>
          <div className='cp-grad'>
            <ColorPicker color={configItems[selectedConfigItem]} onColorChange={setColorForSelectedConfigItem} />
          </div>
        </div>
        <div className='cp-right-side'>
          <ColorPickerConfigItems
            setSelectedConfigItem={(item) => setSelectedConfigItem(item as keyof PointClassStyles)}
            configItems={configItems}
          />
          <div className='cp-hexinput-save'>
            <div>
              <ColorPickerInput color={configItems[selectedConfigItem]} onColorChange={setColorForSelectedConfigItem} />
            </div>
            <div className='cp-ok-button' onClick={() => toggleColorPicker(false)}>
              <div className='cp-ok-svg'>
                <div className='cp-ticket-check'>
                  <Icon icon={'ticketCheck' as IconType} color={'#494B4D'} />
                </div>
              </div>
              <div className='cp-ok-title'>OK</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ColorPickerContainer;
