const NoResult = () => (
  <div
    style={{
      position: 'absolute',
      top: '20px',
      left: '50%',
    }}
  >
    No results...
  </div>
);

export default NoResult;
