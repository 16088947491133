import React from 'react';

const Question = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="Layer_2" data-name="Layer 2">
      <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1,18.74a1.37,1.37,0,0,1-.33.48,1.35,1.35,0,0,1-.48.32,1.59,1.59,0,0,1-1.21,0,1.49,1.49,0,0,1-.49-.32,1.23,1.23,0,0,1-.32-.48,1.56,1.56,0,0,1,0-1.2,1.34,1.34,0,0,1,.32-.49,1.66,1.66,0,0,1,.49-.32,1.59,1.59,0,0,1,1.21,0,1.49,1.49,0,0,1,.48.32,1.51,1.51,0,0,1,.33.49,1.56,1.56,0,0,1,0,1.2ZM15.7,9.5a3.8,3.8,0,0,1-.56,1,4.18,4.18,0,0,1-.74.72l-.74.57a7.06,7.06,0,0,0-.62.53,1.1,1.1,0,0,0-.31.58l-.23,1.48H10.79l-.17-1.65a.28.28,0,0,1,0-.09v-.08a1.35,1.35,0,0,1,.22-.78,3.19,3.19,0,0,1,.56-.61,8.61,8.61,0,0,1,.72-.57,8.06,8.06,0,0,0,.72-.59,3,3,0,0,0,.55-.72,1.93,1.93,0,0,0,.22-.95,1.56,1.56,0,0,0-.13-.66,1.51,1.51,0,0,0-.38-.49,1.64,1.64,0,0,0-.58-.32,2.21,2.21,0,0,0-.74-.11,3.08,3.08,0,0,0-1.65.41,5.3,5.3,0,0,0-.47.28.72.72,0,0,1-.35.13.53.53,0,0,1-.52-.31l-.66-1a9.26,9.26,0,0,1,.75-.58,5.38,5.38,0,0,1,.89-.47,5.17,5.17,0,0,1,1-.32A5.68,5.68,0,0,1,12,4.71,5.09,5.09,0,0,1,13.6,5a3.74,3.74,0,0,1,1.24.7,3.12,3.12,0,0,1,.8,1.09,3.43,3.43,0,0,1,.28,1.42A3.62,3.62,0,0,1,15.7,9.5Z"/>
    </g>
  </svg>
  )
}

export default Question;