export const ClassOptionConfig = [
  { label: "Trunk (20)", value: 20 },
  { label: "Canopy (21)", value: 21 },
  { label: "Other Trunk (22)", value: 22 },
  { label: "Other Canopy (23)", value: 23 },
  { label: "Vegetation (1)", value: 1 },
  { label: "Background (0)", value: 0 },
  { label: "Ignore (30)", value: 30 },
  { label: "Hanging in Trunk (24)", value: 24 },
  { label: "Hanging in Canopy (25)", value: 25 },
  { label: "Roots (26)", value: 26 },
  { label: "Ground (5)", value: 5 },
  { label: "Building (6)", value: 6 },
  { label: "Hanging Poles (7)", value: 7 },
  { label: "Tree Pole (8)", value: 8 },
  { label: "Other Poles (9)", value: 9 },
];

type Props = {
  value: number;
  label: string;
  onSelect: (value: number) => void;
  active: boolean;
};

const ClassOption = ({ value, label, onSelect, active }: Props) => {
  const _handleSelect = () => onSelect(value);

  return (
    <div
      className={`option-wrapper ${active ? "active" : ""}`}
      onClick={_handleSelect}
    >
      <span className="label">{label}</span>
    </div>
  );
};

export default ClassOption;
