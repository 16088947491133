import { useState, useEffect } from 'react';

const useActionStack = (onUndo) => {
  /*
   { action: string, target, payload: { from: Object, to: Object }}
  */
  const [stack, setStack] = useState([]);

  const _handleAction = (action, target, from, to) => {
    setStack((prevStack) => [...prevStack, { action, target, from, to }]);
  };

  const _handleUndo = () => {
    setStack((prevStack) => {
      const newStack = [...prevStack];
      const undoneAction = newStack.pop();

      if (!undoneAction) return;

      onUndo(
        undoneAction.action,
        undoneAction.target,
        undoneAction.from,
        undoneAction.to
      );

      return newStack;
    });
  };

  const _handleKey = (e) => {
    if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      _handleUndo();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', _handleKey);
    return () => window.removeEventListener('keydown', _handleKey);
  }, [stack?.length]);

  return {
    isUndoAvailable: stack?.length > 0,
    stack,
    onAction: _handleAction,
    onUndo: _handleUndo,
  };
};

export default useActionStack;
