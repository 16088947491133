import { ThemeOptions } from '@mui/material/styles';

const defaultThemeOptions = {
  typography: {
    fontFamily: 'Inter',
    fontSize: 13,
    fontWeight: 400,
  },
  palette: {
    primary: {
      main: '#ffc410',
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    ...defaultThemeOptions.palette,
    mode: 'dark',
    secondary: {
      main: '#f50057',
    },
    text: {
      secondary: '#6b6b6b',
    },
    background: {
      default: '#202324',
      paper: '#35393A',
    },
    success: {
      main: '#058B62',
    },
    warning: {
      main: '#FFCC00',
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    ...defaultThemeOptions.palette,
    mode: 'light',
    background: {
      paper: '#f2f2f2',
    },
    text: {
      primary: '#333333',
    },
  },
};
