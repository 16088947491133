import {
  BufferGeometry,
  CircleGeometry,
  Group,
  Intersection,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Plane,
  Points,
  Quaternion,
  Scene,
  Vector2,
  Vector3,
} from 'three';

const projectPointsToPlane = (points: Intersection[], plane: Plane) => {
  return points.map((p) => {
    const projectedVector3 = new Vector3();
    plane.projectPoint(p.point, projectedVector3);
    return projectedVector3;
  });
};

const transformPointsToTwoDimensionalPlane = ({ points, plane, scene }: { points: Vector3[]; plane: Plane; scene: Scene }) => {
  const origo2D = points[0];
  const pNormal = new Vector3().copy(origo2D);
  pNormal.add(plane.normal);

  const mRotation = new Matrix4().lookAt(origo2D, pNormal, new Vector3(0, 1, 0));

  // Create a new 2D coordinate system in the plane of the polygon
  const plane2D = new Group();
  scene.add(plane2D);
  plane2D.position.copy(origo2D);
  plane2D.quaternion.copy(new Quaternion().setFromRotationMatrix(mRotation));
  plane2D.updateMatrix();

  // Get the inverse of the planes matrix4 - will use it to rebase points
  // into the new coordinate system
  const inverse = new Matrix4().copy(plane2D.matrix).invert();

  // The polygon's vertices in the new coordinate system
  const polygon2DPoints = points.map((pgp) => {
    const p = new Vector3().copy(pgp);
    p.applyMatrix4(inverse);
    p.set(p.x, p.y, 0);
    return p;
  });

  scene.remove(plane2D);

  return polygon2DPoints;
};

const createTargetPointObject = (point: Vector3, name: string) => {
  const geometry = new BufferGeometry().setFromPoints([point]);
  geometry.computeBoundingSphere();

  const targetObject = new Points(geometry);
  targetObject.name = name;

  return targetObject;
};

type CreateCircleProps = {
  name: string;
  color?: number;
  radius?: number;
  widthSegment?: number;
  heightSegment?: number;
};

const createCircle = ({ name, color = 0xffffff, radius = 0.2, widthSegment = 30, heightSegment = 16 }: CreateCircleProps) => {
  const geometry = new CircleGeometry(radius, widthSegment, heightSegment);
  // geometry.vertices.shift();

  const material = new MeshBasicMaterial({ color, name });

  const circle = new Mesh(geometry, material);
  circle.visible = false;
  circle.name = name;

  return circle;
};

const generateUvsFromFaceVertexes = (faces: Vector2[][], numberOfFaces: number) => {
  const uvs = new Float32Array(numberOfFaces);

  for (let i = 0; i < faces.length; i++) {
    const face = faces[i];
    const a = face[0],
      b = face[1],
      c = face[2];

    uvs[i * 6] = a.x;
    uvs[i * 6 + 1] = a.y;
    uvs[i * 6 + 2] = b.x;
    uvs[i * 6 + 3] = b.y;
    uvs[i * 6 + 4] = c.x;
    uvs[i * 6 + 5] = c.y;
  }

  return uvs;
};

const calculateClippingPlanes = (target: Vector3, lookAtPosition: Vector3, depth: number) => {
  const direction = new Vector3().subVectors(target, lookAtPosition);
  direction.normalize();

  const arbitraryVector = new Vector3(0, 1, 0); // or any other arbitrary vector
  const up = new Vector3().subVectors(arbitraryVector, direction.clone().projectOnVector(arbitraryVector)).normalize();
  const right = new Vector3().crossVectors(direction, up);
  const up2 = new Vector3().crossVectors(right, direction);
  right.normalize();
  up2.normalize();

  const nearPlane = direction.clone().multiplyScalar(-depth).add(target);
  const farPlane = direction.clone().multiplyScalar(depth).add(target);

  const distance = depth; // or any desired distance
  const nearPlaneOffset = right.clone().multiplyScalar(-distance).add(up2.clone().multiplyScalar(-distance));
  const farPlaneOffset = right.clone().multiplyScalar(distance).add(up2.clone().multiplyScalar(distance));

  const nearClipPlane = new Plane().setFromNormalAndCoplanarPoint(direction, nearPlane.clone().add(nearPlaneOffset));
  const farClipPlane = new Plane().setFromNormalAndCoplanarPoint(direction.negate(), farPlane.clone().add(farPlaneOffset));

  return { nearClipPlane, farClipPlane };
};

export {
  calculateClippingPlanes,
  createCircle,
  createTargetPointObject,
  generateUvsFromFaceVertexes,
  projectPointsToPlane,
  transformPointsToTwoDimensionalPlane,
};
