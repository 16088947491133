import React from "react";
import Icon from "./Icon";
import { NavLink } from "react-router-dom";
import Popup from "./Popup";

const Tabs = ({ tabs = [] }) => {
  return (
    <div className="vertical-tabs-wrapper">
      {tabs
        .filter((tab) => tab !== false)
        .map((tab, i) =>
          tab ? <VerticalTab {...tab} key={i} /> : <Spacer key={i} />
        )}
    </div>
  );
};

const Spacer = () => {
  return <div className="tab-spacer"></div>;
};

const VerticalTab = ({
  icon,
  path = "",
  disabled,
  title,
  active,
  onClick = () => {},
  exact,
}) => {
  const _handleAction = (e) => {
    if (path && !path.includes(":")) return;
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <Popup title={title} direction="right">
      <NavLink
        onClick={_handleAction}
        activeClassName="active"
        className={`vertical-tab-wrapper regular-button-states ${
          disabled ? "disabled" : ""
        } ${!path ? "button" : "link"} ${active ? "force-active" : ""}`}
        to={path}
        exact={[...path].pop() === "/" || exact}
      >
        <Icon icon={icon} />
      </NavLink>
    </Popup>
  );
};

export default Tabs;
