import { useState, useEffect, useCallback } from 'react';
import proj4 from 'proj4';

/** A mediator between Map and Panorama:
 *  updates cameraPosition for Map, when Panorama calls the handlePanoramicViewChange callback.
 * 
 * @param {*} mapRef 
 */
export const usePanoramaChangeOnMap = (mapRef) => {
  const [cameraPosition, setCameraPosition] = useState(null);

  const handlePanoramicViewChange = useCallback((pos) => {
    if (pos.position.x && pos.position.y) {
      const coords = proj4('localProjection', 'EPSG:4326', [
        pos.position.x,
        pos.position.y,
      ]);

      setCameraPosition({ ...pos, coords });
    }
  }, []);

  useEffect(() => {
    if (cameraPosition?.coords) {
      mapRef.current?.focusOnPoint(cameraPosition.coords || []);
    }
  }, [cameraPosition?.coords, mapRef]);

  return { cameraPosition, handlePanoramicViewChange };
};
