import { Config } from '../../@types/Config';
import { useConfig } from '../../providers/config';

const Status = ({ status }: { status?: string }) => {
  const { getConfig } = useConfig() as Config;
  const localStatus = getConfig(`statuses.${status}`);

  return (
    <div className='status-wrapper'>
      <span className='label'>{localStatus?.label || status}</span>
    </div>
  );
};

export default Status;
