const Map = ({ width = 15, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.5 12.6667L3.02667 13.8247C2.96649 13.8448 2.90239 13.8502 2.83967 13.8407C2.77695 13.8311 2.71741 13.8068 2.66595 13.7696C2.6145 13.7325 2.57262 13.6837 2.54376 13.6272C2.51491 13.5707 2.49991 13.5081 2.5 13.4447V3.622C2.50002 3.53809 2.52642 3.45631 2.57547 3.38824C2.62453 3.32016 2.69374 3.26924 2.77333 3.24267L6.5 2M6.5 12.6667L10.5 14M6.5 12.6667V2M10.5 14L14.2267 12.758C14.3064 12.7314 14.3757 12.6804 14.4247 12.6122C14.4738 12.5439 14.5001 12.462 14.5 12.378V2.55467C14.5 2.49128 14.4849 2.4288 14.456 2.37238C14.4271 2.31596 14.3852 2.26722 14.3338 2.23017C14.2823 2.19313 14.2228 2.16883 14.1602 2.1593C14.0975 2.14976 14.0335 2.15526 13.9733 2.17533L10.5 3.33333M10.5 14V3.33333M10.5 3.33333L6.5 2'
        strokeWidth='0.686667'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Map;
