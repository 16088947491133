import React, { PropsWithChildren } from 'react';
import { LoaderWrapperParams } from '../@types/LoaderWrapper';
import Loader from '../components/Loader';

const LoaderWrapper: React.FC<PropsWithChildren<LoaderWrapperParams>> = ({ children, loading = true, customLoader }) => {
  return (
    <div className='loader-container-wrapper'>
      <div className={`overlay ${loading ? 'loading' : 'hidden'}`}>{customLoader ? customLoader : <Loader />}</div>
      {children}
    </div>
  );
};

export default LoaderWrapper;
