const QuestionMark = ({ height = 20, width = 20, color = '#fff' }) => {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" >
    <g clipPath="url(#clip0_1210_618)">
      <path d="M10 18.3333C14.6025 18.3333 18.3333 14.6025 18.3333 9.99996C18.3333 5.39746 14.6025 1.66663 10 1.66663C5.39751 1.66663 1.66667 5.39746 1.66667 9.99996C1.66667 14.6025 5.39751 18.3333 10 18.3333Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 7.5C7.5 4.58333 12.0833 4.58333 12.0833 7.5C12.0833 9.58333 10 9.16667 10 11.6667M10 15.0083L10.0083 14.9992" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1210_618">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg >;
};

export default QuestionMark;