const Voxel = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.5001 9.1222V12.795C15.5001 12.8374 15.4884 12.8789 15.4661 12.9154C15.4438 12.9519 15.4119 12.982 15.3735 13.0025L11.9308 14.8464C11.8943 14.8659 11.8533 14.8762 11.8116 14.8762C11.7698 14.8762 11.7288 14.8659 11.6923 14.8464L8.24969 13.0025C8.21129 12.982 8.1793 12.9519 8.15703 12.9154C8.13476 12.8789 8.12303 12.8374 8.12305 12.795V9.1222C8.1231 9.07993 8.13487 9.03844 8.15713 9.00204C8.1794 8.96563 8.21135 8.93562 8.24969 8.91512L11.6923 7.0708C11.7288 7.05129 11.7698 7.04105 11.8116 7.04105C11.8533 7.04105 11.8943 7.05129 11.9308 7.0708L15.3735 8.91512C15.4118 8.93562 15.4437 8.96563 15.466 9.00204C15.4883 9.03844 15.5 9.07993 15.5001 9.1222V9.1222Z'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.33936 9.09888L11.6918 10.8946C11.7284 10.9142 11.7695 10.9245 11.8113 10.9245C11.8531 10.9245 11.8942 10.9142 11.9307 10.8946L15.2951 9.09256M11.8115 14.5153V10.9586'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6849 10.8911L8.48821 9.17834C8.45077 9.15832 8.40855 9.14807 8.36574 9.14861C8.32293 9.14915 8.281 9.16046 8.24412 9.18142C8.20723 9.20237 8.17665 9.23226 8.15542 9.26811C8.13418 9.30396 8.12303 9.34453 8.12305 9.38582V12.7951C8.12303 12.8374 8.13476 12.879 8.15703 12.9154C8.1793 12.9519 8.21129 12.982 8.24969 13.0025L11.4464 14.7149C11.4839 14.7349 11.5261 14.7451 11.5689 14.7446C11.6117 14.7441 11.6536 14.7328 11.6905 14.7118C11.7274 14.6908 11.758 14.661 11.7792 14.6251C11.8004 14.5893 11.8116 14.5487 11.8116 14.5074V11.0981C11.8115 11.0559 11.7998 11.0144 11.7775 10.978C11.7552 10.9416 11.7233 10.9116 11.6849 10.8911V10.8911Z'
        strokeWidth='0.5'
        strokeLinejoin='round'
      />
      <path
        d='M11.8114 3.08115V6.75399C11.8114 6.79632 11.7997 6.83789 11.7774 6.87437C11.7551 6.91086 11.7231 6.94093 11.6847 6.96146L8.24211 8.80538C8.20562 8.8249 8.16458 8.83514 8.12285 8.83514C8.08112 8.83514 8.04008 8.8249 8.00359 8.80538L4.56097 6.96146C4.52257 6.94093 4.49058 6.91086 4.46831 6.87437C4.44604 6.83789 4.4343 6.79632 4.43433 6.75399V3.08115C4.43438 3.03888 4.44615 2.9974 4.46841 2.96099C4.49068 2.92458 4.52263 2.89457 4.56097 2.87408L8.00359 1.02975C8.04008 1.01024 8.08112 1 8.12285 1C8.16458 1 8.20562 1.01024 8.24211 1.02975L11.6847 2.87408C11.7231 2.89457 11.755 2.92458 11.7773 2.96099C11.7996 2.9974 11.8113 3.03888 11.8114 3.08115V3.08115Z'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.87705 9.24601V12.9188C7.87707 12.9612 7.86534 13.0027 7.84307 13.0392C7.8208 13.0757 7.7888 13.1058 7.75041 13.1263L4.30779 14.9702C4.2713 14.9898 4.23025 15 4.18852 15C4.1468 15 4.10575 14.9898 4.06926 14.9702L0.626639 13.1263C0.588245 13.1058 0.556251 13.0757 0.533982 13.0392C0.511712 13.0027 0.499979 12.9612 0.5 12.9188V9.24601C0.500052 9.20374 0.51182 9.16225 0.534086 9.12585C0.556351 9.08944 0.588306 9.05943 0.626639 9.03893L4.06926 7.19461C4.10575 7.1751 4.1468 7.16486 4.18852 7.16486C4.23025 7.16486 4.2713 7.1751 4.30779 7.19461L7.75041 9.03893C7.78874 9.05943 7.8207 9.08944 7.84296 9.12585C7.86523 9.16225 7.877 9.20374 7.87705 9.24601V9.24601Z'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.65088 3.05784L8.00334 4.85355C8.03988 4.87314 8.081 4.88341 8.12281 4.88341C8.16461 4.88341 8.20573 4.87314 8.24227 4.85355L11.6066 3.05151M8.12301 8.47422V4.91757'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.716309 9.22269L4.06877 11.0184C4.10531 11.038 4.14643 11.0483 4.18824 11.0483C4.23004 11.0483 4.27116 11.038 4.3077 11.0184L7.67205 9.21637M4.18844 14.6391V11.0824'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99621 4.84999L4.79949 3.13727C4.76205 3.11724 4.71983 3.10699 4.67702 3.10753C4.63421 3.10807 4.59228 3.11938 4.55539 3.14034C4.51851 3.1613 4.48793 3.19118 4.4667 3.22703C4.44546 3.26288 4.43431 3.30345 4.43433 3.34474V6.75399C4.4343 6.79632 4.44604 6.83789 4.46831 6.87437C4.49058 6.91086 4.52257 6.94093 4.56097 6.96146L7.75769 8.67379C7.79513 8.69381 7.83735 8.70406 7.88016 8.70352C7.92297 8.70298 7.96489 8.69167 8.00178 8.67072C8.03867 8.64976 8.06924 8.61988 8.09048 8.58403C8.11171 8.54818 8.12287 8.5076 8.12285 8.46632V5.05707C8.1228 5.0148 8.11103 4.97332 8.08877 4.93691C8.0665 4.9005 8.03455 4.87049 7.99621 4.84999V4.84999Z'
        strokeWidth='0.5'
        strokeLinejoin='round'
      />
      <path
        d='M4.06189 11.0148L0.865164 9.30212C0.827721 9.2821 0.785505 9.27185 0.742693 9.27239C0.699881 9.27293 0.657957 9.28424 0.621069 9.30519C0.58418 9.32615 0.553607 9.35604 0.532372 9.39189C0.511138 9.42773 0.499979 9.46831 0.5 9.50959V12.9188C0.499979 12.9612 0.511712 13.0027 0.533982 13.0392C0.556251 13.0757 0.588245 13.1058 0.626639 13.1263L3.82336 14.8386C3.8608 14.8587 3.90302 14.8689 3.94583 14.8684C3.98864 14.8678 4.03057 14.8565 4.06746 14.8356C4.10434 14.8146 4.13492 14.7847 4.15615 14.7489C4.17739 14.713 4.18855 14.6725 4.18852 14.6312V11.2219C4.18847 11.1797 4.1767 11.1382 4.15444 11.1018C4.13217 11.0654 4.10022 11.0353 4.06189 11.0148V11.0148Z'
        strokeWidth='0.5'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Voxel;
