import { lens, withLenses } from '@dhmk/zustand-lens';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import createActionSlices, { ActionSlices } from './ActionSlices';
import createMarkErrorSlice, { MarkErrorSlice } from './MarkErrorSlice';
import createPanoramaSlice, { PanoramaSlice } from './PanoramaSlice';
import createPointCloudSlice, { PointCloudSlice } from './PointCloudSlice';
import createReclassifySlice, { ReclassifySlice } from './ReclassifySlice';
import createToastSlice, { ToastSlice } from './ToastSlice';
import createTreeSlice, { TreeSlice } from './TreeSlice';
import createValidationSlice, { ValidationSlice } from './ValidationSlice';

export type BoundStoreType = {
  markError: MarkErrorSlice;
  reclassify: ReclassifySlice;
  pointCloud: PointCloudSlice;
  tree: TreeSlice;
  validation: ValidationSlice;
  actions: ActionSlices;
  panorama: PanoramaSlice;
  toast: ToastSlice;
};

const useStore = create<BoundStoreType>()(
  withLenses({
    markError: lens<MarkErrorSlice>((...a) => createMarkErrorSlice(...a)),
    reclassify: lens<ReclassifySlice>((...a) => createReclassifySlice(...a)),
    pointCloud: lens<PointCloudSlice>((...a) => createPointCloudSlice(...a)),
    tree: lens<TreeSlice>((...a) => createTreeSlice(...a)),
    validation: lens<ValidationSlice>((...a) => createValidationSlice(...a)),
    actions: lens<ActionSlices>((...a) => createActionSlices(...a)),
    panorama: lens<PanoramaSlice>((...a) => createPanoramaSlice(...a)),
    toast: lens<ToastSlice>((...a) => createToastSlice(...a)),
  })
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useStore);
}

export default useStore;
