import { Lens } from '@dhmk/zustand-lens';
import produce from 'immer';
import { BufferAttribute, Vector3 } from 'three';
import PointClassStyles, { StyledPointClass } from '../@types/PointClassStyles';
import ReclassEvent from '../@types/ReclassEvent';
import TreePointCloud from '../@types/TreePointCloud';
import { writeColor } from '../utils/pointCloudUtils';

const DEFAULT_SECTION_DEPTH = 0.15;

//TODO Determine LAS type
export type PointCloudSlice = {
  laz: TreePointCloud | null;
  originalLaz: TreePointCloud | null;
  envLaz: TreePointCloud | null;
  isEnvVisible: boolean;
  isEnvNotFound: boolean;
  pointClassStyles: PointClassStyles;
  pointSize: number;
  section: {
    depth: number;
    height: number;
    visible: boolean;
    lookAt: Vector3 | null;
    lookFrom: Vector3 | null;
    defaultTarget: Vector3 | null;
    defaultNormal: Vector3 | null;
    target: Vector3 | null;
    normal: Vector3 | null;
    isSetToDefaultTarget: boolean;
  };

  actions: {
    setEnvVisible: (isVisible: boolean) => void;
    setEnvLaz: (las: TreePointCloud | null) => void;
    setOriginalLaz: (las: TreePointCloud | null) => void;
    setLaz: (las: TreePointCloud | null) => void;
    setGeometry: (data: ReclassEvent, pushToEventStack: (data: ReclassEvent) => void) => void;
    setEnvNotFound: (state: boolean) => void;
    setToOriginal: () => void;
    setClassColor: (pclass: keyof typeof StyledPointClass, color: string) => void;
    setClassOpacity: (pclass: keyof typeof StyledPointClass, opacity: boolean) => void;
    setPointSize: (ps: number) => void;
    section: {
      setSectionDepth: (depth: number) => void;
      setSectionHeight: (height: number) => void;
      setVisible: (visible: boolean) => void;
      setLookAt: (at: Vector3) => void;
      setLookFrom: (from: Vector3) => void;
      setTarget: (target: Vector3) => void;
      setNormal: (normal: Vector3) => void;
      setDefaultTarget: (target: Vector3) => void;
      setDefaultNormal: (normal: Vector3) => void;
      resetSectionTargetToDefault: () => void;
      setDefaultDepth: () => void;
    };
  };
};

const createPointCloudSlice: Lens<PointCloudSlice> = (set, get) => ({
  laz: null,

  originalLaz: null,

  envLaz: null,

  isEnvVisible: false,

  isEnvNotFound: false,

  pointClassStyles: {
    trunk: {
      color: '#795548',
      opacity: true,
    },
    otherTrunk: {
      color: '#0000ff',
      opacity: true,
    },
    canopy: {
      color: '#48BB78',
      opacity: true,
    },
    otherCanopy: {
      color: '#b801b9',
      opacity: true,
    },
    environment: {
      color: '#818385',
      opacity: true,
    },
  },

  section: {
    depth: DEFAULT_SECTION_DEPTH,
    height: 1,
    visible: false,
    lookAt: null,
    lookFrom: null,
    defaultTarget: null,
    defaultNormal: null,
    target: null,
    normal: null,
    isSetToDefaultTarget: true,
  },

  pointSize: 1,

  sectionHeight: 1,

  sectionDepth: 0.2,

  actions: {
    setEnvVisible: (isVisible: boolean) => set({ isEnvVisible: isVisible }),

    setEnvLaz: (envLaz: TreePointCloud | null) => set({ envLaz }),

    setOriginalLaz: (originalLaz: TreePointCloud | null) => set({ originalLaz }),

    setLaz: (laz: TreePointCloud | null) => set({ laz }),

    setGeometry: ({ geometry, changes }, pushToEventStack: (data: ReclassEvent) => void) => {
      if (!changes.indices.length) return;

      set((state) =>
        produce(state, (draft) => {
          if (!draft.laz) return;
          draft.laz.geometry = geometry;
        })
      );
      pushToEventStack({ geometry, changes });
    },

    setEnvNotFound: (state) => set({ isEnvNotFound: state }),
    setToOriginal: () => set({ laz: get().originalLaz }),

    setClassColor: (pclass, color) => {
      const newLazColors = writeColor(get().laz!, color, pclass);
      get().laz?.geometry.setAttribute('color', new BufferAttribute(newLazColors, 3));

      const envLAz = get().envLaz;
      if (envLAz) {
        const newEnvColors = writeColor(envLAz, color, pclass);
        envLAz.geometry.setAttribute('color', new BufferAttribute(newEnvColors, 3));
      }

      set((s) =>
        produce(s, (draft) => {
          draft.pointClassStyles[pclass].color = color;
        })
      );
    },

    setClassOpacity: (pclass, opacity) =>
      set((s) =>
        produce(s, (draft) => {
          const laz = get().laz;
          const oldVisibilities = laz!.geometry.getAttribute('visibility').array;
          const newVisibilities = new Float32Array(laz?.pc?.pointCount || oldVisibilities.length);
          const classifications = laz!.geometry.getAttribute('classification').array;
          for (const [index, pointClass] of (classifications as any).entries()) {
            if (StyledPointClass[pclass] === pointClass) {
              newVisibilities[index] = opacity ? 1 : 0;
            } else {
              newVisibilities[index] = oldVisibilities[index];
            }
          }
          laz?.geometry.setAttribute('visibility', new BufferAttribute(newVisibilities, 1));
          draft.pointClassStyles[pclass].opacity = opacity;
        })
      ),

    setPointSize: (pointSize) => set({ pointSize: pointSize }),

    section: {
      setSectionDepth: (depth) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.depth = depth;
          })
        ),
      setSectionHeight: (height) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.height = height;
          })
        ),
      setVisible: (visible) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.visible = visible;
          })
        ),
      setLookAt: (at) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.lookAt = at;
          })
        ),
      setLookFrom: (from) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.lookFrom = from;
          })
        ),
      setTarget: (target) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.target = new Vector3().copy(target);
            draft.section.isSetToDefaultTarget = false;
          })
        ),
      setNormal: (normal) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.normal = new Vector3().copy(normal);
          })
        ),
      setDefaultTarget: (target) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.defaultTarget = new Vector3().copy(target);
            draft.section.isSetToDefaultTarget = false;
          })
        ),
      setDefaultNormal: (normal) =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.defaultNormal = new Vector3().copy(normal);
          })
        ),
      setDefaultDepth: () =>
        set((s) =>
          produce(s, (draft) => {
            draft.section.depth = DEFAULT_SECTION_DEPTH;
          })
        ),
      resetSectionTargetToDefault: () => {
        set((s) =>
          produce(s, (draft) => {
            if (draft.section.isSetToDefaultTarget) return;
            draft.section.target = new Vector3().copy(draft.section.defaultTarget ?? new Vector3(0, 0, 0));
            draft.section.normal = new Vector3().copy(draft.section.defaultNormal ?? new Vector3(0, 0, 1));
            draft.section.isSetToDefaultTarget = true;
          })
        );
      },
    },
  },
});

export default createPointCloudSlice;
