import { IconElementParams } from '../../@types/Icon';

const SectionView = ({ color }: IconElementParams) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.55773 11.5536L3.3313 12.0902L2.38086 12.506L3.3313 12.9219L9.99991 15.8393L16.2419 12.9265L17.1428 12.506L16.2419 12.0856L15.1019 11.5536L14.2011 11.9741L15.3411 12.506L9.98981 15.0033L4.28173 12.506L5.50816 11.9695L4.55773 11.5536Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.55773 9.64886L3.3313 10.1854L2.38086 10.6012L3.3313 11.0172L9.99991 13.9346L16.2419 11.0217L17.1428 10.6012L16.2419 10.1809L15.1019 9.64886L14.2011 10.0693L15.3411 10.6012L9.98981 13.0986L4.28173 10.6012L5.50816 10.0648L4.55773 9.64886Z'
        fill={color}
      />
      <path
        d='M9.99486 11.6118L3.3313 8.69652L9.99486 5.78122L16.2419 8.69652L9.99486 11.6118Z'
        stroke={color}
        strokeWidth='0.8'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default SectionView;
