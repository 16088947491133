const config = {
  frustumSize: 12,

  minZoomDefault: 20,

  maxZoomDefault: 100,

  defaultCameraDistance: 2,

  nearPlane: -1000,

  farPlane: 1000,

  rotateSpeed: 1,

  errorColor: 0xff0000,

  reclassifyColor: 0xf2c200,

  pointSizeScaleFactor: 1 / 50,

  heightLineWidth: 0.1,

  minHandleSize: 0.2,

  maxHandleSize: 1,

  isAxesHelperVisible: localStorage.getItem('isAxesHelperVisible') === '1',
};

export default config;
