import React from "react";

const Greehill = ({ width = 25, height = 25 }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M9.99605 21.3479C15.5167 21.3479 19.9921 16.8725 19.9921 11.3519C19.9921 5.83122 15.5167 1.35583 9.99605 1.35583C4.47538 1.35583 0 5.83122 0 11.3519C0 16.8725 4.47538 21.3479 9.99605 21.3479Z"
        fill="#058C62"
      />
      <path
        d="M16.125 6.49619H18.2286V14.444C18.2286 15.733 17.8016 16.7216 16.9554 17.4017C16.1013 18.0818 15.0732 18.4218 13.8791 18.4218C12.9775 18.4218 12.1867 18.2479 11.4987 17.9078C10.8107 17.5678 10.2887 17.0537 9.92495 16.3894L11.7597 15.3376C12.1788 16.1126 12.9143 16.508 13.9503 16.508C14.6304 16.508 15.1602 16.3262 15.5556 15.9703C15.951 15.6144 16.1408 15.1083 16.1408 14.4519V13.5424C15.5082 14.3886 14.6146 14.8078 13.4679 14.8078C12.2895 14.8078 11.301 14.3965 10.5023 13.5662C9.70352 12.7358 9.30811 11.7235 9.30811 10.5373C9.30811 9.35898 9.71143 8.34672 10.5102 7.51635C11.3089 6.67808 12.2974 6.26685 13.4679 6.26685C14.6146 6.26685 15.5082 6.68598 16.1408 7.53217V6.49619H16.125ZM12.1076 12.1743C12.5505 12.6014 13.1041 12.8149 13.7842 12.8149C14.4643 12.8149 15.0258 12.6014 15.4607 12.1743C15.9036 11.7473 16.1171 11.2016 16.1171 10.5294C16.1171 9.87301 15.8957 9.32734 15.4607 8.9003C15.0258 8.48116 14.4643 8.25973 13.7842 8.25973C13.1041 8.25973 12.5426 8.47325 12.1076 8.9003C11.6648 9.32734 11.4512 9.87301 11.4512 10.5294C11.4512 11.2016 11.6648 11.7473 12.1076 12.1743Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" transform="translate(0 0.651978)" />
      </clipPath>
    </defs>
  </svg>
);

export default Greehill;
