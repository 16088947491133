import { useState } from 'react';

const colorNameMap: Record<string, string> = {
  canopy: 'Canopy',
  trunk: 'Trunk',
  environment: 'Background',
  otherCanopy: 'Other cano',
  otherTrunk: 'Other trunk',
};

const ColorPickerConfigItem = ({
  onClikcAction,
  configItem,
  color,
  active,
}: {
  onClikcAction: any;
  configItem: string;
  color: string;
  active: boolean;
}) => {
  const handleClick = () => {
    onClikcAction(configItem);
  };

  return (
    <div className={`cp-config-item ${active ? 'active' : ''}`} onClick={() => handleClick()}>
      <div
        className='cp-color-box'
        style={{
          background: color,
        }}
      ></div>
      <div className='cp-title'>{colorNameMap[configItem]}</div>
    </div>
  );
};

const ColorPickerConfigItems = ({
  setSelectedConfigItem,
  configItems,
}: {
  setSelectedConfigItem: React.Dispatch<React.SetStateAction<string>>;
  configItems: { [key: string]: string };
}) => {
  const [activeConfigItem, setActiveConfigItem] = useState<string>(Object.keys(configItems)[0]);

  const onConfigItemSelection = ({ configItem }: { configItem: string }) => {
    setSelectedConfigItem(configItem);
    setActiveConfigItem(configItem);
  };

  return (
    <div className='cp-config-items'>
      {Object.entries(configItems).map(([key], index) => (
        <div key={index} style={{ cursor: 'pointer' }}>
          <ColorPickerConfigItem
            onClikcAction={() => onConfigItemSelection({ configItem: key })}
            configItem={key}
            color={configItems[key]}
            active={activeConfigItem === key ? true : false}
          />
        </div>
      ))}
    </div>
  );
};

export default ColorPickerConfigItems;
