import { useReducer } from 'react';

/**
 * A custom hook for fade-in/fade-out animation.
 *
 * @returns {{isMounted: boolean, isVisible: boolean, startFadeIn: function, startFadeOut: function}}
 */
export const useFadeInOut = (animationTimeout = 500) => {
  const reducer = (state, action) => {
    let newState = null;

    switch (state) {
      case 'not-mounted':
        if (action === 'start') {
          newState = 'fade-in-started';
          setTimeout(() => dispatch('finish-fade-in'), 50);
        }
        break;
      case 'fade-in-started':
        // any action forwards to done, to avoid deadlock
        newState = 'visible';
        break;
      case 'visible':
        if (action === 'fade-out') {
          newState = 'fade-out-started';
          setTimeout(() => dispatch('finish-fade-out'), animationTimeout);
        }
        break;
      case 'fade-out-started':
        if (action === 'start') {
          // restart by unmounting first in order to ensure autofocus
          newState = 'not-mounted';
          setTimeout(() => dispatch('start'), 50);
        } else {
          // any action forwards to done, to avoid deadlock
          newState = 'not-mounted';
        }
        break;
      default:
        break;
    }

    return newState || state;
  };

  const [state, dispatch] = useReducer(reducer, 'not-mounted');

  return {
    isVisible: state === 'visible',
    isMounted: state !== 'not-mounted',
    startFadeIn: () => dispatch('start'),
    startFadeOut: () => dispatch('fade-out'),
  };
};
