import React from 'react';

const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>rtms</title>
      <g id="Layer_2" data-name="Layer 2">
        <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1.26,19.51H10.75V9.13h2.51Zm.24-12.8a1.69,1.69,0,0,1-.86.83,1.51,1.51,0,0,1-.63.13,1.39,1.39,0,0,1-.61-.13,1.58,1.58,0,0,1-.5-.34,1.39,1.39,0,0,1-.34-.49,1.42,1.42,0,0,1-.13-.61,1.51,1.51,0,0,1,.13-.63A1.57,1.57,0,0,1,10.9,5a1.56,1.56,0,0,1,.5-.33A1.39,1.39,0,0,1,12,4.5a1.51,1.51,0,0,1,.63.13,1.72,1.72,0,0,1,.51.33,2,2,0,0,1,.35.51,1.51,1.51,0,0,1,.13.63A1.42,1.42,0,0,1,13.5,6.71Z"/>
      </g>
    </svg>
    )
}

export default Info;