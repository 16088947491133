import { useRef, useState } from "react";

/**
 *
 * value: can be either a generic number - for regular slider, or an array containing [min, max] for range
 */
const Slider = ({
  name,
  label,
  valueFormatter,
  onChange = () => {},
  onPureChange = (val) => {},
  min = 0,
  max = 1,
  value = 0.5,
  range = false,
  enableSnapping = false,
  step = 0,
  disabled,
}) => {
  const wrapper = useRef(null);

  const _handleChange = (index) => (val) => {
    if (!range) {
      onChange({ name, value: val });
      onPureChange(val);
    } else {
      const _value = [...value];

      if (index === 1 && val <= _value[0]) {
        val = _value[0];
      } else if (index === 0 && val >= _value[1]) {
        val = _value[1];
      }

      _value[index] = val;
      _value.sort((a, b) => a - b);
      onPureChange(_value);
      onChange({ name, value: _value });
    }
  };

  const val = (value) => (value - min) / (max - min);

  return (
    <div className="slider-outer-wrapper">
      <div className="caption">
        <span className="label">{label}</span>
        <span className="value">
          {range
            ? `${valueFormatter(value[0])} ... ${valueFormatter(value[1])}`
            : valueFormatter(value)}
        </span>
      </div>
      <div className={`slider-input-wrapper ${disabled ? "disabled" : ""}`}>
        <div ref={wrapper} className="line">
          <div
            className="highlight"
            style={{
              left: `${val(range ? Math.min(...value) : min) * 100}%`,
              right: `${(1 - val(range ? Math.max(...value) : value)) * 100}%`,
            }}
          />
          <Knob
            min={min}
            max={max}
            onChange={_handleChange(0)}
            wrapper={wrapper}
            value={range ? value[0] : value}
            label={valueFormatter}
            enableSnapping={enableSnapping}
            step={step}
          />
          {range && (
            <Knob
              min={min}
              max={max}
              onChange={_handleChange(1)}
              wrapper={wrapper}
              value={value[1]}
              label={valueFormatter}
              enableSnapping={enableSnapping}
              step={step}
            />
          )}
        </div>
      </div>
      <div className="labels">
        <span className="label">
          {typeof valueFormatter === "function" ? valueFormatter(min) : min}
        </span>
        <span className="label">
          {typeof valueFormatter === "function" ? valueFormatter(max) : max}
        </span>
      </div>
    </div>
  );
};

const SNAPPING = 0.05;
const limitToMinMax = (x, min, max) => Math.min(max, Math.max(min, x));

const Knob = ({
  label,
  value,
  wrapper,
  onChange,
  min,
  max,
  enableSnapping,
  step,
}) => {
  const [isActive, setIsActive] = useState(false);

  const _handleMouseMove = ({ clientX }) => {
    if (!wrapper.current) return;
    // Getting bounding box of parent
    const { x: xOffset, width } = wrapper.current.getBoundingClientRect();

    // Calculating relative positions, clamping values between 0-1, and applying snappoints
    let x = limitToMinMax((clientX - xOffset) / width, 0, 1);
    if (enableSnapping && x < SNAPPING) x = 0;
    else if (enableSnapping && x > 1 - SNAPPING) x = 1;

    const absolutePosition = min + (max - min) * x;

    const newValue =
      step && absolutePosition < max && absolutePosition > min
        ? limitToMinMax(Math.round(absolutePosition / step) * step, min, max)
        : absolutePosition;

    onChange(newValue);
  };

  const _handleMouseDown = ({ horizontal = false, vertical = false }) => {
    window.addEventListener("mousemove", _handleMouseMove);
    window.addEventListener("mouseup", _handleMouseUp);
    setIsActive(true);
  };
  const _handleMouseUp = () => {
    window.removeEventListener("mousemove", _handleMouseMove);
    window.removeEventListener("mouseup", _handleMouseUp);
    setIsActive(false);
  };

  const val = ((value ?? min) - min) / (max - min);

  return (
    <div
      onMouseDown={_handleMouseDown}
      className={`knob-wrapper ${isActive ? "active" : ""}`}
      style={{ left: `${val * 100}%` }}
    >
      <div className="knob-label">
        {typeof label === "function" ? label(value) : label}
      </div>
      <div className="knob"></div>
    </div>
  );
};

export default Slider;
