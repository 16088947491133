import React, { useEffect, useState } from 'react';

import Segment from '../../components/Segment';
import {
  DropdownInput,
  DateRangePicker,
  Slider,
} from '../../components/inputs';

const CmaFilter = ({
  relevantMAData,
  maFilterArray,
  maFilterState,
  setMaFilterState,

  relevantTreeData,
  treeFilterArray,
  treeFilterState,
  setTreeFilterState,
}) => {
  const onChange = (setState) => (group) => (newValue) => {
    setState((filters) => {
      const newFilter = [...filters];

      const groupToUpdate = newFilter.find((filter) => filter.group === group)
        .inputs;

      const dropdownIndex = groupToUpdate.findIndex(
        (x) => x.label === newValue.label
      );

      groupToUpdate[dropdownIndex].value = newValue.value;

      return newFilter;
    });
  };

  return (
    <div className="sidebar-map-controls-wrapper">
      {maFilterState.map((filterGroup) => (
        <FilterGroup
          key={filterGroup.group}
          {...{
            filterGroup,
            onChange: onChange(setMaFilterState),
            filterArray: maFilterArray,
            relevantData: relevantMAData,
          }}
        />
      ))}
      {treeFilterState.map((filterGroup) => (
        <FilterGroup
          key={filterGroup.group}
          {...{
            filterGroup,
            onChange: onChange(setTreeFilterState),
            filterArray: treeFilterArray,
            relevantData: relevantTreeData,
          }}
        />
      ))}
    </div>
  );
};

const FilterGroup = ({ filterGroup, onChange, filterArray, relevantData }) => {
  return (
    <Segment label={filterGroup.group}>
      {filterGroup.inputs.map((filter) => (
        <FilterInput
          key={filter.label}
          {...{
            filter,
            onChange: onChange(filterGroup.group),
            filterArray,
            relevantData,
          }}
        />
      ))}
    </Segment>
  );
};

const FilterInput = ({ filter, onChange, filterArray, relevantData }) => {
  let component = null;

  switch (filter.type) {
    case 'dropdown':
      component = (
        <DropdownFilter {...{ filter, onChange, filterArray, relevantData }} />
      );
      break;
    case 'daterange':
      component = <DateRangeFilter {...{ filter, onChange, relevantData }} />;
      break;
    case 'range':
      component = <RangeFilter {...{ filter, onChange, relevantData }} />;
      break;
    default:
      break;
  }

  return component;
};

const RangeFilter = ({ filter, onChange, relevantData }) => {
  const [valueLimits, setValueLimits] = useState([null, null]);

  useEffect(() => {
    const values = relevantData.map((x) => x[filter.column]).filter((x) => x);

    const minValue = values.length
      ? values.reduce((prev, current) => (current < prev ? current : prev))
      : 0;

    const maxValue = values.length
      ? values.reduce((prev, current) => (current > prev ? current : prev))
      : 30;

    if (!filter.value[0] || !filter.value[1]) {
      onChange({ ...filter, value: [minValue, maxValue] });
    }

    if (!valueLimits[0]) {
      setValueLimits([minValue, maxValue]);
    }
  }, [relevantData?.length]);

  const _onChange = (newValue) => {
    onChange({ ...filter, value: newValue });
  };

  const formatHeight = (h) => `${(h || 0).toFixed(1)} m`;

  return (
    <Slider
      label={filter.label}
      valueFormatter={formatHeight}
      min={valueLimits[0]}
      max={valueLimits[1]}
      value={filter.value}
      range
      onPureChange={_onChange}
    />
  );
};

const DateRangeFilter = ({ filter, onChange, relevantData }) => {
  const _handleOnChangeFrom = (date) => {
    onChange({ ...filter, value: [date, filter.value[1]] });
  };

  const _handleOnChangeTo = (date) => {
    onChange({ ...filter, value: [filter.value[0], date] });
  };

  useEffect(() => {
    const dates = relevantData.map((x) => x[filter.column]).filter((x) => x);

    if (dates.length && (!filter.value[0] || !filter.value[1])) {
      const minDate = dates.reduce((prev, current) =>
        current < prev ? current : prev
      );
      const maxDate = dates.reduce((prev, current) =>
        current > prev ? current : prev
      );

      onChange({ ...filter, value: [minDate, maxDate] });
    }
  }, [relevantData]);

  return (
    <DateRangePicker
      label={filter.label}
      from={filter.value[0]}
      to={filter.value[1]}
      onChangeFrom={_handleOnChangeFrom}
      onChangeTo={_handleOnChangeTo}
      autoFocus
    />
  );
};

const DropdownFilter = ({ filter, onChange, filterArray, relevantData }) => {
  const filterFunc = generateFilter(filterArray, filter.column);
  const filteredData = relevantData.filter(filterFunc);
  const uniqueOptions = getUniqueValuesFromColumn(filteredData, filter.column);

  const options = uniqueOptions.map((x) =>
    x ? { value: x, label: x } : { value: '', label: `[No ${filter.label}]` }
  );

  options.sort((a, b) => String(a.label).localeCompare(b.label));

  return (
    <DropdownInput
      label={filter.label}
      options={options}
      onChange={onChange}
      value={filter.value}
      clearLabel="[Clear filter]"
      showNumberOfOptions
    />
  );
};

function getUniqueValuesFromColumn(obj, column) {
  const dataset = new Set();

  obj.forEach((x) => dataset.add(x[column]));

  return Array.from(dataset);
}

export const generateFilter = (filterArray, columnToIgnore = null) => {
  const filtersToUse = columnToIgnore
    ? filterArray.filter((filter) => columnToIgnore !== filter.column)
    : filterArray;

  return (obj) =>
    filtersToUse.reduce(
      (prev, filter) => prev && filterOneAttribute(obj, filter),
      true
    );
};

const filterOneAttribute = (obj, filter) => {
  if (filter.type === 'daterange' || filter.type === 'range') {
    const isDisabled = !filter.value[0] || !filter.value[1];
    const hasNoValue = !obj[filter.column];

    return (
      hasNoValue ||
      isDisabled ||
      (obj[filter.column] >= filter.value[0] &&
        obj[filter.column] <= filter.value[1])
    );
  } else {
    return filter.value === null
      ? true
      : filter.value === ''
      ? !obj[filter.column]
      : obj[filter.column] === filter.value;
  }
};

export default CmaFilter;
