import XZYCoords from '../@types/XZYCoords';

const convertXYZToPostGisPoint = <T extends XZYCoords>({ x, y, z }: T) => {
  return JSON.stringify({
    type: 'Point',
    coordinates: [x, y, z],
  });
};

export { convertXYZToPostGisPoint };
