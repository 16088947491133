import Icon from '../Icon';

type CompletedProps = {
  value: boolean;
};

const Completed = ({ value }: CompletedProps) => {
  return value ? (
    <div style={{ display: 'flex' }}>
      <Icon icon={'valid'} />
      <span style={{ marginLeft: '10px' }}>Completed</span>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <Icon icon={'invalid'} />
      <span style={{ marginLeft: '10px' }}>Not completed</span>
    </div>
  );
};

export default Completed;
