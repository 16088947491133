import { MathUtils, Vector3 } from 'three';

const TRUNK_LEANING_DEGREE_CALC_OPTION_DEFAULTS = {
  toFixed: 2,
};

const getTrunkLeaningDegree = (leanBase: Vector3, firstBif: Vector3, options?: { toFixed: number }): string => {
  const newOrig = leanBase.clone();
  newOrig.z += 100; // It can be any positive number to fix a vertical vector
  const vectorAB = new Vector3().subVectors(firstBif, leanBase); // Vector from A to B
  const vectorBC = new Vector3().subVectors(newOrig, leanBase); // Vector from B to C
  const angleRadians = vectorAB.angleTo(vectorBC);
  return MathUtils.radToDeg(angleRadians).toFixed(options?.toFixed ?? TRUNK_LEANING_DEGREE_CALC_OPTION_DEFAULTS.toFixed);
};

export default getTrunkLeaningDegree;
