const MemoryStore = ({ width = 32, height = 32, color = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 25.9921L16 14.5M16 14.5L13 17.5M16 14.5L19 17.5M16 8.38885L16 6.00791M20.2105 8.38885L20.2105 6.00791M23.3684 16.0079L26 16.0079M6 16.0079L8.63158 16.0079M8.63158 16.0079L8.63158 23.0555C8.63158 23.1305 8.64791 23.2048 8.67965 23.2742C8.71139 23.3435 8.75791 23.4065 8.81656 23.4596C8.87521 23.5126 8.94484 23.5547 9.02146 23.5834C9.09809 23.6121 9.18021 23.6269 9.26315 23.6269L11.7895 23.6269M8.63158 16.0079L8.63158 8.96027C8.63158 8.88523 8.64791 8.81093 8.67965 8.7416C8.71139 8.67227 8.75791 8.60927 8.81656 8.55621C8.87521 8.50315 8.94484 8.46106 9.02146 8.43234C9.09809 8.40363 9.18021 8.38885 9.26315 8.38885L11.7895 8.38885M23.3684 21.246L26 21.246M6 21.246L8.63158 21.246M23.3684 10.7698L26 10.7698M6 10.7698L8.63158 10.7698M11.7895 8.38885L22.7368 8.38885C22.9043 8.38885 23.065 8.44905 23.1834 8.55621C23.3019 8.66338 23.3684 8.80872 23.3684 8.96027L23.3684 23.0555C23.3684 23.207 23.3019 23.3524 23.1834 23.4596C23.065 23.5667 22.9043 23.6269 22.7368 23.6269L20.2105 23.6269M11.7895 8.38885L11.7895 6.0079'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MemoryStore;
